/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { postRequest, getRequest } from "../../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import "react-confirm-alert/src/react-confirm-alert.css";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";

const PendingApproval = ({status}) => {
  useEffect(() => {
    document.title = `PeopleSol - Ongoing Prepare PayRoll `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [reqData, setReqData] = useState({
    noticePeriod: null,
    createdOn: null,
    lastUpdatedOn: null,
    status:status,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const [selId, setSelId] = useState(false);
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  const PostApRejHol = async (type, id, Remark,payload) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Approve") {
        await getRequest(`PreparePayroll/Approve/${id}`);
      } else if (type === "Hold") {
        await getRequest(`PreparePayroll/Hold?id=${id}&Remarks=${Remark}`);
      } else {
        await postRequest(`PreparePayroll/SentForCorrection?id=${id}&Remarks=${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      getListData();
    if(isOpen) togglePopup()
    }
  };

  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `PreparePayroll/PayrollApprovalList`,
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              actions: item?.actions,
            })
          );

          const clippedArray = tempData.map(
            ({
              createdBy,
              department,
              designation,
              email,
              employeeId,
              toLocation,

              toLocationName,
              ...rest
            }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              payArea,
              payDate,
              monthPayout,
              employeesCount,
              amount,
              status,
              actions,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              payArea,
              payDate,
              monthPayout,
              employeesCount,
              amount,
              status,
              actions,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          calculateTotalPages(result.data.summary.count);
          setCount(result.data.summary.count);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("payrollapproval"))
      getListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredText]);

  const getFormDetails = (item) => {};

  const actionData = [
    {
      img: null,
      action: "Prepare Payroll",
      clickAction: (item) => getFormDetails(item),
      button: true,
    },
  ];

  const applyFilters = () => {
    setReqData({
      ...reqData,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      noticePeriod: null,
      createdOn: null,
      lastUpdatedOn: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };

  return (
    <div className="fullTableContainer">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              title="Close"
              onClick={() => setFilterSearchToogle((prev) => !prev)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
   
      {loading ? (
        <div className="noDataFound">
          <Loader />
        </div>
      ) : (
        <>
          <div className="boxViewContainer ">
            <div className="homeCardsHolderContainer">
              <div className="homeDashboardCardHolder" key={1}>
                {tbodyData?.length ? (
                  tbodyData?.map((item, index) => {

                    return (
                      <div className="dashboardCard internalJobCard" key={item.id} onClick={(event)=>{
                        event.stopPropagation();
                        navigate('payrollapproval/pending/details',{state:  { id: item.id },})}}
                        >
                        <div className="cardContainer cursor">
                          <div className="headingContainer">
                            <h3
                              style={{
                                fontSize: "16px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.payArea}
                            </h3>
                            <span
                              className="internalJobDateTime"
                              style={{ color: "var(--secondary)" }}
                            >
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    item.status ? item.status : "Pending"
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    item.status ? item.status : "Pending"
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "3px 6px",
                                  width: "fit-content",
                                  borderRadius: "6px",
                                }}
                              >
                                {item.status ? item.status : "Pending"}
                              </div>
                            </span>
                          </div>
                          <div
                            className="jobDetailContainerHolder"
                            style={{ padding: "7px 0px 23px 11px" }}
                          >
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">Pay Date</label>
                              <label>
                                {" "}
                                {item?.payDate &&
                                  format(
                                    new Date(item?.payDate),
                                    "dd/MM/yyyy "
                                  )}
                              </label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Pay Amount
                              </label>
                              <label> {item?.amount}</label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                Pay Month
                              </label>
                              <label> {item?.monthPayout  ?? '-'}</label>
                            </div>
                            <div className="jobDetailContainer">
                              <div className="verticalLine"></div>
                              <label className="jobDesignation">
                                No. of Employee
                              </label>
                              <label>{item?.employeesCount}</label>
                            </div>
                          </div>
                          <div
                            className="applyJobButtonContainer "
                            style={{ flexWrap: "wrap", amrginLeft: "12px" }}
                          >
                            {item?.actions?.map((itemData, index) => {
                              return (
                                <button
                                style={{
                                  backgroundColor: itemData.color,
                                  color:'white',
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                                  onClick={() =>
                                    itemData?.type == "Approve"
                                      ? PostApRejHol(
                                          itemData?.type,
                                          itemData?.id
                                        )
                                      : togglePopup(
                                          itemData?.type,
                                          itemData?.color,
                                          itemData?.id
                                        )
                                  }
                                  className="buttonPrep primaryButton"
                                >
                                  {itemData?.type}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="horizontalLine"></div>
          <div className="tableFooterContainer">
            <label>Total Record Count: </label>
            <span>{count}</span>
            <SelectCustom
              searchable={false}
              options={noOfRecords}
              dropdownPosition="top"
              values={[recordsSelected]}
              onChange={(e) => paginationChanged(e)}
            />
            <label>Records Per Page</label>
            {currentPage > 1 ? (
              <IconButton
                onClick={() => pageChangedPrev()}
                color="primary"
                className="pageChangerIcon pageChangerIconActive"
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton disabled color="primary" className="pageChangerIcon">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
            {currentPage} - {totalPages}
            {totalPages > currentPage ? (
              <IconButton
                onClick={() => pageChangedNext()}
                color="primary"
                className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton
                disabled
                color="primary"
                className={`pageChangerIcon pageChangerIconNext`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
          </div>
        </>
      )}

      {isOpen && (
        <Popup
          popupHeading={
            typeAction == "Sent For Correction"
              ? "Send For Correction"
              : typeAction
          }
          content={
            <>
              <Remark
                loading={loading}
                data={PostApRejHol}
                type={typeAction}
                id={selId}
                color={"red"}
                handleClose={togglePopup}
              />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};
export { PendingApproval };
