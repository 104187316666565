/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { showToast } from "../../../../Features";
import { useState } from "react";
import { getRequest } from "../../../../Services/axios";
import { Loader } from "../../../../Components/FormComponent/Loader";
import "./EmployeePayrollDetails.css";
import {SalaryDetails} from "./SalaryDetails";
import Overview from "./Overview";
import { Loan } from "./Loan";
import { Investments } from "./Investments";
import { PaySlipForm } from "./PaySlipForm";
function EmployeePayrollDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const returnPage = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [tabIndex, setTabIndex] = useState("overview");
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const location=useLocation()
  const { state } = useLocation();
  const editId = state?.id;
  useEffect(() => {
    if (editId) {
    if(!location.pathname.includes('revisesalary'))  {
      getProfileData();
    }
    
    }
  }, [editId]);
  console.log('ed',editId)
  const getProfileData = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`PayrollEmployee/Details/${editId}`);
      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Employee PayRoll Details `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <div
        className="tableHeaderContainer"
        style={{
          padding: "0 12px",
          boxShadow: "0px 1px 6px rgb(0 0 0 / 5%)",
          marginBottom: "6px",
        }}
      >
        <IconButton onClick={returnPage}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.368615 3.35738C0.173352 3.55265 0.173352 3.86923 0.368615 4.06449L3.5506 7.24647C3.74586 7.44173 4.06244 7.44173 4.2577 7.24647C4.45296 7.05121 4.45296 6.73463 4.2577 6.53936L1.42927 3.71094L4.2577 0.88251C4.45296 0.687248 4.45296 0.370666 4.2577 0.175404C4.06244 -0.0198586 3.74586 -0.0198586 3.5506 0.175404L0.368615 3.35738ZM18.749 4.21094C19.0252 4.21094 19.249 3.98708 19.249 3.71094C19.249 3.4348 19.0252 3.21094 18.749 3.21094V4.21094ZM0.722168 4.21094H18.749V3.21094H0.722168V4.21094Z"
              fill="#1296B0"
            />
          </svg>
        </IconButton>
        <h5>{bannerList?.data?.nameWithPersonNumber}</h5>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="preparePayrollMainContainer">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                value={"overview"}
                label={
                  <span
                    className={`tabHeading ${
                      tabIndex === "overview" && "tabActive"
                    }`}
                  >
                    Overview
                  </span>
                }
              />
              <Tab
                value={"salary"}
                label={
                  <span
                    className={`tabHeading ${
                      tabIndex === "salary" && "tabActive"
                    }`}
                  >
                    Salary Details 
                  </span>
                }
              />
              <Tab
                value={"investments"}
                label={
                  <span
                    className={`tabHeading ${
                      tabIndex === "investments" && "tabActive"
                    }`}
                  >
                    Investments
                  </span>
                }
              />
              <Tab
                value={"payslip"}
                label={
                  <span
                    className={`tabHeading ${
                      tabIndex === "payslip" && "tabActive"
                    }`}
                  >
                    Payslip & Forms
                  </span>
                }
              />
              <Tab
                value={"loan"}
                label={
                  <span
                    className={`tabHeading ${
                      tabIndex === "loan" && "tabActive"
                    }`}
                  >
                    Loan 
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine" />
          </div>
          {tabIndex === "overview" && <Overview bannerList={bannerList} />}
          {tabIndex === "salary" && <SalaryDetails employee={bannerList?.data?.nameWithPersonNumber} editId={editId} eId={bannerList?.data?.employeeId} />}
          {tabIndex === "loan" && <Loan empId={bannerList?.data?.employeeId} />}
          {tabIndex === "investments" && <Investments  employeeId={bannerList?.data?.employeeId}/>}
          {tabIndex === "payslip" && <PaySlipForm  employeeId={bannerList?.data?.employeeId}/>}
        </div>
      )}
    </div>
  );
}

export default EmployeePayrollDetails;
