import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { isLoading, showToast } from "../../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../Services/axios";
import * as Yup from "yup";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { format } from "date-fns";
export const SubmitBooking = ({ id, handleClose, getListData }) => {
  const [detailsform, setDetailsform] = useState(false);
  const [formValues, setFormValues] = useState(false);

  useEffect(() => {
    document.title = `PeopleSol - Submit Booking`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const data = id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${data}`
      );
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  console.log("object formValues", formValues);
  useEffect(() => {
    if (data) getFormDetails();
  }, [data]);

  const create = async (values, url) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "TravelHospitalityRequests/UpdateTicketDetails",
        values
      );
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      handleClose();
      getListData();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  // const getInitalValues = () => {
  //   let returnArray = [
  //     {
  //       Id: 0,
  //       date: "",
  //       FromLocation: "",
  //       ToLocation: "",
  //       HotelcheckInTime: "",
  //       HotelcheckOutTime: "",
  //       Hotellocation: "",
  //       HotelpreferHotel: "",

  //       Bookinglist: [
  //         {
  //           IsSelected: false,
  //           Remarks: "",
  //           UploadTD: null,
  //           AmountTD: "",
  //           Upload: null,
  //           Amount: "",
  //           Note: "",
  //         },
  //       ],
  //       BookinglistHotel: [
  //         {
  //           IsSelectedHotel: false,
  //           RemarksHotel: "",
  //           UploadTDHotel: null,
  //           AmountTDHotel: "",
  //           UploadHotel: null,
  //           AmountHotel: "",
  //           NoteHotel: "",
  //         },
  //       ],
  //     },
  //   ];
  //   if (detailsform)
  //     if (detailsform.bookinglist)
  //       if (detailsform.bookinglist.length > 0) {
  //         returnArray = [];
  //         detailsform?.bookinglist?.map((item) => {
  //           return returnArray.push({
  //             Id: item.id,
  //             IsSelected: false,
  //             Remarks: item.remarks,
  //             Amount: item.amount,
  //             Upload: item.attachment ?? null,
  //           });
  //         });
  //       }
  //   return returnArray;
  // };
  const initialvalue = {
    Id: data,
    groupItems:
      detailsform?.travellingdetails?.length > 0
        ? detailsform.travellingdetails.map((item, ind) => ({
            date: item?.fromDate,
            FromLocation: item?.pickupLocation,
            ToLocation: item?.dropLocation,
            Bookinglist:
              detailsform?.travelBookingOptions?.length > 0
                ? detailsform.travelBookingOptions
                    .filter((iit) => iit.travelingDetailsID === item.id)
                    .map((option) => ({
                      id: option?.id,
                      IsSelected: false,
                      Remarks: option?.travelRemarks,
                      UploadTD: option?.travel_Attachment,
                      AmountTD: option?.amount,
                      Upload: null,
                      Amount: "",
                      Note: "",
                    }))
                : [
                    {
                      id: 0,
                      IsSelected: false,
                      Remarks: "",
                      UploadTD: null,
                      AmountTD: "",
                      Upload: null,
                      Amount: "",
                      Note: "",
                    },
                  ],
          }))
        : [
            {
              date: "",
              FromLocation: "",
              ToLocation: "",
              Bookinglist: [
                {
                  id: 0,
                  IsSelected: false,
                  Remarks: "",
                  UploadTD: null,
                  AmountTD: "",
                  Upload: null,
                  Amount: "",
                  Note: "",
                },
              ],
            },
          ],

    groupItemsHotel:
      detailsform?.hoteldetail?.length > 0
        ? detailsform.hoteldetail?.map((item, ind) => ({
            HotelcheckInTime: item?.checkInTime,
            HotelcheckOutTime: item?.checkOutTime,
            Hotellocation: item?.location,
            HotelpreferHotel: item?.preferHotel,
            Bookinglist:
              detailsform?.travelBookingOptions?.length > 0
                ? detailsform.travelBookingOptions
                    .filter((iit) => iit.hotelBookingDetailsID === item.id)
                    .map((option) => ({
                      id: option?.id ?? 0,
                      IsSelectedHotel: false,
                      RemarksHotel: option?.hotelRemarks,
                      UploadTDHotel: option?.hotel_Attachment,
                      AmountTDHotel: option?.amountHotel,
                      UploadHotel: null,
                      AmountHotel: "",
                      NoteHotel: "",
                    }))
                : [
                    {
                      id: 0,
                      IsSelectedHotel: false,
                      RemarksHotel: "",
                      UploadTDHotel: null,
                      AmountTDHotel: "",
                      UploadHotel: null,
                      AmountHotel: "",
                      NoteHotel: "",
                    },
                  ],
          }))
        : [
            {
              HotelcheckInTime: "",
              HotelcheckOutTime: "",
              Hotellocation: "",
              HotelpreferHotel: "",
              Bookinglist: [
                {
                  id: 0,
                  IsSelectedHotel: false,
                  RemarksHotel: "",
                  UploadTDHotel: null,
                  AmountTDHotel: "",
                  UploadHotel: null,
                  AmountHotel: "",
                  NoteHotel: "",
                },
              ],
            },
          ],
  };
  function validateBookingList(val) {
    let travelObj = [];
    let hotelObj = [];
    if (val?.groupItems[0]?.FromLocation) {
      val?.groupItems?.forEach((item, index) => {
        if (item.Bookinglist && Array.isArray(item.Bookinglist)) {
          item.Bookinglist.forEach((booking) => {
            if (booking.IsSelected === true) {
              travelObj[index] = true;
            } else if (
              booking.IsSelected === false &&
              travelObj[index] !== true
            ) {
              travelObj[index] = false;
            }
          });
        }
      });
    }
    if (val?.groupItemsHotel[0]?.HotelcheckInTime) {
      val?.groupItemsHotel?.forEach((item, index) => {
        if (item.Bookinglist && Array.isArray(item.Bookinglist)) {
          item.Bookinglist.forEach((booking) => {
            if (booking.IsSelectedHotel === true) {
              hotelObj[index] = true;
            } else if (
              booking.IsSelectedHotel === false &&
              hotelObj[index] !== true
            ) {
              hotelObj[index] = false;
            }
          });
        }
      });
    }

    if (hotelObj?.length > 0 && travelObj?.length > 0) {
      const resultH = !hotelObj.some((value) => value === false);
      const resultT = !travelObj.some((value) => value === false);
      return resultH && resultT;
    } else if (hotelObj?.length === 0 && travelObj?.length > 0) {
      const resultT = !travelObj.some((value) => value === false);
      return resultT;
    } else if (hotelObj?.length > 0 && travelObj?.length === 0) {
      const resultH = !hotelObj.some((value) => value === false);
      return resultH;
    }
  }
  if (loading) return <Loader />;
  else
    return (
      <Formik
        initialValues={initialvalue}
        validationSchema={
          formValues
            ? formValues?.groupItems[0]?.FromLocation?.length > 0 &&
              formValues?.groupItemsHotel[0]?.Hotellocation?.length === 0
              ? Yup.object({
                  groupItems: Yup.array().of(
                    Yup.object().shape({
                      Bookinglist: Yup.array().of(
                        Yup.object().shape({
                          Note: Yup.string().required("Note Is Required"),
                          Amount: Yup.string().required("Amount Is Required"),
                          Upload: Yup.string().required("Upload Is Required"),
                        })
                      ),
                    })
                  ),
                })
              : formValues?.groupItems[0]?.FromLocation?.length === 0 &&
                formValues?.groupItemsHotel[0]?.Hotellocation?.length > 0
              ? Yup.object({
                  groupItemsHotel: Yup.array().of(
                    Yup.object().shape({
                      Bookinglist: Yup.array().of(
                        Yup.object().shape({
                          NoteHotel: Yup.string().required("Note Is Required"),
                          AmountHotel:
                            Yup.string().required("Amount Is Required"),
                          UploadHotel:
                            Yup.string().required("Upload Is Required"),
                        })
                      ),
                    })
                  ),
                })
              : formValues?.groupItems[0]?.FromLocation?.length > 0 &&
                formValues?.groupItemsHotel[0]?.Hotellocation?.length > 0 &&
                Yup.object({
                  groupItems: Yup.array().of(
                    Yup.object().shape({
                      Bookinglist: Yup.array().of(
                        Yup.object().shape({
                          Note: Yup.string().required("Note Is Required"),
                          Amount: Yup.string().required("Amount Is Required"),
                          Upload: Yup.string().required("Upload Is Required"),
                        })
                      ),
                    })
                  ),
                  groupItemsHotel: Yup.array().of(
                    Yup.object().shape({
                      Bookinglist: Yup.array().of(
                        Yup.object().shape({
                          NoteHotel: Yup.string().required("Note Is Required"),
                          AmountHotel:
                            Yup.string().required("Amount Is Required"),
                          UploadHotel:
                            Yup.string().required("Upload Is Required"),
                        })
                      ),
                    })
                  ),
                })
            : Yup.object({})
        }
        enableReinitialize
        onSubmit={(values) => {
          let count = 0;
          const formData = new FormData();
          if (validateBookingList(values)) {
            formData.append(`Id`, values.Id ?? 0);
            if (values?.groupItems[0]?.FromLocation?.length > 0) {
              values.groupItems?.forEach((it) => {
                it?.Bookinglist?.forEach((item) => {
                  if (item.IsSelected) {
                    formData.append(
                      `Bookinglist[${count}].IsSelectedTravel`,
                      item.IsSelected ?? 0
                    );
                    formData.append(`Bookinglist[${count}].id`, item.id ?? 0);
                    formData.append(
                      `Bookinglist[${count}].Travel_Note`,
                      item.Note ?? 0
                    );
                    formData.append(
                      `Bookinglist[${count}].Budget_By_User_travel`,
                      item.Amount ?? 0
                    );
                    formData.append(
                      `Bookinglist[${count}].Travel_user_upload`,
                      item.Upload ?? 0
                    );
                    count++;
                  }
                });
              });
            }
            if (values?.groupItemsHotel[0]?.HotelcheckInTime?.length > 0) {
              values.groupItemsHotel?.forEach((it) => {
                it?.Bookinglist?.forEach((item) => {
                  if (item.IsSelectedHotel) {
                    formData.append(
                      `Bookinglist[${count}].IsSelectedHotel`,
                      item.IsSelectedHotel ?? 0
                    );
                    formData.append(`Bookinglist[${count}].id`, item.id ?? 0);
                    formData.append(
                      `Bookinglist[${count}].Hotel_Note`,
                      item.NoteHotel ?? 0
                    );
                    formData.append(
                      `Bookinglist[${count}].Budget_By_User_Hotel`,
                      item.AmountHotel ?? 0
                    );
                    formData.append(
                      `Bookinglist[${count}].Hotel_user_upload`,
                      item.UploadHotel ?? 0
                    );
                    count++;
                  }
                });
              });
            }
            create(formData);
          } else {
            dispatch(
              showToast({
                text: "Please select atleast one option",
                severity: "error",
              })
            );
          }
        }}
      >
        {(formik) => {
          setFormValues(formik.values);
          return (
            <Form>
              <div style={{ width: "100%" }} className="SubmitBookingTable">
                <div style={{ display: "grid" }}>
                  {detailsform?.travellingdetails?.length > 0 && (
                    <>
                      <div style={{ marginBottom: "20px" }}>
                        <label className="repeatedHeading">Travel </label>{" "}
                      </div>
                      <div style={{ backgroundColor: "white" }}>
                        <FieldArray name="groupItems">
                          {(fieldArrayProps) => {
                            const { push, remove, form } = fieldArrayProps;
                            const { values } = form;
                            const { groupItems } = values;
                            return (
                              <>
                                {groupItems.map((groupItem, index) => (
                                  <>
                                    {groupItems?.length > 1 && (
                                      <div
                                        style={{
                                          paddingLeft: "18px",
                                          margin: "10px 0px",
                                        }}
                                      >
                                        {" "}
                                        <span style={{ fontSize: "15px" }}>
                                          S no. :
                                        </span>
                                        <span
                                          style={{ fontSize: "15px" }}
                                          className="repeatedHeading"
                                        >
                                          {index + 1}
                                        </span>
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        paddingLeft: "18px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <span style={{ fontSize: "15px" }}>
                                        Travelling Date :
                                      </span>
                                      <span
                                        style={{ fontSize: "15px" }}
                                        className="repeatedHeading"
                                      >
                                        {groupItems[index]?.date
                                          ? format(
                                              new Date(groupItems[index]?.date),
                                              "dd/MM/yyyy"
                                            )
                                          : ""}{" "}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        paddingLeft: "18px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <span style={{ fontSize: "15px" }}>
                                        From Location :
                                      </span>
                                      <span
                                        style={{ fontSize: "15px" }}
                                        className="repeatedHeading"
                                      >
                                        {groupItems[index]?.FromLocation}{" "}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        paddingLeft: "18px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <span style={{ fontSize: "15px" }}>
                                        To Location :
                                      </span>
                                      <span
                                        style={{ fontSize: "15px" }}
                                        className="repeatedHeading"
                                      >
                                        {groupItems[index]?.ToLocation}{" "}
                                      </span>
                                    </div>
                                    <FieldArray
                                      name={`groupItems[${index}].Bookinglist`}
                                      key={index}
                                    >
                                      {(fieldArrayProps) => {
                                        const { push, remove, form } =
                                          fieldArrayProps;
                                        const { values } = form;
                                        const { Bookinglist } =
                                          values.groupItems[index];
                                        return (
                                          <StyledTableCont>
                                            <Table>
                                              <TableHead>
                                                <TableRow>
                                                  <StyledTableCell
                                                    padding="0"
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Select Option"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    padding="0"
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={"Remarks"}
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Estimation By Travel Desk"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Budget By Travel Desk"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Estimation By Requester"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Budget By Requester"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={"Note"}
                                                    />
                                                  </StyledTableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {Bookinglist.map(
                                                  (
                                                    bookingItem,
                                                    bookingIndex
                                                  ) => (
                                                    <TableRow
                                                      key={bookingIndex}
                                                    >
                                                      <StyledTableCell align="center">
                                                        <CheckboxFormiks
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            margin: "-6px",
                                                          }}
                                                          name={`groupItems[${index}].Bookinglist[${bookingIndex}].IsSelected`}
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <LabelCustom
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                          labelName={
                                                            bookingItem?.Remarks
                                                          }
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <div
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              margin: "6px",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${bookingItem?.UploadTD}`}
                                                            target="blank"
                                                          >
                                                            {
                                                              bookingItem?.UploadTD
                                                            }
                                                          </a>
                                                        </div>
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <LabelCustom
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                          labelName={
                                                            bookingItem?.AmountTD
                                                          }
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <FileUploadFormik
                                                          name={`groupItems[${index}].Bookinglist[${bookingIndex}].Upload`}
                                                          addPath={
                                                            "TravelBookings/"
                                                          }
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <InputCustomFormik
                                                          name={`groupItems[${index}].Bookinglist[${bookingIndex}].Amount`}
                                                          type="number"
                                                          step="0.01"
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <InputCustomFormik
                                                          name={`groupItems[${index}].Bookinglist[${bookingIndex}].Note`}
                                                        />
                                                      </StyledTableCell>
                                                    </TableRow>
                                                  )
                                                )}
                                              </TableBody>
                                            </Table>
                                          </StyledTableCont>
                                        );
                                      }}
                                    </FieldArray>
                                  </>
                                ))}
                              </>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </>
                  )}
                  {detailsform?.hoteldetail?.length > 0 && (
                    <>
                      <div style={{ marginBottom: "20px" }}>
                        <label className="repeatedHeading">Hotel </label>{" "}
                      </div>
                      <div style={{ backgroundColor: "white" }}>
                        <FieldArray name="groupItemsHotel">
                          {(fieldArrayProps) => {
                            const { push, remove, form } = fieldArrayProps;
                            const { values } = form;
                            const { groupItemsHotel } = values;
                            return (
                              <>
                                {groupItemsHotel.map((groupItem, index) => (
                                  <>
                                    {groupItemsHotel?.length > 1 && (
                                      <div
                                        style={{
                                          paddingLeft: "18px",
                                          margin: "10px 0px",
                                        }}
                                      >
                                        {" "}
                                        <span style={{ fontSize: "15px" }}>
                                          S no. :
                                        </span>
                                        <span
                                          style={{ fontSize: "15px" }}
                                          className="repeatedHeading"
                                        >
                                          {index + 1}
                                        </span>
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        paddingLeft: "18px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <span style={{ fontSize: "15px" }}>
                                        Check In Time :
                                      </span>
                                      <span
                                        style={{ fontSize: "15px" }}
                                        className="repeatedHeading"
                                      >
                                        {groupItemsHotel[index]
                                          ?.HotelcheckInTime
                                          ? format(
                                              new Date(
                                                groupItemsHotel[
                                                  index
                                                ]?.HotelcheckInTime
                                              ),
                                              "dd/MM/yyyy HH:mm"
                                            )
                                          : ""}{" "}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        paddingLeft: "18px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <span style={{ fontSize: "15px" }}>
                                        Check Out Time :
                                      </span>
                                      <span
                                        style={{ fontSize: "15px" }}
                                        className="repeatedHeading"
                                      >
                                        {groupItemsHotel[index]
                                          ?.HotelcheckOutTime
                                          ? format(
                                              new Date(
                                                groupItemsHotel[
                                                  index
                                                ]?.HotelcheckOutTime
                                              ),
                                              "dd/MM/yyyy HH:mm"
                                            )
                                          : ""}{" "}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        paddingLeft: "18px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <span style={{ fontSize: "15px" }}>
                                        Location :
                                      </span>
                                      <span
                                        style={{ fontSize: "15px" }}
                                        className="repeatedHeading"
                                      >
                                        {groupItemsHotel[index]?.Hotellocation}{" "}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        paddingLeft: "18px",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <span style={{ fontSize: "15px" }}>
                                        Preferred Hotel :
                                      </span>
                                      <span
                                        style={{ fontSize: "15px" }}
                                        className="repeatedHeading"
                                      >
                                        {
                                          groupItemsHotel[index]
                                            ?.HotelpreferHotel
                                        }{" "}
                                      </span>
                                    </div>
                                    <FieldArray
                                      name={`groupItemsHotel[${index}].Bookinglist`}
                                      key={index}
                                    >
                                      {(fieldArrayProps) => {
                                        const { push, remove, form } =
                                          fieldArrayProps;
                                        const { values } = form;
                                        const { Bookinglist } =
                                          values.groupItemsHotel[index];
                                        return (
                                          <StyledTableCont>
                                            <Table>
                                              <TableHead>
                                                <TableRow>
                                                  <StyledTableCell
                                                    padding="0"
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Select Option"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    padding="0"
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={"Remarks"}
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Estimation By Travel Desk"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Budget By Travel Desk"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Estimation By Requester"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={
                                                        "Budget By Requester"
                                                      }
                                                    />
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    style={{ fontSize: "12px" }}
                                                    align="center"
                                                  >
                                                    <LabelCustom
                                                      labelName={"Note"}
                                                    />
                                                  </StyledTableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {Bookinglist.map(
                                                  (
                                                    bookingItem,
                                                    bookingIndex
                                                  ) => (
                                                    <TableRow
                                                      key={bookingIndex}
                                                    >
                                                      <StyledTableCell align="center">
                                                        <CheckboxFormiks
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            margin: "-6px",
                                                          }}
                                                          name={`groupItemsHotel[${index}].Bookinglist[${bookingIndex}].IsSelectedHotel`}
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <LabelCustom
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                          labelName={
                                                            bookingItem?.RemarksHotel
                                                          }
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <div
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              margin: "6px",
                                                              textAlign:
                                                                "center",
                                                            }}
                                                            href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TravelBookings/${bookingItem?.UploadTDHotel}`}
                                                            target="blank"
                                                          >
                                                            {
                                                              bookingItem?.UploadTDHotel
                                                            }
                                                          </a>
                                                        </div>
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <LabelCustom
                                                          style={{
                                                            textAlign: "center",
                                                          }}
                                                          labelName={
                                                            bookingItem?.AmountTDHotel
                                                          }
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <FileUploadFormik
                                                          name={`groupItemsHotel[${index}].Bookinglist[${bookingIndex}].UploadHotel`}
                                                          addPath={
                                                            "TravelBookings/"
                                                          }
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <InputCustomFormik
                                                          name={`groupItemsHotel[${index}].Bookinglist[${bookingIndex}].AmountHotel`}
                                                          type="number"
                                                          step="0.01"
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell>
                                                        <InputCustomFormik
                                                          name={`groupItemsHotel[${index}].Bookinglist[${bookingIndex}].NoteHotel`}
                                                        />
                                                      </StyledTableCell>
                                                    </TableRow>
                                                  )
                                                )}
                                              </TableBody>
                                            </Table>
                                          </StyledTableCont>
                                        );
                                      }}
                                    </FieldArray>
                                  </>
                                ))}
                              </>
                            );
                          }}
                        </FieldArray>
                      </div>
                    </>
                  )}
                </div>
                <div className="createFootCust">
                  <div>
                    <button
                      onClick={handleClose}
                      className="createFooterButtonForm1"
                      type="cancel"
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button className="button primaryButton" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
};
