/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateLeaveComp } from "./FormComp/CreateLeaveComp";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import { ApprovePopup } from "../../../../Components/FormComponent/PopupComponent/ApprovePopup";
import { PopupError } from "../../../../Components/FormComponent/PopupError";

const CreateLeave = (props) => {
  const { state } = useLocation();
  const [detailsform, setDetailsform] = useState(null);
  const [err, seterr] = useState("");
  const [formData, setFormData] = useState({});
  const [leaveType, seteleaveType] = useState('');
  const [preData, setPreData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [days, setDays] = useState(detailsform?.data?.numberOfDays ?? 0);
  const data = state?.id;
  const togglePopup = (values,) => {
   setFormData(values)
    setIsOpen((prev) => !prev);
  };
  const receiveDataFromChild = (data) => {
    setPreData(data);
  };
  const [formValues, setFormValues] = useState(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`NewLeaveRequests/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Create Laeve`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("NewLeaveRequests/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      returnPage();
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("NewLeaveRequests/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const d1 = new Date(formValues?.startDate);
  const d2 = new Date(formValues?.endDate);
  const diff = -(d1.getTime() - d2.getTime());
  const initialvalue = {
    leaveDuration: formValues?.leaveDuration ? formValues?.leaveDuration : "",
    leaveType: formValues?.leaveType ? formValues?.leaveType : "",
    startDate: days >= 0 ? formValues?.startDate : "",
    endDate: days >= 0 ? formValues?.endDate : "",
    comboffdays: null,
    numberOfDays: formValues?.startDate && formValues?.endDate ? days : 0.5,
    reason: "",
    contactPerson: null,
    contactNumber: null,
  };

  const validationSchema = Yup.object({
    leaveDuration: Yup.string().required("Please select the duration"),
    leaveType: Yup.string().required("Please select the type"),
    startDate: Yup.string().required("Please enter the start date"),
    endDate: Yup.string().when("leaveDuration", {
      is: (val) => val === "Full Day",
      then: () => Yup.string().required("Please enter the end date"),
    }),
    reason: Yup.string()

      .max(499, "Reason must be less than 500 characters")
      .required("Please enter the reason"),
  });
  const extractBalance = (label) => {
    const regex = /\(([^)]+)\)/;
    const match = regex.exec(label);
    if (match && match.length > 1) {
        return parseFloat(match[1]);
    }
    return null;
};

// Function to get balance for a given leave type
const getBalance = (leaveType) => {
    const leaveData = preData.find(data => data.value === leaveType);
    if (leaveData) {
        return extractBalance(leaveData.label);
    }
    return null; // Leave type not found
};

  const onSubmit = (values) => {
    if(leaveType!=="Paid"){
      const edit = {
        ...values,
        id: detailsform?.data?.id,
        numberOfDays: values?.numberOfDays,
        contactNumber: String(values?.contactNumber),
      };
  
      const create = {
        ...values,
        startDate: dateFormat(values.startDate, "yyyy-mm-dd"),
        endDate: values.endDate ? dateFormat(values.endDate, "yyyy-mm-dd"):dateFormat(values.startDate, "yyyy-mm-dd"),
        contactNumber: String(values?.contactNumber),
        numberOfDays: values?.numberOfDays,
      };
      if (err)
        return dispatch(
          showToast({
            text: err,
            severity: "error",
          })
        );
      else data ? Edit(edit) : createPageData(create);
    }
   else{
    if(getBalance(values.leaveType)<formValues?.numberOfDays){
      togglePopup(values);
    }
    else{
      const edit = {
        ...values,
        id: detailsform?.data?.id,
        numberOfDays: values?.numberOfDays,
        contactNumber: String(values?.contactNumber),
      };
  
      const create = {
        ...values,
        startDate: dateFormat(values.startDate, "yyyy-mm-dd"),
        endDate: dateFormat(values.endDate, "yyyy-mm-dd"),
        contactNumber: String(values?.contactNumber),
        numberOfDays: values?.numberOfDays,
      };
      if (err)
        return dispatch(
          showToast({
            text: err,
            severity: "error",
          })
        );
      else data ? Edit(edit) : createPageData(create);
    }
   }


  };
console.log('seteleaveType',leaveType)
  const navigate = useNavigate();
  const handleApprove = () => {
    const edit = {
      ...formData,
      id: detailsform?.data?.id,
      numberOfDays: formData?.numberOfDays,
      contactNumber: String(formData?.contactNumber),
    };

    const create = {
      ...formData,
      startDate: dateFormat(formData.startDate, "yyyy-mm-dd"),
      endDate: dateFormat(formData.endDate, "yyyy-mm-dd"),
      contactNumber: String(formData?.contactNumber),
      numberOfDays: formData?.numberOfDays,
    };
    console.log('edit',edit)
    if (err)
      return dispatch(
        showToast({
          text: err,
          severity: "error",
        })
      );
    else data ? Edit(edit) : createPageData(create);
  };
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <>
      <FormCustomFormik
        chooseFormValues={chooseFormValues}
        FormHeading={data ? "Update Leave Request" : props.title}
        ButtonLabel={data ? "Update Leave Request" : "Create Leave Request"}
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
        initialvalue={
          data
            ? {
                ...initialvalue,
                leaveDuration: detailsform?.data?.leaveDuration,
                leaveType: detailsform?.data?.leaveType,
                startDate: detailsform?.data?.startDate
                  ? new Date(detailsform?.data?.startDate)
                  : "",
                endDate: detailsform?.data?.endDate
                  ? new Date(detailsform?.data?.endDate)
                  : "",
                comboffdays: detailsform?.data?.comboffdays,
                numberOfDays: detailsform?.data?.numberOfDays,
                reason: detailsform?.data?.reason,
                contactPerson: detailsform?.data?.contactPerson,
                contactNumber: detailsform?.data?.contactNumber,
              }
            : initialvalue
        }
        returnPage={returnPage}
        propsDiv={
          <CreateLeaveComp
          sendDataToParent={receiveDataFromChild}
            loading={loading}
            handleClick={togglePopup}
            formValues={formValues}
            data={data}
            detailsform={detailsform}
            days={days}
            setLoading={setLoading}
            seterr={seterr}
            setDays={setDays}
            seteleaveType={seteleaveType}
          />
        }
      />
      {isOpen && (
        <PopupError
          handleClose={togglePopup}
        />
      )}
    </>
  );
};

export { CreateLeave };