/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { CreateLoanAdvanceComp } from "./Form/CreateLoanAdvanceComp";
import { useSelector } from "react-redux";
const CreateLoanAdvance = (props) => {
  const [formValues, setFormValues] = useState(null);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [detailsform, setDetailsform] = useState({});
  const data = state?.id;
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`LoanAndAdvance/Details/${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest("LoanAndAdvance/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  const EditPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest("LoanAndAdvance/Edit", values);
      dispatch(
        showToast({ text: "Successfully Updated", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  function monthDiff() {
    return (
      new Date().getMonth() -
      new Date(LoggedInUser?.doj).getMonth() +
      12 *
        (new Date().getFullYear() - new Date(LoggedInUser?.doj).getFullYear())
    );
  }
  useEffect(() => {
    document.title = `PeopleSol - Create Loan Advance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // //console.log("detailsform", formValues?.type === "Salary Advance");
  const initialvalue = {
    time: monthDiff(),
    type: data ? detailsform?.type : "",
    amount: data ? detailsform?.amount : "",
    reason: data ? detailsform?.reason : "",
    requiredBydate:
      JSON.stringify(detailsform) !== "{}"
        ? new Date(detailsform?.requiredBydate)
        : null,
    totalRecoveryMonth: data ? detailsform?.totalRecoveryMonth : null,
    perMonthDeduction: data ? detailsform?.perMonthDeduction : null,
  };
  const validationSchema = Yup.object({
    type: Yup.string().required("Please Select  Type"),
    amount:
      formValues?.type === "Salary Advance"
        ? Yup.number().required("Please Enter Correct Amount").min(0).max(20000)
        : formValues?.type === "Term Advance"
        ? Yup.number().required("Please Enter Correct Amount").min(0).max(50000)
        : Yup.number()
            .required("Please Enter Correct Amount")
            .min(0)
            .max(50000),
    reason: Yup.string()
      .max(499, "Must be less than 500 characters ")
      .required("Please Enter  Reason"),
    requiredBydate: Yup.string().required("Please select  Date"),
    totalRecoveryMonth: Yup.number()
      .required("Please Enter Correct No. of Months")
      .min(1)
      .max(12),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: data,
      totalRecoveryMonth: values?.totalRecoveryMonth
        ? Number(values?.totalRecoveryMonth?.toFixed(2))
        : null,
      perMonthDeduction:
        formValues?.totalRecoveryMonth !== "" && formValues?.amount !== ""
          ? Number(
              formValues?.amount / (formValues?.totalRecoveryMonth).toFixed(2)
            )
          : 0,
    };
    const create = {
      ...values,
      totalRecoveryMonth: values?.totalRecoveryMonth
        ? Number(values?.totalRecoveryMonth?.toFixed(2))
        : null,
      perMonthDeduction:
        formValues?.totalRecoveryMonth !== "" && formValues?.amount !== ""
          ? Number(
              formValues?.amount / (formValues?.totalRecoveryMonth).toFixed(2)
            )
          : 0,
    };
    data ? EditPageData(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={
        data ? "Edit Loan or Advance Request" : "Create Loan or Advance Request"
      }
      ButtonLabel={
        data ? "Edit Loan or Advance Request" : "Create Loan or Advance Request"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateLoanAdvanceComp formValues={formValues} />}
    />
  );
};

export { CreateLoanAdvance };
