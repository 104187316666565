import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BoxView } from "../../../../Components";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];
const HomePayrollCompany = ({ searchParam }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState([]);
  const [payData, setPayData] = useState([]);
  const [month, setMonth] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");

  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tbodyData, setTbodyData] = useState(false);
  const [reqData, setReqData] = useState({
    noticePeriod: null,
    createdOn: null,
    lastUpdatedOn: null,
    status: "Pending",
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });

  const createBackGradient = () => {
    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(138, 136, 237, 0.4)");
    gradient.addColorStop(1, "rgba(193, 54, 153, 0)");
    return gradient;
  };
  const createLineGradient = () => {
    const ctx = document.createElement("canvas").getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, "rgba(138, 136, 237, 1)");
    gradient.addColorStop(1, "rgba(193, 54, 153, 1)");
    return gradient;
  };
  //   const [chartData, setChartData] = useState({
  //     labels,
  //     datasets: [
  //       {
  //         label: "Pay Summary",
  //         data: labels.map(() => randomIntFromInterval(30000, 50000)),
  //         borderColor: createLineGradient(),
  //         backgroundColor: createBackGradient(),
  //         tension: 0.5,
  //         fill: true,
  //         datalabels: {
  //           align: "end",
  //           anchor: "end",
  //         },
  //       },
  //     ],
  //   });
  useEffect(() => {
    document.title = `PeopleSol - Dashboard `;
    getListData();
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  console.log("payData", payData);
  const chartData = {
    labels: payData.map((data) => data.monthPayout),
    datasets: [
      {
        label: "Pay Summary",
        data: payData.map((data) => data.amount),
        borderColor: createLineGradient(),
        backgroundColor: createBackGradient(),
        tension: 0.5,
        fill: true,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
  };

  useEffect(() => {
    //chart();
  }, []);
  const getProfileData = async () => {
    try {
      const result = await getRequest(
        "PayrollDashboard/CompanyDashboard/Home/CBanner"
      );

      setBannerList(result.data);
    } catch (error) {
      dispatch(~
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const fetchPaySummary = async () => {
    try {
      const response = await getRequest(
        "PayrollDashboard/CompanyDashboard/Home/CPaySummary"
      );
      setMonth(response.data[0]?.monthPayout);
      setPayData(response.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "Error fetching pay summary",
          severity: "error",
        })
      );
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("payroll")) {
      getProfileData();
      fetchPaySummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getListData = async () => {
    try {
      setLoading(true);
      const result = await postRequest("PreparePayroll/List", reqData);
      if (result) {
        setTbodyData(result.data.data);
      } else {
        setTbodyData(false);
      }
      setCount(result.data.summary.count);
      calculateTotalPages(result.data.summary.count);
      setSelectedRows([]);
    } catch (error) {
      //dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      setTbodyData(false);
      setCount(0);
    } finally {
      setLoading(false);
    }
  };
  const getFormDetails = (id) => {
    const selectedRecord = tbodyData?.find((item) => item?.Id === id);
    navigate("/hrmsmaster/orgstructure/band/details", {
      state: { selectedRecord },
    });
  };
  const actionData = [
    {
      img: (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8437V4.84375Z"
            fill="#0DB53C"
          />
          <line
            x1="14.7295"
            y1="7.43701"
            x2="7.72949"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="7.43701"
            x2="5.61462"
            y2="7.43701"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="11.3437"
            x2="7.72949"
            y2="11.3437"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="11.3437"
            x2="5.61462"
            y2="11.3438"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="14.7295"
            y1="15.2505"
            x2="7.72949"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
          <line
            x1="5.72949"
            y1="15.2505"
            x2="5.61462"
            y2="15.2505"
            stroke="#0DB53C"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Details",
      clickAction: (id) => getFormDetails(id),
    },
    {
      img: (
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.110596"
            y="0.587402"
            width="18.4572"
            height="18.4572"
            rx="4"
            fill="#D8F0F2"
          />
          <path
            d="M9.92749 4.5127C9.92749 4.23655 9.70363 4.0127 9.42749 4.0127C9.15135 4.0127 8.92749 4.23655 8.92749 4.5127L9.92749 4.5127ZM8.92749 4.5127L8.92749 12.5518L9.92749 12.5518L9.92749 4.5127L8.92749 4.5127Z"
            fill="#1296B0"
          />
          <path
            d="M5.41748 8.9668L9.42752 12.9768L13.4376 8.9668"
            stroke="#1296B0"
            stroke-linecap="round"
          />
          <path
            d="M5.18164 15.1191H13.4969"
            stroke="#1296B0"
            stroke-linecap="round"
          />
        </svg>
      ),
      action: "Download",
      clickAction: (id) => id,
    },
  ];
  return (
    <div className="fullTableContainer" style={{ overflow: "auto" }}>
      <div className="headingContainer">
        <h5>Aess solutions</h5>
      </div>
      <div className={`homeDashboardContainer`} style={{ margin: "12px" }}>
        <div
          style={{
            width: "60%",
            display: "flex",
            flexWrap: "wrap",
            gap: "12px",
            paddingLeft: "12px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "calc(50% - 6px)" }}>
            <div
              className="horizontalLine"
              style={{
                backgroundColor: "#B69FB3",
                width: "20%",
                marginBottom: "6px",
              }}
            />
            <h5>{bannerList?.payArea ?? "Bhopal"}</h5>
          </div>
          <div style={{ width: "calc(50% - 6px)" }}>
            <div
              style={{
                backgroundColor: "var(--primary-hover)",
                color: "var(--primary)",
                padding: "6px 12px",
                width: "fit-content",
                borderRadius: "6px",
              }}
            >
              {bannerList?.status ?? "In-Progress"}
            </div>
          </div>
          <div style={{ width: "calc(50% - 6px)" }}>
            <p>{`Payout ${month ?? "Sep 2023"}`}</p>
            <h2 style={{ color: "#B69FB3" }}>
              {bannerList?.totalPayout ?? "1.25CR"}
            </h2>
          </div>
          <div style={{ width: "calc(50% - 6px)" }}>
            <p>No. of Employee</p>
            <h2 style={{ color: "#5BC4BF" }}>
              {bannerList?.noOfEmployees ?? "99"}
            </h2>
          </div>
        </div>
        <div
          style={{
            width: "60%",
            height: "fit-content",

            borderRadius: "6px",
          }}
        >
          <div
            style={{
              padding: "12px",
              display: "flex",
              flexWrap: "wrap",
              gap: "12px",
              overflow: "auto",
              height: "calc(100% - 50px)",
            }}
          >
            <div className="payrollSummaryContainer tdsSummary">
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px" }}>TDS</p>
                <h5 style={{ color: "var(--primary-text)" }}>
                  {bannerList?.tds}
                </h5>
              </div>
            </div>
            <div className="payrollSummaryContainer esicSummary">
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px" }}>ESIC</p>
                <h5 style={{ color: "var(--primary-text)" }}>
                  {bannerList.esic}
                </h5>
              </div>
            </div>
            <div className="payrollSummaryContainer insuranceSummary">
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px" }}>PT</p>
                <h5 style={{ color: "var(--primary-text)" }}>
                  {bannerList.pt}
                </h5>
              </div>
            </div>
            <div className="payrollSummaryContainer epfSummary">
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px" }}>EPF</p>
                <h5 style={{ color: "var(--primary-text)" }}>
                  {bannerList?.epf}
                </h5>
              </div>
            </div>
            <div className="payrollSummaryContainer gratuitySummary">
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px" }}>
                  Gratuity
                </p>
                <h5 style={{ color: "var(--primary-text)" }}>
                  {bannerList?.gratuity}
                </h5>
              </div>
            </div>
            <div className="payrollSummaryContainer loanSummary">
              <svg
                width="15"
                height="18"
                viewBox="0 0 15 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                  stroke="#8A88ED"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <p style={{ fontWeight: "500", lineheight: "14px" }}>Loan</p>
                <h5 style={{ color: "var(--primary-text)" }}>
                  {bannerList?.loan ?? "-"}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "calc(35% - 24px)",
            margin: "12px",
            height: "calc(100% - 24px)",
          }}
        ></div>
      </div>
      <h5 style={{ margin: "12px 0 0 24px" }}>Monthly Pay Out {month}</h5>
      <BoxView
        payroll={"overH"}
        tbodyData={tbodyData}
        actionData={actionData}
        headingTile="payArea"
        subTiles={["payDate", "amount", "employeesCount", "monthPayout"]}
        date="LastUpdatedOn"
      />
      <div
        style={{
          width: "100%",
          boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
          borderRadius: "6px",
          margin: "12px",
        }}
      >
        <div className="headingContainer">
          <h5>Pay Summary</h5>
        </div>
        <div style={{ width: "100%", padding: "12px" }}>
          <Line
            data={chartData}
            width={"100%"}
            height={300} // Adjust the height here
            options={{
              plugins: {
                legend: false,
                datalabels: {
                  backgroundColor: "white",
                  borderRadius: 4,
                  color: "#8A88ED",
                  font: {
                    weight: "bold",
                  },
                  formatter: Math.round,
                  fill: true,
                },
              },
              scales: {
                y: {
                  suggestedMin: 20000, // Adjust the suggestedMin value as per your requirement
                  suggestedMax: 60000, // Adjust the suggestedMax value as per your requirement
                },
              },
              elements: {
                point: {
                  radius: 4,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export { HomePayrollCompany };
