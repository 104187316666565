import React from "react";
import { useState, useCallback } from "react";
import SurveyHeader from "./SurveyHeader";
import { useEffect } from "react";
import { postRequest } from "../../../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../../../Features";
import SurveyAndPollPostHeader from "./SurveyAndPollPostHeader";
import SurveyAndPollImages from "./SurveyAndPollImages";
import SurveyAndPollTitle from "./SurveyAndPollTitle";
import SurveyAndPollDescription from "./SurveyAndPollDescription";
import QuestionAnswerContainer from "./QuestionAnswerContainer";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { setSurveyData } from "../../../../../../../Features/SocialSlice";
import PollQuestionAnswerContainer from "./PollQuestionAnswerContaner";
import SocialPostNotFoundIcon from "../../../../../../../Assets/SocialPostNotFoundIcon.svg";
import { Loader } from "../../../../../../../Components/FormComponent/Loader";
import SurveyPostDetails from "./SurveyPostDetails";
import { Route, Routes } from "react-router-dom";

function SurvayMainDisplayList({ type, reRefresh, setReRefreshProp }) {
  const [selectedData, setSelectedData] = useState([]);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [notRefresh, setNotRefresh] = useState(false);

  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 5,
  });
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [loading, setLoading] = useState(false);
  const [dataFound, setDataFound] = useState(true);
  const [surveyApiCall, setSurveyApiCall] = useState(
    type === "survey" ? "mySurvey" : "myPoll"
  );
  const dispatch = useDispatch();
  const { surveyData } = useSelector((state) => state.social);
  const [selectSurveyType, setSelectSurveyType] = useState([
    { label: "My Survey", value: "mySurvey" },
  ]);
  const [selectPollType, setSelectPollType] = useState([
    { label: "My Poll", value: "myPoll" },
  ]);

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".survayMainDisplayListWrapper"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".survayMainDisplayListWrapper"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && surveyData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 5);
  }, [loading, surveyData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".survayMainDisplayListWrapper")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 5)) &&
      !loading &&
      (postDataCount === -1 || surveyData?.length < postDataCount)
    ) {
      if (!reRefresh) {
        getSurveyDataList();
      }
    } else if (offset > totaloffset + 5) {
      setOffset(totaloffset + 5);
    }
  }, [offset, totaloffset, surveyApiCall]);

  useEffect(() => {
    setOffset(0);
  }, []);

  const getSurveyDataList = async (freshLoad = false, reload = false) => {
    if (!reload) setLoading(true);
    try {
      let result = null;
      if (surveyApiCall === "mySurvey") {
        result = await postRequest(`Survey/MyList`, {
          createdOn: null,
          lastUpdatedOn: null,
          dataTableRequest: {
            iDisplayLength: freshLoad ? 5 : reload ? surveyData?.length : 5,
            iDisplayStart: freshLoad || reload ? 0 : offset,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
          },
        });
      } else if (surveyApiCall === "otherSurvey") {
        result = await postRequest(`Survey/OtherList`, {
          createdOn: null,
          lastUpdatedOn: null,
          dataTableRequest: {
            iDisplayLength: freshLoad ? 5 : reload ? offset : 5,
            iDisplayStart: freshLoad || reload ? 0 : offset,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
          },
        });
      } else if (surveyApiCall === "myPoll") {
        result = await postRequest(`Survey/PollMyList`, {
          createdOn: null,
          lastUpdatedOn: null,
          dataTableRequest: {
            iDisplayLength: freshLoad ? 5 : reload ? offset : 5,
            iDisplayStart: freshLoad || reload ? 0 : offset,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
          },
        });
      } else if (surveyApiCall === "otherPoll") {
        result = await postRequest(`Survey/PollOtherList`, {
          createdOn: null,
          lastUpdatedOn: null,
          dataTableRequest: {
            iDisplayLength: freshLoad ? 5 : reload ? offset : 5,
            iDisplayStart: freshLoad || reload ? 0 : offset,
            sSearch: null,
            sorting: "Id",
            sSortDir_0: "descending",
            isExport: false,
          },
        });
      }
      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        // setSocialData([]);
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });
        if (!reload) {
          setPostDataCount(result?.data?.summary?.allCount);
          setTotalOffset(offset);
        }
        if (reload || reRefresh) {
          if (reload) {
            dispatch(setSurveyData(listData));
          }
          if (freshLoad) {
            document.querySelector(
              ".survayMainDisplayListWrapper"
            ).scrollTop = 0;
            dispatch(setSurveyData(listData));
            setOffset(0);
            setTotalOffset(0);
          }
        } else {
          dispatch(setSurveyData([...surveyData, ...listData]));
        }
        setCurrentIndex((currentIndex) => currentIndex + 5);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
      }
      if (selectedData?.length > 0) {
        if (surveyApiCall === "myPoll" || surveyApiCall === "otherPoll") {
          responseSurvey((selectedDataRef.current = selectedData));
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Something went wrong!",
          severity: "error",
        })
      );
      return;
    } finally {
      setLoading(false);
      setReRefreshProp(false);
    }
  };

  const selectedDataRef = useRef(selectedData);

  const responseSurvey = async (respons) => {
    if (selectedDataRef.current?.length > 0) {
      // Check if token is present in local storage
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const result = await postRequest(
            "Survey/Respondents",
            respons ?? (selectedDataRef.current = selectedData)
          );
          if (selectedData?.length > 0) {
            if (
              surveyApiCall === "mySurvey" ||
              surveyApiCall === "otherSurvey"
            ) {
              getSurveyDataList(false, true);
            }
          }
          setSelectedData([]);
        } catch (error) {
          dispatch(
            showToast({
              text: "Some error occurred in fetching data",
              severity: "error",
            })
          );
        }
      } else {
        console.log("Token is not present in local storage");
      }
    }
  };

  const updateSurveyValue = (value) => {
    setSurveyApiCall(value);
    setReRefreshProp(true);
  };

  useEffect(() => {
    selectedDataRef.current = selectedData;
  }, [selectedData]);

  useEffect(() => {
    const cleanupFunction = () => {
      responseSurvey(selectedDataRef.current);
    };
    return cleanupFunction;
  }, []);

  useEffect(() => {
    if (reRefresh) {
      getSurveyDataList(true);
    }
  }, [reRefresh]);

  return (
    <div className="survayMainDisplayListContainer">
      <SurveyHeader
        hType={type}
        selectSurveyPostType={
          type === "survey" ? selectSurveyType : selectPollType
        }
        setSelectSurveyPostType={
          type === "survey" ? setSelectSurveyType : setSelectPollType
        }
        updateSurveyValue={updateSurveyValue}
      />
      <div className="survayMainDisplayListWrapper">
        {surveyData?.length > 0 &&
          surveyData?.map((surData, index) => {
            return (
              <div className="survayMainDisplayList">
                <SurveyAndPollPostHeader hData={surData} type={type} />
                <div>
                  <SurveyAndPollTitle titleData={surData} type={type} />
                  <SurveyAndPollImages imgData={surData} />
                  <SurveyAndPollDescription descriptionData={surData} />
                </div>
                {type === "survey" && (
                  <QuestionAnswerContainer
                    questionData={surData}
                    setSelectedData={setSelectedData}
                    // selectedQuestionsId={selectedQuestionsId}
                    // setSelectedQuestionsId={setSelectedQuestionsId}
                    selectedData={selectedData}
                    responseSurvey={responseSurvey}
                    // responseSurvey={responseSurvey}
                  />
                )}
                {type === "poll" && (
                  <PollQuestionAnswerContainer
                    questionData={surData}
                    setSelectedData={setSelectedData}
                    // selectedQuestionsId={selectedQuestionsId}
                    // setSelectedQuestionsId={setSelectedQuestionsId}
                    selectedData={selectedData}
                    responseSurvey={responseSurvey}
                  />
                )}
              </div>
            );
          })}
        {loading && <Loader />}
        {!dataFound && surveyData?.length <= 0 && (
          <div className="noPostfoundContainer">
            <div className="noPostfoundWrapper">
              <img src={SocialPostNotFoundIcon} alt="" />
              <h5>No Post Found</h5>
              <p>Nothing has posted in Survey</p>
            </div>
          </div>
        )}
      </div>
      <Routes>
        <Route path="details" element={<SurveyPostDetails />} />
      </Routes>
    </div>
  );
}

export default SurvayMainDisplayList;
