import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../Assets/CloseIcon.svg";
import { getRequest } from "../../../../Services/axios";
import { useSelector } from "react-redux";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { IconButton } from "@mui/material";
import AESSCompanyLogo from "../../../../Assets/AESSCompanyLogo.png";
import { EmployeeSummary } from "./EmployeeSummary";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { EarningsComponent } from "./EarningsComponent";
import { DeductionsComponent } from "./DeductionsComponent";
import { ReimbursementsComponent } from "./ReimbursementsComponent";
import html2pdf from "html2pdf.js";

export const SalaryTempLate = ({
  LoggedInUser,
  month,
  employeID,
  download,
}) => {
  const [loading, setLoading] = useState(false);
  const [slipDetails, setSlipDetails] = useState(false);
  const [slipSummary, setSlipSummary] = useState(false);
  var converter = require("number-to-words");
  function toTitleCase(str) {
    return str
      .toLowerCase()
      .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
  }
  const getFormDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PreparePayroll/SalarySlipDetails/${employeID}/${month}`
      );
      setSlipDetails(result?.data?.data);
      setSlipSummary(result?.data?.summary);

      setTimeout(() => {
        if (download) downloadPDF();
      }, 1);
    } catch (error) {
      // dispatch(
      //   showToast({
      //     text: error?.response?.data ?? "Error in fetching Data",
      //     severity: "error",
      //   })
      // );
    } finally {
      setLoading(false);
    }
  };
  const contentRef = useRef(null);

  const downloadPDF = () => {
    const content = contentRef.current;

    const options = {
      filename: "my-document.pdf",
      margin: 0,
      image: { type: "jpeg", quality: 1.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "portrait",
      },
    };

    html2pdf().set(options).from(content).save();
  };

  useEffect(() => {
    getFormDetails();
  }, []);
  console.log("slipSummary", slipDetails);
  if (loading) return <Loader />;
  else
    return (
      <div
        style={{ fontFamily: "Poppins", height: "100%",overflow:'auto', position: "relative" }}
        ref={contentRef}
      >
        <div className="payslipHeaderContainer">
          <div className="payslipHeadingTextContainer">
            <h4 style={{ color: "var(--primary)", fontFamily: "system-ui" }}>
              {slipDetails?.companyName ?? "-"}
            </h4>
            <p>{slipDetails?.address ?? "-"}</p>
            <p>CIN: U72900MP2022PTC060562</p>
          </div>
          <img
            src={AESSCompanyLogo}
            alt="logo"
            className="payslipCompanyLogo"
          />
        </div>

        <div className="payslipMainContainer" style={{ background: "white" }}>
          <div className="payslipMainHeaderContainer">
            <div className="payslipHeadingTextContainer">
              <h4 style={{ color: "var(--primary)" }}>Payslip - July, 2023</h4>
              <h4>₹{slipSummary?.employeeSummary?.totalNetPayable}</h4>
            </div>
          </div>
          {slipSummary && (
            <EmployeeSummary employeeSummary={slipSummary?.employeeSummary} />
          )}
          <div className="payslipEmployeeSummaryContainer">
            <h5>Pay Summary</h5>
            <div className="paySummaryContainer">
              {slipSummary && (
                <EarningsComponent
                  employeeSummary={slipSummary.employeeSummary}
                  earnings={slipSummary?.earnings}
                />
              )}
              {slipSummary && (
                <DeductionsComponent
                  employeeSummary={slipSummary.employeeSummary}
                  deductions={slipSummary.deduction}
                />
              )}
            </div>
            {slipSummary && (
              <ReimbursementsComponent
                employeeSummary={slipSummary.employeeSummary}
                reimbursements={slipSummary?.reimbursments}
              />
            )}
          </div>
          <div className="payslipEmployeeSummaryContainer">
            <h5
              style={{
                color: "var(--primary)",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span style={{ fontSize: "18px" }}> Total Net Payable</span>: ₹
              {slipSummary?.employeeSummary?.totalNetPayable.toFixed(2)}
              <span> </span>
              <span style={{ fontSize: "11px" }}>
                {`(${toTitleCase(
                  converter?.toWords(
                    isNaN(slipSummary?.employeeSummary?.totalNetPayable)
                      ? 0
                      : slipSummary?.employeeSummary?.totalNetPayable
                  )
                )} Only)`}
              </span>{" "}
            </h5>
            <p style={{ fontSize: "11px", fontWeight: 500 }}>
              Total Net Payable = (Gross Earnings - Total Deductions) +
              Reimbursements
            </p>
          </div>
          <div
            style={{
              fontSize: "15px",
              fontWeight: 500,
              margin: "auto",
              textAlign: "center",
              marginTop: "24px",
            }}
          >
            This is system generated pay slip no signature required-
          </div>
        </div>
      </div>
    );
};
