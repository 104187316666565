import React from "react";
import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { getUserInitials } from "../../../../../../../Utilities/socialAllFunctions";
import { Popup } from "../../../../../../../Components/FormComponent/PopupComponent/Popup";
import {
  profileImages,
  surveyPostImages,
} from "../../../../../../../Utilities/profileAndUploadImages";
import getDisplayName from "../../../../../../../Utilities/displayNameFormat";
import ProfileAvatar from "../../../../../../../Assets/ProfileAvatar.svg";
import CloseIcon from "../../../../../../../Assets/CloseIcon.svg";
import ContentSocialDisplay from "../../ContentSocialDisplay";
import { IconButton } from "@mui/material";
function SurveyShowFullAttachment({ commentedPost, postData }) {
  const navigate = useNavigate();

  // let attachment = getAttachments(fullAtachmentData);
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };

  return (
    <div className="showAttachmentsWithCommentContainer">
      <Popup
        firstClassName="showAttachmentsWithCommentPopupbox1"
        secondClassName="showAttachmentsWithCommentPopupbox2"
        createMyHead="showAttachmentsWithCommentCreateHead"
        content={
          <>
            <div className="showAttachmentsWithCommentWrapper">
              <div className="showAttachmentsWithCommentImgContainer">
                <img
                  src={
                    process.env.REACT_APP_.BASE_URL +
                    surveyPostImages +
                    postData?.attachment
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="showAttachmentsWithCommentListContainer">
              <div className="showAttachmentsWithCommentListWrapper">
                <div
                  className="showAttachmentsWithCommentListHeaderContainer"
                  style={{
                    padding: "0",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    padding:"10px",
                  }}
                >
                  <div
                    className="leftPostHeaderContainer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUserProfileClick(postData?.employeeId);
                    }}
                  >
                    <div
                      className={`${
                        postData?.image
                          ? "socialProfilePicContainer"
                          : "socialCustomProfilePic"
                      }`}
                    >
                      {!postData?.image ? (
                        getUserInitials(postData?.nameWithPersonNumber)
                      ) : (
                        <img
                          className="socialProfilePic"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUserProfileClick(postData?.employeeId);
                          }}
                          src={
                            process.env.REACT_APP_.BASE_URL +
                            profileImages +
                            postData?.image
                          }
                          alt="Profile"
                          onError={(e) => {
                            e.target.src = ProfileAvatar;
                          }}
                        />
                      )}
                    </div>

                    <div className="postMainTextContainer">
                      <label className="postMainName">
                        {getDisplayName(postData?.nameWithPersonNumber)}
                      </label>
                      <label>{postData.roleName}</label>
                      <label className="dateAndViewsHeaderContainer">
                        {postData?.locationName}
                        <span className="postHrTime">
                          &nbsp; •{" "}
                          {dateFormat(
                            postData?.createdOn,
                            "hh:MM TT | dd mmm yyyy"
                          )}
                        </span>
                      </label>
                    </div>
                  </div>
                  <IconButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img
                      src={CloseIcon}
                      alt=""
                      className="detailsPostHeaderCloseIcon"
                    />
                  </IconButton>
                </div>
                <div className="showAttachmentsWithCommentListBodyContainer">
                  <div className="showAttachmentsWithCommentListBodyWrapper"></div>
                </div>
              </div>
            </div>
          </>
        }
        handleClose={() => {
          navigate(-1);
        }}
      />
    </div>
  );
}

export default SurveyShowFullAttachment;
