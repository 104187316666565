import React, { useState, useEffect } from "react";
import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { StyledTableCell } from "../../../../Components/FormComponent/Repeated";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import SurveyAnswersDeleteIcon from "../../../../Assets/SurveyAnswersDeleteIcon.svg";

import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";

export const HotelGroupComp = ({
  formValues,
  popAnswer,
  pushEmployee,
  hotelCategoryOptions,
  isBoth,
}) => {
  const [employeeList, setEmployeeList] = useState([]);
  useEffect(() => {
    document.title = `PeopleSol - Group Hotel`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const travellingOptions = [
    { label: "One Way Trip", value: "One Way Trip" },
    { label: "Multilocation Trip", value: "Multilocation Trip" },
  ];

  const dispatch = useDispatch();
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={
          formValues?.TravellingType && !isBoth
            ? {
                borderBottom: "1px solid #E6E6E6",
                marginBottom: "20px",
                paddingBottom: " 20px",
              }
            : {}
        }
        className="formcustom threeColumn"
      >
        {!isBoth && (
          <div>
            <LabelCustom labelName={"Travel Type"} mandatory={true} />
            <SelectForm name="TravellingType" options={travellingOptions} />
          </div>
        )}
        {!isBoth &&
          formValues?.TravellingType &&
          formValues?.TravellingType === "One Way Trip" && (
            <div>
              <LabelCustom labelName={"Hotel Category"} mandatory={true} />
              <SelectForm name="HotelCategory" options={hotelCategoryOptions} />
            </div>
          )}
        {formValues?.TravellingType &&
          formValues?.TravellingType !== "One Way Trip" &&
          !isBoth && (
            <div>
              <LabelCustom labelName={"Budget (INR)"} mandatory={true} />
              <InputCustomFormik
                type="number"
                name="Budget"
                placeholder={"Please enter budget"}
              />
            </div>
          )}
      </div>
      {formValues?.TravellingType && (
        <div className="formcustom threeColumn">
          {formValues?.TravellingType !== "Multilocation Trip" && (
            <>
              {isBoth &&
                formValues?.TravellingType &&
                formValues?.TravellingType === "One Way Trip" && (
                  <div>
                    <LabelCustom
                      labelName={"Hotel Category"}
                      mandatory={true}
                    />
                    <SelectForm
                      name="HotelCategory"
                      options={hotelCategoryOptions}
                    />
                  </div>
                )}
              <div>
                <LabelCustom
                  labelName={"Check-In Date and Time"}
                  mandatory={true}
                />
                <DatePickerFormiks
                  name={"CheckInTime"}
                  minDate={new Date()}
                  showTimeSelect
                  maxDate={formValues && formValues?.CheckOutTime}
                />
              </div>
              <div>
                <LabelCustom
                  labelName={"Check-Out Date and Time"}
                  mandatory={true}
                />
                <DatePickerFormiks
                  name={"CheckOutTime"}
                  minDate={formValues ? formValues?.CheckInTime : new Date()}
                  showTimeSelect
                />
              </div>

              <div>
                <LabelCustom labelName={"Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="Location"
                  type={"text"}
                  placeholder={"Please Enter Location"}
                />
              </div>

              <div>
                <LabelCustom labelName={"Preferred Hotel"} mandatory={true} />
                <InputCustomFormik
                  maxLength={150}
                  name="PreferHotel"
                  type={"text"}
                  placeholder={"Please Enter Preferred Hotel"}
                />
              </div>
              <div>
                <LabelCustom labelName={"Budget (INR)"} mandatory={true} />
                <InputCustomFormik
                  type={"number"}
                  name="Budget"
                  placeholder={"Please enter budget"}
                />
              </div>
              <div>
                <LabelCustom labelName={"Booking Note"} />
                <TextAreaFormik
                  name="BookingNote"
                  placeholder={"Please Enter Booking Note"}
                  maxLength={500}
                />
              </div>
            </>
          )}
        </div>
      )}
      {formValues?.TravellingType &&
        formValues?.TravellingType !== "Multilocation Trip" && (
          <div>
            <label className="repeatedHeading">
              Please specify the employee's details
            </label>
            <div style={{ width: "100%", overflowX: "auto" }}>
              <FieldArray name="groupItemsNotMulti">
                {(fieldArrayProps) => {
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { groupItemsNotMulti } = values;
                  return (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S. No"} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Employee Name"}
                              mandatory={true}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Phone No."}
                              mandatory={true}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Email"}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Adults"}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom
                              labelName={"Children"}
                              style={{ margin: "0px 0px 0px 5px" }}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {groupItemsNotMulti?.map((groupItemss, index) => (
                        <TableBody key={index}>
                          {/* 1st column */}
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          {/* 2nd column */}

                          <StyledTableCell>
                            <SelectForm
                              name={`groupItemsNotMulti[${index}].EmployeeId`}
                              options={employeeList}
                            />
                          </StyledTableCell>
                          {/* 3rd column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              type="number"
                              step="0.01"
                              name={`groupItemsNotMulti[${index}].PhoneNumber`}
                              placeholder={" Enter Phone No."}
                            />
                          </StyledTableCell>
                          {/* 4th column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              maxLength={320}
                              type="text"
                              name={`groupItemsNotMulti[${index}].EmailId`}
                              placeholder={"Enter the Email"}
                            />
                          </StyledTableCell>
                          {/* 5th column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              type="number"
                              step="0.01"
                              name={`groupItemsNotMulti[${index}].Adults`}
                              placeholder={"Enter the Age"}
                            />
                          </StyledTableCell>
                          {/* 6th column */}
                          <StyledTableCell>
                            <InputCustomFormik
                              type="number"
                              step="0.01"
                              name={`groupItemsNotMulti[${index}].Children`}
                              placeholder={"Enter the Age"}
                            />
                          </StyledTableCell>

                          <StyledTableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                groupItemsNotMulti.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  EmployeeId: null,
                                  PhoneNumber: "",
                                  EmailId: "",
                                  Adults: "",
                                  Children: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  );
                }}
              </FieldArray>
            </div>
          </div>
        )}
      {formValues?.TravellingType &&
        formValues?.TravellingType === "Multilocation Trip" && (
          <div>
            <FieldArray name="groupItems">
              {(fieldArrayProps) => {
                const { remove, insert, form } = fieldArrayProps;
                const { values } = form;
                const { groupItems } = values;
                return (
                  <Table style={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Journey"} />
                        </StyledTableCell>

                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {groupItems?.map((Questions, index) => (
                      <TableBody key={index}>
                        <StyledTableCell sx={{ width: "96px" }} align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ maxWidth: "920px", overflowX: "auto" }}
                        >
                          <div
                            style={{
                              overflowX: "auto",
                              display: "flex",
                              padding: "20px 0px 0px 0px",
                            }}
                          >
                            <div style={{ display: "flex", flexShrink: "1" }}>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Hotel Category"}
                                  mandatory={true}
                                />
                                <SelectForm
                                  name={`groupItems[${index}].HotelCategory`}
                                  options={hotelCategoryOptions}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Check-In Date and Time"}
                                  mandatory={true}
                                />
                                <DatePickerFormiks
                                  name={`groupItems[${index}].CheckInTime`}
                                  minDate={new Date()}
                                  showTimeSelect
                                  maxDate={
                                    formValues?.groupItems
                                      ? formValues?.groupItems[index]
                                          ?.CheckOutTime
                                      : null
                                  }
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Check-Out Date and Time"}
                                  mandatory={true}
                                />
                                <DatePickerFormiks
                                  name={`groupItems[${index}].CheckOutTime`}
                                  minDate={
                                    formValues?.groupItems
                                      ? formValues?.groupItems[index]
                                          ?.CheckInTime
                                      : new Date()
                                  }
                                  showTimeSelect
                                />
                              </div>

                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Location"}
                                  mandatory={true}
                                />
                                <InputCustomFormik
                                  maxLength={50}
                                  name={`groupItems[${index}].Location`}
                                  type={"text"}
                                  placeholder={"Please Enter Location"}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Preferred Hotel"}
                                  mandatory={true}
                                />
                                <InputCustomFormik
                                  maxLength={150}
                                  name={`groupItems[${index}].PreferHotel`}
                                  type={"text"}
                                  placeholder={"Please Enter Preferred Hotel"}
                                />
                              </div>
                              {isBoth && (
                                <div style={{ width: "245px" }}>
                                  <LabelCustom
                                    labelName={"Budget (INR)"}
                                    mandatory={true}
                                  />
                                  <InputCustomFormik
                                    name={`groupItems[${index}].Budget`}
                                    type="number"
                                    placeholder={"Please enter budget"}
                                  />
                                </div>
                              )}
                              <div style={{ width: "245px" }}>
                                <LabelCustom labelName={"Booking Note"} />
                                <TextAreaFormik
                                  name={`groupItems[${index}].Bookingnote`}
                                  placeholder={" Enter Note"}
                                  maxLength={500}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ maxHeight: "300px", overflowY: "auto" }}
                          >
                            {Questions?.EmployeeDetails?.map((item, indexW) => (
                              <div
                                style={{
                                  overflow: "auto",
                                  width: "100%",
                                  height: "197px",
                                }}
                              >
                                <div
                                  className="formcustom sixColumn"
                                  style={{
                                    minWidth: "1152px",
                                    padding: "18px 0px",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "67px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <LabelCustom labelName={"S. No."} />

                                    <div style={{ padding: "21px 8px" }}>
                                      {indexW + 1}
                                    </div>
                                  </div>
                                  <div style={{ width: "264px" }}>
                                    <LabelCustom
                                      labelName={"Employee Name"}
                                      mandatory={true}
                                    />

                                    <SelectForm
                                      name={`groupItems[${index}].EmployeeDetails[${indexW}].EmployeeId`}
                                      options={employeeList}
                                    />
                                  </div>
                                  <div style={{ width: "264px" }}>
                                    <LabelCustom
                                      labelName={"Phone No."}
                                      mandatory={true}
                                    />

                                    <InputCustomFormik
                                      maxLength={10}
                                      type="number"
                                      step="0.01"
                                      name={`groupItems[${index}].EmployeeDetails[${indexW}].PhoneNumber`}
                                      placeholder={" Enter Phone No."}
                                    />
                                  </div>
                                  <div style={{ width: "264px" }}>
                                    <LabelCustom
                                      labelName={"Email"}
                                      mandatory={true}
                                    />
                                    <InputCustomFormik
                                      maxLength={320}
                                      type="text"
                                      name={`groupItems[${index}].EmployeeDetails[${indexW}].EmailId`}
                                      placeholder={"Enter the Email"}
                                    />
                                  </div>
                                  <div style={{ width: "264px" }}>
                                    <LabelCustom
                                      labelName={"Adults"}
                                      // mandatory={true}
                                    />
                                    <InputCustomFormik
                                      type="number"
                                      step="0.01"
                                      name={`groupItems[${index}].EmployeeDetails[${indexW}].Adults`}
                                      placeholder={"Enter "}
                                    />
                                  </div>
                                  <div style={{ width: "264px" }}>
                                    <LabelCustom
                                      labelName={"Children"}
                                      // mandatory={true}
                                    />
                                    <InputCustomFormik
                                      type="number"
                                      step="0.01"
                                      name={`groupItems[${index}].EmployeeDetails[${indexW}].Children`}
                                      placeholder={"Enter"}
                                    />
                                  </div>
                                </div>
                                {indexW ===
                                Questions?.EmployeeDetails?.length - 1 ? (
                                  <div
                                    className="surveyAddBtnContainer"
                                    style={{
                                      position: "sticky",
                                      left: "86%",
                                      /* right: 30px; */
                                      bottom: "21px",
                                      width: "131px",
                                      // position: "absolute",
                                      // right: "12px",
                                      // bottom: "21px",
                                    }}
                                    onClick={() => pushEmployee(index)}
                                  >
                                    <button
                                      type="button"
                                      className="add-survey-btn"
                                    >
                                      +
                                    </button>{" "}
                                    Add Employee
                                  </div>
                                ) : (
                                  <div
                                    className="surveyAddBtnContainer"
                                    style={{
                                      position: "sticky",
                                      left: "83.5%",
                                      bottom: "21px",
                                      width: "140px",
                                      display: "flex",
                                      alignItems: "center",
                                      color: "red",
                                    }}
                                    onClick={() => popAnswer(index, indexW)}
                                  >
                                    <img
                                      // onClick={() => {
                                      //   popAnswer(answer, answerIndex, index);
                                      // }}
                                      style={{
                                        marginRight: "5px",
                                        cursor: "pointer",
                                      }}
                                      src={SurveyAnswersDeleteIcon}
                                      alt="SurveyAnswersDeleteIcon"
                                    />
                                    Remove Employee
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ width: "100px", textAlign: "center" }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              groupItems.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() => {
                              insert(index + 1, {
                                HotelCategory: null,
                                CheckInTime: null,
                                CheckOutTime: null,
                                Location: "",
                                PreferHotel: "",
                                Bookingnote: "",
                                Budget: "",
                                EmployeeDetails: [
                                  {
                                    EmployeeId: null,
                                    Adults: "",
                                    Children: "",
                                    PhoneNumber: "",
                                    EmailId: "",
                                  },
                                ],
                              });
                            }}
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                );
              }}
            </FieldArray>
          </div>
        )}
    </>
  );
};
