import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { RadioFormik } from "../../../../Components/FormComponent/RadioFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { currentMonth } from "../../../../Utilities/currentMonth";
const daysof = [
  // { label: '1', value: '1' },
  // { label: '2', value: '2' },
  // { label: '3', value: '3' },
  // { label: '4', value: '4' },
  // { label: '5', value: '5' },
  // { label: '6', value: '6' },
  // { label: '7', value: '7' },
  // { label: '8', value: '8' },
  // { label: '9', value: '9' },
  // { label: '10', value: '10' },
  // { label: '11', value: '11' },
  // { label: '12', value: '12' },
  // { label: '13', value: '13' },
  // { label: '14', value: '14' },
  // { label: '15', value: '15' },
  // { label: '16', value: '16' },
  // { label: '17', value: '17' },
  // { label: '18', value: '18' },
  // { label: '19', value: '19' },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
  { label: "31", value: 31 },
];
const daysf = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
];

export const CreatePayConfigComp = ({ formValues, data }) => {
  const dispatch = useDispatch();
  const [areaList, setareaList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const filterDates = (date) => {
    // Disable dates that are not in April of the selected year
    return date?.getMonth() === selectedDate?.getMonth();
  };
  // Function to get the current financial year based on the current date
  function getCurrentFinancialYear() {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month
    const currentYear = today.getFullYear();

    // Financial year starts from April, so if the current month is April or later,
    // the financial year starts from the current year's April, else it starts from
    // the previous year's April
    const startYear = currentMonth >= 4 ? currentYear : currentYear - 1;
    const endYear = startYear + 1;

    return { startYear, endYear };
  }

  // Function to generate dropdown options for the financial year
  function generateFinancialYearOptions() {
    const { startYear, endYear } = getCurrentFinancialYear();
    const options = [];

    for (let year = startYear; year <= endYear; year++) {
      const startMonth = year === startYear ? 4 : 1; // If it's the starting year, start from April, else start from January
      const endMonth = year === endYear ? 3 : 12; // If it's the ending year, end in March, else end in December

      for (let month = startMonth; month <= endMonth; month++) {
        const label = `${new Date(year, month - 1).toLocaleString("default", {
          month: "long",
        })}-${year}`;
        options.push({ label, value: label });
      }
    }

    return options;
  }

  // Usage example
  const dropdownOptions = generateFinancialYearOptions();
  console.log(dropdownOptions);

  const getEmployeeData = async () => {
    try {
      const result = await getRequest("PayArea/GetPayAreaDropDown");
      let listData = [];
      result.data?.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setareaList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    if (formValues?.firstPayrollFrom) {
      const monthYear = formValues.firstPayrollFrom;
      let day = 1;
      if (
        formValues?.payYourEmployeesOn !== "The last working day of every month"
      ) {
        day =
          formValues?.payYourEmployeesOnValue !== 0
            ? formValues?.payYourEmployeesOnValue
            : 1;
      } else {
        day = 30;
      }
      const [month, year] = monthYear.split("-");
      const currentDate = new Date(`${month} 1, ${year}`);

      if (
        formValues?.payYourEmployeesOn === "The last working day of every month"
      ) {
        currentDate.setMonth(currentDate.getMonth());
      } else {
        currentDate.setMonth(currentDate.getMonth() + 1);
      } // Add one month

      // Use the updated month and year in the selected date
      const selectedDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        day
      );

      setSelectedDate(selectedDate);
      formValues.paidOn = selectedDate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValues?.firstPayrollFrom,
    formValues?.payYourEmployeesOnValue,
    formValues?.payYourEmployeesOn,
  ]);

  useEffect(() => {
    document.title = `PeopleSol - Create Pay Area `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div>
        <LabelCustom
          clas="rebo"
          labelName={"Select your Pay Area"}
          mandatory={true}
        />
      </div>
      <div>
        <SelectForm
          options={areaList}
          name="payAreaId"
          placeholder="Select Area"
        />
      </div>
      <div>
        <LabelCustom
          clas="rebo"
          labelName={"Calculate monthly salary based on"}
          mandatory={true}
        />
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <RadioFormik
            editstyle={{ paddingTop: "14px" }}
            name="calculateMonthlySalaryBasedOn"
            options={[
              {
                label: "Actual days in a month",
                value: "Actual days in a month",
              },
            ]}
            // value={ formValues?.SalaryRevisionValue ? detailsForm.annualCTC+(detailsForm.annualCTC*formValues?.SalaryRevisionValue/100):''}
            placeholder="Enter revised CtC in  %"
          />
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <RadioFormik
            editstyle={{ paddingTop: "14px" }}
            name="calculateMonthlySalaryBasedOn"
            options={[
              {
                label: "Organisation working days",
                value: "Organisation working days",
              },
            ]}
            placeholder="Enter template name"
          />
        </div>
      </div>
      <div>
        <LabelCustom
          clas="rebo"
          labelName={"Pay your employees on"}
          mandatory={true}
        />
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <RadioFormik
            editstyle={{ paddingTop: "14px" }}
            name="payYourEmployeesOn"
            options={[
              {
                label: "The last working day of every month",
                value: "The last working day of every month",
              },
            ]}
            // value={ formValues?.SalaryRevisionValue ? detailsForm.annualCTC+(detailsForm.annualCTC*formValues?.SalaryRevisionValue/100):''}
            placeholder="Enter revised CtC in  %"
          />
        </div>
        <div style={{ display: "flex", alignItems: "self-start" }}>
          <RadioFormik
            editstyle={{ paddingTop: "14px" }}
            name="payYourEmployeesOn"
            options={[
              {
                label: "Day",
                value: "day",
              },
            ]}
            placeholder="Enter template name"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <SelectForm
                disabled={
                  formValues?.payYourEmployeesOn ===
                  "The last working day of every month"
                    ? true
                    : false
                }
                options={daysf}
                name="payYourEmployeesOnValue"
                placeholder=" Enter Days Per Month"
              />
              <span>of every month</span>
            </span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <DatePickerFormiks
                disabled={data ? true : false}
                openToDate={selectedDate}
                name="paidOn"
                filterDate={filterDates}
              />
              <span>To Be Paid On</span>
            </div>
          </div>
        </div>
        {/* <p style={{ paddingLeft: "12px" }}>
          {" "}
          Note:
          <span
            style={{
              color: "var(--primary)",
              fontWeight: 500,
              fontSize: "12px",
            }}
          >
            {" "}
            When payday falls on a non-working day or a holiday, employees will
            get paid on the previous working day.
          </span>
        </p> */}
      </div>
      <div style={{ marginTop: "12px" }}>
        <LabelCustom
          clas="rebo"
          labelName={"Select your Payment Date"}
          mandatory={true}
        />
      </div>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom
            labelName={"Select Month From Current Financial Year"}
            mandatory={true}
          />
          <SelectForm
            // disabled={data ? true : false}
            menuPosition={'top'}
            options={dropdownOptions}
            name="firstPayrollFrom"
          />
        </div>
      </div>
    </>
  );
};
