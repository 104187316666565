import React from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { postRequest } from "../../../../../../../Services/axios";
import { showToast } from "../../../../../../../Features";
import SurveyShowFullAttachment from "./SurveyShowFullAttachment";

function SurveyPostDetails() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const result = await postRequest(`Survey/AllList`, {
        createdOn: null,
        lastUpdatedOn: null,
        Id: location?.state?.postid,
        dataTableRequest: {
          iDisplayLength: 10,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      setData(result?.data?.data[0]?.item);
      // console.log("res",result?.data?.data[0]?.item);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return <SurveyShowFullAttachment getData={getData} postData={data} />;
}

export default SurveyPostDetails;
