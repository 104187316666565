import React from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import "./Community.css";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import PlusYellowIcon from "../../../../../Assets/PlusYellowIcon.svg";
import { IconButton } from "@mui/material";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SearchMagnifierIcon from "../../../../../Assets/SearchMagnifierIcon.svg";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import MakeRemoveAdminActivityContainer from "./MakeRemoveAdminActivityContainer";
import { useSelector } from "react-redux";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";

function CommunityAdminMemberDetails() {
  const [commData, setCommData] = useState();
  const [communityTeamMemberData, setCommunityTeamMemberData] = useState();
  const [searchCommData, setSearchCommData] = useState();
  const [loading, setLoading] = useState(false);
  const { communityid } = useParams();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedParticipant, setSelectedParticipant] = useState([]);
  const [isEmployeeSelectActive, setIsEmployeeSelectActive] = useState(false);
  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [selectEmployee, setSelectEmployee] = useState();
  const [communityAdminAndMemberList, setCommunityAdminAndMemberList] =
    useState([]);
  const [canManageSocial, setCanManageSocial] = useState(
    LoggedInUser?.permissions?.includes("Can Manage Social?")
  );
  const [
    communityAdminAndMemberListSearch,
    setCommunityAdminAndMemberListSearch,
  ] = useState([]);
  const [makeAndRemoveAdmin, setMakeAndRemoveAdmin] = useState(false);

  function searchAdminMemberList(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(
        JSON.stringify(communityAdminAndMemberListSearch)
      );
      tempChatters = tempChatters.filter((d, i) =>
        d?.name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
      );
      setCommunityAdminAndMemberList(tempChatters);
    } else {
      setCommunityAdminAndMemberList(communityAdminAndMemberListSearch);
    }
  }
  function addMemberSearch(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(selectEmployee));
      tempChatters = tempChatters.filter((d, i) =>
        d?.name?.toLowerCase()?.includes(e.currentTarget.value?.toLowerCase())
      );
      setAllEmployeeList(tempChatters);
    } else {
      setAllEmployeeList(selectEmployee);
    }
  }

  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        `Social/GetEmployeesImageDropDown?CommunityId=${communityid}`
      );
      if (result?.data?.length <= 0) {
      }
      setAllEmployeeList(result?.data.textfilters);
      setSelectEmployee(result?.data.textfilters);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const removeFromCommunity = async (cid, eid) => {
    try {
      const result = await postRequest(
        `Community/RemoveGroupMember?CommunityId=${cid}&MemberId=${eid}`
      );
      if (result?.data?.isSuccess) {
        getCommunityData();
        setMakeAndRemoveAdmin(false);
        dispatch(
          showToast({
            text: "Member deleted successfully!",
            severity: "success",
          })
        );
      } else if (
        !result?.data?.isSuccess &&
        result?.data?.message === "UnAthorized Access"
      ) {
        dispatch(
          showToast({
            text: "UnAthorized Access",
            severity: "error",
          })
        );
        return;
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in posting comment",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const markAdmin = async (cid, eid) => {
    try {
      const result = await getRequest(
        `Community/MarkAdmin?CommunityId=${cid}&MemberId=${eid}`
      );

      if (result?.data?.isSuccess) {
        getCommunityData();
        setMakeAndRemoveAdmin(false);
        dispatch(
          showToast({
            text: "Member marked as Admin successfully!",
            severity: "success",
          })
        );
      } else if (
        !result?.data?.isSuccess &&
        result?.data?.message === "UnAthorized Access"
      ) {
        dispatch(
          showToast({
            text: "UnAthorized Access",
            severity: "error",
          })
        );
        return;
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in posting comment",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const unmarkAdmin = async (cid, eid) => {
    try {
      const result = await getRequest(
        `Community/UnmarkAdmin?CommunityId=${cid}&MemberId=${eid}`
      );

      if (result?.data?.isSuccess) {
        getCommunityData();
        setMakeAndRemoveAdmin(false);
        dispatch(
          showToast({
            text: "Member unmarked as Admin successfully!",
            severity: "success",
          })
        );
      } else if (
        !result?.data?.isSuccess &&
        result?.data?.message === "UnAthorized Access"
      ) {
        dispatch(
          showToast({
            text: "UnAthorized Access",
            severity: "error",
          })
        );
        return;
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in posting comment",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const addMembersToComunity = async () => {
    try {
      if (selectedParticipant?.length === 1) {
        const result = await postRequest(
          `Community/AddGroupMember?CommunityId=${communityid}&MemberId=${selectedParticipant[0]?.id}`
        );
      }
      if (selectedParticipant?.length > 1) {
        let ids = [];
        selectedParticipant.forEach((d) => {
          ids.push(d?.id);
        });
        const result = await postRequest(`Community/AddMultipleGroupMember`, {
          CommunityId: Number(communityid),
          MemberId: ids,
        });
      }
      setSelectedParticipant([]);
      setIsEmployeeSelectActive(false);
      dispatch(
        showToast({
          text: "Members Added Successfully!",
          severity: "success",
        })
      );
      getCommunityData();
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getCommunityData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`Community/Details?Id=${communityid}`);
      if (result?.data?.data) {
        setCommunityTeamMemberData(result?.data?.data);
        setCommData(result?.data?.summary);
        setSearchCommData(result?.data?.summary);
      }
      let updatedList = [];
      for (let i = 0; i < result?.data?.summary?.groupMembers?.length; i++) {
        let isMemberAdmin = false;
        for (let j = 0; j < result?.data?.summary?.groupAdmin?.length; j++) {
          if (
            result?.data?.summary?.groupMembers[i]?.personId ===
            result?.data?.summary?.groupAdmin[j]?.personId
          ) {
            isMemberAdmin = true;
            break;
          }
        }
        updatedList.push({
          ...result?.data?.summary?.groupMembers[i],
          isMemberAdmin,
        });
      }
      updatedList.sort((a, b) => (b?.isMemberAdmin ? 1 : -1));
      setCommunityAdminAndMemberList(updatedList);
      setCommunityAdminAndMemberListSearch(updatedList);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  function getDisplayName(nameWithPersonNumber) {
    let index = nameWithPersonNumber?.indexOf("(");
    let name = nameWithPersonNumber?.substring(0, index);
    return name;
  }
  useEffect(() => {
    getCommunityData();
  }, [communityid]);
  return (
    <div className="communityAdminMemberContainer">
      <div className="communityAdminMemberWrapper">
        <div className="communityAdminContainer">
          <h4>Admin</h4>
          <div className="communityAdminListContainer">
            {commData &&
              commData?.groupAdmin?.map((d, i) => {
                return (
                  <div className="communityAdminList">
                    <div>
                      <div
                        onClick={(e) => {
                          navigate(`/home/social/userprofile/${d?.personId}`);
                        }}
                        style={{ margin: "0", marginRight: "10px" }}
                        className={`${
                          d?.profilePicture
                            ? "socialProfilePicContainer"
                            : "socialCustomProfilePic"
                        }`}
                      >
                        {!d?.profilePicture && d?.name ? (
                          getUserInitials(getDisplayName(d?.name))
                        ) : (
                          <img
                            className="socialProfilePic"
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              profileImages +
                              d?.profilePicture
                            }
                            alt="Profile"
                            onError={(e) => {
                              e.target.src = ProfileAvatar;
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="communityAdminPrifileContainer">
                      <div className="communityAdminPrifileWrapper">
                        <h6>{getDisplayName(d?.name)}</h6>
                        <p>{d?.roal}</p>
                        <p>{d?.location}</p>
                      </div>
                      {communityTeamMemberData?.createdby !== d?.personId && (
                        <div className="communityMemberBtn">
                          <button>Admin</button>
                        </div>
                      )}
                      {communityTeamMemberData?.createdby === d?.personId && (
                        <div className="communityAdminBtn">
                          <button>Creator</button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="communityMemberContainer">
          <h4>{commData ? commData?.groupMembers?.length : 0} Members</h4>
          {((communityTeamMemberData?.createdby === LoggedInUser?.id &&
            communityTeamMemberData?.communityType === "private") ||
            (canManageSocial &&
              communityTeamMemberData?.communityType === "private")) && (
            <div
              className="showCommunityRightHeaderContainer"
              onClick={() => {
                setIsEmployeeSelectActive(true);
                getEmployeeData();
              }}
            >
              <IconButton>
                <img
                  src={PlusYellowIcon}
                  alt=""
                  style={{ marginRight: "7px", cursor: "pointer" }}
                />
                <span>Add Members</span>
              </IconButton>
            </div>
          )}
        </div>
        <div className="CommunityMemberImgContainer">
          <div className="CommunityMemberImgWrapper">
            <div className="CommunityMemberImgList">
              {commData &&
                commData?.groupMembers?.length === 1 &&
                commData?.groupMembers?.map((d, i) => {
                  return (
                    <img
                      src={
                        d?.profilePicture
                          ? process.env.REACT_APP_.BASE_URL +
                            profileImages +
                            d?.profilePicture
                          : ProfileAvatar
                      }
                      alt=""
                    />
                  );
                })}
              {commData?.groupMembers?.length === 2 && (
                <div className="">
                  <img
                    src={
                      commData?.groupMembers[0]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[0]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                  <img
                    src={
                      commData?.groupMembers[1]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[1]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                </div>
              )}
              {commData?.groupMembers?.length === 3 && (
                <div className="">
                  <img
                    src={
                      commData?.groupMembers[0]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[0]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                  <img
                    src={
                      commData?.groupMembers[1]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[1]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                  <img
                    src={
                      commData?.groupMembers[2]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[2]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                </div>
              )}
              {commData?.groupMembers?.length >= 4 && (
                <div className="">
                  <img
                    src={
                      commData?.groupMembers[0]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[0]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                  <img
                    src={
                      commData?.groupMembers[1]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[1]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                  <img
                    src={
                      commData?.groupMembers[2]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[2]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                  <img
                    src={
                      commData?.groupMembers[3]?.profilePicture
                        ? process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commData?.groupMembers[3]?.profilePicture
                        : ProfileAvatar
                    }
                    alt=""
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                </div>
              )}

              <span
                onClick={() => {
                  setMakeAndRemoveAdmin(true);
                }}
              >
                {" "}
                +{commData?.groupMembers?.length} Members...
              </span>
            </div>
            <div className="CommunityMemberCounts"></div>
          </div>
        </div>
      </div>
      {isEmployeeSelectActive && (
        <Popup
          firstClassName="SelectCommunityMembers1"
          secondClassName="SelectCommunityMembers2"
          contentClassName="SelectCommunityMemberContent"
          bodyClassName="SelectCommunityMembersBody"
          popupHeading={"Add New Member"}
          content={
            <div className="newCommunityContainer">
              <div className="communityEmployeeSearchContainer">
                {selectedParticipant?.length > 0 && (
                  <div className="communitySelectedEmployeeContainer">
                    {selectedParticipant.map((item, index) => {
                      return (
                        <div className="communitySelectChipContainer">
                          <div
                            className="chipImg"
                            style={{
                              backgroundImage: `url(${SocialCommunityGroupIcon})`,
                            }}
                          >
                            {/* <img src={item?.image ? process.env.REACT_APP_.BASE_URL + profileImages + item?.image : ""} alt=""/> */}
                          </div>
                          <label className="chipLabel">{item.name}</label>
                          <IconButton
                            onClick={() => {
                              let tempList = JSON.parse(
                                JSON.stringify(selectedParticipant)
                              );
                              tempList = tempList.filter((selectedValue) => {
                                return (
                                  Number(selectedValue.id) !== Number(item.id)
                                );
                              });
                              setSelectedParticipant(tempList);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="9"
                              viewBox="0 0 10 9"
                              fill="none"
                            >
                              <line
                                x1="8.11133"
                                y1="1.68654"
                                x2="1.44905"
                                y2="8.34882"
                                stroke="#555555"
                                stroke-linecap="round"
                              />
                              <line
                                x1="0.5"
                                y1="-0.5"
                                x2="9.92189"
                                y2="-0.5"
                                transform="matrix(0.707107 0.707107 0.707107 -0.707107 1.67139 0.979431)"
                                stroke="#555555"
                                stroke-linecap="round"
                              />
                            </svg>
                          </IconButton>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="chatListSearchContainer">
                  <input
                    type="text"
                    className={`chatListSearch ${
                      selectedParticipant?.length > 0
                        ? "communityListSearch"
                        : null
                    }`}
                    placeholder="Search"
                    onChange={addMemberSearch}
                  />
                  <img
                    src={SearchMagnifierIcon}
                    alt=""
                    style={{ marginRight: "15px", cursor: "pointer" }}
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", borderBottom: "1px solid #EEE" }}
              ></div>
              {allEmployeeList?.length <= 0 && <Loader />}
              {allEmployeeList?.length > 0 && (
                <div className="communityMemberListBox newScrollbar">
                  {allEmployeeList.map((item, index) => (
                    <div
                      onClick={() => {
                        let searchSelectedParticipant =
                          selectedParticipant?.find((d) => d?.id === item?.id);
                        if (!searchSelectedParticipant)
                          setSelectedParticipant([
                            ...selectedParticipant,
                            item,
                          ]);
                      }}
                      className="communityAllEmployeeListContainer"
                    >
                      <div className="newChatActiveImage">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/home/social/userprofile/${item?.id}`);
                          }}
                          className={`${
                            item?.image
                              ? "socialProfilePicContainer"
                              : "socialCustomProfilePic"
                          }`}
                        >
                          {!item?.image ? (
                            getUserInitials(getDisplayName(item?.name))
                          ) : (
                            <img
                              className="socialProfilePic"
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                profileImages +
                                item?.image
                              }
                              alt="Profile"
                              onError={(e) => {
                                e.target.src = ProfileAvatar;
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="newChatPostMainTextContainer">
                        <label className="chatListName">
                          {getDisplayName(item?.name)}
                        </label>
                        <label className="chatListRole">{item?.jobRoal}</label>
                        <label className="chatListRole">{item?.location}</label>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedParticipant.length > 0 && (
                <div
                  className="groupCreationNxtBtn"
                  onClick={() => {
                    addMembersToComunity();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                  >
                    <circle cx="18.6455" cy="18.8914" r="18" fill="#1296B0" />
                    <path
                      d="M27.8232 19.1169L21.3721 12.5392M27.8232 19.1169L21.3721 24.9637M27.8232 19.1169H9.18649"
                      stroke="white"
                      stroke-width="1.10154"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>
          }
          handleClose={() => {
            setIsEmployeeSelectActive(false);
            setSelectedParticipant([]);
          }}
        />
      )}

      {makeAndRemoveAdmin && (
        <Popup
          firstClassName="SelectCommunityMembers1"
          secondClassName="SelectCommunityMembers2"
          contentClassName="SelectCommunityMemberContent"
          bodyClassName="SelectCommunityMembersBody"
          popupHeading={"Community Members"}
          content={
            <div className="newCommunityContainer">
              <div className="communityEmployeeSearchContainer">
                <div className="chatListSearchContainer">
                  <input
                    type="text"
                    className={`chatListSearch ${
                      selectedParticipant?.length > 0
                        ? "communityListSearch"
                        : null
                    }`}
                    placeholder="Search"
                    onChange={searchAdminMemberList}
                  />
                  <img
                    src={SearchMagnifierIcon}
                    alt=""
                    style={{ marginRight: "15px", cursor: "pointer" }}
                  />
                </div>
              </div>
              <div
                style={{ width: "100%", borderBottom: "1px solid #EEE" }}
              ></div>
              {commData?.groupAdmin?.length <= 0 && <Loader />}
              {commData?.groupAdmin?.length > 0 && (
                <div className="communityMemberListBox newScrollbar">
                  {communityAdminAndMemberList?.map((d, i) => {
                    return (
                      <div
                        className="communityGroupEmployeeListContainer "
                        style={{
                          justifyContent: "space-between",
                          paddingRight: "15px",
                        }}
                      >
                        <div
                          className="employeeList"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="newChatActiveImage">
                            <div
                              className={`${
                                d?.profilePicture
                                  ? "socialProfilePicContainer"
                                  : "socialCustomProfilePic"
                              }`}
                            >
                              {!d?.profilePicture && d?.name ? (
                                getUserInitials(getDisplayName(d?.name))
                              ) : (
                                <img
                                  className="communityViewPageProfilePic"
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    profileImages +
                                    d?.profilePicture
                                  }
                                  alt="Profile"
                                  onError={(e) => {
                                    e.target.src = ProfileAvatar;
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="newChatPostMainTextContainer">
                            <label className="chatListName">
                              {getDisplayName(d?.name)}
                            </label>
                            <label className="chatListRole">{d?.roal}</label>
                            <label className="chatListRole">
                              {d?.location}
                            </label>
                          </div>
                        </div>

                        {d?.isMemberAdmin ? (
                          <div className="makeRemoveAdminOption">
                            <span>Admin</span>
                            {(LoggedInUser?.id ===
                              communityTeamMemberData?.createdby ||
                              canManageSocial) && (
                              <MakeRemoveAdminActivityContainer
                                isAdminActive={true}
                                isMemberActive={false}
                                employeeId={d?.personId}
                                communityId={communityid}
                                unmarkAdmin={unmarkAdmin}
                                removeFromCommunity={removeFromCommunity}
                              />
                            )}
                          </div>
                        ) : (
                          <div className="makeRemoveAdminOption">
                            {(commData?.groupAdminId?.includes(
                              LoggedInUser?.id
                            ) ||
                              canManageSocial) && (
                              <MakeRemoveAdminActivityContainer
                                isAdminActive={false}
                                isMemberActive={true}
                                employeeId={d?.personId}
                                communityId={communityid}
                                markAdmin={markAdmin}
                                removeFromCommunity={removeFromCommunity}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          }
          handleClose={() => {
            setMakeAndRemoveAdmin(false);
          }}
        />
      )}
    </div>
  );
}

export default CommunityAdminMemberDetails;
