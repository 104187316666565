import React from "react";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import starWhite from "../../../Assets/starWhite.svg";
import starYellow from "../../../Assets/starYellow.svg";
import doubleTickGreen from "../../../Assets/doubleTickGreen.svg";
import DropdownArrowChatMesssageWhite from "../../../Assets/DropdownArrowChatMesssageWhite.svg";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import PhotosAndVideos from "./PhotosAndVideos";
import DocumentsChat from "./DocumentsChat";
const MediaTypeMessageComponent = ({
  message,
  messageClass,
  setPopupOpen,
  popupOpen,
  setReplyData,
  setForwardData,
  toggleBookMark,
  deleteMessage,
  LoggedInUser,
  messageType,
  ViewFullscreen,
}) => {
  if (messageType === 6) {
    return (
      <PhotosAndVideos
        messageType={messageType}
        message={message}
        messageClass={messageClass}
        setPopupOpen={setPopupOpen}
        popupOpen={popupOpen}
        setReplyData={setReplyData}
        setForwardData={setForwardData}
        toggleBookMark={toggleBookMark}
        deleteMessage={deleteMessage}
        LoggedInUser={LoggedInUser}
        ViewFullscreen={ViewFullscreen}
      />
    );
  } else if (messageType === 4) {
    return (
      <DocumentsChat
        messageType={messageType}
        message={message}
        messageClass={messageClass}
        setPopupOpen={setPopupOpen}
        popupOpen={popupOpen}
        setReplyData={setReplyData}
        setForwardData={setForwardData}
        toggleBookMark={toggleBookMark}
        deleteMessage={deleteMessage}
        LoggedInUser={LoggedInUser}
      />
    );
  } else if (
    message?.messageType > 0 ||
    (message?.messageType === 0 &&
      message?.replyingToMessage?.messageType === 4 &&
      message?.replyingToMessage?.id)
  ) {
    return (
      <DocumentsChat
        messageType={messageType}
        message={message}
        messageClass={messageClass}
        setPopupOpen={setPopupOpen}
        popupOpen={popupOpen}
        setReplyData={setReplyData}
        setForwardData={setForwardData}
        toggleBookMark={toggleBookMark}
        deleteMessage={deleteMessage}
        LoggedInUser={LoggedInUser}
      />
    );
  } else if (
    message?.messageType > 0 ||
    (message?.messageType === 0 &&
      message?.replyingToMessage?.messageType === 6 &&
      message?.replyingToMessage?.id)
  ) {
    return (
      <PhotosAndVideos
        messageType={messageType}
        message={message}
        messageClass={messageClass}
        setPopupOpen={setPopupOpen}
        popupOpen={popupOpen}
        setReplyData={setReplyData}
        setForwardData={setForwardData}
        toggleBookMark={toggleBookMark}
        deleteMessage={deleteMessage}
        LoggedInUser={LoggedInUser}
        ViewFullscreen={ViewFullscreen}
      />
    );
  }
};

export default MediaTypeMessageComponent;
