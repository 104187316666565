/* eslint-disable react-hooks/exhaustive-deps */
import { Calendar } from "antd";
import "./MyAttendance.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { MyAttendanceComp } from "./MyAttendanceComp";
import { MonthDetailComp } from "./MonthDetailComp/MonthDetailComp";
import dateFormat from "dateformat";
import { PresentComp } from "./AttendanceComp/PresentComp";
import { LeaveComp } from "./AttendanceComp/LeaveComp";
import { AbsentComp } from "./AttendanceComp/AbsentComp";
import { HalfDayComp } from "./AttendanceComp/HalfDayComp";
import { OutdoorComp } from "./AttendanceComp/OutdoorComp";
import { OptionalLeaveComp } from "./AttendanceComp/OptionalLeaveComp";
import { HolidayComp } from "./AttendanceComp/HolidayComp";
import { BlankComp } from "./AttendanceComp/BlankComp";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { TodayComp } from "./AttendanceComp/TodayComp";
import { WeeklyOffComp } from "./AttendanceComp/WeeklyOffComp";
import { useSelector } from "react-redux";
import SelectCustomEmployeeCreate from "../../../DropdownComponents/SelectCustomEmployeeCreate";
import SelectCustomEmployeeCreateAttendance from "./SelectCustomEmployeeCreateAttendance";

const MyAttendance = (props) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);

  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);

  const [idActive, setIdActive] = useState(LoggedInUser?.id);
  const [dateObject, setDateObject] = useState({
    startDate: dateFormat(
      new Date(new Date()?.getFullYear(), new Date()?.getMonth(), 1),
      "yyyy-mm-dd"
    ),
    endDate: dateFormat(
      new Date(new Date()?.getFullYear(), new Date()?.getMonth() + 1, 0),
      "yyyy-mm-dd"
    ),
  });
  const onPanelChange = (value, mode) => {
    const starttDate = dateFormat(
      new Date(value.$d?.getFullYear(), value.$d?.getMonth(), 1),
      "yyyy-mm-dd"
    );
    const enddDate = dateFormat(
      new Date(value.$d?.getFullYear(), value.$d?.getMonth() + 1, 0),
      "yyyy-mm-dd"
    );
    setDateObject({ startDate: starttDate, endDate: enddDate });
  };

  const myClickHandler = () => {
    setIdActive(LoggedInUser?.id);
    setSelectedEmployeeList([]);
  };

  const [attendanceList, setAttendanceList] = useState("");

  const presentCount =
    attendanceList &&
    attendanceList?.filter((item) => item.title === "Present").length;
  const absentCount =
    attendanceList &&
    attendanceList?.filter((item) => item.title === "Absent").length;
  const weeklyCount =
    attendanceList &&
    attendanceList?.filter((item) => item.title === "Weekly Off").length;
  const leaveCount =
    attendanceList &&
    attendanceList?.filter((item) => item.title === "Leave").length;
  const halfCount =
    attendanceList &&
    attendanceList?.filter((item) => item.title === "Half Day").length;
  const holidaysCount =
    attendanceList &&
    attendanceList?.filter((item) => item.title === "Holiday").length;
  const outdoorsCount =
    attendanceList &&
    attendanceList?.filter((item) => item.title === "Outdoors").length;
  const optionalCount =
    attendanceList &&
    attendanceList?.filter((item) => item.title === "Optional Leave").length;

  const attendanceData = [
    {
      id: 1,
      color: "#6AB1BF",
      numberDay: presentCount,
      typeDays: "Present Days",
    },
    {
      id: 2,
      color: "#F71D1D",
      numberDay: absentCount,
      typeDays: "Absent Days",
    },
    {
      id: 7,
      color: "#0DB53C",
      numberDay: weeklyCount,
      typeDays: "Weekly Offs",
    },
    { id: 3, color: "#B69FB3", numberDay: leaveCount, typeDays: "Leave Days" },
    { id: 4, color: "#F7941D", numberDay: halfCount, typeDays: "Half Days" },
    { id: 5, color: "#8A88ED", numberDay: holidaysCount, typeDays: "Holidays" },
    { id: 6, color: "#DE64A1", numberDay: outdoorsCount, typeDays: "Outdoors" },
    {
      id: 8,
      color: "#FB7158",
      numberDay: optionalCount,
      typeDays: "Optional Leave",
    },
  ];
  const getAttendanceData = async (id, startDate, endDate) => {
    if (!id) return null;
    try {
      setLoading(true);
      const result = await getRequest(
        `Attendance/GetAttendance/${id}/${startDate}/${endDate}`
      );
      setAttendanceList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - My Attendance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getEmployeeData = async () => {
    if (!LoggedInUser?.id) return;
    try {
      const result = await getRequest(`Employees/GetEmployeesForAttendance`);
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({
          label: item.text,
          value: item.id,
        });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (LoggedInUser) {
      if (location.pathname.toLowerCase().includes("attendance"))
        getAttendanceData(idActive, dateObject?.startDate, dateObject?.endDate);
    }
  }, [LoggedInUser, idActive, dateObject]);
  // console.log("first idActive", idActive);
  // console.log("first selectedEmployeeList", selectedEmployeeList);
  const monthCellRender = (month) => {
    const monthData =
      attendanceList &&
      attendanceList.find(
        (x) =>
          new Date(x.start).getMonth() === new Date(month).getMonth() &&
          new Date(x.start).getFullYear() === new Date(month).getFullYear()
      );
    if (!monthData) return;
    else
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="monthDataHolder">
            {attendanceData &&
              attendanceData?.map((item) => {
                return (
                  <MonthDetailComp
                    color={item?.color}
                    type={item?.typeDays}
                    days={item?.numberDay}
                  />
                );
              })}
          </div>
        </div>
      );
  };

  const dateFullCellRender = (date) => {
    const data =
      attendanceList &&
      attendanceList?.find(
        (x) =>
          new Date(x.start).toDateString() === new Date(date).toDateString()
      );
    if (!data) {
      return (
        <>
          <BlankComp date={dateFormat(date, "dd")} />
        </>
      );
    } else if (data?.title.includes("Present")) {
      return (
        <>
          <PresentComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
            source={data?.source}
          />
        </>
      );
    } else if (data?.title.includes("Absent")) {
      return (
        <>
          <AbsentComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            // time={data?.timing?.split("\n")}   //to be decided in future
          />
        </>
      );
    } else if (data?.title.includes("Half")) {
      return (
        <>
          <HalfDayComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
            source={data?.source}
          />
        </>
      );
    } else if (
      data?.title.includes("Outdoor") ||
      data?.title.includes("Early Going") ||
      data?.title.includes("Late Coming")
    ) {
      return (
        <>
          <OutdoorComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
          />
        </>
      );
    } else if (data?.title.includes("Optional")) {
      return (
        <>
          <OptionalLeaveComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            desc={data?.desc}
          />
        </>
      );
    } else if (data?.title.includes("Holiday")) {
      return (
        <>
          <HolidayComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            desc={data?.desc}
          />
        </>
      );
    } else if (data?.title.includes("Leave")) {
      return (
        <>
          <LeaveComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            desc={data?.desc}
          />
        </>
      );
    } else if (data?.title.includes("Weekly")) {
      return (
        <>
          <WeeklyOffComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (new Date(date).getDate() === new Date().getDate()) {
      return (
        <>
          <TodayComp date={dateFormat(date, "dd")} />
        </>
      );
    } else {
      return (
        <>
          <BlankComp date={dateFormat(date, "dd")} />
        </>
      );
    }
  };

  return (
    <div className="attendanceMainContainer">
      {loading && (
        <div className="attendanceLoadingContainer">
          <Loader />
        </div>
      )}
      <div className="attendanceCalendar">
        <div className="myTeamHolder">
          <div
            className={`myHolder cursor ${
              idActive === LoggedInUser?.id && "activeClass"
            }`}
            onClick={myClickHandler}
          >
            <svg
              width="40"
              height="20"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.56452 8.0322C9.45004 8.0322 10.9786 6.51133 10.9786 4.63524C10.9786 2.75915 9.45004 1.23828 7.56452 1.23828C5.679 1.23828 4.15048 2.75915 4.15048 4.63524C4.15048 6.51133 5.679 8.0322 7.56452 8.0322Z"
                stroke="#F7941D"
                strokeMiterlimit="10"
              />
              <path
                d="M1.35742 15.2394V14.313C1.35742 10.916 4.15072 8.13672 7.56476 8.13672C10.9788 8.13672 13.7721 10.916 13.7721 14.313V15.2394"
                stroke="#F7941D"
                strokeMiterlimit="10"
              />
            </svg>

            <label style={{ marginLeft: "-3px" }} className="labelCount">
              My
            </label>
          </div>

          {(LoggedInUser?.isHRHead ||
            LoggedInUser?.isHRDirector ||
            LoggedInUser?.isReportingManager ||
            LoggedInUser?.teamnames?.includes("HR Team")) && (
            <div
              id="2"
              className={`teamHolder cursor ${
                idActive !== LoggedInUser?.id && "activeClass"
              }`}
            >
              <div style={{ display: "flex" }}>
                <svg
                  class="noShrink"
                  width="60"
                  height="19"
                  viewBox="0 0 28 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.9684 9.65894C23.5715 9.65894 24.918 8.37651 24.918 6.70935C24.918 5.10632 23.6356 3.75977 21.9684 3.75977C20.3654 3.75977 19.0188 5.04219 19.0188 6.70935C19.0188 8.37651 20.3013 9.65894 21.9684 9.65894Z"
                    stroke="#1296B0"
                  />
                  <path
                    d="M18.8783 10.7838C19.7189 10.0859 20.7946 9.66016 21.9676 9.66016C24.5966 9.66016 26.8408 11.8403 26.8408 14.5334V15.2387H19.793"
                    stroke="#1296B0"
                  />
                  <path
                    d="M5.64667 9.65894C4.04363 9.65894 2.69708 8.37651 2.69708 6.70935C2.69708 5.10632 3.97951 3.75977 5.64667 3.75977C7.2497 3.75977 8.59625 5.04219 8.59625 6.70935C8.59625 8.37651 7.31382 9.65894 5.64667 9.65894Z"
                    stroke="#1296B0"
                  />
                  <path
                    d="M8.73676 10.7838C7.89615 10.0859 6.82047 9.66016 5.64748 9.66016C3.0185 9.66016 0.774245 11.8403 0.774245 14.5334V15.2387H7.82212"
                    stroke="#1296B0"
                  />
                  <path
                    d="M13.9041 8.38066C15.904 8.38066 17.4753 6.73791 17.4753 4.80947C17.4753 2.8096 15.8325 1.23828 13.9041 1.23828C11.9042 1.23828 10.3329 2.88103 10.3329 4.80947C10.2615 6.80933 11.9042 8.38066 13.9041 8.38066Z"
                    stroke="#F7941D"
                  />
                  <path
                    d="M19.8313 15.2375H7.90353V14.3805C7.90353 11.095 10.5462 8.38086 13.9031 8.38086C17.1886 8.38086 19.9027 11.0235 19.9027 14.3805L19.8313 15.2375Z"
                    stroke="#F7941D"
                  />
                </svg>
                <label
                  className="labelCount noShrink"
                  style={{ marginLeft: "-6px" }}
                >
                  Team
                </label>
              </div>
              <SelectCustomEmployeeCreateAttendance
                selectedEmployeeList={selectedEmployeeList}
                setSelectedEmployeeList={setSelectedEmployeeList}
                values={selectedEmployeeList}
                setIdActive={setIdActive}
                urlProp={"Employees/GetEmployeesForAttendance"}
                lgid={LoggedInUser?.id}
                styl={{
                  backgroundColor: "var(--primary-hover)",
                  margin: 0,
                  minHeight: "34px",
                  maxHeight: "34px",
                  padding: "5px 10px",
                }}
                styles={{ minWidth: "250px" }}
              />
              {/* <SelectCustom
                onDropdownOpen={() => {
                  getEmployeeData();
                }}
                options={employeeList}
                values={selectedEmployeeList}
                fullWidth={true}
                onChange={(value) => {
                  setSelectedEmployeeList(value);
                  setIdActive(value?.[0]?.value);
                }}
                styl={{
                  backgroundColor: "var(--primary-hover)",
                  margin: 0,
                  minHeight: "34px",
                  maxHeight: "34px",
                  padding: "5px 10px",
                }}
                styles={{ minWidth: "250px" }}
              /> */}
            </div>
          )}
        </div>

        <Calendar
          fullCellRender={dateFullCellRender}
          cellRender={monthCellRender}
          onPanelChange={onPanelChange}
        />
      </div>
      <div className="attendanceHolder">
        {attendanceData &&
          attendanceData.map((item) => {
            return (
              <MyAttendanceComp
                color={{ color: item.color }}
                bgcolor={{ backgroundColor: item.color }}
                days={item.numberDay}
                type={item.typeDays}
              />
            );
          })}
      </div>
    </div>
  );
};
export { MyAttendance };
