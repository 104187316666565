import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import ArrowBackButton from "../../../../../../../Assets/ArrowBackButton.svg";
import SurveyDownArrowIcon from "../../../../../../../Assets/SurveyDownArrowIcon.svg";
import SurveyPremiumLockIcon from "../../../../../../../Assets/SurveyPremiumLockIcon.svg";
import SurveyUpArrowIcon from "../../../../../../../Assets/SurveyUpArrowIcon.svg";
import RightClickIcon from "../../../../../../../Assets/RightClickIcon.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRequest } from "../../../../../../../Services/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../../../Features";
import ProgressBar from "@ramonak/react-progress-bar";
import { Popup } from "../../../../../../../Components/FormComponent/PopupComponent/Popup";

function SurveyDetailsPage() {
  const [surveyDetailsData, setSurveyDetailsData] = useState({});
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [showBuyPopup, setShowBuyPopup] = useState(false);
  const [showQuestionAnswer, setShowQuestionAnswer] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const getSurveyDetails = async () => {
    setLoading(true);
    try {
      const result = await getRequest("Survey/Details/" + id);
      setSurveyDetailsData(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const goToReportPage = (
    displayType,
    answerId = 0,
    surveyTitle = "Overall Survey Report"
  ) => {
    navigate("/home/social/surveyreport/" + id, {
      state: {
        data: surveyDetailsData,
        type: displayType,
        ansId: answerId,
        title: surveyTitle,
      },
    });
  };
  useEffect(() => {
    getSurveyDetails();
  }, []);
  return (
    <div className="surveyDetailsContainer">
      <div className="surveyDetailsWrapper">
        <div className="surveyDetailsHeader">
          <div className="surveyDetailsHeaderImg">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={ArrowBackButton}
                alt=""
                style={{ margin: "4px 1px", cursor: "pointer" }}
              />
            </IconButton>
          </div>
          <div className="surveyDetailsHeaderText">
            {surveyDetailsData?.survey?.surveyTitle}
          </div>
        </div>
        <div className="surveyDetailsData">
          <div className="surveyDetailsParaContainer">
            <p>{surveyDetailsData?.survey?.description}</p>
          </div>
          <div className="surveyDetailsSurveyOptionsContainer">
            <h5>
              {state?.type === "survey"
                ? "Overall Survey Report"
                : "Overall Poll Report"}
            </h5>
            <div className="surveyDetailsSurveyOptionsWrapper">
              <div
                className="optionAll"
                onClick={() => {
                  goToReportPage("All");
                }}
              >
                <span>All</span> <label>{surveyDetailsData?.allCount}</label>
              </div>
              <div
                className="optionView"
                onClick={() => {
                  goToReportPage("Viewed");
                }}
              >
                <span>Viewed by</span>{" "}
                <label>{surveyDetailsData?.viewCount}</label>
              </div>
              <div
                className="optionRespond"
                onClick={() => {
                  goToReportPage("Respond");
                }}
              >
                <span>Respond by</span>
                <label>{surveyDetailsData?.respondCount}</label>
              </div>
              <div
                className="optionNotRespond"
                onClick={() => {
                  goToReportPage("NotRespond");
                }}
              >
                <span>Not Respond</span>
                <label>{surveyDetailsData?.notRespondCount}</label>
              </div>
            </div>
          </div>
          <div className="surveyDetailsQuestionContainer">
            <div className="surveyDetailsQuestionWrapper">
              {surveyDetailsData?.survey?.surveyQuestions?.map((d, index) => {
                return (
                  <div className="surveyDetailsQuestionListContent" key={index}>
                    {showQuestionAnswer !== d.id && (
                      <div
                        className="surveyDetailsQuestion"
                        style={{ borderRadius: "6px", margin: "15px 0px" }}
                        onClick={() => {
                          setShowQuestionAnswer(d?.id);
                        }}
                      >
                        <p>{d?.questionText}</p>
                        <img src={SurveyDownArrowIcon} alt="" />
                      </div>
                    )}
                    {showQuestionAnswer === d?.id && (
                      <div className="surveyDetailsFullQuestionAnswer">
                        <div
                          className="surveyDetailsQuestion"
                          onClick={() => {
                            setShowQuestionAnswer(0);
                          }}
                        >
                          <p>{d?.questionText}</p>
                          <img src={SurveyUpArrowIcon} alt="" />
                        </div>
                        <div className="surveyDetailsAnswersListContainer">
                          {d?.surveyQuestionAnswers?.map((qAns, qIndex) => {
                            return (
                              <div
                                className="surveyDetailsAnswers"
                                key={qIndex}
                              >
                                <label>
                                  {qIndex + 1} . {qAns?.answerText}
                                </label>
                                <div className="surveyDetailsGraphContainer">
                                  <div className="surveyDetailsGraphWrapper">
                                    <div className="surveyDetailsGraphBar">
                                      <ProgressBar
                                        completed={qAns?.respondPercentage}
                                        bgColor="#1296b0"
                                        height="5px"
                                        width="100%"
                                        labelColor="#1296b0"
                                      />
                                    </div>
                                    <div className="surveyDetailsGraphData">
                                      <div style={{minWidth:"45px"}}>
                                        <span>{qAns?.respondPercentage}%</span>{" "}
                                      </div>
                                      <div style={{minWidth:"80px"}}>
                                        <button
                                          className="button surveyDetailsBtn"
                                          onClick={() => {
                                            goToReportPage(
                                              "All",
                                              qAns?.id,
                                              qAns?.answerText
                                            );
                                          }}
                                        >
                                          Details
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="surveyPremiumBtnContainer">
              <IconButton
                onClick={() => {
                  setShowBuyPopup(true);
                }}
                style={{
                  borderRadius: "5px",
                  background: "#F7941D",
                  color: "#fff",
                  fontSize: "16px",
                  padding: "8px 20px",
                }}
              >
                <img src={SurveyPremiumLockIcon} alt="" />
                <span style={{ paddingLeft: "7px" }}>
                  Unlock and download complete report
                </span>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showBuyPopup && (
          <Popup
            firstClassName="surveyPremiumPostPopupBox1"
            secondClassName="surveyPremiumPostPopupBox2"
            popupHeading={
              <div className="surveyPremiumHeaderPopupBox2">
                Unlock Actionable Insights Today!
              </div>
            }
            content={
              <div className="surveyPremiumPopupContainer">
                <h6>
                  Transform your Organization with Comprehensive Poll Reports
                </h6>
                <div className="surveyPremiumFeatureContainer">
                  <h4>Features:</h4>
                  <article>
                    <img src={RightClickIcon} alt="" />
                    <span>Overall Poll Report</span>
                  </article>
                  <article>
                    <img src={RightClickIcon} alt="" />
                    <span>Age & Gender Analysis</span>
                  </article>
                  <article>
                    <img src={RightClickIcon} alt="" />
                    <span>Location-based Insights</span>
                  </article>
                  <article>
                    <img src={RightClickIcon} alt="" />
                    <span>Departmental Breakdown</span>
                  </article>
                </div>
                <div className="surveyPremiumPriceContainer">
                  <h5>Only</h5>
                  <p>
                    <span>$ 24/- </span> for full access
                  </p>
                </div>
                <h3>
                  Don't miss out on valuable insights that can drive your
                  organization forward. Reach out to our dedicated team now to
                  access the complete report tailored to your specific needs.
                </h3>
                <div className="surveyPremiumBuyContainer">
                  <a
                    href="https://aesssolutions.com/pricing.html"
                    target="_blank"
                    rel="noReferrer"
                  >
                    Buy
                  </a>
                </div>
              </div>
            }
            handleClose={() => {
              setShowBuyPopup(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SurveyDetailsPage;
