/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateTravelAdvanceComp } from "./FormComp/CreateTravelAdvanceComp";
import { format } from "date-fns";

const CreateTravelAdvance = (props) => {
  const { state } = useLocation();

  const [detailsform, setDetailsform] = useState({});
  const [formValues, setFormValues] = useState(null);
  const [perDayEligibilty, setperDayEligibilty] = useState(null);
  const data = state?.id;

  useEffect(() => {
    document.title = `PeopleSol - Create Advance Travel`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`TravelAdvanceRequests/Details/${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getperDayEligibilty = async () => {
    try {
      const result = await getRequest(
        `TravelAdvanceEligibilities/PerDayEligibilty`
      );
      setperDayEligibilty(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    getperDayEligibilty();
    if (data) getFormDetails();
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("TravelAdvanceRequests/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.message ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("TravelAdvanceRequests/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const initialvalue = {
    id: data ? data : 0,
    TravelID: data ? detailsform?.travelID : "",
    perDayEligibilty: data ? detailsform?.perDayEligibilty : perDayEligibilty,
    advanceSalary: data ? detailsform?.advanceSalary : "",
    purposeOfAdvance: data ? detailsform?.purposeOfAdvance : "",
  };
  const validationSchema = Yup.object({
    TravelID: Yup.string().required("Please Select ID"),
    advanceSalary: Yup.number()
      .min(1)
      .max(9999999999)
      .required("Please Enter Travel Advance Amount(Rs)"),
    purposeOfAdvance: Yup.string()
      .min(1)
      .max(499, "Purpose must be less than 500 words")
      .notRequired("Please Enter Purpose Of Advance"),
  });

  const onSubmit = (values) => {
    // values["startDate"] = format(values.startDate, "yyyy-MM-dd");
    // values["endDate"] = format(values.endDate, "yyyy-MM-dd");
    data ? Edit(values) : createPageData(values);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={
        data ? "Edit Travel Advance Request" : "Create Travel Advance Request"
      }
      ButtonLabel={
        data ? "Update Travel Advance Request" : "Create Travel Advance Request"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateTravelAdvanceComp data={detailsform} formValues={formValues} />
      }
    />
  );
};

export { CreateTravelAdvance };
