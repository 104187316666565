import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CreateSkillSetComp } from "./FormComp/CreateSkillSetComp";

const CreateSkillset = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formValues, setformValues] = useState({});
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`SkillSetController/Details?ID=${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("SkillSetController/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("SkillSetController/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {}
  };

  const initialvalue = {
    SkillSetName: data ? detailsform?.skillSet?.skillSetName : "",
    DepartmentId: data ? detailsform?.skillSet?.departmentId : "",
    SubDepartmentId: data ? detailsform?.skillSet?.subDepartmentId : "",
  };
  const validationSchema = Yup.object({
    SkillSetName: Yup.string()
      .max(149, "Name must be less than 150 characters")
      .required("Please Enter Name"),
    DepartmentId: Yup.string().required("Please Select Department"),
    SubDepartmentId: Yup.string().required("Please Select Sub Department Set"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: detailsform?.skillSet?.id,
    };

    const create = {
      ...values,
      Id: 0,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };
  const chooseFormValues = (values) => {
    setformValues(values);
  };
  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Skill Set" : "Create Skill Set"}
      ButtonLabel={data ? "Edit Skill Set" : "Create Skill Set"}
      chooseFormValues={chooseFormValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateSkillSetComp formValues={formValues} data={detailsform} />
      }
    />
  );
};

export { CreateSkillset };
