import React from "react";
import { useState } from "react";
import SurveyPage from "./Components/SurveyPage";
import PollPage from "./Components/PollPage";
import { Tab, Tabs } from "@mui/material";
import "./Survey.css";
import { useNavigate } from "react-router-dom";

function Survey({ reRefresh, setReRefreshProp }) {
  const [tabHeading, setTabHeading] = useState("survey");
  const navigate = useNavigate();
  function handleTabChange() {
    navigate("/home/social/polls");
  }

  return (
    <div className="surveyContainer">
      <div className="surveyWrapper">
        <Tabs
          value={tabHeading}
          variant="scrollable"
          scrollButtons="auto"
          className="socialSurveyTabs"
        >
          <Tab
            value={"Survey"}
            label={"Survey"}
            className={`socialSurveyHeading socialSurveyActive`}
          ></Tab>
          <Tab
            value={"Polls"}
            label={"Polls"}
            className={`socialSurveyHeading `}
            onClick={handleTabChange}
          ></Tab>
          {}
        </Tabs>
      </div>
      {tabHeading === "survey" && (
        <SurveyPage reRefresh={reRefresh} setReRefreshProp={setReRefreshProp} />
      )}
      {tabHeading === "polls" && (
        <PollPage reRefresh={reRefresh} setReRefreshProp={setReRefreshProp} />
      )}
    </div>
  );
}

export default Survey;
