import React from "react";
import { useNavigate } from "react-router-dom";
import {
  docsArray,
  getAttachments,
  getContentLength,
  getExtension,
  getUserInitials,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import {
  communityProfileImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import SocialBirthdayTag from "../../../../../Assets/SocialBirthdayTag.svg";
import SocialNewsTag from "../../../../../Assets/SocialNewsTag.svg";
import SocialAnnouncementTag from "../../../../../Assets/SocialAnnouncementTag.svg";
import SocialWorkAnnyTag from "../../../../../Assets/SocialWorkAnnyTag.svg";
import SocialNewJoiningTag from "../../../../../Assets/SocialNewJoiningTag.svg";
import SocialKudosTag from "../../../../../Assets/SocialKudosTag.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";
import SocialTextLinkIcon from "../../../../../Assets/SocialTextLinkIcon.svg";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import dateFormat from "dateformat";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import PostActivityContainer from "./PostActivityContainer";
import ContentSocialDisplay from "./ContentSocialDisplay";
import ShowCarouselGreetingsImages from "./ShowCarouselGreetingsImages";
import ShowCarouselSocialImages from "./ShowCarouselSocialImages";
import PDFViewer from "./PDFViewer";
import { useState } from "react";

function SharedPostComponent({
  d,
  setIsSocialPostDeletePopupActive,
  setEditDeleteSocialPostId,
  setIsSharePosEditActive,
  setIsMyPosEditActive,
  setEditDeleteSocialPostData,
}) {
  const navigate = useNavigate();
  const [showContent, setShowContent] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  const getDetailPage = (id) => {
    navigate("details/" + id);
  };
  return (
    <div
      className={
        d?.sharedEmployee !== null ? `sharedSocialPostDataListing` : ""
      }
    >
      {!d?.announcementTitle && !d?.sharedNews && (
        <div className="postHeaderContainer" style={{ boxShadow: "none" }}>
          {d?.sharedPost?.postType === "organisation" && (
            <div
              className="leftPostHeaderContainer"
              onClick={(e) => {
                e.stopPropagation();
                handleUserProfileClick(d?.employeeId);
              }}
            >
              <div
                className={`${
                  d?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePic"
                }`}
              >
                {!d?.image ? (
                  getUserInitials(d?.name)
                ) : (
                  <img
                    className="socialProfilePic"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUserProfileClick(d?.employeeId);
                    }}
                    src={
                      process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">
                  {getDisplayName(d?.nameWithPersonNumber)}
                </label>
                <label>{d?.roleName}</label>
                <label>
                  {d?.locationName}
                  <span className="postHrTime">
                    &nbsp; •{" "}
                    {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                </label>
              </div>
            </div>
          )}
          {d?.sharedPost?.postType === "community" && (
            <div
              className="leftPostHeaderContainer"
              style={{ position: "relative" }}
            >
              <div
                onClick={(e) => {
                  navigate(
                    `/home/social/community/${d?.sharedPost?.community?.id}`
                  );
                }}
                className={`${
                  d?.sharedPost?.community?.communityImage
                    ? "socialConnectProfilePicContainer"
                    : "nestedCommunityCustomProfilePic "
                }`}
              >
                {!d?.sharedPost?.community?.communityImage &&
                d?.sharedPost?.community?.communityName ? (
                  getUserInitials(d?.sharedPost?.community?.communityName)
                ) : (
                  <img
                    className="socialProfilePic"
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      communityProfileImages +
                      d?.sharedPost?.community?.communityImage
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = SocialCommunityGroupIcon;
                    }}
                  />
                )}
              </div>

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserProfileClick(d?.employeeId);
                }}
                className={`${
                  d?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePicCommunity"
                }`}
              >
                {!d?.image ? (
                  getUserInitials(d?.name)
                ) : (
                  <img
                    className={`socialProfilePic ${
                      d?.sharedPost?.postType !== "organisation"
                        ? "prTeamCommunity"
                        : ""
                    }`}
                    src={
                      process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div
                className="postMainTextContainer"
                onClick={() => {
                  navigate(`/home/social/community/${d?.community?.id}`);
                }}
              >
                <label className="postMainName">
                  {d?.sharedPost?.community?.communityName}
                </label>
                <label>
                  Post from &nbsp;
                  <span className="nameofposter">
                    {getDisplayName(d?.nameWithPersonNumber)}
                  </span>
                  <span className="postHrTime">
                    &nbsp; •{" "}
                    {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                </label>
              </div>
            </div>
          )}
          {d?.sharedPost?.postType === "team" && (
            <div
              className="leftPostHeaderContainer"
              style={{ position: "relative" }}
            >
              <img
                src={SocialPostTeamIcon}
                alt=""
                style={{
                  height: "50px",
                  width: "50px",
                  // marginRight: "18px",
                }}
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleUserProfileClick(d?.employeeId);
                }}
                className={`${
                  d?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePicCommunity"
                }`}
              >
                {!d?.image ? (
                  getUserInitials(d?.name)
                ) : (
                  <img
                    className={`socialProfilePic ${
                      d?.sharedPost?.postType !== "organisation"
                        ? "prTeamCommunity"
                        : ""
                    }`}
                    src={
                      process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">Team</label>
                <label>
                  Post from &nbsp;
                  <span className="nameofposter">
                    {getDisplayName(d?.nameWithPersonNumber)}
                  </span>
                  <span className="postHrTime">
                    &nbsp; •{" "}
                    {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                </label>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {d?.sharedPost?.subType === "kudos" && (
              <div className="kudosTypeContainer">
                <span>Kudos</span>
                <article></article>
                <img src={SocialKudosTag} alt="" />
              </div>
            )}
            {d?.sharedPost?.subType === "birthday" && (
              <div className="birthdayTypeContainer">
                <span>Birthday</span>
                <article></article>
                <img src={SocialBirthdayTag} alt="" />
              </div>
            )}
            {d?.sharedPost?.subType === "workanniversary" && (
              <div className="workAnnyTypeContainer">
                <span>Work Anniversary</span>
                <article></article>
                <img src={SocialWorkAnnyTag} alt="" />
              </div>
            )}
            {d?.sharedPost?.subType === "welcome" && (
              <div className="newJoiningTypeContainer">
                <span>New Joining</span>
                <article></article>
                <img src={SocialNewJoiningTag} alt="" />
              </div>
            )}
            {d?.sharedEmployee === null &&
              d?.nameWithPersonNumber === LoggedInUser.nameWithPersonNumber && (
                <IconButton>
                  <PostActivityContainer
                    setIsSocialPostDeletePopupActive={
                      setIsSocialPostDeletePopupActive
                    }
                    setIsSharePosEditActive={setIsSharePosEditActive}
                    setIsMyPosEditActive={setIsMyPosEditActive}
                    isMyPosEditActive={true}
                    isSharePosEditActive={false}
                    setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                    editDeleteSocialPostId={d?.id}
                    editDeleteSocialPostData={d}
                    setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                  />
                </IconButton>
              )}
          </div>
        </div>
      )}
      {d?.announcementTitle && (
        <div className="postHeaderContainer" style={{ boxShadow: "none" }}>
          <div className="leftPostHeaderContainer">
            <div className="announcementHeaderContainer">
              <label>{d?.announcementTitle ?? "--"}</label>
              <span className="postHrTime">
                • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="announcementTypeContainer">
              <span>Announcement</span>
              <article></article>
              <img src={SocialAnnouncementTag} alt="" />
            </div>
          </div>
        </div>
      )}
      {d?.sharedNews && (
        <div className="postHeaderContainer" style={{ boxShadow: "none" }}>
          <div className="leftPostHeaderContainer">
            <div className="socialPostTitleAndTimeContainer">
              <label>
                {d?.sharedNews?.title?.length > 40
                  ? d?.sharedNews?.title?.substring(0, 40) + "..."
                  : d?.sharedNews?.title}
              </label>
              <span className="postHrTime">
                • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="newsTypeContainer">
              <span>{d?.sharedNews?.category}</span>
              <article></article>
              <img src={SocialNewsTag} alt="" />
            </div>
          </div>
        </div>
      )}

        <div className="postBodyTextContainer">
          {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
            <ContentSocialDisplay content={getContentLength(d?.content)} />
          ) : (
            <ContentSocialDisplay content={d?.content?.trim()} />
          )}
          {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
            <span
              onClick={() => {
                setShowContent(true);
              }}
            >
              ...View More
            </span>
          )}
        </div>
      <div className="postBodyContainer" style={{borderRadius:"0 0 10px 10px"}}>
        {d?.attachment?.length > 0 &&
          imgArray.includes(
            getExtension(
              getAttachments(d?.attachment)
                ? getAttachments(d?.attachment)[0]
                : ""
            )
          ) && (
            <div
              className={` ${
                d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
              }`}
            >
              {d?.sharedPost?.subType !== null && d?.sharedNews === null && (
                <div
                  className={"newPostBodyImgMainWrapper socialCarouselStylr"}
                  style={{ margin: "0" }}
                >
                  <div className="newPostBodyImgContainer ">
                    {d?.attachment?.includes("gif") && (
                      <ShowCarouselSocialImages
                        photo={getAttachments(d?.attachment) || []}
                        showModal={() => {
                          getDetailPage(d?.id);
                        }}
                      />
                    )}
                    {!d?.attachment?.includes("gif") && (
                      <ShowCarouselGreetingsImages
                        photo={getAttachments(d?.attachment) || []}
                        showModal={() => {
                          getDetailPage(d?.id);
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {d?.sharedPost?.subType === null && d?.sharedNews === null && (
                <div
                  className={"newPostBodyImgMainWrapper socialCarouselStylr"}
                  style={{ margin: "0" }}
                >
                  <ShowCarouselSocialImages
                    photo={getAttachments(d?.attachment) || []}
                    showModal={() => {
                      getDetailPage(d?.id);
                    }}
                  />
                </div>
              )}
              {d?.sharedPost?.subType !== null && d?.sharedNews !== null && (
                <div
                  className={"newPostBodyImgMainWrapper socialCarouselStylr"}
                  style={{ margin: "0" }}
                >
                  <ShowCarouselSocialImages
                    photo={getAttachments(d?.attachment) || []}
                    showModal={() => {
                      getDetailPage(d?.id);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        {d?.attachment?.length > 0 &&
          videoArray.includes(
            getExtension(
              getAttachments(d?.attachment)
                ? getAttachments(d?.attachment)[0]
                : ""
            )
          ) && (
            <div
              className={` ${
                d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
              }`}
              onClick={() => {
                getDetailPage(d?.id);
              }}
            >
              <div
                className={`${
                  d?.sharedEmployee !== null
                    ? "sharePostBodyImgMainWrapper"
                    : "newPostBodyImgMainWrapper"
                }`}
                style={{ margin: "0" }}
              >
                <div
                  className={` ${
                    d?.sharedEmployee !== null
                      ? "shareNewPostBodyImgContainer"
                      : "newPostBodyImgContainer"
                  }`}
                >
                  <video
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      socialPostImages +
                      getAttachments(d?.attachment)[0]
                    }
                    controls
                    width="630"
                    height="335"
                  />
                </div>
              </div>
            </div>
          )}
        {d?.attachment?.length > 0 &&
          docsArray.includes(
            getExtension(
              getAttachments(d?.attachment)
                ? getAttachments(d?.attachment)[0]
                : ""
            ).toLowerCase()
          ) && (
            <div
              className={` ${
                d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
              }`}
            >
              {getAttachments(d?.attachment)?.length === 1 && (
                <div
                  className="newPostBodyImgContainer"
                  onClick={() => {
                    getDetailPage(d?.id);
                  }}
                >
                  <PDFViewer
                    pdfUrl={
                      process.env.REACT_APP_.BASE_URL +
                      socialPostImages +
                      getAttachments(d?.attachment)[0]
                    }
                  />
                </div>
              )}
            </div>
          )}
        {d?.news?.link && (
          <div className="newsLinkContainer">
            <div className="newsLinkWrapper">
              <div className="newsLink">
                <img src={SocialTextLinkIcon} alt="" />{" "}
                <a href={d?.news?.link} target="_blank" rel="noreferrer">
                  <span>{d?.news?.link}</span>
                </a>
              </div>
              <img
                style={{ width: "29px" }}
                src={SocialNewsEndLinkIcon}
                alt=""
              />
            </div>
          </div>
        )}
      </div>

      {d?.sharedNews && (
        <div
          className="newsLinkContainer"
          style={{ margin: "2%", width: "96%" }}
        >
          <div className="newsLinkWrapper">
            <div className="newsLink">
              <img src={SocialTextLinkIcon} alt="" />{" "}
              <a href={d?.sharedNews?.link} target="_blank" rel="noreferrer">
                <span>{d?.sharedNews?.link?.substring(0, 50)}</span>
              </a>
            </div>
            <img style={{ width: "29px" }} src={SocialNewsEndLinkIcon} alt="" />
          </div>
        </div>
      )}
    </div>
  );
}

export default SharedPostComponent;
