import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getRequest } from "../../../Services/axios";
import { showToast } from "../../../Features";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { format } from "date-fns";
import { Loader } from "../../../Components/FormComponent/Loader";
import Nodata from "../../../Assets/Nodata.svg";

const DetailsPageDeduction = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`SalaryComponents/DeductionDetails/${selectedId}`);
      setEventtData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Details Asset Material`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  console.log('eventtData',eventtData)
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Deductions Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>

        <div className="moduleMasterDetailsPageBody">
          {loading ? <Loader/>          
          : 
              <>

                      <DetailsStep
                  detailTitle={"Name In Payslip"}
                  Details={eventtData?.nameInPayslip}
                />
                <DetailsStep
                  detailTitle={"Deduction Type"}
                  Details={
                    eventtData?.deductionType
                  
                  }
                />

                <DetailsStep
                  detailTitle={"Deduction Frequency"}
                  Details={
                    eventtData?.deductionFrequency
                  
                  }
                />
                <DetailsStep
                  detailTitle={"Is Active"}
                  Details={
                    eventtData?.isActive ? "Yes":"No"
                  
                  }
                />
                
              </>}
       
        </div>
      </div>
    </div>
  );
};

export { DetailsPageDeduction };
