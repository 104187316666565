import React from "react";

const CheckSubmit = ({ isEdit, data, values, onSubmit, onClose, navigate }) => {
  const isBoth = true;
  const returnUrl = "travel/travelbooking";
  if (isEdit) {
    return (
      // <div style={{ width: "326px" }}>
      <div style={{ width: "100%" }}>
        <div
          className="detailInner"
          style={{ fontFamily: "Poppins", fontSize: "15px" }}
        >
          Please update the hotel details to reflect the recent changes in the
          travel plans.
        </div>
        <div className="createFootCust" style={{ backgroundColor: "white" }}>
          {/* <div>
            <div
              onClick={() => {
                onSubmit(values);
                onClose();
              }}
              className={`button secondaryButton `}
            >
              No
            </div>
          </div> */}
          <div>
            <button
              onClick={() => {
                onClose();
                navigate("/travel/hotelbooking/create", {
                  state: { values, isBoth, data, returnUrl },
                });
              }}
              className="button primaryButton"
              type="submit"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      // <div style={{ width: "326px" }}>
      <div style={{ width: "100%" }}>
        <div
          className="detailInner"
          style={{ fontFamily: "Poppins", fontSize: "15px" }}
        >
          Would you also like to create a hotel booking request ?
        </div>
        <div className="createFootCust" style={{ backgroundColor: "white" }}>
          <div>
            <div
              onClick={() => {
                onSubmit(values);
                onClose();
              }}
              className={`button secondaryButton `}
            >
              No
            </div>
          </div>
          <div>
            <button
              onClick={() => {
                onClose();
                navigate("/travel/hotelbooking/create", {
                  state: { values, isBoth, data, returnUrl },
                });
              }}
              className="button primaryButton"
              type="submit"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default CheckSubmit;
