import React, { useState, useEffect } from "react";
import { getRequest } from "../../../Services/axios";

const ImageComponent = ({ imageUrlProp, style, alt = "" }) => {
  const [base64Img, setBase64Img] = useState("");
  const base64FormofImage = async (imageUrlProp) => {
    const result = await getRequest(
      `LMSController/imageToBase64?imageUrl=${imageUrlProp}`
    );
    setBase64Img(result?.data);
  };

  useEffect(() => {
    base64FormofImage(imageUrlProp);
  }, [imageUrlProp]);

  return (
    <img style={style} src={`data:image/*;base64,${base64Img}`} alt={alt} />
  );
};

export default ImageComponent;
