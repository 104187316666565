/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PayrollcompanystructurePic from "../../Assets/PayrollcompanystructurePic.svg";
import PayrollworklocationsPic from "../../Assets/PayrollworklocationsPic.svg";
import PayrollstatutorycompliancesPic from "../../Assets/PayrollstatutorycompliancesPic.svg";
import PayrollpayrollcomponentsPic from "../../Assets/PayrollpayrollcomponentsPic.svg";
import PayrollpayrolltemplatePic from "../../Assets/PayrollpayrolltemplatePic.svg";
import PayrollpayrollconfigPic from "../../Assets/PayrollpayrollconfigPic.svg";
import PayrollpayareaPic from "../../Assets/PayrollpayareaPic.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { CompanyStructure } from "./Components/CompanyStructure";
import { PayrollTemplate } from "./Components/PayrollTemplate";
import { ModuleMasterDetailsPage } from "../HRMSMaster/Components";
import { WorkLocation } from "./Components/WorkLocation";
import { StatutoryCompliances } from "./Components/StatutoryCompliances";
import { PayrollComponents } from "./Components/PayrollComponents";
import { PayConfig } from "./Components/PayConfig";
import { PayArea } from "./Components/PayArea";
import { CreatePayArea } from "./Components/CreateComps/CreatePayArea";
import { GroupBy } from "../../Utilities";
import Skeleton from "react-loading-skeleton";
import { getRequest } from "../../Services/axios";
import CreatePayrollTemplate from "./Components/CreateComps/CreatePayrollTemplate";
import CreateEarning from "./Components/CreateComps/CreateEarning";
import { PayslipTemplateDetails } from "./Components/PayslipTemplate/PayslipTemplateDetails";
import CreateDeduction from "./Components/CreateComps/CreateDeduction";
import CreateReimbursement from "./Components/CreateComps/CreateReimbursement";
import { DetailsPayrollTemplate } from "./Components/CreateComps/DetailsPayrollTemplate";
import { DetailsPayArea } from "./Components/CreateComps/DetailsPayArea";
import { CreatePayConfig } from "./Components/CreateComps/CreatePayConfig";
import { DetailsPagePayConfig } from "./Components/PayConfig/DetailsPagePayConfig";
import { PayNotice } from "./Components/Payroll Notice/PayNotice";
import { DetailsPageNoticePayroll } from "./Components/Payroll Notice/DetailsPageNoticePayroll";
import { CreateNoticePay } from "./Components/Payroll Notice/CreateNoticePay";
import { DetailsPagePayroll } from "./Components/PayrollEmployee/DetailsPagePayroll";
import { DetailsPageEarnings } from "./Components/DetailsPageEarnings";
import { DetailsPageReimbursements } from "./Components/DetailsPageReimbursements";
import { DetailsPageDeduction } from "./Components/DetailsPageDeduction";

const PayrollMaster = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [subMenuList, setSubMenuList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState("");
  const [activeFeature, setActiveFeature] = useState("");
  const [createActive, setCreateActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempMenu = Object.values(GroupBy(result?.data, "menuName"));
        tempMenu.map((item) => {
          try {
            if (
              item?.[0]?.menuName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase() !== "payroll"
            )
              return null;
            const tempGroup = GroupBy(item, "subMenuName");
            let tempSubMenu = Object.values(tempGroup);
            return setSubMenuList(tempSubMenu);
          } catch (error) {}
        });
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("payrollmaster")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "payrollmaster");
      if (splitArray?.length > submoduleIndex + 1) {
        if (subMenuList) {
          if (
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              subMenuList
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        if (subMenuList)
          navigate(
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        }
      }
    }
  }, [location.pathname, subMenuList]);
  const getFeatureMenu = (submenu) => {
    if (!subMenuList) return null;
    return subMenuList?.find(
      (obj) =>
        obj?.[0]?.subMenuName
          ?.replaceAll(" ", "")
          ?.replaceAll("&", "")
          ?.toLowerCase() === submenu
    );
  };
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "companystructure") return PayrollcompanystructurePic;
    else if (subMenuName === "worklocations") return PayrollworklocationsPic;
    else if (subMenuName === "statutorycompliances")
      return PayrollstatutorycompliancesPic;
    else if (subMenuName === "payrollcomponents")
      return PayrollpayrollcomponentsPic;
    else if (subMenuName === "payrolltemplate")
      return PayrollpayrolltemplatePic;
    else if (subMenuName === "payconfig") return PayrollpayrollconfigPic;
    else if (subMenuName === "payarea") return PayrollpayareaPic;
    return PayrollcompanystructurePic;
  };
  console.log("detailsActive", detailsActive);
  return (
    <div className="moduleContainer">
      {detailsActive ? <ModuleMasterDetailsPage /> : null}
      {activeSubmodule === "payarea" && (
        <>
          {createActive && <CreatePayArea />}
          {detailsActive && <DetailsPayArea />}
        </>
      )}
      {activeSubmodule === "companystructure" && (
        <>{detailsActive && <DetailsPagePayroll />}</>
      )}
      {activeSubmodule === "payrollcomponents" && (
       activeFeature == 'earnings' ? <>{detailsActive && <DetailsPageEarnings />}</>
       :activeFeature == 'reimbursements' ? <>{detailsActive && <DetailsPageReimbursements />}</> : <>{detailsActive && <DetailsPageDeduction />}</>
      )}
      {activeSubmodule === "paysliptemplate" && (
        <>{detailsActive && <PayslipTemplateDetails />}</>
      )}
           {activeSubmodule === "worklocations" ? (
              detailsActive && <ModuleMasterDetailsPage />
            ) : null}
      {activeSubmodule === "payconfig" && (
        <>
          {detailsActive && <DetailsPagePayConfig />}
          {createActive && <CreatePayConfig />}
        </>
      )}
      {activeSubmodule === "payrolltemplate" && (
        <>
          {detailsActive && <DetailsPayrollTemplate />}
          {/* {createActive && <CreatePayConfig  />} */}
        </>
      )}
      {activeSubmodule === "payrollnoticeboard" && (
        <>
          {detailsActive && <DetailsPageNoticePayroll />}
          {createActive && <CreateNoticePay />}
        </>
      )}

      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {loading ? (
            <Skeleton height={55} count={5} />
          ) : (
            <>
              {subMenuList &&
                subMenuList
                  ?.slice()
                  ?.filter((x) => x[0].isSubMenuActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a[0].subMenuOrder) -
                      parseFloat(b[0].subMenuOrder)
                  )
                  ?.map((subMenu, index) => {
                    return (
                      <div
                        key={index}
                        className={`menuOptions ${
                          activeSubmodule ===
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "menuOptionsActive"
                        }`}
                        onClick={() => {
                          setActiveSubmodule(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          );
                          navigate(
                            `/payrollmaster/${subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }}
                      >
                        <div className="verticalLine"></div>
                        <img
                          src={handleMenuIcon(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          )}
                          alt={subMenu[0]?.subMenuDisplayName}
                        />
                        <label>{subMenu[0]?.subMenuDisplayName}</label>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <div className="homeEngageMainContainer" style={{ overflow: "hidden" }}>
        {loading ? (
          <div className="moduleMainContainer">
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {activeSubmodule === "companystructure" ? (
              <CompanyStructure
                featureMenu={getFeatureMenu("companystructure")}
              />
            ) : null}
            {activeSubmodule === "worklocations" ? (
              <WorkLocation featureMenu={getFeatureMenu("worklocations")} />
            ) : null}
            {activeSubmodule === "payarea" ? (
              <PayArea featureMenu={getFeatureMenu("payarea")} />
            ) : null}
            {activeSubmodule === "payrollcomponents" ? (
              activeFeature === "earnings" ? (
                createActive ? (
                  <CreateEarning />
                ) : (
                  <PayrollComponents
                    featureMenu={getFeatureMenu("payrollcomponents")}
                  />
                )
              ) : activeFeature === "reimbursements" ? (
                createActive ? (
                  <CreateReimbursement />
                ) : (
                  <PayrollComponents
                    featureMenu={getFeatureMenu("payrollcomponents")}
                  />
                )
              ) : createActive ? (
                <CreateDeduction />
              ) : (
                <PayrollComponents
                  featureMenu={getFeatureMenu("payrollcomponents")}
                />
              )
            ) : null}
            {activeSubmodule === "statutorycompliances" ? (
              <StatutoryCompliances
                featureMenu={getFeatureMenu("statutorycompliances")}
              />
            ) : null}
            {activeSubmodule === "payrolltemplate" ? (
              createActive ? (
                <CreatePayrollTemplate />
              ) : (
                <PayrollTemplate
                  featureMenu={getFeatureMenu("payrolltemplate")}
                />
              )
            ) : null}
            {activeSubmodule === "payrollnoticeboard" ? (
              <PayNotice
                featureMenu={getFeatureMenu("Payroll Notice")}
                title="Payroll Notice"
              />
            ) : null}
            {activeSubmodule === "payconfig" ? (
              <PayConfig
                featureMenu={getFeatureMenu("payconfig")}
                title="Pay Config"
              />
            ) : null}
            {activeSubmodule === "paysliptemplate" ? (
              <div className="moduleMainContainer">
                <div className="headingContainer" style={{ padding: "0" }}>
                  <div
                    style={{ display: "flex" }}
                    className="returnToDashboard cursor"
                  >
                    <div>
                    </div>
                    <label className="returnLabelHolder">
                      {"Payslip Template"}
                    </label>
                  </div>
                </div>
                <PayslipTemplateDetails />
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export { PayrollMaster };
