import React from "react";
import { ClaimTypeTab } from "./ClaimTypeTab";
import { useEffect } from "react";

const ClaimType = () => {
  useEffect(() => {
    document.title = `PeopleSol - Claim Type`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <div className="moduleMainContainer">
      <ClaimTypeTab title="Expense Type" />
    </div>
  );
};

export { ClaimType };
