import React, { useState } from "react";
import { VictoryPie, VictoryLabel } from "victory";

const DepartmentDetails = ({ count, percent, data }) => {
  const [color, setColor] = useState([
    "#1296b0",
    "#f7941d",
    "#8a88ed",
    "#fb7158",
    "#77a2c7",
    "#de64a1",
    "#b69fb3",
    "#e9b000",
    "#ff5733",
    "#48c9b0",
    "#ffc300",
    "#9b59b6",
    "#3498db",
    "#2ecc71",
    "#e74c3c",
    "#f39c12",
    "#1abc9c",
  ]);

  const filteredData = [
    { y: data?.department?.["AESS Solutions Pvt Ltd"] },
    { y: data?.department?.["Human Resource"] },
    { y: data?.department?.["Engineering & Product"] },
    { y: data?.department?.Marketing },
    { y: data?.department?.Media },
    { y: data?.department?.Admin },
    { y: data?.department?.Product },
    { y: data?.department?.DevOps },
    { y: data?.department?.Testing },
    { y: data?.department?.Mobile },
    { y: data?.department?.Backend },
    { y: data?.department?.Sales },
    { y: data?.department?.Frontend },
    { y: data?.department?.PR },
    { y: data?.department?.["UI/UX"] },
    { y: data?.department?.Finance },
    { y: data?.department?.Account },
    { y: data?.department?.["Finance & Account"] },
  ].filter((item) => item.y !== 0);
  const sortedData = filteredData.sort((a, b) => b.y - a.y);

  return (
    <div style={{ height: "200px", width: "200px" }}>
      {count > 0 && (
        <svg viewBox="0 0 350 350">
          <VictoryPie
            standalone={false}
            width={350}
            height={350}
            data={sortedData}
            innerRadius={100}
            labelRadius={140}
            labels={() => ""}
            colorScale={color}
            style={{ labels: { fontSize: 0, fill: "none" } }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={175}
            y={160}
            text={count}
            style={{ fontSize: 50, fill: "#1296b0", fontFamily: "Poppins" }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={175}
            y={200}
            text={`${Math.round(percent)}%`}
            style={{ fontSize: 20, fill: "#555", fontFamily: "Poppins" }}
          />
        </svg>
      )}
      {count <= 0 && (
        <svg viewBox="0 0 300 300">
          <VictoryPie
            standalone={false}
            width={350}
            height={350}
            data={[{ y: 1 }]}
            innerRadius={100}
            labelRadius={140}
            labels={() => ""}
            colorScale={["#ccc"]}
            style={{ labels: { fontSize: 0, fill: "none" } }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={170}
            y={160}
            text={count}
            style={{ fontSize: 50, fill: "#1296b0", fontFamily: "Poppins" }}
          />
          <VictoryLabel
            textAnchor="middle"
            verticalAnchor="middle"
            x={170}
            y={200}
            text={`${Math.round(percent)}%`}
            style={{ fontSize: 25, fill: "#555", fontFamily: "Poppins" }}
          />
        </svg>
      )}
    </div>
  );
};

export default DepartmentDetails;
