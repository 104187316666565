import React, { useEffect, useState } from "react";
import SocialRoundThumbLike from "../../../../../Assets/SocialRoundThumbLike.svg";
import SocialRoundHeartLike from "../../../../../Assets/SocialRoundHeartLike.svg";
import SocialRoundClapLike from "../../../../../Assets/SocialRoundClapLike.svg";
import SocialRoundKnowledgeLike from "../../../../../Assets/SocialRoundKnowledgeLike.svg";
import SocialRoundBadLike from "../../../../../Assets/SocialRoundBadIcon.svg";
import SocialRoundSadLike from "../../../../../Assets/SocialRoundSadIcon.svg";
import SocialRoundHateLike from "../../../../../Assets/SocialRoundHateIcon.svg";
import SocialRoundAngryLike from "../../../../../Assets/SocialRoundAngryIcon.svg";
import SocialPostHateIcon from "../../../../../Assets/SocialPostHateIcon.svg";
import SocialPostBadIcon from "../../../../../Assets/SocialPostBadIcon.svg";
import SocialPostSadIcon from "../../../../../Assets/SocialPostSadIcon.svg";
import SocialPostAngryIcon from "../../../../../Assets/SocialPostAngryIcon.svg";
import SocialThumbLike from "../../../../../Assets/SocialThumbLike.svg";
import SocialHeartLike from "../../../../../Assets/SocialHeartLike.svg";
import SocialClapLike from "../../../../../Assets/SocialClapLike.svg";
import SocialKnowledgeLike from "../../../../../Assets/SocialKnowledgeLike.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import { useSelector } from "react-redux";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import CommentActivityContainer from "./CommentActivityContainer";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import setFocusAndScroll from "../../../../../Utilities/setFocusAndScroll";
import dateFormat from "dateformat";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { updatePostCommentCount } from "../../../../../Features/SocialSlice";
import LikeDislikeComment from "./LikeDislikeComment";
import {
  processExpression,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Connect.css";
import { Mention, MentionsInput } from "react-mentions";
import ReplyActivityContainer from "./ReplyActivityContainer";
import ModeratorPostActivityContainer from "./ModeratorPostActivityContainer";
import ModeratorReplyActivityContainer from "./ModeratorReplyActivityContainer";
import {
  commentDisLikeRemoveClickHandler,
  commentLikeRemoveClickHandler,
  disLikeCommentClickHandler,
  likeCommentClickHandler,
} from "../../../../../Utilities/SocialLikeDislikeFunction";
import {
  disLikePost,
  likePost,
  removePostDisLike,
  removePostLike,
} from "./SocialFunctions";
import InfiniteScroll from "react-infinite-scroll-component";

function CommentPost({
  getData,
  setMakeCommentsActive,
  commentedPost,
  postData,
  setPostData,
  setCommentedPost,
  shared,
  localStorageCommentId,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allCommentData, setAllCommentData] = useState([]);
  const [isCommentDisLikeHovered, setIsCommentDisLikeHovered] = useState(false);
  const [isCommentLikeHovered, setIsCommentLikeHovered] = useState(false);
  const [commentInputTxt, setCommentInputTxt] = useState("");
  const [replyInputTxt, setReplyInputTxt] = useState("");
  const [commentId, setCommentId] = useState(0);
  const [parentCommentId, setParentCommentId] = useState(0);
  const [replyId, setReplyId] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 5,
  });

  const [writeReplyActive, setWriteReplyActive] = useState(false);
  const [commentLikeActive, setCommentLikeActive] = useState(false);
  const [commentDislikeActive, setCommentDislikeActive] = useState(false);
  const [commentLikeDislikeCommentId, setCommentLikeDislikeCommentId] =
    useState(0);
  const [replyData, setReplyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [showFullContent, setShowFullContent] = useState(false);
  const [editCommentActive, setEditCommentActive] = useState(false);
  const [editReplyActive, setEditReplyActive] = useState(false);
  const [currentPostData, setCurrentPostData] = useState({});
  const [page, setPage] = useState(1);
  const [selectEmployee, setSelectEmployee] = useState([]);
  const [allEmployeeList, setAllEmplyeeList] = useState([]);
  const [presentEmployeeData, setPresentEmployeeData] = useState([]);
  const [canManageSocial, setCanManageSocial] = useState(
    LoggedInUser?.permissions?.includes("Can Manage Social?")
  );
  const toggleContent = (id, type) => {
    setCurrentPostData({ id, type });
    setShowFullContent(!showFullContent);
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  const getEmployeeData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(
        `Social/GetEmployeesImageDropDown?i=${page}`
      );
      setAllEmplyeeList([...allEmployeeList, ...result?.data.textfilters]);
      setSelectEmployee([...selectEmployee, ...result?.data.textfilters]);
      setPresentEmployeeData(result?.data.textfilters);
      setPage((prev) => prev + 1);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteComment = async (id) => {
    try {
      const result = await getRequest(`SocialPost/DeleteComment/${id}`);
      let updatedSocialData = postData?.map((d) => {
        if (d?.id === commentedPost?.id) {
          return {
            ...d,
            commentsCount: d?.commentsCount - 1,
          };
        }
        return d;
      });
      dispatch(setPostData(updatedSocialData));
      setCommentedPost({
        ...commentedPost,
        commentsCount: commentedPost.commentsCount - 1,
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in deleting ",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const deleteReply = async (data) => {
    try {
      const result = await getRequest(`SocialPost/DeleteComment/${data?.id}`);

      setAllCommentData((prevData) =>
        prevData.map((comment) =>
          comment.item.id === data?.commentId
            ? {
                ...comment,
                item: {
                  ...comment.item,
                  replayCount: comment.item.replayCount - 1,
                },
              }
            : comment
        )
      );
      let updatedSocialData = postData?.map((d) => {
        if (d?.id === data?.postId) {
          return {
            ...d,
            commentsCount: d?.commentsCount - 1,
          };
        }
        return d;
      });
      dispatch(setPostData(updatedSocialData));
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in deleting ",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const editComment = async () => {
    try {
      const result = await postRequest(`SocialPost/CommentEdit`, {
        Id: commentId,
        PostId: commentedPost?.id,
        Comments: commentInputTxt,
      });
      if (result?.data?.isSuccess) {
        let x = allCommentData.map((d) => {
          if (d?.item?.id === commentId) {
            return { ...d, item: { ...d.item, comments: commentInputTxt } };
          } else {
            return d;
          }
        });
        setAllCommentData(x);
        setCommentId(0);
        setCommentInputTxt("");
        setEditCommentActive(false);
        dispatch(
          showToast({
            text: result?.data?.message,
            severity: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in posting comment",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const editReply = async () => {
    try {
      const result = await postRequest(`SocialPost/CommentEdit`, {
        Id: replyId,
        PostId: commentedPost?.id,
        CommentReplay: replyInputTxt,
      });
      if (result?.data?.isSuccess) {
        let x = replyData.map((d) => {
          if (d?.item?.id === replyId) {
            return { ...d, item: { ...d.item, commentReplay: replyInputTxt } };
          } else {
            return d;
          }
        });
        setReplyData(x);
        setReplyInputTxt("");
        setEditReplyActive(false);
        dispatch(
          showToast({
            text: result?.data?.message,
            severity: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in posting comment",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const writeComment = async () => {
    try {
      const result = await postRequest(`SocialPost/Comment`, {
        PostId: commentedPost?.id,
        Comments: commentInputTxt,
      });
      await getPostCommentData(true, 0);
      const newCommentsCount = (commentedPost?.commentsCount || 0) + 1;
      const updatedCommentedPost = {
        ...commentedPost,
        commentsCount: newCommentsCount,
      };
      setCommentedPost(updatedCommentedPost);
      let updatedSocialData = postData?.map((d) => {
        if (d?.id === commentedPost?.id) {
          return {
            ...d,
            commentsCount: d?.commentsCount + 1,
          };
        }
        return d;
      });
      dispatch(setPostData(updatedSocialData));
      dispatch(
        showToast({
          text: "Commented successfully",
          severity: "success",
        })
      );
      setCommentInputTxt("");
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in posting comment",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const writeReply = async (commentid) => {
    try {
      const result = await postRequest(`SocialPost/CommentReplay`, {
        PostId: commentedPost?.id,
        CommentId: commentid,
        CommentReplay: replyInputTxt,
      });
      loadReply(commentid);
      // setCommentId(commentId);
      const newCommentsCount = (commentedPost?.commentsCount || 0) + 1;
      const updatedCommentedPost = {
        ...commentedPost,
        commentsCount: newCommentsCount,
      };
      setCommentedPost(updatedCommentedPost);
      let updatedSocialData = postData?.map((d) => {
        if (d?.id === commentedPost?.id) {
          return {
            ...d,
            commentsCount: d?.commentsCount + 1,
          };
        }
        return d;
      });
      let newAllCommentData = allCommentData?.map((d) => {
        if (d?.item?.id === commentid) {
          return {
            ...d,
            item: { ...d.item, replayCount: d?.item?.replayCount + 1 },
          };
        }
        return d;
      });
      setAllCommentData(newAllCommentData);
      dispatch(setPostData(updatedSocialData));
      dispatch(
        showToast({
          text: "Reply successfully",
          severity: "success",
        })
      );
      setReplyInputTxt("");
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occurred in posting comment",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getPostCommentData = async (
    updateSocialComment = false,
    itemIndex,
    commentLength
  ) => {
    try {
      const result = await postRequest(`SocialPost/AllCommentList`, {
        postId: commentedPost?.id,
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: paginationData.per_page,
          iDisplayStart: itemIndex ?? currentIndex,
          sSearch: null,
          sorting: "createdOn",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (commentLength) {
      }
      setAllCommentData(
        itemIndex === 0
          ? result?.data?.data
          : [...allCommentData, ...result?.data?.data]
      );
      setCurrentIndex(allCommentData?.length + result?.data?.data?.length);
      setPaginationData({
        ...paginationData,
        total: result?.data?.summary.allCount,
      });

      if (updateSocialComment) {
        dispatch(
          updatePostCommentCount({
            postId: commentedPost?.id,
            commCount: result?.data?.data.length,
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const loadReply = async (commentid) => {
    try {
      const result = await postRequest(`SocialPost/AllCommentList`, {
        postId: commentedPost?.id,
        commentId: commentid,
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 100,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",

          isExport: false,
        },
      });
      setReplyData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    setLoading(true);
    getPostCommentData();
    getEmployeeData();
  }, []);
  return (
    <div style={{ padding: "15px" }}>
      {!editCommentActive && (
        <div className="makeCommentBottomContainer">
          <div className="makeCommentBottomWrapper">
            <div
              className="makeCommentProfilePic"
              onClick={() => {
                navigate(`/home/social/userprofile/${LoggedInUser?.id}`);
              }}
            >
              <div
                className={`${
                  LoggedInUser?.image
                    ? "commentProfilePicContainer"
                    : "makeCommentCustomProfilePic"
                }`}
              >
                {!LoggedInUser?.image ? (
                  getUserInitials(
                    getDisplayName(LoggedInUser?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className="commentProfilePic"
                    onClick={() => {
                      navigate(`/home/social/userprofile/${LoggedInUser?.id}`);
                    }}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      LoggedInUser?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>
            </div>
            <div className="makeCommentInput">
              {!loading && (
                <InfiniteScroll
                  dataLength={allEmployeeList?.length}
                  next={getEmployeeData}
                  hasMore={presentEmployeeData?.length < 20 ? false : true}
                  height={300}
                  width={200}
                >
                  <MentionsInput
                    placeholder=" Write a Comment "
                    autoFocus
                    value={commentInputTxt}
                    onChange={(e) => {
                      setCommentInputTxt(e.target.value);
                    }}
                    maxLength={1000}
                    a11ySuggestionsListLabel={" Write a Comment "}
                    className={"inputCommentClass"}
                  >
                    <Mention
                      trigger={"@"}
                      data={allEmployeeList?.map((d) => {
                        return { id: d?.id, display: getDisplayName(d?.name) };
                      })}
                    />
                  </MentionsInput>
                </InfiniteScroll>
              )}
              {loading && <Loader />}
            </div>
          </div>
          {commentInputTxt !== "" && (
            <div className="makeCommentSendIcon">
              <div
                className="replyBtn"
                style={{ padding: "5px 20px" }}
                onClick={() => {
                  writeComment(commentedPost?.item?.id);
                }}
              >
                <span>Post</span>
              </div>
            </div>
          )}
        </div>
      )}
      {editCommentActive && (
        <div className="makeCommentBottomContainer">
          <div className="makeCommentBottomWrapper">
            <div
              className="makeCommentProfilePic"
              onClick={() => {
                navigate(`/home/social/userprofile/${LoggedInUser?.id}`);
              }}
            >
              <div
                className={`${
                  LoggedInUser?.image
                    ? "commentProfilePicContainer"
                    : "makeCommentCustomProfilePic"
                }`}
              >
                {!LoggedInUser?.image ? (
                  getUserInitials(
                    getDisplayName(LoggedInUser?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className="commentProfilePic"
                    onClick={() => {
                      // handleUserProfileClick(res?.id);
                      navigate(`/home/social/userprofile/${LoggedInUser?.id}`);
                    }}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      LoggedInUser?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>
            </div>
            <div className="makeCommentInput">
              {!loading && (
                <InfiniteScroll
                  dataLength={allEmployeeList?.length}
                  next={getEmployeeData}
                  hasMore={presentEmployeeData?.length < 20 ? false : true}
                  height={300}
                  width={200}
                >
                  <MentionsInput
                    placeholder=" Write a Comment "
                    autoFocus
                    value={commentInputTxt}
                    onChange={(e) => {
                      setCommentInputTxt(e.target.value);
                    }}
                    maxLength={1000}
                    a11ySuggestionsListLabel={" Write a Comment "}
                    className={"inputCommentClass"}
                  >
                    <Mention
                      trigger={"@"}
                      data={allEmployeeList?.map((d) => {
                        return { id: d?.id, display: getDisplayName(d?.name) };
                      })}
                    />
                  </MentionsInput>
                </InfiniteScroll>
              )}
              {loading && <Loader />}
            </div>
          </div>
          {commentInputTxt !== "" && (
            <div className="makeCommentSendIcon">
              <div
                className="replyBtn"
                style={{ padding: "5px 20px" }}
                onClick={() => {
                  editComment();
                }}
              >
                <span>Save</span>
              </div>
            </div>
          )}
        </div>
      )}

      {loading && <Loader />}
      {!loading && (
        <div
          className="mainCommentContainer"
          style={{ margin: "20px 0 10px 0" }}
        >
          {allCommentData?.length > 0 && <h5>Comments</h5>}
          {allCommentData?.length > 0 &&
            allCommentData?.map((d, i) => {
              return (
                <div className="commentContainerList">
                  {d?.item?.commentId === null && (
                    <>
                      <div className="commentContainer">
                        <div
                          className="commentImgContainer"
                          onClick={() => {
                            navigate(
                              `/home/social/userprofile/${d?.item?.employeeId}`
                            );
                          }}
                        >
                          <div
                            className={`${
                              d?.item?.image
                                ? "commentProfilePicContainer"
                                : "commentCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.image ? (
                              getUserInitials(
                                getDisplayName(d?.item?.nameWithPersonNumber)
                              )
                            ) : (
                              <img
                                className="commentProfilePic"
                                onClick={() => {
                                  // handleUserProfileClick(res?.id);
                                  navigate(
                                    `/home/social/userprofile/${d?.item?.id}`
                                  );
                                }}
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.item?.image
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = ProfileAvatar;
                                }}
                              />
                            )}
                          </div>
                          {/* <img
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              profileImages +
                              d?.item?.image
                            }
                            alt=""
                          /> */}
                        </div>
                        <div className="commentTextContainer">
                          <div className="commenteeDetails">
                            <div className="commenteeDetailsText">
                              <h5>
                                {getDisplayName(d?.item?.nameWithPersonNumber)}
                              </h5>
                              <h6>
                                {d?.item?.roleName}{" "}
                                <span>
                                  .{" "}
                                  {dateFormat(
                                    d?.item?.createdOn,
                                    "hh:MM TT | dd mmm yyyy"
                                  )}
                                </span>
                              </h6>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              {d?.item?.employeeId === LoggedInUser?.id && (
                                <CommentActivityContainer
                                  commentid={d?.item?.id}
                                  getPostCommentData={getPostCommentData}
                                  setAllCommentData={setAllCommentData}
                                  allCommentData={allCommentData}
                                  postData={postData}
                                  setPostData={setPostData}
                                  comment={d?.item?.comments}
                                  deleteComment={deleteComment}
                                  setEditCommentActive={setEditCommentActive}
                                  setCommentInputTxt={setCommentInputTxt}
                                  setCommentId={setCommentId}
                                />
                              )}
                              {d?.item?.employeeId !== LoggedInUser?.id &&
                                canManageSocial && (
                                  <ModeratorPostActivityContainer
                                    commentid={d?.item?.id}
                                    getPostCommentData={getPostCommentData}
                                    setAllCommentData={setAllCommentData}
                                    allCommentData={allCommentData}
                                    postData={postData}
                                    setPostData={setPostData}
                                    deleteComment={deleteComment}
                                    setCommentId={setCommentId}
                                    // comment={d?.item?.comments}
                                    // setEditCommentActive={setEditCommentActive}
                                    // setCommentInputTxt={setCommentInputTxt}
                                  />
                                )}
                            </div>
                          </div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                showFullContent &&
                                currentPostData?.id === d?.item?.id &&
                                currentPostData?.type === "comment"
                                  ? processExpression(
                                      truncateText(d?.item?.comments)
                                    )
                                  : processExpression(
                                      truncateText(d?.item?.comments, 200)
                                    ),
                            }}
                          ></p>
                          {d?.item?.comments?.length > 200 && (
                            <span
                              style={{
                                cursor: "pointer",
                                color: "#1296b0",
                                fontWeight: "500",
                                fontSize: "13px",
                              }}
                              onClick={(e) => {
                                toggleContent(d?.item?.id, "comment");
                              }}
                            >
                              {showFullContent &&
                              currentPostData?.id === d?.item?.id &&
                              currentPostData?.type === "comment"
                                ? "See Less"
                                : "View More"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="likeAndReply">
                        <div className="likeAndReplyList">
                          <div
                            className="parentCommentLike"
                            onMouseOver={() =>
                              setIsCommentLikeHovered(d?.item?.id)
                            }
                            onMouseLeave={() => setIsCommentLikeHovered(null)}
                          >
                            {isCommentLikeHovered === d?.item?.id && (
                              <div className="doCommentLikeList">
                                <div className="likeList">
                                  <div
                                    className="thumbLike"
                                    onClick={async () => {
                                      let newD = d?.item;
                                      if (
                                        d?.item?.isDisLiked !== null ||
                                        d?.item?.isDisLiked?.isLike
                                      ) {
                                        newD =
                                          await commentDisLikeRemoveClickHandler(
                                            d?.item?.id,
                                            d?.item,
                                            allCommentData,
                                            setAllCommentData,
                                            removePostDisLike,
                                            setIsCommentDisLikeHovered,
                                            getData
                                          );
                                      }
                                      likeCommentClickHandler(
                                        d?.item?.id,
                                        newD,
                                        "thumb",
                                        allCommentData,
                                        setAllCommentData,
                                        likePost,
                                        setIsCommentLikeHovered,
                                        getData
                                      );
                                    }}
                                  >
                                    <img src={SocialThumbLike} alt="" />
                                  </div>
                                  <div
                                    className="clapLike"
                                    onClick={async () => {
                                      let newD = d?.item;
                                      if (
                                        d?.item?.isDisLiked !== null ||
                                        d?.item?.isDisLiked?.isLike
                                      ) {
                                        newD =
                                          await commentDisLikeRemoveClickHandler(
                                            d?.item?.id,
                                            d?.item,
                                            allCommentData,
                                            setAllCommentData,
                                            removePostDisLike,
                                            setIsCommentDisLikeHovered,
                                            getData
                                          );
                                      }
                                      likeCommentClickHandler(
                                        d?.item?.id,
                                        newD,
                                        "clap",
                                        allCommentData,
                                        setAllCommentData,
                                        likePost,
                                        setIsCommentLikeHovered,
                                        getData
                                      );
                                    }}
                                  >
                                    <img src={SocialClapLike} alt="" />
                                  </div>
                                  <div
                                    className="knowledgeLike"
                                    onClick={async () => {
                                      let newD = d?.item;
                                      if (
                                        d?.item?.isDisLiked !== null ||
                                        d?.item?.isDisLiked?.isLike
                                      ) {
                                        newD =
                                          await commentDisLikeRemoveClickHandler(
                                            d?.item?.id,
                                            d?.item,
                                            allCommentData,
                                            setAllCommentData,
                                            removePostDisLike,
                                            setIsCommentDisLikeHovered,
                                            getData
                                          );
                                      }
                                      likeCommentClickHandler(
                                        d?.item?.id,
                                        newD,
                                        "knowledge",
                                        allCommentData,
                                        setAllCommentData,
                                        likePost,
                                        setIsCommentLikeHovered,
                                        getData
                                      );
                                    }}
                                  >
                                    <img src={SocialKnowledgeLike} alt="" />
                                  </div>
                                  <div
                                    className="heartLike"
                                    onClick={async () => {
                                      let newD = d?.item;
                                      if (
                                        d?.item?.isDisLiked !== null ||
                                        d?.item?.isDisLiked?.isLike
                                      ) {
                                        newD =
                                          await commentDisLikeRemoveClickHandler(
                                            d?.item?.id,
                                            d?.item,
                                            allCommentData,
                                            setAllCommentData,
                                            removePostDisLike,
                                            setIsCommentDisLikeHovered,
                                            getData
                                          );
                                      }
                                      likeCommentClickHandler(
                                        d?.item?.id,
                                        newD,
                                        "heart",
                                        allCommentData,
                                        setAllCommentData,
                                        likePost,
                                        setIsCommentLikeHovered,
                                        getData
                                      );
                                    }}
                                  >
                                    <img src={SocialHeartLike} alt="" />
                                  </div>
                                </div>
                              </div>
                            )}
                            <p
                              onClick={async () => {
                                if (
                                  d?.item?.isLiked === null ||
                                  d?.item?.isLiked?.isLike === false
                                ) {
                                  let newD = d?.item;
                                  if (
                                    d?.isDisLiked !== null ||
                                    d?.isDisLiked?.isLike
                                  ) {
                                    newD =
                                      await commentDisLikeRemoveClickHandler(
                                        d?.item?.id,
                                        d?.item,
                                        allCommentData,
                                        setAllCommentData,
                                        removePostDisLike,
                                        setIsCommentDisLikeHovered
                                      );
                                  }
                                  likeCommentClickHandler(
                                    d?.item?.id,
                                    newD,
                                    "thumb",
                                    allCommentData,
                                    setAllCommentData,
                                    likePost,
                                    setIsCommentLikeHovered,
                                    getData
                                  );
                                } else {
                                  commentLikeRemoveClickHandler(
                                    d?.item?.id,
                                    d?.item,
                                    allCommentData,
                                    setAllCommentData,
                                    removePostLike,
                                    setIsCommentLikeHovered
                                  );
                                }
                              }}
                            >
                              {(d?.item?.isLiked === null ||
                                !d?.item?.isLiked?.isLike) && <span>Like</span>}
                              {d?.item?.isLiked?.likeType === "thumb" &&
                                d?.item?.isLiked?.isLike && (
                                  <span className={"liked"}>Like</span>
                                )}
                              {d?.item?.isLiked?.likeType === "clap" &&
                                d?.item?.isLiked?.isLike && (
                                  <span className={"clapLike"}>Clap</span>
                                )}
                              {d?.item?.isLiked?.likeType === "knowledge" &&
                                d?.item?.isLiked?.isLike && (
                                  <span className={"kowledgeLike"}>
                                    Knowledge
                                  </span>
                                )}
                              {d?.item?.isLiked?.likeType === "heart" &&
                                d?.item?.isLiked?.isLike && (
                                  <span className={"heartLike"}>Heart</span>
                                )}
                            </p>
                          </div>
                          <div
                            className="postLikeIconContainer"
                            onClick={() => {
                              setCommentLikeActive(true);
                              setCommentLikeDislikeCommentId(d?.item?.id);
                            }}
                          >
                            <div className="commentLikeIcons">
                              {d?.item?.likedType?.length > 0 && (
                                <>
                                  {d?.item?.likedType[0] && (
                                    <div className="thumbLikeRoundIcon">
                                      {d?.item?.likedType[0]?.name ===
                                        "thumb" && (
                                        <img
                                          src={SocialRoundThumbLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.likedType[0]?.name ===
                                        "clap" && (
                                        <img src={SocialRoundClapLike} alt="" />
                                      )}
                                      {d?.item?.likedType[0]?.name ===
                                        "heart" && (
                                        <img
                                          src={SocialRoundHeartLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.likedType[0]?.name ===
                                        "knowledge" && (
                                        <img
                                          src={SocialRoundKnowledgeLike}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  )}
                                  {d?.item?.likedType[1] && (
                                    <div className="clapLikeRoundIcon">
                                      {d?.item?.likedType[1]?.name ===
                                        "thumb" && (
                                        <img
                                          src={SocialRoundThumbLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.likedType[1]?.name ===
                                        "clap" && (
                                        <img src={SocialRoundClapLike} alt="" />
                                      )}
                                      {d?.item?.likedType[1]?.name ===
                                        "heart" && (
                                        <img
                                          src={SocialRoundHeartLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.likedType[1]?.name ===
                                        "knowledge" && (
                                        <img
                                          src={SocialRoundKnowledgeLike}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  )}
                                  {d?.item?.likedType[2] && (
                                    <div className="heartLikeRoundIcon">
                                      {d?.item?.likedType[2]?.name ===
                                        "thumb" && (
                                        <img
                                          src={SocialRoundThumbLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.likedType[2]?.name ===
                                        "clap" && (
                                        <img src={SocialRoundClapLike} alt="" />
                                      )}
                                      {d?.item?.likedType[2]?.name ===
                                        "heart" && (
                                        <img
                                          src={SocialRoundHeartLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.likedType[2]?.name ===
                                        "knowledge" && (
                                        <img
                                          src={SocialRoundKnowledgeLike}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  )}
                                  {d?.item?.likedType[3] && (
                                    <div className="knowledgeLikeRoundIcon">
                                      {d?.item?.likedType[3]?.name ===
                                        "thumb" && (
                                        <img
                                          src={SocialRoundThumbLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.likedType[3]?.name ===
                                        "clap" && (
                                        <img src={SocialRoundClapLike} alt="" />
                                      )}
                                      {d?.item?.likedType[3]?.name ===
                                        "heart" && (
                                        <img
                                          src={SocialRoundHeartLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.likedType[3]?.name ===
                                        "knowledge" && (
                                        <img
                                          src={SocialRoundKnowledgeLike}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            {d?.item?.commentLike > 0 && (
                              <span
                                className={
                                  d?.item?.likedType?.length === 1
                                    ? "likeFirstCls"
                                    : null || d?.item?.likedType?.length === 2
                                    ? "likeSecondCls"
                                    : null || d?.item?.likedType?.length === 3
                                    ? "likeThirdCls"
                                    : null || d?.item?.likedType?.length === 4
                                    ? "likeFourthCls"
                                    : null
                                }
                              >
                                {d?.item?.commentLike < 10 ? 0 : null}
                                {d?.item?.commentLike}
                              </span>
                            )}
                          </div>
                          <p className="commentVerticalLine">|</p>
                          <div
                            className="parentCommentDisLike"
                            onMouseOver={() =>
                              setIsCommentDisLikeHovered(d?.item?.id)
                            }
                            onMouseLeave={() =>
                              setIsCommentDisLikeHovered(null)
                            }
                          >
                            {isCommentDisLikeHovered === d?.item?.id && (
                              <div className="doCommentDislikeList">
                                <div className="likeList">
                                  <div
                                    className="thumbLike"
                                    onClick={async () => {
                                      let newD = d?.item;
                                      if (
                                        d?.item?.isLiked !== null ||
                                        d?.item?.isLiked?.isLike
                                      ) {
                                        newD =
                                          await commentLikeRemoveClickHandler(
                                            d?.item?.id,
                                            d?.item,
                                            allCommentData,
                                            setAllCommentData,
                                            removePostLike,
                                            setIsCommentLikeHovered
                                          );
                                      }
                                      disLikeCommentClickHandler(
                                        d?.item?.id,
                                        newD,
                                        "bad",
                                        allCommentData,
                                        setAllCommentData,
                                        disLikePost,
                                        setIsCommentDisLikeHovered,
                                        getData
                                      );
                                    }}
                                  >
                                    <img src={SocialPostBadIcon} alt="" />
                                  </div>
                                  <div
                                    className="clapLike"
                                    onClick={async () => {
                                      let newD = d?.item;
                                      if (
                                        d?.item?.isLiked !== null ||
                                        d?.item?.isLiked?.isLike
                                      ) {
                                        newD =
                                          await commentLikeRemoveClickHandler(
                                            d?.item?.id,
                                            d?.item,
                                            allCommentData,
                                            setAllCommentData,
                                            removePostLike,
                                            setIsCommentLikeHovered
                                          );
                                      }
                                      disLikeCommentClickHandler(
                                        d?.item?.id,
                                        newD,
                                        "angry",
                                        allCommentData,
                                        setAllCommentData,
                                        disLikePost,
                                        setIsCommentDisLikeHovered,
                                        getData
                                      );
                                    }}
                                  >
                                    <img src={SocialPostAngryIcon} alt="" />
                                  </div>
                                  <div
                                    className="knowledgeLike"
                                    onClick={async () => {
                                      let newD = d?.item;
                                      if (
                                        d?.item?.isLiked !== null ||
                                        d?.item?.isLiked?.isLike
                                      ) {
                                        newD =
                                          await commentLikeRemoveClickHandler(
                                            d?.item?.id,
                                            d?.item,
                                            allCommentData,
                                            setAllCommentData,
                                            removePostLike,
                                            setIsCommentLikeHovered
                                          );
                                      }
                                      disLikeCommentClickHandler(
                                        d?.item?.id,
                                        newD,
                                        "sad",
                                        allCommentData,
                                        setAllCommentData,
                                        disLikePost,
                                        setIsCommentDisLikeHovered,
                                        getData
                                      );
                                    }}
                                  >
                                    <img src={SocialPostSadIcon} alt="" />
                                  </div>
                                  <div
                                    className="heartLike"
                                    onClick={async () => {
                                      let newD = d?.item;
                                      if (
                                        d?.item?.isLiked !== null ||
                                        d?.item?.isLiked?.isLike
                                      ) {
                                        newD =
                                          await commentLikeRemoveClickHandler(
                                            d?.item?.id,
                                            d?.item,
                                            allCommentData,
                                            setAllCommentData,
                                            removePostLike,
                                            setIsCommentLikeHovered
                                          );
                                      }
                                      disLikeCommentClickHandler(
                                        d?.item?.id,
                                        newD,
                                        "hate",
                                        allCommentData,
                                        setAllCommentData,
                                        disLikePost,
                                        setIsCommentDisLikeHovered,
                                        getData
                                      );
                                    }}
                                  >
                                    <img src={SocialPostHateIcon} alt="" />
                                  </div>
                                </div>
                              </div>
                            )}
                            <p
                              onClick={async () => {
                                if (
                                  d?.item?.isDisLiked === null ||
                                  d?.item?.isDisLiked?.isLike === false
                                ) {
                                  let newD = d?.item;
                                  if (
                                    d?.isLiked !== null ||
                                    d?.isLiked?.isLike
                                  ) {
                                    newD = await commentLikeRemoveClickHandler(
                                      d?.item?.id,
                                      d?.item,
                                      allCommentData,
                                      setAllCommentData,
                                      removePostLike,
                                      setIsCommentLikeHovered
                                    );
                                  }
                                  disLikeCommentClickHandler(
                                    d?.item?.id,
                                    newD,
                                    "bad",
                                    allCommentData,
                                    setAllCommentData,
                                    disLikePost,
                                    setIsCommentDisLikeHovered,
                                    getData
                                  );
                                } else {
                                  commentDisLikeRemoveClickHandler(
                                    d?.item?.id,
                                    d?.item,
                                    allCommentData,
                                    setAllCommentData,
                                    removePostDisLike,
                                    setIsCommentDisLikeHovered
                                  );
                                }
                              }}
                            >
                              {(d?.item?.isDisLiked === null ||
                                !d?.item?.isDisLiked?.isLike) && (
                                <span>Dislike</span>
                              )}
                              {d?.item?.isDisLiked?.likeType === "bad" &&
                                d?.item?.isDisLiked?.isLike && (
                                  <span className={"disliked"}>Dislike</span>
                                )}
                              {d?.item?.isDisLiked?.likeType === "sad" &&
                                d?.item?.isDisLiked?.isLike && (
                                  <span className={"sadLike"}>Sad</span>
                                )}

                              {d?.item?.isDisLiked?.likeType === "hate" &&
                                d?.item?.isDisLiked?.isLike && (
                                  <span className={"hateLike"}>Hate</span>
                                )}

                              {d?.item?.isDisLiked?.likeType === "angry" &&
                                d?.item?.isDisLiked?.isLike && (
                                  <span className={"angryLike"}>Angry</span>
                                )}
                            </p>
                          </div>
                          <div
                            className="postLikeIconContainer"
                            onClick={() => {
                              setCommentDislikeActive(true);
                              setCommentLikeDislikeCommentId(d?.item?.id);
                            }}
                          >
                            <div className="commentLikeIcons">
                              {d?.item?.disLikedType?.length > 0 && (
                                <>
                                  {d?.item?.disLikedType[0] && (
                                    <div className="thumbLikeRoundIcon">
                                      {d?.item?.disLikedType[0]?.name ===
                                        "bad" && (
                                        <img src={SocialRoundBadLike} alt="" />
                                      )}
                                      {d?.item?.disLikedType[0]?.name ===
                                        "sad" && (
                                        <img src={SocialRoundSadLike} alt="" />
                                      )}
                                      {d?.item?.disLikedType[0]?.name ===
                                        "angry" && (
                                        <img
                                          src={SocialRoundAngryLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.disLikedType[0]?.name ===
                                        "hate" && (
                                        <img src={SocialRoundHateLike} alt="" />
                                      )}
                                    </div>
                                  )}
                                  {d?.item?.disLikedType[1] && (
                                    <div className="clapLikeRoundIcon">
                                      {d?.item?.disLikedType[1]?.name ===
                                        "bad" && (
                                        <img src={SocialRoundBadLike} alt="" />
                                      )}
                                      {d?.item?.disLikedType[1]?.name ===
                                        "sad" && (
                                        <img src={SocialRoundSadLike} alt="" />
                                      )}
                                      {d?.item?.disLikedType[1]?.name ===
                                        "angry" && (
                                        <img
                                          src={SocialRoundAngryLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.disLikedType[1]?.name ===
                                        "hate" && (
                                        <img src={SocialRoundHateLike} alt="" />
                                      )}
                                    </div>
                                  )}
                                  {d?.item?.disLikedType[2] && (
                                    <div className="heartLikeRoundIcon">
                                      {d?.item?.disLikedType[2]?.name ===
                                        "bad" && (
                                        <img src={SocialRoundBadLike} alt="" />
                                      )}
                                      {d?.item?.disLikedType[2]?.name ===
                                        "sad" && (
                                        <img src={SocialRoundSadLike} alt="" />
                                      )}
                                      {d?.item?.disLikedType[2]?.name ===
                                        "angry" && (
                                        <img
                                          src={SocialRoundAngryLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.disLikedType[2]?.name ===
                                        "hate" && (
                                        <img src={SocialRoundHateLike} alt="" />
                                      )}
                                    </div>
                                  )}{" "}
                                  {d?.item?.disLikedType[3] && (
                                    <div className="knowledgeLikeRoundIcon">
                                      {d?.item?.disLikedType[3]?.name ===
                                        "bad" && (
                                        <img src={SocialRoundBadLike} alt="" />
                                      )}
                                      {d?.item?.disLikedType[3]?.name ===
                                        "sad" && (
                                        <img src={SocialRoundSadLike} alt="" />
                                      )}
                                      {d?.item?.disLikedType[3]?.name ===
                                        "angry" && (
                                        <img
                                          src={SocialRoundAngryLike}
                                          alt=""
                                        />
                                      )}
                                      {d?.item?.disLikedType[3]?.name ===
                                        "hate" && (
                                        <img src={SocialRoundHateLike} alt="" />
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            {d?.item?.commentDisLike > 0 && (
                              <span
                                className={
                                  d?.item?.disLikedType?.length === 1
                                    ? "likeFirstCls"
                                    : null ||
                                      d?.item?.disLikedType?.length === 2
                                    ? "likeSecondCls"
                                    : null ||
                                      d?.item?.disLikedType?.length === 3
                                    ? "likeThirdCls"
                                    : null ||
                                      d?.item?.disLikedType?.length === 4
                                    ? "likeFourthCls"
                                    : null
                                }
                              >
                                {d?.item?.commentDisLike < 10 ? 0 : null}
                                {d?.item?.commentDisLike}
                              </span>
                            )}
                          </div>
                          {d?.item?.employeeId !== LoggedInUser?.id && (
                            <div
                              className="makeReply"
                              onClick={() => {
                                setWriteReplyActive(d?.item?.id);
                                setTimeout(() => {
                                  setFocusAndScroll("#replyField");
                                }, 500);
                              }}
                            >
                              <p>.</p>
                              <span>Reply</span>
                            </div>
                          )}
                          <div
                            className="viewReply"
                            style={{ padding: "0" }}
                            // onClick={() => {
                            //   setCommentId(d?.item?.id);
                            // }}
                          >
                            {d?.item?.replayCount > 0 && (
                              <span
                                onClick={() => {
                                  setCommentId(d?.item?.id);
                                  loadReply(d?.item?.id);
                                }}
                                className={`totalReplyText ${
                                  commentId !== d?.item?.id
                                    ? "activeComment"
                                    : ""
                                }`}
                              >
                                {d?.item?.replayCount} Reply
                                {/* {replyData?.length} Reply */}
                              </span>
                            )}
                            {d?.item?.replayCount <= 0 && (
                              <span className="totalReplyText">Reply</span>
                            )}
                          </div>
                        </div>
                      </div>
                      {replyData[0]?.item?.commentId === d?.item?.id && (
                        <div className="mainRepliedContainer">
                          {replyData?.map((replyItem, i) => {
                            return (
                              <div className="repliedContainerList">
                                <div className="repliedContainer">
                                  <div
                                    className="repliedImgContainer"
                                    onClick={() => {
                                      navigate(
                                        `/home/social/userprofile/${replyItem?.item?.employeeId}`
                                      );
                                    }}
                                  >
                                    <div
                                      className={`${
                                        replyItem?.item?.image
                                          ? "commentProfilePicContainer"
                                          : "commentCustomProfilePic"
                                      }`}
                                    >
                                      {!replyItem?.item?.image ? (
                                        getUserInitials(
                                          getDisplayName(
                                            replyItem?.item
                                              ?.nameWithPersonNumber
                                          )
                                        )
                                      ) : (
                                        <img
                                          className="commentProfilePic"
                                          onClick={() => {
                                            // handleUserProfileClick(res?.id);
                                            navigate(
                                              `/home/social/userprofile/${replyItem?.item?.id}`
                                            );
                                          }}
                                          src={
                                            process.env.REACT_APP_.BASE_URL +
                                            profileImages +
                                            replyItem?.item?.image
                                          }
                                          alt="Profile"
                                          onError={(e) => {
                                            e.target.src = ProfileAvatar;
                                          }}
                                        />
                                      )}
                                    </div>
                                    {/* <img
                                      src={
                                        process.env.REACT_APP_.BASE_URL +
                                        profileImages +
                                        replyItem?.item?.image
                                      }
                                      alt=""
                                    /> */}
                                  </div>
                                  <div className="repliedTextContainer">
                                    <div className="repliedDetails">
                                      <div>
                                        <h5>
                                          {getDisplayName(
                                            replyItem?.item
                                              ?.nameWithPersonNumber
                                          )}
                                        </h5>
                                        <h6>
                                          {replyItem?.item?.roleName}{" "}
                                          <span>
                                            .{" "}
                                            {dateFormat(
                                              replyItem?.item?.createdOn,
                                              "hh:MM TT | dd mmm yyyy"
                                            )}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          showFullContent &&
                                          currentPostData?.id ===
                                            replyItem?.item?.id &&
                                          currentPostData?.type === "reply"
                                            ? processExpression(
                                                truncateText(
                                                  replyItem?.item?.commentReplay
                                                )
                                              )
                                            : processExpression(
                                                truncateText(
                                                  replyItem?.item
                                                    ?.commentReplay,
                                                  200
                                                )
                                              ),
                                      }}
                                    ></p>
                                    {replyItem?.item?.commentReplay?.length >
                                      200 && (
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "#1296b0",
                                          fontWeight: "500",
                                          fontSize: "13px",
                                        }}
                                        onClick={(e) => {
                                          toggleContent(
                                            replyItem?.item?.id,
                                            "reply"
                                          );
                                        }}
                                      >
                                        {showFullContent &&
                                        currentPostData?.id ===
                                          replyItem?.item?.id &&
                                        currentPostData?.type === "reply"
                                          ? "See Less"
                                          : "View More"}
                                      </span>
                                    )}
                                  </div>
                                  {replyItem?.item?.employeeId ===
                                    LoggedInUser?.id && (
                                    <ReplyActivityContainer
                                      parentCommentid={
                                        replyItem?.item?.commentId
                                      }
                                      commentid={replyItem?.item?.id}
                                      replyItemData={replyItem?.item}
                                      loadReply={loadReply}
                                      comment={replyItem?.item?.commentReplay}
                                      replyData={replyData}
                                      setReplyData={setReplyData}
                                      setReplyId={setReplyId}
                                      deleteReply={deleteReply}
                                      allCommentData={allCommentData}
                                      setReplyInputTxt={setReplyInputTxt}
                                      setEditReplyActive={setEditReplyActive}
                                      setWriteReplyActive={setWriteReplyActive}
                                      setParentCommentId={setParentCommentId}
                                    />
                                  )}
                                  {replyItem?.item?.employeeId !==
                                    LoggedInUser?.id &&
                                    canManageSocial && (
                                      <ModeratorReplyActivityContainer
                                        parentCommentid={
                                          replyItem?.item?.commentId
                                        }
                                        commentid={replyItem?.item?.id}
                                        replyItemData={replyItem?.item}
                                        loadReply={loadReply}
                                        comment={replyItem?.item?.commentReplay}
                                        replyData={replyData}
                                        setReplyData={setReplyData}
                                        setReplyId={setReplyId}
                                        deleteReply={deleteReply}
                                        allCommentData={allCommentData}
                                        setParentCommentId={setParentCommentId}
                                        // setReplyInputTxt={setReplyInputTxt}
                                        // setEditReplyActive={setEditReplyActive}
                                        // setWriteReplyActive={setWriteReplyActive}
                                      />
                                    )}
                                </div>
                                <div className="likeAndReply">
                                  <div className="likeAndReplyList">
                                    <div
                                      className="parentCommentLike"
                                      onMouseOver={() =>
                                        setIsCommentLikeHovered(
                                          replyItem?.item?.id
                                        )
                                      }
                                      onMouseLeave={() =>
                                        setIsCommentLikeHovered(null)
                                      }
                                    >
                                      {isCommentLikeHovered ===
                                        replyItem?.item?.id && (
                                        <div className="doCommentLikeList">
                                          <div className="likeList">
                                            <div
                                              className="thumbLike"
                                              onClick={async () => {
                                                let newD = replyItem?.item;
                                                if (
                                                  replyItem?.item
                                                    ?.isDisLiked !== null ||
                                                  replyItem?.item?.isDisLiked
                                                    ?.isLike
                                                ) {
                                                  newD =
                                                    await commentDisLikeRemoveClickHandler(
                                                      replyItem?.item?.id,
                                                      replyItem?.item,
                                                      allCommentData,
                                                      setAllCommentData,
                                                      removePostDisLike,
                                                      setIsCommentDisLikeHovered,
                                                      getData
                                                    );
                                                }
                                                likeCommentClickHandler(
                                                  replyItem?.item?.id,
                                                  newD,
                                                  "thumb",
                                                  allCommentData,
                                                  setAllCommentData,
                                                  likePost,
                                                  setIsCommentLikeHovered,
                                                  getData
                                                );
                                              }}
                                            >
                                              <img
                                                src={SocialThumbLike}
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              className="clapLike"
                                              onClick={async () => {
                                                let newD = replyItem?.item;
                                                if (
                                                  replyItem?.item
                                                    ?.isDisLiked !== null ||
                                                  replyItem?.item?.isDisLiked
                                                    ?.isLike
                                                ) {
                                                  newD =
                                                    await commentDisLikeRemoveClickHandler(
                                                      replyItem?.item?.id,
                                                      replyItem?.item,
                                                      allCommentData,
                                                      setAllCommentData,
                                                      removePostDisLike,
                                                      setIsCommentDisLikeHovered,
                                                      getData
                                                    );
                                                }
                                                likeCommentClickHandler(
                                                  replyItem?.item?.id,
                                                  newD,
                                                  "clap",
                                                  allCommentData,
                                                  setAllCommentData,
                                                  likePost,
                                                  setIsCommentLikeHovered,
                                                  getData
                                                );
                                              }}
                                            >
                                              <img
                                                src={SocialClapLike}
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              className="knowledgeLike"
                                              onClick={async () => {
                                                let newD = replyItem?.item;
                                                if (
                                                  replyItem?.item
                                                    ?.isDisLiked !== null ||
                                                  replyItem?.item?.isDisLiked
                                                    ?.isLike
                                                ) {
                                                  newD =
                                                    await commentDisLikeRemoveClickHandler(
                                                      replyItem?.item?.id,
                                                      replyItem?.item,
                                                      allCommentData,
                                                      setAllCommentData,
                                                      removePostDisLike,
                                                      setIsCommentDisLikeHovered,
                                                      getData
                                                    );
                                                }
                                                likeCommentClickHandler(
                                                  replyItem?.item?.id,
                                                  newD,
                                                  "knowledge",
                                                  allCommentData,
                                                  setAllCommentData,
                                                  likePost,
                                                  setIsCommentLikeHovered,
                                                  getData
                                                );
                                              }}
                                            >
                                              <img
                                                src={SocialKnowledgeLike}
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              className="heartLike"
                                              onClick={async () => {
                                                let newD = replyItem?.item;
                                                if (
                                                  replyItem?.item
                                                    ?.isDisLiked !== null ||
                                                  replyItem?.item?.isDisLiked
                                                    ?.isLike
                                                ) {
                                                  newD =
                                                    await commentDisLikeRemoveClickHandler(
                                                      replyItem?.item?.id,
                                                      replyItem?.item,
                                                      allCommentData,
                                                      setAllCommentData,
                                                      removePostDisLike,
                                                      setIsCommentDisLikeHovered,
                                                      getData
                                                    );
                                                }
                                                likeCommentClickHandler(
                                                  replyItem?.item?.id,
                                                  newD,
                                                  "heart",
                                                  allCommentData,
                                                  setAllCommentData,
                                                  likePost,
                                                  setIsCommentLikeHovered,
                                                  getData
                                                );
                                              }}
                                            >
                                              <img
                                                src={SocialHeartLike}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <p
                                        onClick={async () => {
                                          if (
                                            replyItem?.item?.isLiked === null ||
                                            replyItem?.item?.isLiked?.isLike ===
                                              false
                                          ) {
                                            let newD = replyItem?.item;
                                            if (
                                              replyItem?.isDisLiked !== null ||
                                              replyItem?.isDisLiked?.isLike
                                            ) {
                                              newD =
                                                await commentDisLikeRemoveClickHandler(
                                                  replyItem?.item?.id,
                                                  replyItem?.item,
                                                  allCommentData,
                                                  setAllCommentData,
                                                  removePostDisLike,
                                                  setIsCommentDisLikeHovered
                                                );
                                            }
                                            likeCommentClickHandler(
                                              replyItem?.item?.id,
                                              newD,
                                              "thumb",
                                              allCommentData,
                                              setAllCommentData,
                                              likePost,
                                              setIsCommentLikeHovered,
                                              getData
                                            );
                                          } else {
                                            commentLikeRemoveClickHandler(
                                              replyItem?.item?.id,
                                              replyItem?.item,
                                              allCommentData,
                                              setAllCommentData,
                                              removePostLike,
                                              setIsCommentLikeHovered
                                            );
                                          }
                                        }}
                                      >
                                        {(replyItem?.item?.isLiked === null ||
                                          !replyItem?.item?.isLiked
                                            ?.isLike) && <span>Like</span>}
                                        {replyItem?.item?.isLiked?.likeType ===
                                          "thumb" &&
                                          replyItem?.item?.isLiked?.isLike && (
                                            <span className={"liked"}>
                                              Like
                                            </span>
                                          )}
                                        {replyItem?.item?.isLiked?.likeType ===
                                          "clap" &&
                                          replyItem?.item?.isLiked?.isLike && (
                                            <span className={"clapLike"}>
                                              Clap
                                            </span>
                                          )}
                                        {replyItem?.item?.isLiked?.likeType ===
                                          "knowledge" &&
                                          replyItem?.item?.isLiked?.isLike && (
                                            <span className={"kowledgeLike"}>
                                              Knowledge
                                            </span>
                                          )}
                                        {replyItem?.item?.isLiked?.likeType ===
                                          "heart" &&
                                          replyItem?.item?.isLiked?.isLike && (
                                            <span className={"heartLike"}>
                                              Heart
                                            </span>
                                          )}
                                      </p>
                                    </div>
                                    <div
                                      className="postLikeIconContainer"
                                      onClick={() => {
                                        setCommentLikeActive(true);
                                        setCommentLikeDislikeCommentId(
                                          replyItem?.item?.id
                                        );
                                      }}
                                    >
                                      <div className="commentLikeIcons">
                                        {replyItem?.item?.likedType?.length >
                                          0 && (
                                          <>
                                            {replyItem?.item?.likedType[0] && (
                                              <div className="thumbLikeRoundIcon">
                                                {replyItem?.item?.likedType[0]
                                                  ?.name === "thumb" && (
                                                  <img
                                                    src={SocialRoundThumbLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[0]
                                                  ?.name === "clap" && (
                                                  <img
                                                    src={SocialRoundClapLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[0]
                                                  ?.name === "heart" && (
                                                  <img
                                                    src={SocialRoundHeartLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[0]
                                                  ?.name === "knowledge" && (
                                                  <img
                                                    src={
                                                      SocialRoundKnowledgeLike
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}
                                            {replyItem?.item?.likedType[1] && (
                                              <div className="clapLikeRoundIcon">
                                                {replyItem?.item?.likedType[1]
                                                  ?.name === "thumb" && (
                                                  <img
                                                    src={SocialRoundThumbLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[1]
                                                  ?.name === "clap" && (
                                                  <img
                                                    src={SocialRoundClapLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[1]
                                                  ?.name === "heart" && (
                                                  <img
                                                    src={SocialRoundHeartLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[1]
                                                  ?.name === "knowledge" && (
                                                  <img
                                                    src={
                                                      SocialRoundKnowledgeLike
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}
                                            {replyItem?.item?.likedType[2] && (
                                              <div className="heartLikeRoundIcon">
                                                {replyItem?.item?.likedType[2]
                                                  ?.name === "thumb" && (
                                                  <img
                                                    src={SocialRoundThumbLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[2]
                                                  ?.name === "clap" && (
                                                  <img
                                                    src={SocialRoundClapLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[2]
                                                  ?.name === "heart" && (
                                                  <img
                                                    src={SocialRoundHeartLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[2]
                                                  ?.name === "knowledge" && (
                                                  <img
                                                    src={
                                                      SocialRoundKnowledgeLike
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}
                                            {replyItem?.item?.likedType[3] && (
                                              <div className="knowledgeLikeRoundIcon">
                                                {replyItem?.item?.likedType[3]
                                                  ?.name === "thumb" && (
                                                  <img
                                                    src={SocialRoundThumbLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[3]
                                                  ?.name === "clap" && (
                                                  <img
                                                    src={SocialRoundClapLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[3]
                                                  ?.name === "heart" && (
                                                  <img
                                                    src={SocialRoundHeartLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item?.likedType[3]
                                                  ?.name === "knowledge" && (
                                                  <img
                                                    src={
                                                      SocialRoundKnowledgeLike
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      {replyItem?.item?.commentLike > 0 && (
                                        <span
                                          className={
                                            replyItem?.item?.likedType
                                              ?.length === 1
                                              ? "likeFirstCls"
                                              : null ||
                                                replyItem?.item?.likedType
                                                  ?.length === 2
                                              ? "likeSecondCls"
                                              : null ||
                                                replyItem?.item?.likedType
                                                  ?.length === 3
                                              ? "likeThirdCls"
                                              : null ||
                                                replyItem?.item?.likedType
                                                  ?.length === 4
                                              ? "likeFourthCls"
                                              : null
                                          }
                                        >
                                          {replyItem?.item?.commentLike < 10
                                            ? 0
                                            : null}
                                          {replyItem?.item?.commentLike}
                                        </span>
                                      )}
                                    </div>
                                    <p className="commentVerticalLine">|</p>
                                    <div
                                      className="parentCommentDisLike"
                                      onMouseOver={() =>
                                        setIsCommentDisLikeHovered(
                                          replyItem?.item?.id
                                        )
                                      }
                                      onMouseLeave={() =>
                                        setIsCommentDisLikeHovered(null)
                                      }
                                    >
                                      {isCommentDisLikeHovered ===
                                        replyItem?.item?.id && (
                                        <div className="doCommentDislikeList">
                                          <div className="likeList">
                                            <div
                                              className="thumbLike"
                                              onClick={async () => {
                                                let newD = replyItem?.item;
                                                if (
                                                  replyItem?.item?.isLiked !==
                                                    null ||
                                                  replyItem?.item?.isLiked
                                                    ?.isLike
                                                ) {
                                                  newD =
                                                    await commentLikeRemoveClickHandler(
                                                      replyItem?.item?.id,
                                                      replyItem?.item,
                                                      allCommentData,
                                                      setAllCommentData,
                                                      removePostLike,
                                                      setIsCommentLikeHovered
                                                    );
                                                }
                                                disLikeCommentClickHandler(
                                                  replyItem?.item?.id,
                                                  newD,
                                                  "bad",
                                                  allCommentData,
                                                  setAllCommentData,
                                                  disLikePost,
                                                  setIsCommentDisLikeHovered,
                                                  getData
                                                );
                                              }}
                                            >
                                              <img
                                                src={SocialPostBadIcon}
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              className="clapLike"
                                              onClick={async () => {
                                                let newD = replyItem?.item;
                                                if (
                                                  replyItem?.item?.isLiked !==
                                                    null ||
                                                  replyItem?.item?.isLiked
                                                    ?.isLike
                                                ) {
                                                  newD =
                                                    await commentLikeRemoveClickHandler(
                                                      replyItem?.item?.id,
                                                      replyItem?.item,
                                                      allCommentData,
                                                      setAllCommentData,
                                                      removePostLike,
                                                      setIsCommentLikeHovered
                                                    );
                                                }
                                                disLikeCommentClickHandler(
                                                  replyItem?.item?.id,
                                                  newD,
                                                  "angry",
                                                  allCommentData,
                                                  setAllCommentData,
                                                  disLikePost,
                                                  setIsCommentDisLikeHovered,
                                                  getData
                                                );
                                              }}
                                            >
                                              <img
                                                src={SocialPostAngryIcon}
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              className="knowledgeLike"
                                              onClick={async () => {
                                                let newD = replyItem?.item;
                                                if (
                                                  replyItem?.item?.isLiked !==
                                                    null ||
                                                  replyItem?.item?.isLiked
                                                    ?.isLike
                                                ) {
                                                  newD =
                                                    await commentLikeRemoveClickHandler(
                                                      replyItem?.item?.id,
                                                      replyItem?.item,
                                                      allCommentData,
                                                      setAllCommentData,
                                                      removePostLike,
                                                      setIsCommentLikeHovered
                                                    );
                                                }
                                                disLikeCommentClickHandler(
                                                  replyItem?.item?.id,
                                                  newD,
                                                  "sad",
                                                  allCommentData,
                                                  setAllCommentData,
                                                  disLikePost,
                                                  setIsCommentDisLikeHovered,
                                                  getData
                                                );
                                              }}
                                            >
                                              <img
                                                src={SocialPostSadIcon}
                                                alt=""
                                              />
                                            </div>
                                            <div
                                              className="heartLike"
                                              onClick={async () => {
                                                let newD = replyItem?.item;
                                                if (
                                                  replyItem?.item?.isLiked !==
                                                    null ||
                                                  replyItem?.item?.isLiked
                                                    ?.isLike
                                                ) {
                                                  newD =
                                                    await commentLikeRemoveClickHandler(
                                                      replyItem?.item?.id,
                                                      replyItem?.item,
                                                      allCommentData,
                                                      setAllCommentData,
                                                      removePostLike,
                                                      setIsCommentLikeHovered
                                                    );
                                                }
                                                disLikeCommentClickHandler(
                                                  replyItem?.item?.id,
                                                  newD,
                                                  "hate",
                                                  allCommentData,
                                                  setAllCommentData,
                                                  disLikePost,
                                                  setIsCommentDisLikeHovered,
                                                  getData
                                                );
                                              }}
                                            >
                                              <img
                                                src={SocialPostHateIcon}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <p
                                        onClick={async () => {
                                          if (
                                            replyItem?.item?.isDisLiked ===
                                              null ||
                                            replyItem?.item?.isDisLiked
                                              ?.isLike === false
                                          ) {
                                            let newD = replyItem?.item;
                                            if (
                                              replyItem?.isLiked !== null ||
                                              replyItem?.isLiked?.isLike
                                            ) {
                                              newD =
                                                await commentLikeRemoveClickHandler(
                                                  replyItem?.item?.id,
                                                  replyItem?.item,
                                                  allCommentData,
                                                  setAllCommentData,
                                                  removePostLike,
                                                  setIsCommentLikeHovered
                                                );
                                            }
                                            disLikeCommentClickHandler(
                                              replyItem?.item?.id,
                                              newD,
                                              "bad",
                                              allCommentData,
                                              setAllCommentData,
                                              disLikePost,
                                              setIsCommentDisLikeHovered,
                                              getData
                                            );
                                          } else {
                                            commentDisLikeRemoveClickHandler(
                                              replyItem?.item?.id,
                                              replyItem?.item,
                                              allCommentData,
                                              setAllCommentData,
                                              removePostDisLike,
                                              setIsCommentDisLikeHovered
                                            );
                                          }
                                        }}
                                      >
                                        {(replyItem?.item?.isDisLiked ===
                                          null ||
                                          !replyItem?.item?.isDisLiked
                                            ?.isLike) && <span>Dislike</span>}
                                        {replyItem?.item?.isDisLiked
                                          ?.likeType === "bad" &&
                                          replyItem?.item?.isDisLiked
                                            ?.isLike && (
                                            <span className={"disliked"}>
                                              Dislike
                                            </span>
                                          )}
                                        {replyItem?.item?.isDisLiked
                                          ?.likeType === "sad" &&
                                          replyItem?.item?.isDisLiked
                                            ?.isLike && (
                                            <span className={"sadLike"}>
                                              Sad
                                            </span>
                                          )}

                                        {replyItem?.item?.isDisLiked
                                          ?.likeType === "hate" &&
                                          replyItem?.item?.isDisLiked
                                            ?.isLike && (
                                            <span className={"hateLike"}>
                                              Hate
                                            </span>
                                          )}

                                        {replyItem?.item?.isDisLiked
                                          ?.likeType === "angry" &&
                                          replyItem?.item?.isDisLiked
                                            ?.isLike && (
                                            <span className={"angryLike"}>
                                              Angry
                                            </span>
                                          )}
                                      </p>
                                    </div>
                                    <div
                                      className="postLikeIconContainer"
                                      onClick={() => {
                                        setCommentDislikeActive(true);
                                        setCommentLikeDislikeCommentId(
                                          replyItem?.item?.id
                                        );
                                      }}
                                    >
                                      <div className="commentLikeIcons">
                                        {replyItem?.item?.disLikedType?.length >
                                          0 && (
                                          <>
                                            {replyItem?.item
                                              ?.disLikedType[0] && (
                                              <div className="thumbLikeRoundIcon">
                                                {replyItem?.item
                                                  ?.disLikedType[0]?.name ===
                                                  "bad" && (
                                                  <img
                                                    src={SocialRoundBadLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[0]?.name ===
                                                  "sad" && (
                                                  <img
                                                    src={SocialRoundSadLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[0]?.name ===
                                                  "angry" && (
                                                  <img
                                                    src={SocialRoundAngryLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[0]?.name ===
                                                  "hate" && (
                                                  <img
                                                    src={SocialRoundHateLike}
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}
                                            {replyItem?.item
                                              ?.disLikedType[1] && (
                                              <div className="clapLikeRoundIcon">
                                                {replyItem?.item
                                                  ?.disLikedType[1]?.name ===
                                                  "bad" && (
                                                  <img
                                                    src={SocialRoundBadLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[1]?.name ===
                                                  "sad" && (
                                                  <img
                                                    src={SocialRoundSadLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[1]?.name ===
                                                  "angry" && (
                                                  <img
                                                    src={SocialRoundAngryLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[1]?.name ===
                                                  "hate" && (
                                                  <img
                                                    src={SocialRoundHateLike}
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}
                                            {replyItem?.item
                                              ?.disLikedType[2] && (
                                              <div className="heartLikeRoundIcon">
                                                {replyItem?.item
                                                  ?.disLikedType[2]?.name ===
                                                  "bad" && (
                                                  <img
                                                    src={SocialRoundBadLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[2]?.name ===
                                                  "sad" && (
                                                  <img
                                                    src={SocialRoundSadLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[2]?.name ===
                                                  "angry" && (
                                                  <img
                                                    src={SocialRoundAngryLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[2]?.name ===
                                                  "hate" && (
                                                  <img
                                                    src={SocialRoundHateLike}
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}{" "}
                                            {replyItem?.item
                                              ?.disLikedType[3] && (
                                              <div className="knowledgeLikeRoundIcon">
                                                {replyItem?.item
                                                  ?.disLikedType[3]?.name ===
                                                  "bad" && (
                                                  <img
                                                    src={SocialRoundBadLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[3]?.name ===
                                                  "sad" && (
                                                  <img
                                                    src={SocialRoundSadLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[3]?.name ===
                                                  "angry" && (
                                                  <img
                                                    src={SocialRoundAngryLike}
                                                    alt=""
                                                  />
                                                )}
                                                {replyItem?.item
                                                  ?.disLikedType[3]?.name ===
                                                  "hate" && (
                                                  <img
                                                    src={SocialRoundHateLike}
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      {replyItem?.item?.commentDisLike > 0 && (
                                        <span
                                          className={
                                            replyItem?.item?.disLikedType
                                              ?.length === 1
                                              ? "likeFirstCls"
                                              : null ||
                                                replyItem?.item?.disLikedType
                                                  ?.length === 2
                                              ? "likeSecondCls"
                                              : null ||
                                                replyItem?.item?.disLikedType
                                                  ?.length === 3
                                              ? "likeThirdCls"
                                              : null ||
                                                replyItem?.item?.disLikedType
                                                  ?.length === 4
                                              ? "likeFourthCls"
                                              : null
                                          }
                                        >
                                          {replyItem?.item?.commentDisLike < 10
                                            ? 0
                                            : null}
                                          {replyItem?.item?.commentDisLike}
                                        </span>
                                      )}
                                    </div>
                                    {replyItem?.item?.employeeId !==
                                      LoggedInUser?.id && (
                                      <div
                                        className="makeReply"
                                        onClick={() => {
                                          setWriteReplyActive(
                                            replyItem?.item?.commentId
                                          );
                                          setTimeout(() => {
                                            setFocusAndScroll("#replyField");
                                          }, 500);
                                        }}
                                      >
                                        <p>.</p>
                                        <span>Reply</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {writeReplyActive === d?.item?.id && !editReplyActive && (
                        <div className="replyOnParentCommentContainer">
                          <div className="replyOnParentCommentWrapper">
                            <div
                              className="replyOnParentCommentImg"
                              onClick={() => {
                                navigate(
                                  `/home/social/userprofile/${LoggedInUser?.id}`
                                );
                              }}
                            >
                              <div
                                className={`${
                                  LoggedInUser?.image
                                    ? "socialProfilePicContainer"
                                    : "commentCustomProfilePic"
                                }`}
                              >
                                {!LoggedInUser?.image ? (
                                  getUserInitials(
                                    getDisplayName(
                                      LoggedInUser?.nameWithPersonNumber
                                    )
                                  )
                                ) : (
                                  <img
                                    className="socialProfilePic"
                                    onClick={() => {
                                      // handleUserProfileClick(res?.id);
                                    }}
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      profileImages +
                                      LoggedInUser?.image
                                    }
                                    alt="Profile"
                                    onError={(e) => {
                                      e.target.src = ProfileAvatar;
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="replyOnParentCommentInputbox">
                              {!loading && (
                                <InfiniteScroll
                                  dataLength={allEmployeeList?.length}
                                  next={getEmployeeData}
                                  hasMore={
                                    presentEmployeeData?.length < 20
                                      ? false
                                      : true
                                  }
                                  height={300}
                                  width={200}
                                >
                                  <MentionsInput
                                    placeholder=" Write a reply "
                                    autoFocus
                                    value={replyInputTxt}
                                    id="replyField"
                                    onChange={(e) => {
                                      setReplyInputTxt(e.target.value);
                                    }}
                                    maxLength={1000}
                                    a11ySuggestionsListLabel={" Write a reply "}
                                    className={"inputReplyClass"}
                                  >
                                    <Mention
                                      trigger={"@"}
                                      data={allEmployeeList?.map((d) => {
                                        return {
                                          id: d?.id,
                                          display: getDisplayName(d?.name),
                                        };
                                      })}
                                    />
                                  </MentionsInput>
                                </InfiniteScroll>
                              )}
                              {loading && <Loader />}
                            </div>
                          </div>
                          {replyInputTxt !== "" && (
                            <div
                              className="replyBtn"
                              onClick={() => {
                                writeReply(d?.item?.id);
                                setCommentId(d?.item?.id);
                              }}
                            >
                              <span>Reply</span>
                            </div>
                          )}
                        </div>
                      )}
                      {editReplyActive && parentCommentId === d?.item?.id && (
                        <div className="replyOnParentCommentContainer">
                          <div className="replyOnParentCommentWrapper">
                            <div
                              className="replyOnParentCommentImg"
                              onClick={() => {
                                navigate(
                                  `/home/social/userprofile/${LoggedInUser?.id}`
                                );
                              }}
                            >
                              <div
                                className={`${
                                  LoggedInUser?.image
                                    ? "socialProfilePicContainer"
                                    : "commentCustomProfilePic"
                                }`}
                              >
                                {!LoggedInUser?.image ? (
                                  getUserInitials(
                                    getDisplayName(
                                      LoggedInUser?.nameWithPersonNumber
                                    )
                                  )
                                ) : (
                                  <img
                                    className="socialProfilePic"
                                    onClick={() => {}}
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      profileImages +
                                      LoggedInUser?.image
                                    }
                                    alt="Profile"
                                    onError={(e) => {
                                      e.target.src = ProfileAvatar;
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="replyOnParentCommentInputbox">
                              {!loading && (
                                <InfiniteScroll
                                  dataLength={allEmployeeList?.length}
                                  next={getEmployeeData}
                                  hasMore={
                                    presentEmployeeData?.length < 20
                                      ? false
                                      : true
                                  }
                                  height={300}
                                  width={200}
                                >
                                  <MentionsInput
                                    placeholder=" Write a reply "
                                    autoFocus
                                    value={replyInputTxt}
                                    id="replyField"
                                    onChange={(e) => {
                                      setReplyInputTxt(e.target.value);
                                    }}
                                    maxLength={1000}
                                    a11ySuggestionsListLabel={" Write a reply "}
                                    className={"inputReplyClass"}
                                  >
                                    <Mention
                                      trigger={"@"}
                                      data={allEmployeeList?.map((d) => {
                                        return {
                                          id: d?.id,
                                          display: getDisplayName(d?.name),
                                        };
                                      })}
                                    />
                                  </MentionsInput>
                                </InfiniteScroll>
                              )}
                              {loading && <Loader />}
                            </div>
                          </div>
                          {replyInputTxt !== "" && (
                            <div
                              className="replyBtn"
                              onClick={() => {
                                editReply();
                                // setCommentId(d?.item?.id);
                              }}
                            >
                              <span>Save</span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          {currentIndex < paginationData?.total && (
            <div
              className="commentLoadMoreBtn"
              onClick={() => {
                getPostCommentData();
              }}
            >
              <IconButton>
                <h5>Load More</h5>
              </IconButton>
            </div>
          )}
          {allCommentData?.length <= 0 && (
            <div className="noComment">
              <h3>No Comment Posted Yet!!</h3>
            </div>
          )}
        </div>
      )}
      {(commentLikeActive || commentDislikeActive) && (
        <LikeDislikeComment
          commId={commentLikeDislikeCommentId}
          postId={commentedPost?.id}
          commentLikeActive={commentLikeActive}
          commentDislikeActive={commentDislikeActive}
          setCommentLikeActive={setCommentLikeActive}
          setCommentDislikeActive={setCommentDislikeActive}
          setCommentLikeDislikeCommentId={setCommentLikeDislikeCommentId}
        />
      )}
    </div>
  );
}

export default CommentPost;
