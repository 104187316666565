import React, { useEffect, useState } from "react";
import GroupIcon from "../../Assets/GroupIcon.svg";
import signalRService from "../../Services/signalRService";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";

const ForwardPopup = ({
  LoggedInUser,
  contactsList,
  chatList,
  setForwardData,
  forwardData,
  setViewAll,
  viewAll,
  handleClose,
}) => {
  // const newForwardArray = [...chatList, ...contactsList];

  const newForwardArray = [...chatList];
  const [searchContacts, setSearchContacts] = useState();
  const [selected, setSelected] = useState(true);
  const dispatch = useDispatch();
  function showStatus(onlineStatus) {
    if (onlineStatus === 1) {
      return "greenDot";
    } else if (onlineStatus === 2) {
      return "redDot";
    } else if (onlineStatus === 3) {
      return "yellowDot";
    } else if (onlineStatus === 4) {
      return "offlineDot";
    } else {
      return "offlineDot";
    }
  }
  const invokecontacts = async () => {
    await signalRService.invokeGetContacts(dispatch, LoggedInUser.personNumber);
  };
  const invokeForwardAction = async () => {
    await signalRService.invokeForwardMessages(
      dispatch,
      forwardData?.conversationId[0],
      forwardData?.msgId?.join(","),
      forwardData?.userId?.join(",")
    );
  };
  useEffect(() => {
    invokecontacts();
  }, []);
  const selectBox = (id) => {
    setForwardData((prevState) => ({
      ...prevState,
      userId: [...prevState?.userId, id],
    }));
  };
  const unSelectBox = (id) => {
    setForwardData((prevState) => ({
      ...prevState,
      userId: prevState.userId.filter((item) => item !== id),
    }));
  };

  // console.log("object contactsList", contactsList);
  // console.log("object newForwardArray", newForwardArray);
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "column", height: "600px" }}
      >
        <div className="chatListSearchContainer">
          <input
            type="text"
            className="chatListSearch"
            placeholder="Search"
            value={searchContacts}
            onChange={(e) => {
              setSearchContacts(e?.target?.value);
            }}
          />
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0" />
            <line
              x1="8.70711"
              y1="9"
              x2="12"
              y2="12.2929"
              stroke="#1296B0"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div
          style={{
            borderTop: "1px solid #F1F2F2",
          }}
        ></div>

        <div style={{ overflowY: "auto", flex: "1" }}>
          <div style={{ height: "100%" }}>
            {newForwardArray.map((contact, index) => (
              <div
                key={contact?.id}
                onClick={(e) => {
                  forwardData?.isActive &&
                  forwardData?.userId?.includes(contact?.id)
                    ? unSelectBox(contact?.id)
                    : forwardData?.isActive && selectBox(contact?.id);
                  e.stopPropagation();
                }}
                className={`chatForwardListHover chatListContentContainer ${
                  contact?.participatingContacts?.filter(
                    (item) => item?.myIBID !== LoggedInUser?.id
                  )[0]?.onlineStatus && "chatListContentContainerActive"
                }  `}
                style={
                  forwardData?.isActive &&
                  forwardData?.userId?.includes(contact?.id)
                    ? { backgroundColor: "#E9F3F4" }
                    : {}
                }
              >
                <div className="chatListLeftcontent">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0px 7px 0px 18px",
                    }}
                  >
                    {forwardData?.isActive &&
                    forwardData?.userId?.includes(contact?.id) ? (
                      <svg
                        // onClick={() => {
                        //   unSelectBox(message.id);
                        // }}
                        width="19"
                        height="19"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.54248"
                          y="1.22949"
                          width="14"
                          height="14"
                          rx="2.8"
                          fill="#1296B0"
                          stroke="#1296B0"
                          stroke-width="0.75"
                        />
                        <path
                          d="M4.10742 8.52771L6.11076 10.5435L11.321 5.91602"
                          stroke="white"
                          stroke-linecap="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        // onClick={() => {
                        //   selectBox(message.id);
                        // }}
                        width="19"
                        height="19"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.54248"
                          y="1.22949"
                          width="14"
                          height="14"
                          rx="2.8"
                          fill="white"
                          stroke="#BABABA"
                          stroke-width="0.75"
                        />
                      </svg>
                    )}
                  </div>
                  <div className="activeImage">
                    <div
                      className={`activeImageContainer ${
                        !contact.image
                          ? "customProfilePic"
                          : !contact.icon
                          ? "customProfilePic"
                          : ""
                      }`}
                      style={
                        contact.image
                          ? {
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url(${
                                contact.image ?? contact.icon
                              })`,
                            }
                          : contact.icon
                          ? {
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundImage: `url(${
                                contact.image ?? contact.icon
                              })`,
                            }
                          : {}
                      }
                    >
                      {!contact.image && !contact.type === "Group"
                        ? contact?.name
                            ?.split(" ")
                            ?.slice(0, 2)
                            ?.map((item) => {
                              return item?.charAt(0)?.toUpperCase();
                            })
                            ?.join("") ??
                          contact.title
                            ?.split(" ")
                            ?.slice(0, 2)
                            ?.map((item) => {
                              return item?.charAt(0)?.toUpperCase();
                            })
                            ?.join("")
                        : !contact.icon
                        ? contact?.name
                            ?.split(" ")
                            ?.slice(0, 2)
                            ?.map((item) => {
                              return item?.charAt(0)?.toUpperCase();
                            })
                            ?.join("") ??
                          contact.title
                            ?.split(" ")
                            ?.slice(0, 2)
                            ?.map((item) => {
                              return item?.charAt(0)?.toUpperCase();
                            })
                            ?.join("")
                        : null}
                    </div>

                    {contact.type === "Contact" && (
                      <div
                        className={showStatus(
                          contact?.participatingContacts?.filter(
                            (item) => item?.myIBID !== LoggedInUser?.id
                          )[0]?.onlineStatus
                        )}
                      ></div>
                    )}

                    {contact.type === "Group" && (
                      <div className="groupDot">
                        <img src={GroupIcon} alt="group" />
                      </div>
                    )}
                  </div>
                  <div
                    className="allChatListDisplayContainer"
                    style={{ justifyContent: "start", paddingTop: "6px" }}
                  >
                    <label className="chatListName">
                      {" "}
                      {contact?.name
                        ? contact?.name?.replace(/\s\(\d+\)$/, "")?.length > 18
                          ? contact?.name
                              ?.replace(/\s\(\d+\)$/, "")
                              ?.substring(0, 18) + "..."
                          : contact?.name?.replace(/\s\(\d+\)$/, "")
                        : contact?.title?.replace(/\s\(\d+\)$/, "")?.length > 18
                        ? contact?.title
                            ?.replace(/\s\(\d+\)$/, "")
                            ?.substring(0, 18) + "..."
                        : contact?.title?.replace(/\s\(\d+\)$/, "")}
                    </label>
                    <label
                      className="chatListRole"
                      style={{ textWrap: "nowrap" }}
                    >
                      {contact.type === "Contact"
                        ? contact?.designation
                          ? contact?.designation
                          : contact?.participatingContacts?.filter(
                              (item) => item?.myIBID !== LoggedInUser?.id
                            )[0]?.designation
                        : contact?.designation}
                    </label>

                    {/* <label className="chatListlastMsg">
                {contact?.type === "Contact" &&
                  (contact?.recentMessage !== null &&
                  contact?.recentMessage?.content?.length > 18
                    ? contact?.recentMessage?.content?.substring(0, 18) + "..."
                    : contact?.recentMessage?.content)}
              </label> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {forwardData?.isActive && forwardData?.userId?.length > 0 && (
          <div
            style={{
              borderTop: "1px solid #F1F2F2",
              height: "60px",
              width: "100%",
              backgroundColor: "#D8F0F2",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 23px",
            }}
          >
            <div
              style={{
                display: "flex",
                fontFamily: "Poppins",
                fontSize: "13.5px",
                fontWeight: "400",
                textAlign: "left",
                color: "#1296B0",
                alignItems: "center",
              }}
            >
              {forwardData?.userId
                ?.map((items) =>
                  newForwardArray?.filter((item) => items === item?.id)
                )
                ?.slice(0, 10)
                ?.flat()
                ?.map((item) => item.title)
                ?.join(", ")?.length > 25
                ? `${forwardData?.userId
                    ?.map((items) =>
                      newForwardArray?.filter((item) => items === item?.id)
                    )
                    ?.slice(0, 10)
                    ?.flat()
                    ?.map((item) => item.title)
                    ?.join(", ")
                    ?.slice(0, 25)}` + "..."
                : forwardData?.userId
                    ?.map((items) =>
                      newForwardArray?.filter((item) => items === item?.id)
                    )
                    ?.slice(0, 10)
                    ?.flat()
                    ?.map((item) => item.title)
                    ?.join(", ")}

              <div
                className="viewAllButton"
                onClick={() => setViewAll((prev) => !prev)}
                style={{
                  cursor: "pointer",
                  border: "0.8px solid #D8F0F2",
                  backgroundColor: "white",
                  padding: "2px 5px",
                  marginLeft: "16px",
                }}
              >
                View All
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={() => {
                  invokeForwardAction();
                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                  handleClose();
                }}
              >
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="18" cy="18" r="18" fill="#1296B0" />
                  <path
                    d="M27.1779 18.2254L20.7267 11.6477M27.1779 18.2254L20.7267 24.0722M27.1779 18.2254H8.5411"
                    stroke="white"
                    stroke-width="1.10154"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForwardPopup;
