import React from "react";
import { useEffect } from "react";

const BlankCardComp = ({ date, title, time, source }) => {
  return (
    <div
      className="attendanceCardContentContainer"
      style={{ backgroundColor: `white` }}
    >
      <div
        className="dateContainer"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          className={`regularisedIconContainerDashboard ${
            source?.includes("Regular") ? null : "rIconClass"
          }`}
        >
          {source?.includes("Regular") ? "R" : null}
        </div>
        <div className="cardDateDisplayRegularise">{date ? date : ""}</div>
      </div>{" "}
      {/* <div className="horizontalLinePrimary calendarLine" style={{backgroundColor:`black`}}></div> */}
      <div
        className="attendanceContentStatus"
        style={{ color: `black`, fontSize: "12px" }}
      >
        {title ? title : ""}
      </div>
      <div style={{ display: "flex" }}>
        <label
          className="attendanceIn"
          style={{ color: "black", fontSize: "13px" }}
        >
          {time ? time : ""}
        </label>
      </div>
    </div>
  );
};

export { BlankCardComp };
