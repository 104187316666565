import React, { useState, useEffect } from "react";

import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { YesNoFormik } from "../../../Components/FormComponent/YesNoFormik";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";

const policyOption = [
  { label: "Company", value: "Company" },
  { label: "HR", value: "HR" },
  { label: "IT", value: "IT" },
];

export const CreatePoliciesComp = ({ data }) => {
  const dispatch = useDispatch();
  const [option, setOption] = useState([]);

  const opt = [{ label: "select", value: null }];
  const getPersonalData = async () => {
    try {
      const result = await getRequest("Personal/GetPersonal");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Policies`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getPersonalData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Subject"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="Subject"
          type={"text"}
          placeholder={"Enter Subject"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Start Date"} mandatory={true} />
        <DatePickerFormiks name="StartDate" />
      </div>
      <div>
        <LabelCustom labelName={"End Date"} mandatory={true} />
        <DatePickerFormiks name="EndDate" />
      </div>
      <div>
        <LabelCustom labelName={"Review Date"} mandatory={true} />
        <DatePickerFormiks name="ReviewDate" />
      </div>

      <div>
        <LabelCustom labelName={"Description"} />
        <TextAreaFormik
          name="Description"
          maxLength={500}
          placeholder={"Enter Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload Attachment"} mandatory={true} />
        <FileUploadFormik name="upload" />
        <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
          Size: 2 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
          Format: PDF
        </span>
      </div>

      <div>
        <LabelCustom labelName={"Location Group"} mandatory={true} />
        <SelectForm
          placeholder={"Select Location Group"}
          values={
            data
              ? option.filter(
                  (options) => options.label === data?.locationGroup
                )
              : opt
          }
          name="locationIds"
          options={option}
        />
      </div>
      <div>
        <LabelCustom labelName={"Policy Group"} />
        <SelectForm
          placeholder={"Select Policy Group"}
          name="PolicyGroup"
          options={policyOption}
        />
      </div>
      <div>
        <LabelCustom labelName={"Display in Onboarding?"} />
        <YesNoFormik name="DisplayInOnboarding" />
      </div>
    </div>
  );
};
