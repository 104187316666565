import React, { useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";
import { FieldArray } from "formik";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";

export const HotelCandidateComp = ({
  formValues,
  hotelCategoryOptions,
  isBoth,
}) => {
  const travellingOptions = [
    { label: "One Way Trip", value: "One Way Trip" },
    { label: "Multilocation Trip", value: "Multilocation Trip" },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Candidate Hotel`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [employeeList, setEmployeeList] = useState([]);

  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        "Candidate/GetCandidatesListWithoutRejection"
      );
      // //console.log("result", result);
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={
          formValues?.TravellingType
            ? { borderBottom: "1px solid #E6E6E6", padding: "10px 0px" }
            : {}
        }
        className="formcustom threeColumn"
      >
        {!isBoth && (
          <div>
            <LabelCustom labelName={"Travel Type"} mandatory={true} />
            <SelectForm name="TravellingType" options={travellingOptions} />
          </div>
        )}
        {formValues?.TravellingType &&
          formValues?.TravellingType === "One Way Trip" && (
            <div>
              <LabelCustom labelName={"Hotel Category"} mandatory={true} />
              <SelectForm name="HotelCategory" options={hotelCategoryOptions} />
            </div>
          )}
        {!isBoth &&
          formValues?.TravellingType &&
          formValues?.TravellingType !== "One Way Trip" && (
            <div>
              <LabelCustom labelName={"Budget (INR)"} mandatory={true} />
              <InputCustomFormik
                type="number"
                name="Budget"
                placeholder={"Please enter budget"}
              />
            </div>
          )}

        {formValues?.TravellingType && (
          <div>
            <LabelCustom labelName={"Candidate"} mandatory={true} />
            <SelectForm name="CandidateId" options={employeeList} />
          </div>
        )}
      </div>
      {formValues?.TravellingType && (
        <div className="formcustom threeColumn">
          {formValues?.TravellingType !== "Multilocation Trip" && (
            <>
              {isBoth &&
                formValues?.TravellingType &&
                formValues?.TravellingType === "One Way Trip" && (
                  <div>
                    <LabelCustom
                      labelName={"Hotel Category"}
                      mandatory={true}
                    />
                    <SelectForm
                      name="HotelCategory"
                      options={hotelCategoryOptions}
                    />
                  </div>
                )}
              <div>
                <LabelCustom
                  labelName={"Check-In Date and Time"}
                  mandatory={true}
                />
                <DatePickerFormiks
                  name={"CheckInTime"}
                  minDate={new Date()}
                  showTimeSelect
                  maxDate={formValues && formValues?.CheckOutTime}
                />
              </div>
              <div>
                <LabelCustom
                  labelName={"Check-Out Date and Time"}
                  mandatory={true}
                />
                <DatePickerFormiks
                  name={"CheckOutTime"}
                  minDate={formValues ? formValues?.CheckInTime : new Date()}
                  showTimeSelect
                />
              </div>

              <div>
                <LabelCustom labelName={"Location"} mandatory={true} />
                <InputCustomFormik
                  maxLength={50}
                  name="Location"
                  type={"text"}
                  placeholder={"Please Enter Location"}
                />
              </div>

              <div>
                <LabelCustom labelName={"Preferred Hotel"} mandatory={true} />
                <InputCustomFormik
                  maxLength={150}
                  name="PreferHotel"
                  type={"text"}
                  placeholder={"Please Enter Preferred Hotel"}
                />
              </div>

              <div>
                <LabelCustom labelName={"Adults"} />
                <InputCustomFormik
                  name="Adults"
                  type="number"
                  placeholder={" Enter "}
                />
              </div>
              <div>
                <LabelCustom labelName={"Children"} />
                <InputCustomFormik
                  name="Children"
                  type="number"
                  placeholder={" Enter "}
                />
              </div>
              <div>
                <LabelCustom labelName={"Budget (INR)"} mandatory={true} />
                <InputCustomFormik
                  type="number"
                  name="Budget"
                  placeholder={"Please enter budget"}
                />
              </div>
              <div>
                <LabelCustom labelName={"Booking Note"} />
                <TextAreaFormik
                  name="BookingNote"
                  placeholder={"Please Enter Booking Note"}
                  maxLength={500}
                />
              </div>
            </>
          )}
        </div>
      )}
      {formValues?.TravellingType === "Multilocation Trip" && (
        <div
          style={
            {
              // marginTop: "20px",
              // borderTop: "1px solid #E6E6E6",
              // padding: "20px 0px",
            }
          }
          className="repeatedSectionContainer"
        >
          {/* <label className="repeatedHeading">
            Please specify your journey details
          </label> */}
          <FieldArray name="hotelbookingdetails">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values, errors } = form;
              const { hotelbookingdetails } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"S. No."} />
                        </StyledTableCell>
                        <StyledTableCell
                          padding="0"
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={"Hotel Booking Details"} />
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            fontSize: "12px",
                          }}
                          align="center"
                        >
                          <LabelCustom labelName={" Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {hotelbookingdetails?.map((Requestlist, index) => (
                      <TableBody key={index}>
                        {/* 1st column */}
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ maxWidth: "920px", overflowX: "auto" }}
                        >
                          <div style={{ overflowX: "auto", display: "flex" }}>
                            <div style={{ display: "flex", flexShrink: "1" }}>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Hotel Category"}
                                  mandatory={true}
                                />
                                <SelectForm
                                  name={`hotelbookingdetails[${index}].HotelCategory`}
                                  options={hotelCategoryOptions}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Check-In Date and Time"}
                                  mandatory={true}
                                />
                                <DatePickerFormiks
                                  name={`hotelbookingdetails[${index}].CheckInTime`}
                                  minDate={new Date()}
                                  showTimeSelect
                                  maxDate={
                                    formValues?.hotelbookingdetails &&
                                    formValues?.hotelbookingdetails[index]
                                      ?.CheckOutTime
                                  }
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Check-Out Date and Time"}
                                  mandatory={true}
                                />
                                <DatePickerFormiks
                                  name={`hotelbookingdetails[${index}].CheckOutTime`}
                                  minDate={
                                    formValues?.hotelbookingdetails
                                      ? formValues?.hotelbookingdetails[index]
                                          ?.CheckInTime
                                      : new Date()
                                  }
                                  showTimeSelect
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Location"}
                                  mandatory={true}
                                />
                                <InputCustomFormik
                                  maxLength={50}
                                  name={`hotelbookingdetails[${index}].Location`}
                                  type={"text"}
                                  placeholder={"Please Enter Location"}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom
                                  labelName={"Preferred Hotel"}
                                  mandatory={true}
                                />
                                <InputCustomFormik
                                  maxLength={150}
                                  name={`hotelbookingdetails[${index}].PreferHotel`}
                                  type={"text"}
                                  placeholder={"Please Enter Preferred Hotel"}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom labelName={"Adults"} />
                                <InputCustomFormik
                                  name={`hotelbookingdetails[${index}].Adults`}
                                  type={"number"}
                                  placeholder={" Enter "}
                                />
                              </div>
                              <div style={{ width: "245px" }}>
                                <LabelCustom labelName={"Children"} />
                                <InputCustomFormik
                                  name={`hotelbookingdetails[${index}].Children`}
                                  type={"number"}
                                  placeholder={" Enter "}
                                />
                              </div>
                              {isBoth && (
                                <div style={{ width: "245px" }}>
                                  <LabelCustom
                                    labelName={"Budget (INR)"}
                                    mandatory={true}
                                  />
                                  <InputCustomFormik
                                    type="number"
                                    name="Budget"
                                    placeholder={"Please enter budget"}
                                  />
                                </div>
                              )}

                              <div style={{ width: "245px" }}>
                                <LabelCustom labelName={"Booking Note"} />
                                <TextAreaFormik
                                  name={`hotelbookingdetails[${index}].Bookingnote`}
                                  placeholder={" Enter Note"}
                                  maxLength={500}
                                />
                              </div>
                            </div>
                          </div>
                        </StyledTableCell>
                        {/* 2nd column */}

                        {/* 8th column */}
                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button"
                            className="remove-btn"
                            onClick={() =>
                              hotelbookingdetails.length > 1
                                ? remove(index)
                                : dispatch(
                                    showToast({
                                      text: "Atleast One Item is Required ",
                                      severity: "error",
                                    })
                                  )
                            }
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="add-btn"
                            onClick={() =>
                              push({
                                HotelCategory: null,
                                CheckInTime: null,
                                CheckOutTime: null,
                                Location: "",
                                PreferHotel: "",
                                Adults: "",
                                Children: "",
                                Bookingnote: "",
                              })
                            }
                          >
                            +
                          </button>
                        </StyledTableCell>
                      </TableBody>
                    ))}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
      )}
    </>
  );
};
