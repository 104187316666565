import React from 'react'

export const EpfData = ({ePFData}) => {
    // console.log('ePFData',ePFData)
  return (
    <div> 
  <div className="dataTableRow">
    <label className="dataTableRowCell">Employee Female Contribution</label>
    <label className="dataTableRowCell">:</label>
    <label className="dataTableRowCell">
      {ePFData?.employeeFemaleContribution} %
    </label>
  </div>
  <div className="dataTableRow">
    <label className="dataTableRowCell">Employer EPF Contribution Rate</label>
    <label className="dataTableRowCell">:</label>
    <label className="dataTableRowCell">
      {ePFData?.employerEPFContribution} %
    </label>
  </div>
  <div className="dataTableRow">
    <label className="dataTableRowCell">Employer EPS Contribution Rate</label>
    <label className="dataTableRowCell">:</label>
    <label className="dataTableRowCell">
      {ePFData?.employerEPSContribution} %
    </label>
  </div>
  <div className="dataTableRow">
    <label className="dataTableRowCell">EDLIS Contribution</label>
    <label className="dataTableRowCell">:</label>
    <label className="dataTableRowCell">
      {ePFData?.edlisContribution} %
    </label>
  </div>
  <div className="dataTableRow">
    <label className="dataTableRowCell">Employer EPS Contribution Rate</label>
    <label className="dataTableRowCell">:</label>
    <label className="dataTableRowCell">
      {ePFData?.edlisContribution} %
    </label>
  </div>
  <div className="dataTableRow">
    <label className="dataTableRowCell">EPF Admin Charges</label>
    <label className="dataTableRowCell">:</label>
    <label className="dataTableRowCell">
      {ePFData?.epfAdminCharge} %
    </label>
  </div>
  <div className="dataTableRow">
    <label className="dataTableRowCell">EDLIS Admin Charges</label>
    <label className="dataTableRowCell">:</label>
    <label className="dataTableRowCell">
      {ePFData?.edlisAdminCharge}%
    </label>
  </div></div>
  )
}
