import React from "react";
import { useEffect } from "react";

const OutdoorsCardComp = ({ time, title, date, source }) => {
  return (
    <div
      className="attendanceCardContentContainer"
      style={{ background: "#FFEEF2" }}
      // style={{ background: "#fff" }}
    >
      <div
        className="cardDateDisplay"
        style={{ justifyContent: "space-between" }}
      >
        {date ? date : ""}
        <label
          style={{
            fontSize: "8px",
            // background: "#6ab1bf",
            background: "rgb(59 144 161)",
            borderRadius: "100%",
            width: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "16px",
            color: "white",
          }}
        >
          {title.includes("Outdoor") && "OD"}
          {title.includes("Early Going") && "EG"}
          {title.includes("Late Coming") && "LC"}
        </label>{" "}
      </div>

      <div
        className="attendanceCardPresentCell"
        style={{ background: "#FFEEF2", borderLeft: "2px solid #DE64A1" }}
      >
        <label
          className="attendanceIn"
          style={{ fontSize: "9px", paddingLeft: "2px" }}
        >
          {time ? time[0] : ""}
        </label>
      </div>

      <div
        className="attendanceCardPresentCell"
        style={{ background: "#FFEEF2", borderLeft: "2px solid #DE64A1" }}
      >
        <label
          className="attendanceOut"
          style={{ fontSize: "9px", paddingLeft: "2px" }}
        >
          {time ? time[1] : ""}
        </label>
      </div>
    </div>
  );
};

export { OutdoorsCardComp };
