import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../../../Features";
import { postRequest } from "../../../../../Services/axios";
import "./Greetings.css";
import SocialGalleryHeaderIcon from "../../../../../Assets/SocialGalleryHeaderIcon.svg";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostViewsEyeColorIcon from "../../../../../Assets/SocialPostViewsEyeColorIcon.svg";
import SocialPostNotFoundIcon from "../../../../../Assets/SocialPostNotFoundIcon.svg";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import HeaderComponent from "./HeaderComponent";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import dateFormat from "dateformat";
import ShowGalleryFullImages from "./ShowGalleryFullImages";
import ShowCarouselGalleryImages from "./ShowCarouselGalleryImages";
import ShowViewsList from "./ShowViewsList";
import { getFormattedCount } from "../../../../../Utilities/socialAllFunctions";
const Gallery = ({ setReRefreshProp, reRefresh }) => {
  const [fullAtachmentData, setFullAtachmentData] = useState([]);
  const [showFullAttachmentActive, setShowFullAttachmentActive] =
    useState(false);
  const [viewsPostId, setViewsPostId] = useState(0);
  const [viewsListActive, setViewsListActive] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const [dataFound, setDataFound] = useState(true);
  const [viewsHovered, setIsViewsHovered] = useState(true);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });
  const navigate = useNavigate();

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && galleryData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, galleryData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || galleryData?.length < postDataCount)
    ) {
      if (!reRefresh) {
        getData();
      }
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset]);

  useEffect(() => {
    setOffset(0);
  }, []);

  const getData = async (freshLoad = false) => {
    setLoading(true);
    try {
      const result = await postRequest(`Social/AlbumList`, {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength:
            postDataCount === -1 || offset + 2 <= postDataCount || freshLoad
              ? 2
              : postDataCount - offset,
          iDisplayStart: freshLoad ? 0 : offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        setGalleryData([]);
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });
        setPostDataCount(result?.data?.summary?.allCount);
        setTotalOffset(offset);

        if (freshLoad) {
          document.querySelector(
            ".homeEngageMainCenterContainer"
          ).scrollTop = 0;
          setGalleryData(listData);
          setOffset(0);
          setTotalOffset(0);
        } else {
          setGalleryData([...galleryData, ...listData]);
        }

        setCurrentIndex((currentIndex) => currentIndex + 2);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      setReRefreshProp();
    }
  };

  const location = useLocation();
  useEffect(() => {
    document.title = `PeopleSol - Gallery`;
    return () => {
      document.title = "PeopleSol";
    };
  }, [location.pathname]);
  useEffect(() => {
    if (reRefresh === true) {
      getData(true);
    }
  }, [reRefresh]);
  return (
    <>
      <div className="engageMainContentContainer">
        <div className="greetingEngageHeaderContainer">
          <HeaderComponent icon={SocialGalleryHeaderIcon} title={"Gallery"} />
        </div>
        <div className="homeEngageMainCenterContainer">
          <div className="homeEngagePostContainer">
            {galleryData?.map((d, i) => {
              return (
                <div className="socialAllPostContainer">
                  <div
                    className="engagePostContentHolder"
                    style={{ paddingBottom: "1%" }}
                  >
                    <div className="galleryTextContainer">
                      <div className="announcementViewsContainer">
                        <h6>{d?.name}</h6>
                        <article
                          onClick={(e) => {
                            e.stopPropagation();
                            setViewsListActive(true);
                            setViewsPostId(d?.id);
                          }}
                          onMouseOver={() => setIsViewsHovered(d?.id)}
                          onMouseLeave={() => setIsViewsHovered(null)}
                        >
                          <img
                            src={
                              viewsHovered === d?.id
                                ? SocialPostViewsEyeColorIcon
                                : SocialPostViewsEyeIcon
                            }
                            alt=""
                            className="socialViewsImgContainer"
                            style={{ bottom: "0" }}
                          />
                          <span
                            className={
                              viewsHovered === d?.id
                                ? "postColorViews"
                                : `socialViewsSpanContainer`
                            }
                          >
                            {d?.views < 10
                              ? "0" + d?.views
                              : getFormattedCount(d?.views)}{" "}
                            Views
                          </span>
                        </article>
                      </div>

                      <span>{dateFormat(d?.createdOn, "dd mmm yyyy")}</span>
                    </div>
                    <div className="galleryDescriptionContainer">
                      {d?.description}
                    </div>
                    <ShowCarouselGalleryImages
                      photo={d?.albumPhoto || []}
                      type={"gallery"}
                      showModal={() => {
                        navigate("details/" + d?.id);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {!dataFound && galleryData?.length <= 0 && (
              <div className="noPostfoundContainer">
                <div className="noPostfoundWrapper">
                  <img src={SocialPostNotFoundIcon} alt="" />
                  <h5>No Post Found</h5>
                  <p>No one has posted something in social</p>
                </div>
              </div>
            )}
          </div>
          {loading && <Loader />}
        </div>
      </div>

      {showFullAttachmentActive && (
        <div>
          <Popup
            firstClassName="galleryFullAttachmentPopupBox1"
            secondClassName="galleryFullAttachmentPopupBox2"
            bodyClassName="showFullAttachmentPopupBox2Body socialPopupBody2"
            // popupHeading={galleryData?.name}
            content={
              <ShowGalleryFullImages
                fullAtachmentData={fullAtachmentData}
                setShowFullAttachmentActive={setShowFullAttachmentActive}
                setIsHovered={setIsHovered}
                isHovered={isHovered}
              />
            }
            handleClose={() => {
              setShowFullAttachmentActive(false);
            }}
          />
        </div>
      )}
      {viewsListActive && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"Gallery"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
      <Routes>
        <Route path="details/:postid*" element={<ShowGalleryFullImages />} />
      </Routes>
    </>
  );
};
export { Gallery };
