/* eslint-disable react-hooks/exhaustive-deps */
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CheckboxFormiks } from "../../../../../Components/FormComponent/CheckboxFormiks";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";

export const ExceptionForm = ({ formValues, data, setDetailsform }) => {
  const [optionSer, setOptionSer] = useState([]);
  const [optionReqType, setOptionReqType] = useState([]);
  const [requestCat, setOptionRequestCat] = useState([]);
  const [exReq, setExReq] = useState([]);
  const [optionGroup, setOptionGroup] = useState([]);
  const [optionExcep, setOptionExcep] = useState({});
  const [optionind, setOptionInd] = useState({});
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const getExceptionRequests = async () => {
    try {
      setLoading(true);
      const result = await getRequest("ExceptionRequests/GetExceptionRequests");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setExReq(listData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Exception`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`ExceptionRequests/Details?ID=${data}`);
      setDetailsform(result?.data?.data);
      // //console.log("first,",result?.data?.data?.exceptionRequests?.exceptionType)
      result?.data?.data?.expectionStatements.map((item, index) => {
        return getOptionExc(index, item.exceptionGroupId);
      });
      getOptionReq(result?.data?.data?.exceptionRequests?.exceptionType);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getDetails();
    }
  }, []);
  const getOptionReq = async (value) => {
    try {
      const result = await getRequest(
        `ExceptionRequests/GetExceptionCatelogue?type=${value}`
      );
      let listData = [];

      result?.data?.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOptionRequestCat(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionExc = async (index, val) => {
    try {
      const result = await getRequest(
        `ExceptionRequests/GetExceptionStatements?ExceptionGroupId=${val}`
      );
      let ListData = [];
      result?.data?.results.map((item) => {
        return ListData.push({ label: item.text, value: item.id });
      });
      setOptionExcep((prev) => {
        const x = { ...prev };
        x[index] = ListData;
        return x;
      });
    } catch (error) {
    } finally {
    }
  };
  const getOptionGroup = async (index) => {
    try {
      const result = await getRequest(`ExceptionRequests/GetExceptionGroups`);
      let listData = [];
      result?.data?.results.map((item, index) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOptionGroup(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionExceptionType = async () => {
    try {
      const result = await getRequest(`ExceptionRequests/GetExceptionType`);

      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOptionReqType(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionAssets = async () => {
    try {
      const result = await getRequest("AssetCategories/GetAssetCategories");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (formValues?.ExceptionType) {
      getOptionReq(formValues?.ExceptionType);
    }
  }, [formValues?.ExceptionType]);
  useEffect(() => {
    if (formValues?.List[optionind]?.ExceptionGroupId) {
      getOptionExc(optionind, formValues?.List[optionind]?.ExceptionGroupId);
    }
  }, [formValues?.List[optionind]?.ExceptionGroupId]);

  useEffect(() => {
    getOptionGroup();
    getOptionAssets();
    getOptionExceptionType();
    getExceptionRequests();
  }, []);
  const getExistingIds = (data, index) => {
    let idArray = [];
    //console.log('data',data)
    data?.map((item, indexN) => {
      if (indexN !== index) {
        idArray.push(item.ExceptionGroupId);
      }
      return null;
    });
    return idArray;
  };
  if (loading) {
    <Loader />;
  } else
    return (
      <div style={{ width: "100%" }}>
        <div className="formcustom twoColumn">
          <div>
            <LabelCustom
              labelName={"Where you need Exception? "}
              mandatory={true}
            />
            <SelectForm
              name="ExceptionType"
              options={optionReqType}
              placeholder={" Select Request Type"}
            />
          </div>
          {formValues?.ExceptionType === "IT Infra" && (
            <div>
              <LabelCustom labelName={"Asset Category "} mandatory={true} />
              <SelectForm
                name="AssetCategoryId"
                options={optionSer}
                placeholder={" Select Asset Category"}
              />
            </div>
          )}
          <div>
            <LabelCustom labelName={"Select Category "} mandatory={true} />
            <SelectForm
              onDropdownOpen={
                formValues?.ExceptionType === ""
                  ? () =>
                      dispatch(
                        showToast({
                          text: "Please select  Exception type",
                          severity: "error",
                        })
                      )
                  : () => {}
              }
              name={"ExceptionCatalogueId"}
              options={requestCat}
              placeholder={" Select  Exception Cataegory"}
            />
          </div>
          <div>
            <LabelCustom
              labelName={"Exception Description "}
              mandatory={true}
            />
            <TextAreaFormik
              maxlength="150"
              style={{ height: "100px" }}
              name="DeviationDescription"
              placeholder={" Enter Description"}
            />
          </div>
          <div>
            <LabelCustom labelName={"Previous Exception Request "} />
            <SelectForm
              name="PreviousExceptionId"
              options={exReq}
              placeholder={" Select Previous Exception Request "}
            />
          </div>
          <div>
            <LabelCustom labelName={"From Date"} mandatory={true} />
            <DatePickerFormiks name={"FromDate"} placeholder={"Enter Date"} />
          </div>
          <div>
            <LabelCustom labelName={"To Date"} mandatory={true} />
            <DatePickerFormiks
              name={"TillDate"}
              placeholder={"Enter Date"}
              minDate={formValues?.FromDate ?? null}
            />
          </div>
        </div>
        <div style={{ display: "grid" }}>
          <FieldArray name="List">
            {(fieldArrayProps) => {
              const { push, remove, form } = fieldArrayProps;
              const { values } = form;
              const { List } = values;
              return (
                <StyledTableCont>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell padding="0" align="center">
                          <LabelCustom labelName={"S.No."} />
                        </StyledTableCell>
                        <StyledTableCell padding="0" align="center">
                          <LabelCustom
                            labelName={"Exception Group"}
                            mandatory={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <LabelCustom
                            labelName={"Exception Statement"}
                            mandatory={true}
                          />
                        </StyledTableCell>

                        <StyledTableCell
                          sx={{
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          <LabelCustom labelName={"Add/Remove"} />
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {List.map((ListItem, index) => {
                      ListItem?.ExceptionGroupId !== "" && setOptionInd(index);
                      return (
                        <>
                          <TableBody key={index}>
                            <StyledTableCell align="center">
                              {index + 1}
                            </StyledTableCell>
                            <StyledTableCell>
                              <SelectForm
                                placeholder={" Enter ExceptionGroup"}
                                name={`List[${index}].ExceptionGroupId`}
                                options={optionGroup.filter(
                                  (item) =>
                                    getExistingIds(List, index).indexOf(
                                      item.value
                                    ) === -1
                                )}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <SelectForm
                                placeholder={" Enter ExceptionStatement"}
                                onDropdownOpen={
                                  ListItem?.ExceptionGroupId === ""
                                    ? () =>
                                        dispatch(
                                          showToast({
                                            text: "Please select ExceptionGroup",
                                            severity: "error",
                                          })
                                        )
                                    : () => {}
                                }
                                name={`List[${index}].ExceptionStatementId`}
                                options={optionExcep[index]}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <button
                                type="button"
                                className="remove-btn"
                                onClick={() =>
                                  List.length > 1
                                    ? remove(index)
                                    : dispatch(
                                        showToast({
                                          text: "Atleast One Item is Required ",
                                          severity: "error",
                                        })
                                      )
                                }
                              >
                                -
                              </button>
                              <button
                                type="button"
                                className="add-btn"
                                onClick={() =>
                                  push({
                                    ExceptionGroupId: "",
                                    ExceptionStatementId: "",
                                  })
                                }
                              >
                                +
                              </button>
                            </StyledTableCell>
                          </TableBody>
                        </>
                      );
                    })}
                  </Table>
                </StyledTableCont>
              );
            }}
          </FieldArray>
        </div>
         {" "}
        <CheckboxFormiks
          style={{ justifySelf: "end" }}
          name="acceptTerms"
          labelName="I am aware of the requirements of Company  IT Policy and understand that by requesting the above exception, I may compromise the security of Aess or its subsidiaries and/or associated companies. Further I undertake to ensure that mitigations and remediation identified are / will be implemented by the date/s mentioned above."
        />
      </div>
    );
};
