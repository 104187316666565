import React from "react";
import { useEffect } from "react";

const OutdoorComp = ({ time, title, date }) => {
  useEffect(() => {
    document.title = `PeopleSol - Outdoor`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div
      className="attendanceContentContainer"
      style={{ backgroundColor: `#FFEEF2` }}
    >
      <div
        className="dateDisplay"
        style={{
          fontSize: "26px",
          fontWeight: "400",
          fontFamily: "poppins",
          marginTop: "-3px",
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {date ? date : ""}
        <label
          style={{
            fontSize: "11px",
            // background: "#6ab1bf",
            background: "rgb(59 144 161)",
            borderRadius: "100%",
            width: "27px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "27px",
            color: "white",
            margin: "0px 4px",
          }}
        >
          {title.includes("Outdoor") && "OD"}
          {title.includes("Early Going") && "EG"}
          {title.includes("Late Coming") && "LC"}
        </label>{" "}
      </div>
      <div
        className="horizontalLinePrimary calendarLine"
        style={{ backgroundColor: `#DE64A1` }}
      ></div>
      {/* <div className="attendanceContentStatus" style={{ color: "#DE64A1" }}>
        {title ? title : ""}
      </div> */}
      <div style={{ display: "flex", alignItems: "center", marginTop: "23px" }}>
        <svg
          class="calendarSvg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7865 5.905C12.0802 7.08894 11.9715 8.33696 11.4775 9.45229C10.9835 10.5676 10.1323 11.4868 9.0582 12.0649C7.98406 12.643 6.74806 12.8472 5.54507 12.6452C4.34208 12.4432 3.24055 11.8465 2.41415 10.9493C1.58775 10.052 1.08349 8.90525 0.980892 7.68975C0.878291 6.47424 1.18318 5.25916 1.84749 4.23609C2.5118 3.21301 3.49773 2.44015 4.64984 2.03936C5.80194 1.63856 7.05468 1.63264 8.21052 2.02252"
            stroke="#1296B0"
            stroke-width="0.75"
            stroke-linecap="round"
          />
          <path
            d="M6.33003 4.20117L6.33003 8.14192H9.3305"
            stroke="#1296B0"
            stroke-width="0.75"
            stroke-linecap="round"
          />
          <path
            d="M11.3814 4.9707L9.03334 4.9707L9.03334 2.62263"
            stroke="#F7941D"
            stroke-width="0.75"
            stroke-linecap="round"
          />
          <line
            x1="11.9449"
            y1="2.26469"
            x2="9.35379"
            y2="4.82031"
            stroke="#F7941D"
            stroke-width="0.75"
            stroke-linecap="round"
          />
        </svg>
        <label className="attendanceIn">{time ? time[0] : "--"}</label>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <svg
          class="calendarSvg"
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.7861 5.58651C12.0798 6.77042 11.9711 8.0184 11.4772 9.1337C10.9832 10.249 10.1321 11.1682 9.05795 11.7462C7.98385 12.3243 6.74788 12.5285 5.54493 12.3265C4.34198 12.1245 3.24048 11.5279 2.4141 10.6306C1.58773 9.73342 1.08349 8.58668 0.980891 7.37121C0.878294 6.15574 1.18317 4.94069 1.84746 3.91765C2.51175 2.89461 3.49766 2.12177 4.64973 1.72099C5.8018 1.32021 7.0545 1.31428 8.2103 1.70415"
            stroke="#1296B0"
            stroke-width="0.75"
            stroke-linecap="round"
          />
          <path
            d="M6.3307 3.88477L6.3307 7.8254H9.33108"
            stroke="#1296B0"
            stroke-width="0.75"
            stroke-linecap="round"
          />
          <path
            d="M9.73299 1.60352L12.081 1.60352L12.081 3.95152"
            stroke="#F7941D"
            stroke-width="0.75"
            stroke-linecap="round"
          />
          <line
            x1="9.16994"
            y1="4.30953"
            x2="11.7609"
            y2="1.754"
            stroke="#F7941D"
            stroke-width="0.75"
            stroke-linecap="round"
          />
        </svg>

        <label className="attendanceOut">{time ? time[1] : "--"}</label>
      </div>
    </div>
  );
};

export { OutdoorComp };
