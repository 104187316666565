import React, { useEffect, useState } from "react";
import "./AttendanceCard.css";
import { Calendar } from "antd";
import dateFormat from "dateformat";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import { BlankCardComp } from "./AttendanceCardComp/BlankCardComp";
import { AbsentCardComp } from "./AttendanceCardComp/AbsenCardComp";
import { PresentCardComp } from "./AttendanceCardComp/PresentCardComp";
import { WeeklyCardComp } from "./AttendanceCardComp/WeeklyCardComp";
import { HolidayCardComp } from "./AttendanceCardComp/HolidayCardComp";
import { HalfDayCardComp } from "./AttendanceCardComp/HalfDayCardComp";
import { LeaveCardComp } from "./AttendanceCardComp/LeaveCardComp";
import { OptionalLeaveCardComp } from "./AttendanceCardComp/OptionalLeaveCardComp";
import { OutdoorsCardComp } from "./AttendanceCardComp/OutdoorsCardComp";
import { useSelector } from "react-redux";
import { SelectCustom } from "../../../../../Components/FormComponent/SelectCustom";
import Skeleton from "react-loading-skeleton";
import SelectCustomEmployeeCreateAttendance from "../../../../HRMS/Components/Time & Attendance/SelectCustomEmployeeCreateAttendance";

const AttendanceCard = ({ isManager, navigate, teamList }) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [attendanceList, setAttendanceList] = useState(null);
  const [idActive, setIdActive] = useState(LoggedInUser?.id);
  const [dateObject, setDateObject] = useState({
    startDate: dateFormat(
      new Date(new Date()?.getFullYear(), new Date()?.getMonth(), 1),
      "yyyy-mm-dd"
    ),
    endDate: dateFormat(
      new Date(new Date()?.getFullYear(), new Date()?.getMonth() + 1, 0),
      "yyyy-mm-dd"
    ),
  });
  const [currentMonth, setCurrentMonth] = useState(
    dateFormat(new Date(), "mmmm yyyy")
  ); // State variable to hold the current month name and year

  useEffect(() => {
    getAttendanceData(idActive, dateObject?.startDate, dateObject?.endDate);
  }, [idActive, dateObject]);

  useEffect(() => {
    if (LoggedInUser) {
      setIdActive(LoggedInUser?.id);
    }
  }, [LoggedInUser]);

  const [selectedEmployee, setSelectedEmployee] = useState([
    { label: "Myself", value: LoggedInUser?.id },
  ]);

  const onPanelChange = (value, mode = null) => {
    if (mode == "mode" && value) {
      const starttDate = dateFormat(
        new Date(value.getFullYear(), value.getMonth(), 1),
        "yyyy-mm-dd"
      );
      const enddDate = dateFormat(
        new Date(value.getFullYear(), value.getMonth() + 1, 0),
        "yyyy-mm-dd"
      );
      setDateObject({ startDate: starttDate, endDate: enddDate });
      setCurrentMonth(dateFormat(value, "mmmm yyyy"));
    } else {
      const starttDate = dateFormat(
        new Date(value.$d?.getFullYear(), value.$d?.getMonth(), 1),
        "yyyy-mm-dd"
      );
      const enddDate = dateFormat(
        new Date(value.$d?.getFullYear(), value.$d?.getMonth() + 1, 0),
        "yyyy-mm-dd"
      );
      setDateObject({ startDate: starttDate, endDate: enddDate });
      setCurrentMonth(dateFormat(value.$d, "mmmm yyyy"));
    }
  };

  const getAttendanceData = async (id, startDate, endDate) => {
    if (!id) return;
    if (!startDate) return;
    if (!endDate) return;
    try {
      setLoading(true);
      const result = await getRequest(
        `Attendance/GetAttendance/${id}/${startDate}/${endDate}`
      );
      setAttendanceList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  // console.log("object attendanceList", attendanceList);
  const dateFullCellRender = (date) => {
    const data =
      attendanceList &&
      attendanceList?.find((x) => {
        return (
          new Date(x.start).toDateString() === new Date(date).toDateString()
        );
      });

    // console.log("dateFullCellRender", new Date(date));
    // console.log("object data", data);
    if (!data) {
      return (
        <>
          <BlankCardComp
            date={dateFormat(date, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
            source={data?.source}
          />
        </>
      );
    } else if (data?.title?.includes("Absent")) {
      return (
        <>
          <AbsentCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title?.includes("Present")) {
      return (
        <>
          <PresentCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
            source={data?.source}
          />
        </>
      );
    } else if (data?.title.includes("Weekly")) {
      return (
        <>
          <WeeklyCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title.includes("Holiday")) {
      return (
        <>
          <HolidayCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title.includes("Half")) {
      return (
        <>
          <HalfDayCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
          />
        </>
      );
    } else if (data?.title.includes("Leave")) {
      return (
        <>
          <LeaveCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (data?.title.includes("Optional")) {
      return (
        <>
          <OptionalLeaveCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
          />
        </>
      );
    } else if (
      data?.title.includes("Outdoor") ||
      data?.title.includes("Early Going") ||
      data?.title.includes("Late Coming")
    ) {
      return (
        <>
          <OutdoorsCardComp
            date={dateFormat(data?.start, "dd")}
            title={data?.title}
            time={data?.timing ? data?.timing?.split("\n") : null}
          />
        </>
      );
    } else {
      return (
        <>
          <BlankCardComp date={dateFormat(date, "dd")} title={data?.title} />
        </>
      );
    }
  };
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthFormatted = String(currentMonth).padStart(2, "0"); // Add leading zero if needed

  const initialMonth = {
    label: new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      currentDate
    ),
    value: `${currentMonthFormatted}-${currentYear}`,
  };
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  // console.log("selectedMonth", selectedMonth[0]?.value);
  // Define handleMonthChange function to handle month selection
  const handleMonthChange = (selectedOptions) => {
    // console.log("object handleMonthChange selectedOptions", selectedOptions);
    if (selectedOptions.length > 0) {
      const [month, year] = selectedOptions[0]?.value?.split("-");
      setSelectedMonth(selectedOptions);
      // Create a new Date object using the extracted month and year
      const date = new Date(`${month}/01/${year}`);
      // console.log("object handleMonthChange date", date);
      onPanelChange(date, "mode");
    }
  };

  const headerRender = () => null;

  return (
    <>
      <div
        className="headingContainer cursor headingWithDropdown"
        style={{ marginBottom: "-12px", zIndex: "999" }}
      >
        <h3
          className="cursor"
          onClick={() => navigate("/hrms/timeattendance/myattendance")}
        >
          Attendance
        </h3>
        {/* <div style={{ flex: "2" }}>
          <SelectCustom
            options={[
              { label: "January", value: "01-2024" },
              { label: "February", value: "02-2024" },
              { label: "March", value: "03-2024" },
              { label: "April", value: "04-2024" },
              { label: "May", value: "05-2024" },
              { label: "June", value: "06-2024" },
              { label: "July", value: "07-2024" },
              { label: "August", value: "08-2024" },
              { label: "September", value: "09-2024" },
              { label: "October", value: "10-2024" },
              { label: "November", value: "11-2024" },
              { label: "December", value: "12-2024" },
            ]}
            styl={{
              backgroundColor: "var(--primary-hover)",
              margin: 0,
              minHeight: "40px",
              maxHeight: "40px",
              padding: "5px 10px",
            }}
            deepStyle={{ backgroundColor: "var(--primary-hover)" }}
            onChange={handleMonthChange}
            values={[selectedMonth]}
          />
        </div> */}
        {isManager && (
          <div style={{ width: "48%", flex: "1" }}>
            <SelectCustomEmployeeCreateAttendance
              styles={{
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
              }}
              selectedEmployeeList={selectedEmployee}
              setSelectedEmployeeList={setSelectedEmployee}
              // values={selectedEmployee}
              setIdActive={setIdActive}
              urlProp={"Employees/GetEmployeesForAttendance"}
              lgid={LoggedInUser?.id}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
              deepStyle={{ backgroundColor: "var(--primary-hover)" }}
            />
            {/* <SelectCustom
                    dropdownHeight="236px"
                    styles={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
                    values={selectedEmployee}
                    options={teamList}
                    onChange={(value) => {
                      setIdActive(value?.[0]?.value);
                      setSelectedEmployee(value);
                    }}
                    fullWidth={true}
                    styl={{
                      backgroundColor: "var(--primary-hover)",
                      margin: 0,
                      minHeight: "40px",
                      maxHeight: "40px",
                      padding: "5px 10px",
                    }}
                  /> */}
          </div>
        )}
      </div>
      <div className="dashboardCardContentHolder">
        {loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
          >
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        )}
        <div className="calendarContainer">
          <Calendar
            fullscreen={true}
            fullCellRender={dateFullCellRender}
            style={{ textAlign: "center", height: "100%" }}
            onPanelChange={onPanelChange}
            // headerRender={headerRender}
          />
        </div>
      </div>
    </>
  );
};

export { AttendanceCard };
