import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import {
  docsArray,
  getAttachments,
  getContentLength,
  getExtension,
  getFormattedCount,
  getUserInitials,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import {
  communityProfileImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import dateFormat from "dateformat";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostViewsEyeColorIcon from "../../../../../Assets/SocialPostViewsEyeColorIcon.svg";
import { IconButton } from "@mui/material";
import PostActivityContainer from "./PostActivityContainer";
import SocialKudosTag from "../../../../../Assets/SocialKudosTag.svg";
import SocialBirthdayTag from "../../../../../Assets/SocialBirthdayTag.svg";
import SocialWorkAnnyTag from "../../../../../Assets/SocialWorkAnnyTag.svg";
import SocialNewJoiningTag from "../../../../../Assets/SocialNewJoiningTag.svg";
import ContentSocialDisplay from "./ContentSocialDisplay";
import { useState } from "react";
import ShowCarouselGreetingsImages from "./ShowCarouselGreetingsImages";
import ShowCarouselSocialImages from "./ShowCarouselSocialImages";
import PDFViewer from "./PDFViewer";
import SocialTextLinkIcon from "../../../../../Assets/SocialTextLinkIcon.svg";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";

function CommunitySharedPost({
  d,
  setViewsListActive,
  setViewsPostId,
  LoggedInUser,
  setEditDeleteSocialPostId,
  setIsSocialPostDeletePopupActive,
  setEditDeleteSocialPostData,
  setIsSharePosEditActive,
  setIsMyPosEditActive,
}) {
  const [showContent, setShowContent] = useState(false);
  const { communityid } = useParams();
  const navigate = useNavigate();
  const [viewsHovered, setIsViewsHovered] = useState(false);
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  return (
    <div className="engagePostContentHolder">
      <div
        className={
          d?.sharedEmployee !== null ? `sharedSocialPostDataListing` : ""
        }
      >
        <div className="postHeaderContainer" style={{ boxShadow: "none" }}>
          {d?.sharedPost?.postType === "community" &&
          d?.sharedPost?.community?.id !== communityid ? (
            <div
              className="leftPostHeaderContainer"
              style={{ position: "relative" }}
            >
              <img
                src={
                  d?.sharedPost?.community?.communityImage === null
                    ? SocialPostTeamIcon
                    : process.env.REACT_APP_.BASE_URL +
                      communityProfileImages +
                      d?.sharedPost?.community?.communityImage
                }
                alt=""
                style={{
                  height: "50px",
                  width: "50px",
                  // marginRight: "18px",
                }}
                onClick={() => {
                  navigate(`/home/social/community/${d?.community?.id}`);
                }}
              />

              <div
                onClick={() => {
                  handleUserProfileClick(d?.id);
                }}
                className={`${
                  d?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePicCommunity"
                }`}
              >
                {!d?.image ? (
                  getUserInitials(d?.name)
                ) : (
                  <img
                    className={`socialProfilePic ${
                      d?.postType !== "organisation" ? "prTeamCommunity" : ""
                    }`}
                    src={
                      process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div
                className="postMainTextContainer"
                onClick={() => {
                  navigate(`/home/social/community/${d?.community?.id}`);
                }}
              >
                <label className="postMainName">
                  {d?.sharedPost?.community?.communityName}
                </label>
                <label className="dateAndViewsContainer">
                  Post from{" "}
                  <span className="nameofposter">
                    {getDisplayName(d?.nameWithPersonNumber)}{" "}
                  </span>
                  <span className="postHrTime">
                    {" "}
                    • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                  <article
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewsListActive(true);
                      setViewsPostId(d?.id);
                    }}
                    onMouseOver={() => setIsViewsHovered(d?.id)}
                    onMouseLeave={() => setIsViewsHovered(null)}
                  >
                    <img
                      src={
                        viewsHovered === d?.id
                          ? SocialPostViewsEyeColorIcon
                          : SocialPostViewsEyeIcon
                      }
                      alt=""
                      className="socialViewsImgContainer"
                    />
                    <span
                      className={
                        viewsHovered === d?.id
                          ? "postColorViews"
                          : `socialViewsSpanContainer`
                      }
                    >
                      {d?.views < 10
                        ? "0" + d?.views
                        : getFormattedCount(d?.views)}{" "}
                      Views
                    </span>
                  </article>
                </label>
              </div>
            </div>
          ) : d?.sharedPost?.postType === "team" ? (
            <div
              className="leftPostHeaderContainer"
              style={{ position: "relative" }}
            >
              <img
                src={SocialPostTeamIcon}
                alt=""
                style={{
                  height: "50px",
                  width: "50px",
                  // marginRight: "18px",
                }}
              />
              <div
                onClick={() => {
                  handleUserProfileClick(d?.id);
                }}
                className={`${
                  d?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePicCommunity"
                }`}
              >
                {!d?.image ? (
                  getUserInitials(d?.name)
                ) : (
                  <img
                    className={`socialProfilePic ${
                      d?.postType !== "organisation" ? "prTeamCommunity" : ""
                    }`}
                    src={
                      process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">Team</label>
                <label className="dateAndViewsContainer">
                  Post from{" "}
                  <span className="nameofposter">
                    {getDisplayName(d?.nameWithPersonNumber)}{" "}
                  </span>
                  <span className="postHrTime">
                    {" "}
                    • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                  <article
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewsListActive(true);
                      setViewsPostId(d?.id);
                    }}
                    onMouseOver={() => setIsViewsHovered(d?.id)}
                    onMouseLeave={() => setIsViewsHovered(null)}
                  >
                    <img
                      src={
                        viewsHovered === d?.id
                          ? SocialPostViewsEyeColorIcon
                          : SocialPostViewsEyeIcon
                      }
                      alt=""
                      className="socialViewsImgContainer"
                    />
                    <span
                      className={
                        viewsHovered === d?.id
                          ? "postColorViews"
                          : `socialViewsSpanContainer`
                      }
                    >
                      {d?.views < 10
                        ? "0" + d?.views
                        : getFormattedCount(d?.views)}{" "}
                      Views
                    </span>
                  </article>
                </label>
              </div>
            </div>
          ) : (
            <div
              className="leftPostHeaderContainer"
              onClick={() => {
                handleUserProfileClick(d?.employeeId);
              }}
            >
              <div
                className={`${
                  d?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePic"
                }`}
              >
                {!d?.image ? (
                  getUserInitials(d?.name)
                ) : (
                  <img
                    className="socialProfilePic"
                    onClick={() => {
                      handleUserProfileClick(d?.id);
                    }}
                    src={
                      process.env.REACT_APP_.BASE_URL + profileImages + d?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">
                  {getDisplayName(d?.nameWithPersonNumber)}
                </label>
                <label>{d?.roleName}</label>
                <label className="dateAndViewsContainer">
                  {d?.locationName}{" "}
                  <span className="postHrTime">
                    {" "}
                    • {dateFormat(d?.createdOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                  <article
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewsListActive(true);
                      setViewsPostId(d?.id);
                    }}
                    onMouseOver={() => setIsViewsHovered(d?.id)}
                    onMouseLeave={() => setIsViewsHovered(null)}
                  >
                    <img
                      src={
                        viewsHovered === d?.id
                          ? SocialPostViewsEyeColorIcon
                          : SocialPostViewsEyeIcon
                      }
                      alt=""
                      className="socialViewsImgContainer"
                    />
                    <span
                      className={
                        viewsHovered === d?.id
                          ? "postColorViews"
                          : `socialViewsSpanContainer`
                      }
                    >
                      {d?.views < 10
                        ? "0" + d?.views
                        : getFormattedCount(d?.views)}{" "}
                      Views
                    </span>
                  </article>
                </label>
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {d?.subType === "kudos" && (
              <div className="kudosTypeContainer">
                <span>Kudos</span>
                <article></article>
                <img src={SocialKudosTag} alt="" />
              </div>
            )}
            {d?.subType === "birthday" && (
              <div className="birthdayTypeContainer">
                <span>Birthday</span>
                <article></article>
                <img src={SocialBirthdayTag} alt="" />
              </div>
            )}
            {d?.subType === "workanniversary" && (
              <div className="workAnnyTypeContainer">
                <span>Work Anniversary</span>
                <article></article>
                <img src={SocialWorkAnnyTag} alt="" />
              </div>
            )}
            {d?.subType === "welcome" && (
              <div className="newJoiningTypeContainer">
                <span>New Joining</span>
                <article></article>
                <img src={SocialNewJoiningTag} alt="" />
              </div>
            )}
            {d?.sharedEmployee === null &&
              d?.nameWithPersonNumber === LoggedInUser.nameWithPersonNumber && (
                <IconButton>
                  <PostActivityContainer
                    setIsSocialPostDeletePopupActive={
                      setIsSocialPostDeletePopupActive
                    }
                    setIsSharePosEditActive={setIsSharePosEditActive}
                    setIsMyPosEditActive={setIsMyPosEditActive}
                    isMyPosEditActive={true}
                    isSharePosEditActive={false}
                    setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                    editDeleteSocialPostId={d?.id}
                    editDeleteSocialPostData={d}
                    setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                  />
                </IconButton>
              )}
          </div>
        </div>
        {d?.subType !== null && (
          <>
            <div className="postBodyTextContainer">
              {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
                <ContentSocialDisplay content={getContentLength(d?.content)} />
              ) : (
                <ContentSocialDisplay content={d?.content?.trim()} />
              )}
              {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
                <span
                  onClick={() => {
                    setShowContent(true);
                  }}
                >
                  ...View More
                </span>
              )}
            </div>
            <div className="postBodyContainer">
              <div
                className="newPostBodyImgMainWrapper"
                onClick={() => {
                  navigate("details/" + d?.id);
                }}
              >
                <div
                  className={` ${
                    d?.sharedEmployee !== null
                      ? "shareNewPostBodyImgContainer"
                      : "newPostBodyImgContainer"
                  }`}
                >
                  <ShowCarouselGreetingsImages
                    photo={getAttachments(d?.attachment) || []}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {d?.subType === null && (
          <>
            <div className="postBodyTextContainer">
              {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
                <ContentSocialDisplay content={getContentLength(d?.content)} />
              ) : (
                <ContentSocialDisplay content={d?.content?.trim()} />
              )}
              {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
                <span
                  onClick={() => {
                    setShowContent(true);
                  }}
                >
                  ...View More
                </span>
              )}
            </div>
            <div className="postBodyContainer">
              {d?.attachment?.length > 0 &&
                imgArray.includes(
                  getExtension(
                    getAttachments(d?.attachment)
                      ? getAttachments(d?.attachment)[0]
                      : ""
                  )
                ) && (
                  <div
                    className={` ${
                      d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
                    }`}
                  >
                    <div
                      className={"newPostBodyImgMainWrapper"}
                      onClick={() => {
                        navigate("details/" + d?.id);
                      }}
                      style={{ margin: "0" }}
                    >
                      <ShowCarouselSocialImages
                        photo={getAttachments(d?.attachment) || []}
                      />
                    </div>
                  </div>
                )}

              {d?.attachment?.length > 0 &&
                videoArray.includes(
                  getExtension(
                    getAttachments(d?.attachment)
                      ? getAttachments(d?.attachment)[0]
                      : ""
                  )
                ) && (
                  <div
                    className={` ${
                      d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
                    }`}
                    onClick={() => {
                      navigate("details/" + d?.id);
                    }}
                  >
                    <div
                      className={`${
                        d?.sharedEmployee !== null
                          ? "sharePostBodyImgMainWrapper"
                          : "newPostBodyImgMainWrapper"
                      }`}
                      style={{ margin: "0" }}
                    >
                      <div
                        className={` ${
                          d?.sharedEmployee !== null
                            ? "shareNewPostBodyImgContainer"
                            : "newPostBodyImgContainer"
                        }`}
                      >
                        <video
                          src={
                            process.env.REACT_APP_.BASE_URL +
                            socialPostImages +
                            getAttachments(d?.attachment)[0]
                          }
                          controls
                          width="630"
                          height="335"
                        />
                      </div>
                    </div>
                  </div>
                )}

              {d?.attachment?.length > 0 &&
                docsArray.includes(
                  getExtension(
                    getAttachments(d?.attachment)
                      ? getAttachments(d?.attachment)[0]
                      : ""
                  ).toLowerCase()
                ) && (
                  <div
                    className={` ${
                      d?.sharedEmployee !== null ? "postBodyImgContainer" : ""
                    }`}
                  >
                    {getAttachments(d?.attachment)?.length === 1 && (
                      <div
                        className="newPostBodyImgContainer"
                        onClick={() => {
                          navigate("details/" + d?.id);
                        }}
                      >
                        <PDFViewer
                          pdfUrl={
                            process.env.REACT_APP_.BASE_URL +
                            socialPostImages +
                            getAttachments(d?.attachment)[0]
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              {d?.news?.link && (
                <div className="newsLinkContainer">
                  <div className="newsLinkWrapper">
                    <div className="newsLink">
                      <img src={SocialTextLinkIcon} alt="" />{" "}
                      <a href={d?.news?.link} target="_blank" rel="noreferrer">
                        <span>{d?.news?.link}</span>
                      </a>
                    </div>
                    <img
                      style={{ width: "29px" }}
                      src={SocialNewsEndLinkIcon}
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CommunitySharedPost;
