import React, { useState } from 'react';

function StatusFeature() {
    const [statuses, setStatuses] = useState([
        { id: 1, seen: false },
        { id: 2, seen: false },
        { id: 3, seen: false },
        // Add more statuses as needed
    ]);

    const handleClick = (statusId) => {
        const updatedStatuses = statuses.map(status =>
            status.id === statusId ? { ...status, seen: true } : status
        );
        setStatuses(updatedStatuses);
    };

    return (
        <div className="status-container">
            {statuses.map(status => (
                <div
                    key={status.id}
                    className={`status-item ${status.seen ? 'seen' : 'unseen'}`}
                    onClick={() => handleClick(status.id)}
                >
                    Status {status.id}
                </div>
            ))}
        </div>
    );
}

export default StatusFeature;
