import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { StepsHierarchy } from "../../../Components/FormComponent/StepsHierarchy";
import { WorkflowStepsComp } from "../../../Components/FormComponent/WorkflowStepsComp";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { format } from "date-fns";
import { Loader } from "../../../Components/FormComponent/Loader";

const DetailsTravelCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(false);
  const [eventtData, setEventtData] = useState(false);

  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `TravelCategories/Details?ID=${selectedId}`
      );
      setEventtData(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  const categoryArray = eventtData?.allCategoryIds?.map((item, index) => ({
    id: item,
    category: eventtData?.allcategoryNames[index],
  }));
  console.log("object categoryArray", categoryArray);
  useEffect(() => {
    getDetails();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Travel Eligibilities Details `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // //console.log("eventtData", eventtData);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            {/* <div className="moduleMasterDetailsBox"> */}
            <DetailsStep
              detailTitle={"Mode"}
              Details={eventtData?.travelCategories?.mode}
            />

            <DetailsStep
              detailTitle={"Created On"}
              Details={
                eventtData?.travelCategories?.createdOn
                  ? format(
                      new Date(eventtData?.travelCategories?.createdOn),
                      "dd/MM/yyyy hh:mm a"
                    )
                  : null
              }
            />
            <DetailsStep
              detailTitle={"Last Updated On"}
              Details={
                eventtData?.travelCategories?.lastUpdatedOn
                  ? format(
                      new Date(eventtData?.travelCategories?.lastUpdatedOn),
                      "dd/MM/yyyy hh:mm a"
                    )
                  : null
              }
            />
            <div style={{ width: "100%" }}>
              <table
                className="tabless tabless-bordered"
                style={{ width: "100%" }}
              >
                <thead
                  style={{
                    backgroundColor: "var(--primary-hover)",
                  }}
                >
                  <tr>
                    <td>S.no</td>
                    <td>Category</td>
                    <td>Budget</td>
                    <td>Budget Type</td>
                  </tr>
                </thead>
                <tbody>
                  {eventtData?.categoriesdetails?.length > 0
                    ? eventtData?.categoriesdetails?.map((item, index) => {
                        return (
                          <tr className="trStyle">
                            <td>{index + 1}</td>
                            <td>{item?.category}</td>
                            <td>{item?.budget}</td>
                            <td>{item?.type}</td>
                          </tr>
                        );
                      })
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsTravelCategory };
