import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import "./TimeConfig.css";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import UpdateTimeAttendanceConfig from "./UpdateTimeAttendanceConfig";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { OneKPlusOutlined } from "@mui/icons-material";
const TimeConfig = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [ipAddress, setIpAddress] = useState();
  const [optionSerCat, setOptionSerCat] = React.useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [resonForRegularization, setResonForRegularization] = useState("");
  const [timeAttendanceInitialValues, setTimeAttendanceInitialValues] =
    useState({
      mobileLogIn: false,
      geofence: false,
      faceReader: false,
      webLogIn: false,
      captureLocation: false,
      locationMandatory: false,
      ipAddressList: null,
      departmentList: null,
      departmentIds: null,
    });
  const [regularizationInitialValues, setRegularizationInitialValues] =
    useState({
      createdBy: null,
      createdOn: null,
      id: 0,
      lastUpdatedOn: null,
      needInOut: false,
      numberOfRegularizeDays: 0,
      reasonForRegularize: "",
      updatedBy: 0,
    });
  const [isTimeConfigUpdateActive, setIsTimeConfigUpdateActive] =
    useState(false);
  const [isAttenConfigUpdateActive, setIsAttenConfigUpdateActive] =
    useState(false);
  const [optionDepartment, setOptionDepartment] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([
    {
      label:
        "Total " + timeAttendanceInitialValues?.ipAddressList !== null
          ? timeAttendanceInitialValues?.ipAddressList?.length
          : 0,
      value: "0",
    },
  ]);
  const getDropDown = () => {
    let listData = [];
    ipAddress?.map((item) => {
      if (ipAddress[0] !== "") listData.push({ label: item, value: item });
    });
    setOptionSerCat(listData);
  };


  const getDepartment = async () => {
    try {
      const result = await getRequest("LateComingRequests/GetDepartments");
      // console.log("result", result);
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
        return null;
      });
      // console.log("listData", listData);

      setOptionDepartment(listData);
    } catch (error) {
    } finally {
    }
  };

  const getTimeAttendanceConfigData = async () => {
    try {
      const result = await getRequest(`TimeAttendanceConfig/Details`);
      setTimeAttendanceInitialValues({
        webLogIn: result?.data?.webLogIn,
        mobileLogIn: result?.data?.mobileLogIn,
        geofence: result?.data?.geofence,
        faceReader: result?.data?.faceReader,
        captureLocation: result?.data?.captureLocation,
        locationMandatory: result?.data?.locationMandatory,
        ipAddressList:
          result?.data?.ipAddressList !== null
            ? result?.data?.ipAddressList
            : null,
        departmentIds: result?.data?.departmentIds,
        departmentList: result?.data?.departmentList,
      });

      if (result?.data?.ipAddressList !== null) {
        setIpAddress([...result?.data?.ipAddressList]);
        setSelectedEmployee([
          {
            label:
              result?.data?.ipAddressList[0] !== ""
                ? "Total " + result?.data?.ipAddressList?.length
                : "No Ip Address",
            value: "0",
          },
        ]);
      }

    } catch (error) {
      dispatch(
        showToast({
          text: "Error in fetching Data",
          severity: "succes",
        })
      );
    }
  };

  const getRegularizationConfigData = async () => {
    try {
      const result = await getRequest(`AttendanceRegularizationConfig/Details`);


      if (result?.status === 200) {
        setRegularizationInitialValues(result?.data);
        setResonForRegularization(result?.data?.reasonForRegularize);
        // setSelectedEmployee(
        //   result?.data?.reasonForRegularize.split(",").map((item) => {
        //     return { label: item, value: item };
        //   })
        // );
      } else {
        dispatch(
          showToast({
            text: "Error in fetching Data",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "Error in fetching Data",
          severity: "error",
        })
      );
    }
  };
  const updateRegularizationConfigData = async () => {
    if (
      regularizationInitialValues.numberOfRegularizeDays === "" ||
      regularizationInitialValues.numberOfRegularizeDays === null ||
      regularizationInitialValues.numberOfRegularizeDays === undefined
    ) {
      dispatch(
        showToast({
          text: "Please provide regularize days to proceed",
          severity: "error",
        })
      );
      return;
    }
    try {
      const result = await postRequest(
        `AttendanceRegularizationConfig/Update`,
        {
          createdBy: regularizationInitialValues.createdBy,
          createdOn: regularizationInitialValues.createdOn,
          id: regularizationInitialValues.id,
          lastUpdatedOn: regularizationInitialValues.lastUpdatedOn,
          needInOut: regularizationInitialValues.needInOut,
          numberOfRegularizeDays:
            regularizationInitialValues.numberOfRegularizeDays,
          reasonForRegularize: resonForRegularization,
          updatedBy: regularizationInitialValues.updatedBy,
        }
      );
      setIsAttenConfigUpdateActive(false);
    } catch (error) {
      dispatch(
        showToast({
          text: "Error in fetching Data",
          severity: "succes",
        })
      );
    }
  };
  useEffect(() => {
    getTimeAttendanceConfigData();
    getRegularizationConfigData();
    document.title = `PeopleSol - Time Config`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="timeAttenConfigContainer">
      <div className="timeAttenConfigWrapper">
        {!isTimeConfigUpdateActive && (
          <div className="timeConfigContainer">
            <div className="timeConfigHeader">
              <h2>Attendance Configuration</h2>
              <IconButton className="configIconBtn">
                <span
                  onClick={() => {
                    getTimeAttendanceConfigData();
                    setIsTimeConfigUpdateActive(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <line
                      x1="9.09082"
                      y1="15.6436"
                      x2="15.8737"
                      y2="15.6436"
                      stroke="#FB7158"
                      stroke-linecap="round"
                    />
                    <path
                      d="M9.13064 3.84683L0.882812 12.0396V15.311H4.20622L12.3609 7.10426M9.13064 3.84683L11.2438 1.74779C12.1366 0.860928 13.5786 0.863344 14.4684 1.75319V1.75319C15.3584 2.64315 15.3607 4.08533 14.4736 4.97812L12.3609 7.10426M9.13064 3.84683L12.3609 7.10426"
                      stroke="#FB7158"
                    />
                  </svg>
                  <p>Update Config</p>
                </span>
              </IconButton>
            </div>
            <div className="timeConfigWrapper">
              <div className="configRow1">
                <div className="configBox">
                  <div className="configTxt">
                    Allow employees to mark attendance from web browser ?
                  </div>
                  <div
                    className={`configValue ${
                      timeAttendanceInitialValues?.webLogIn ? "" : "forNo"
                    }`}
                  >
                    {timeAttendanceInitialValues?.webLogIn ? "Yes" : "No"}
                  </div>
                </div>
                <div className="configBox">
                  <div className="configTxt">
                    Allow employees to mark attendance from mobile App ?
                  </div>
                  <div
                    className={`configValue ${
                      timeAttendanceInitialValues?.mobileLogIn ? "" : "forNo"
                    }`}
                  >
                    {timeAttendanceInitialValues?.mobileLogIn ? "Yes" : "No"}
                  </div>
                </div>
                <div className="configBox">
                  <div className="configTxt">
                    Capture image when marking the attendance from mobile app ?
                  </div>
                  <div
                    className={`configValue ${
                      timeAttendanceInitialValues?.faceReader ? "" : "forNo"
                    }`}
                  >
                    {timeAttendanceInitialValues?.faceReader ? "Yes" : "No"}
                  </div>
                </div>
              </div>
              <div className="configRow2">
                <div className="configBox">
                  <div className="configTxt">
                    Enable geo fence to mark the attendance from mobile app ?
                  </div>
                  <div
                    className={`configValue ${
                      timeAttendanceInitialValues?.geofence ? "" : "forNo"
                    }`}
                  >
                    {timeAttendanceInitialValues?.geofence ? "Yes" : "No"}
                  </div>
                </div>
                <div className="configBox">
                  <div className="configTxt">
                    Capture user location when marking the attendance from
                    mobile app ?
                  </div>
                  <div
                    className={`configValue ${
                      timeAttendanceInitialValues?.captureLocation
                        ? ""
                        : "forNo"
                    }`}
                  >
                    {timeAttendanceInitialValues?.captureLocation
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
                <div className="configBox">
                  <div className="configTxt">
                    Location is mandatory when marking the attendance from
                    mobile app ?
                  </div>
                  <div
                    className={`configValue ${
                      timeAttendanceInitialValues?.locationMandatory
                        ? ""
                        : "forNo"
                    }`}
                  >
                    {timeAttendanceInitialValues?.locationMandatory
                      ? "Yes"
                      : "No"}
                  </div>
                </div>
              </div>
              <div className="configRow3">
                <div className="configBox">
                  <div className="configTxt">IP Address</div>
                  <SelectCustom
                    onDropdownOpen={() => {
                      getDropDown();
                    }}
                    options={optionSerCat}
                    values={selectedEmployee}
                    // onChange={(value) => {
                    //   setSelectedEmployeeList(value);
                    // }}
                  />
                </div>
                <div className="configBox">
                  <div className="configTxt">Departments</div>
                  {/* <SelectCustom
                    // onDropdownOpen={() => {
                    //   getDepartment();
                    // }}
                    // options={optionDepartment}
                    values={selectedDepartment ?? "No Department Found"}
                  /> */}
                  <div className="departmentsListContainer">
                  {/* {timeAttendanceInitialValues?.departmentList?.join()} */}
                  {timeAttendanceInitialValues?.departmentList?.map((d)=>{
                    return <article>{d}, {" "}</article>;
                  })}

                  </div>
                </div>
                {/* <div
                  className="configBox"
                  style={{ visibility: "hidden" }}
                ></div> */}
              </div>
            </div>
          </div>
        )}
        {isTimeConfigUpdateActive && (
          <UpdateTimeAttendanceConfig
          isTimeConfigUpdateActive={isTimeConfigUpdateActive}
            setIsTimeConfigUpdateActive={setIsTimeConfigUpdateActive}
            timeAttendanceInitialValues={timeAttendanceInitialValues}
            getTimeAttendanceConfigData={getTimeAttendanceConfigData}
          />
        )}
        {!isAttenConfigUpdateActive && (
          <div className="attenConfigContainer">
            <div className="attenConfigHeader">
              <h2>Regularisation Configuration</h2>
              <IconButton className="configIconBtn">
                <span
                  onClick={() => {
                    setIsAttenConfigUpdateActive(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <line
                      x1="9.09082"
                      y1="15.6436"
                      x2="15.8737"
                      y2="15.6436"
                      stroke="#FB7158"
                      stroke-linecap="round"
                    />
                    <path
                      d="M9.13064 3.84683L0.882812 12.0396V15.311H4.20622L12.3609 7.10426M9.13064 3.84683L11.2438 1.74779C12.1366 0.860928 13.5786 0.863344 14.4684 1.75319V1.75319C15.3584 2.64315 15.3607 4.08533 14.4736 4.97812L12.3609 7.10426M9.13064 3.84683L12.3609 7.10426"
                      stroke="#FB7158"
                    />
                  </svg>
                  <p>Update Config</p>
                </span>
              </IconButton>
            </div>
            <div className="attenConfigWrapper">
              <div className="reguConfig">
                <div className="configBox">
                  <div className="configTxt">
                    Can regularise when sign in and sign out time is present ?
                  </div>
                  <div
                    className={`configValue ${
                      regularizationInitialValues.needInOut ? "" : "forNo"
                    }`}
                  >
                    {regularizationInitialValues.needInOut ? "Yes" : "No"}
                  </div>
                </div>
                <div className="configBox" style={{ marginLeft: "2%" }}>
                  <div className="configTxt">
                    Number Of days regularisation is allowed in a month
                  </div>
                  <div className="configValue">
                    {regularizationInitialValues.numberOfRegularizeDays}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isAttenConfigUpdateActive && (
          <div className="attenConfigContainer">
            <div className="attenConfigHeader">
              <h2>Regularisation Configuration</h2>
            </div>
            <div className="attenConfigWrapper">
              <div className="reguConfig">
                <div className="configBox configEditBox">
                  <div className="configTxt">Need In Out?</div>
                  <div className="changeConfigValue">
                    <div>
                      <label class="configContainer">
                        <input
                          type="radio"
                          name="attenAllowFromWeb"
                          checked={regularizationInitialValues.needInOut}
                          onChange={() => {
                            setRegularizationInitialValues({
                              ...regularizationInitialValues,
                              needInOut: true,
                            });
                          }}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <span>Yes</span>
                    </div>
                    <div>
                      <label class="configContainer">
                        <input
                          type="radio"
                          name="attenAllowFromWeb"
                          checked={!regularizationInitialValues.needInOut}
                          onChange={() => {
                            setRegularizationInitialValues({
                              ...regularizationInitialValues,
                              needInOut: false,
                            });
                          }}
                        />
                        <span class="checkmark"></span>
                      </label>
                      <span className="no">No</span>
                    </div>
                  </div>
                </div>
                <div
                  className="configBox configEditBox"
                  style={{ marginLeft: "2%" }}
                >
                  <div className="configTxt">Number Of Regularize Days?</div>
                  <div className="numberOfRegularize">
                    <label>
                      <input
                        type="number"
                        value={
                          regularizationInitialValues.numberOfRegularizeDays
                        }
                        onChange={(e) => {
                          setRegularizationInitialValues({
                            ...regularizationInitialValues,
                            numberOfRegularizeDays: e.target.value,
                          });
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isAttenConfigUpdateActive && (
          <div className="btnContainer">
            <button
              className="button configCancelButton myCancelBtn"
              type="button"
              onClick={() => {
                getRegularizationConfigData();
                setIsAttenConfigUpdateActive(false);
              }}
            >
              Cancel
            </button>
            <button
              className="button configUpdateButton"
              type="submit"
              onClick={() => {
                updateRegularizationConfigData();
              }}
            >
              Update
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export { TimeConfig };
