import React, { useCallback, useState } from "react";
import "./Connect.css";

import SocialUserProfileBackBtnIcon from "../../../../../Assets/SocialUserProfileBackBtnIcon.svg";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import CreatePost from "./CreatePost";
import SharePost from "./SharePost";
import EditSharePost from "./EditSharePost";
import EditMyPost from "./EditMyPost";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

import LikeDislike from "./LikeDislike";
import ShareDetails from "./ShareDetails";
import { setSocialData } from "../../../../../Features/SocialSlice";
import ShowNestedSharedPostDetails from "./ShowNestedSharedPostDetails";
import SharedEmployeeComponent from "./SharedEmployeeComponent";
import UnSharedPostComponent from "./UnSharedPostComponent";
import SharedPostComponent from "./SharedPostComponent";
import NestedSharedPostComponent from "./NestedSharedPostComponent";
import SocialLikeAndDislikeComponent from "./SocialLikeAndDislikeComponent";
import ShowViewsList from "./ShowViewsList";
import SocialPostDetails from "./SocialPostDetails";

const SearchByWords = () => {
  // const [socialData, setSocialData] = useState([]);
  const [connectPostSearchActive, setConnectPostSearchActive] = useState(false);
  const [connectPostSearchTxt, setConnectPostSearchTxt] = useState("");
  const [connectPostSearchData, setConnectPostSearchData] = useState([]);
  const [createPostActive, setCreatePostActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { socialData } = useSelector((state) => state.social);
  const [isSocialPostDeletePopupActive, setIsSocialPostDeletePopupActive] =
    useState(false);
  const [editDeleteSocialPostId, setEditDeleteSocialPostId] = useState(0);
  const [editDeleteSocialPostData, setEditDeleteSocialPostData] = useState();
  const [sharePostActive, setSharePostActive] = useState(false);
  const [
    showNestedSharedPostDetailActive,
    setShowNestedSharedPostDetailActive,
  ] = useState(false);
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [postDataCount, setPostDataCount] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 5,
  });
  const { word } = useParams();
  const [shareDetailsActive, setShareDetailsActive] = useState(false);
  const [postCommentData, setPostCommentData] = useState([
    {
      actions: [],
      checkBoxVisibility: false,
      item: {
        commentId: null,
        commentLike: 0,
        commentReplay: null,
        comments: "",
        commentsCount: 0,
        createdOn: "",
        employeeId: 0,
        id: 0,
        image: "",
        isComment: true,
        locationName: "",
        nameWithPersonNumber: "",
        postId: 0,
        roleName: "",
      },
      projectDelayed: null,
      projectDuration: null,
      projectProgress: 0,
      status: null,
      statusColor: null,
      statusLabel: null,
      tat: [],
    },
  ]);
  const [commentedPost, setCommentedPost] = useState([]);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [isSharePosEditActive, setIsSharePosEditActive] = useState(false);
  const [isMyPosEditActive, setIsMyPosEditActive] = useState(false);
  const [isGreetings, setIsGreetings] = useState(false);
  const [allEmployeeList, setAllEmplyeeList] = useState(false);
  const [viewsPostId, setViewsPostId] = useState(0);
  const [viewsListActive, setViewsListActive] = useState(false);
  const [fullAtachmentData, setFullAtachmentData] = useState([]);
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const dispatch = useDispatch();

  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && socialData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 5);
  }, [loading, socialData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 5)) &&
      !loading &&
      (postDataCount === -1 || socialData?.length < postDataCount)
    ) {
      getData();
    } else if (offset > totaloffset + 5) {
      setOffset(totaloffset + 5);
    }
  }, [offset, totaloffset, loading]);

  useEffect(() => {
    setOffset(0);
  }, []);

  const getEmployeeData = async () => {
    setLoading(true);
    try {
      const result = await getRequest("Social/GetEmployeesImageDropDown");
      setAllEmplyeeList([...allEmployeeList, ...result?.data.textfilters]);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const searchPostByName = async (text) => {
    let data = allEmployeeList?.filter((d, i) => {
      if (d?.name.toLowerCase().includes(text.toLowerCase())) {
        return d;
      }
    });

    setConnectPostSearchData(data);
  };

  const deleteSocialPostById = async () => {
    setLoading(true);
    try {
      const result = await getRequest(
        `SocialPost/Delete/?Id=${editDeleteSocialPostId}`
      );
      await getData(true, true);
      setIsSocialPostDeletePopupActive(false);
      setEditDeleteSocialPostId(0);
      dispatch(
        showToast({
          text: "Post Deleted Successfully",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in deleting post",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getData = async (freshLoad = false, reload = false) => {
    if (!reload) setLoading(true);
    try {
      const result = await postRequest(`SocialPost/AllPostList`, {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: reload
            ? socialData?.length
            : postDataCount === -1 || offset + 5 <= postDataCount
            ? 5
            : postDataCount - offset,
          iDisplayStart: freshLoad || reload ? 0 : offset,
          sSearch: word,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      let listData = [];
      result?.data?.data?.map((item) => {
        listData.push(item?.item);
      });

      if (!reload) {
        setPostDataCount(result?.data?.summary?.allCount);
        setTotalOffset(offset);
      }
      if (freshLoad) {
        dispatch(setSocialData(listData));
      } else {
        dispatch(setSocialData([...socialData, ...listData]));
      }
      setCurrentIndex((currentIndex) => currentIndex + 5);
      setPaginationData({
        ...paginationData,
        total: result?.data?.summary.allCount,
        per_page: paginationData.per_page,
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  useEffect(() => {
    getEmployeeData();
  }, []);
  const moreActivityHandler = useCallback((e) => {
    let moreActivity = document.querySelector(`.connectSearchPostContainer`);
    if (moreActivity && !moreActivity?.contains(e.target)) {
      setConnectPostSearchActive(false);
      if (connectPostSearchTxt === "") {
        setTimeout(() => {
          setConnectPostSearchTxt("");
          setConnectPostSearchData([]);
        }, 3000);
      }
    }
  }, []);
  useEffect(() => {
    document.addEventListener("mouseup", moreActivityHandler);
    return () => {
      document.removeEventListener("mouseup", moreActivityHandler);
    };
  }, [moreActivityHandler]);
  useEffect(() => {
    return () => {
      dispatch(setSocialData([]));
    };
  }, []);
  //  if (loading) return <Loader />;
  // else
  return (
    <>
      <div className="homeEngageMainCenterContainer">
        <div className="postUpdateContainerParent">
          <div
            className="postUpdateContainer"
            style={{
              borderRadius: "0 0 8px 8px",
              padding: "15px",
              margin: "0 3%",
            }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={SocialUserProfileBackBtnIcon}
                alt=""
                style={{ height: "20px", width: "20px" }}
              />
            </IconButton>
            <span style={{ fontSize: "15px", marginLeft: "10px" }}>
              #{word}
            </span>
          </div>
          <div
            className={`connectSearchPostContainer ${
              connectPostSearchActive ? "active" : ""
            }`}
          ></div>
        </div>
        <div className="homeEngagePostContainer">
          {socialData.map((d, i) => {
            return (
              <div className="socialAllPostContainer" key={"social-item-" + i}>
                <div className="engagePostContentHolder">
                  {d?.sharedEmployee !== null && (
                    <SharedEmployeeComponent
                      d={d}
                      getData={getData}
                      setIsSocialPostDeletePopupActive={
                        setIsSocialPostDeletePopupActive
                      }
                      setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                      setIsSharePosEditActive={setIsSharePosEditActive}
                      setIsMyPosEditActive={setIsMyPosEditActive}
                      setViewsPostId={setViewsPostId}
                      setViewsListActive={setViewsListActive}
                      setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                    />
                  )}

                  {d?.sharedPost === null && (
                    <UnSharedPostComponent
                      d={d}
                      getData={getData}
                      setIsSocialPostDeletePopupActive={
                        setIsSocialPostDeletePopupActive
                      }
                      setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                      setIsSharePosEditActive={setIsSharePosEditActive}
                      setIsMyPosEditActive={setIsMyPosEditActive}
                      setViewsPostId={setViewsPostId}
                      setViewsListActive={setViewsListActive}
                      setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                      setFullAtachmentData={setFullAtachmentData}
                      setIsGreetings={setIsGreetings}
                      setCommentedPost={setCommentedPost}
                      // setShowFullAttachmentActive={setShowFullAttachmentActive}
                    />
                  )}
                  {d?.sharedPost && !d?.isNasted && (
                    <SharedPostComponent
                      d={d}
                      getData={getData}
                      setIsSocialPostDeletePopupActive={
                        setIsSocialPostDeletePopupActive
                      }
                      setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                      setIsSharePosEditActive={setIsSharePosEditActive}
                      setIsMyPosEditActive={setIsMyPosEditActive}
                      setViewsPostId={setViewsPostId}
                      setViewsListActive={setViewsListActive}
                      setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                      setFullAtachmentData={setFullAtachmentData}
                      setIsGreetings={setIsGreetings}
                      setCommentedPost={setCommentedPost}
                    />
                  )}
                  {d?.sharedPost && d?.isNasted && (
                    <NestedSharedPostComponent
                      d={d}
                      setFullAtachmentData={setFullAtachmentData}
                      setIsGreetings={setIsGreetings}
                      setCommentedPost={setCommentedPost}
                      // setShowNestedSharedPostDetailActive={
                      //   setShowNestedSharedPostDetailActive
                      // }
                    />
                  )}
                  <SocialLikeAndDislikeComponent
                    d={d}
                    setSharePostData={setSharePostData}
                    setSharePostId={setSharePostId}
                    setLikeId={setLikeId}
                    setTotalLikesWatching={setTotalLikesWatching}
                    setDislikeId={setDislikeId}
                    setTotalDisLikesWatching={setTotalDisLikesWatching}
                    setFullAtachmentData={setFullAtachmentData}
                    setIsGreetings={setIsGreetings}
                    setCommentedPost={setCommentedPost}
                    setShareDetailsActive={setShareDetailsActive}
                    postData={socialData}
                    setPostData={setSocialData}
                    getData={getData}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {loading && <Loader />}
      </div>

      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
      {sharePostActive && (
        <SharePost
          sharePostData={sharePostData}
          sharePostId={sharePostId}
          getData={getData}
          setSharePostActive={setSharePostActive}
        />
      )}
      {isSocialPostDeletePopupActive && (
        <Popup
          firstClassName="deletePostPopupBox1"
          secondClassName="deletePostPopupBox2"
          popupHeading={`Delete Post`}
          content={
            <>
              <div className="socialPostDeleteBody">
                <p>Are you sure want to Permanently remove this Post?</p>
              </div>
              {!loading && (
                <div className="socialPostDeleteBtn">
                  <button onClick={deleteSocialPostById}>Delete</button>
                  <button
                    onClick={() => {
                      setIsSocialPostDeletePopupActive(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              )}
              {loading && (
                <span
                  className="posting-socialpost"
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "10px",
                  }}
                >
                  Deleting...
                </span>
              )}
            </>
          }
          handleClose={() => {
            setIsSocialPostDeletePopupActive(false);
          }}
        />
      )}
      {isSharePosEditActive && (
        <EditSharePost
          editDeleteSocialPostData={editDeleteSocialPostData}
          editDeleteSocialPostId={editDeleteSocialPostId}
          getData={getData}
          setIsSharePosEditActive={setIsSharePosEditActive}
        />
      )}
      {isMyPosEditActive && (
        <EditMyPost
          editDeleteSocialPostData={editDeleteSocialPostData}
          editDeleteSocialPostId={editDeleteSocialPostId}
          setEditDeleteSocialPostData={setEditDeleteSocialPostData}
          getData={getData}
          setIsMyPosEditActive={setIsMyPosEditActive}
        />
      )}
      {createPostActive && (
        <CreatePost
          getData={getData}
          setCreatePostActive={setCreatePostActive}
          createPostActive={createPostActive}
        />
      )}
      <Routes>
        <Route path="details/:postid" element={<SocialPostDetails />} />
      </Routes>
      {showNestedSharedPostDetailActive && (
        <ShowNestedSharedPostDetails
          fullAtachmentData={fullAtachmentData}
          setShowNestedSharedPostDetailActive={
            setShowNestedSharedPostDetailActive
          }
          commentedPost={commentedPost}
          setCommentedPost={setCommentedPost}
          setPostCommentData={setPostCommentData}
          postCommentData={postCommentData}
          getData={getData}
          postData={socialData}
          setPostData={setSocialData}
          isGreetings={isGreetings}
        />
      )}
      {shareDetailsActive && (
        <ShareDetails
          setShareDetailsActive={setShareDetailsActive}
          sharePostId={sharePostId}
          setSharePostId={setSharePostId}
        />
      )}
      {viewsListActive && (
        <ShowViewsList
          viewsPostId={viewsPostId}
          module={"Social"}
          setViewsListActive={setViewsListActive}
          setViewsPostId={setViewsPostId}
        />
      )}
    </>
  );
};
export default SearchByWords;
