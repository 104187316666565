import React, { useState } from "react";
import { FieldArray } from "formik";
import { TableBody, TableRow, Table, TableCell } from "@mui/material";
import { useDispatch } from "react-redux";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { showToast } from "../../../../Features";
import SurveyAnswersDeleteIcon from "../../../../Assets/SurveyAnswersDeleteIcon.svg";
const CreateTaxStructureComp = ({ loading, setInitialValues, formValues }) => {
  const dispatch = useDispatch();

  const pushSubComponent = (index, subDisplayName) => {
    setInitialValues((prev) => {
      return {
        ...formValues, // Copy previous state
        DisplayName: formValues.DisplayName.map((item, i) => {
          if (i === index) {
            // If this is the item we're updating
            return {
              ...item,
              SubDisplayName: [
                ...subDisplayName,
                { SubComponentName: "", SubLimitAmount: "" },
              ],
            };
          }
          return item; // Return unchanged items
        }),
      };
    });
  };

  const popSubComponent = (index, subIndex) => {
    setInitialValues((prev) => {
      return {
        ...formValues,
        DisplayName: formValues.DisplayName.map((item, i) => {
          if (i === index) {
            // If this is the item we're updating
            return {
              ...item,
              SubDisplayName: item.SubDisplayName.filter(
                (_, ind) => ind !== subIndex
              ),
            };
          }
          return item; // Return unchanged items
        }),
      };
    });
  };

  if (loading) return <Loader />;
  else
    return (
      <>
        <div className="formcustom threeColumn createSurveyComp">
          <div>{/* Render your other form fields here */}</div>
        </div>
        <div>
          <div
            style={{
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
              paddingTop: "12px",
            }}
            className="hideSurveyScrollBar"
          >
            <div>
              <Table>
                <TableBody>
                  <TableRow
                    style={{
                      background: "var(--primary-hover)",
                      paddingBottom: "16px",
                    }}
                  >
                    <div
                      style={{
                        display: "grid",

                        gridTemplateColumns: " repeat(2, 1fr)",
                      }}
                    >
                      {" "}
                      <TableCell
                        style={{
                          padding: "0 0 16px 0",
                          borderBottom: "none",
                        }}
                      >
                        <LabelCustom labelName={"Investment Type"} />
                      </TableCell>
                      <TableCell
                        style={{
                          border: "none",
                          padding: "0 0 16px 0",
                          borderBottom: "none",
                        }}
                      >
                        {" "}
                        <LabelCustom labelName={"Permisible Limit"} />
                      </TableCell>
                    </div>

                    <TableCell
                      style={{
                        padding: "0 0 16px 0",
                        borderBottom: "none",
                      }}
                    >
                      <LabelCustom labelName={"Add/Remove"} />
                    </TableCell>
                  </TableRow>
                  <FieldArray name="DisplayName">
                    {(fieldArrayProps) => {
                      const { remove, push, form } = fieldArrayProps;
                      const { values } = form;
                      const { DisplayName } = values;
                      return (
                        <>
                          {DisplayName.map((item, index) => (
                            <React.Fragment key={index}>
                              <TableRow
                                style={{
                                  background: "white",
                                  boxShadow: "0px 2px 10px 0px #0000000F",
                                }}
                              >
                                <TableCell
                                  style={{
                                    borderTop: "none !important",
                                    padding: "0 0 16px 0",
                                    borderBottom: "none",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: " repeat(2, 1fr)",
                                      background: "var(--primary-hover)",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <LabelCustom
                                        labelName={`${index + 1}.`}
                                      />
                                      <InputCustomFormik
                                        name={`DisplayName[${index}].ComponentName`}
                                        placeholder={"Enter component name"}
                                      />
                                    </div>
                                    <InputCustomFormik
                                      name={`DisplayName[${index}].LimitAmount`}
                                      placeholder={"Enter limit amount"}
                                      type={"number"}
                                    />
                                  </div>
                                  <TableCell
                                    style={{
                                      padding: "0 0 16px 0",
                                      borderBottom: "none",
                                    }}
                                  >
                                    {item.SubDisplayName &&
                                      item.SubDisplayName.map(
                                        (subItem, subIndex) => (
                                          <div
                                            key={subIndex}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              paddingLeft: "24px",
                                            }}
                                          >
                                            <LabelCustom
                                              labelName={`${String.fromCharCode(
                                                97 + subIndex
                                              )}.`}
                                            />
                                            <InputCustomFormik
                                              style={{
                                                border: ".75px solid #E6E6E6",
                                              }}
                                              name={`DisplayName[${index}].SubDisplayName[${subIndex}].SubComponentName`}
                                              placeholder={
                                                "Enter subcomponent name"
                                              }
                                            />
                                            <InputCustomFormik
                                              style={{
                                                border: ".75px solid #E6E6E6",
                                              }}
                                              name={`DisplayName[${index}].SubDisplayName[${subIndex}].SubLimitAmount`}
                                              placeholder={
                                                "Enter sublimit amount"
                                              }
                                              type={"number"}
                                            />
                                            <div>
                                              {" "}
                                              <div
                                                type="button"
                                                onClick={() =>
                                                  popSubComponent(
                                                    index,
                                                    subIndex
                                                  )
                                                }
                                              >
                                                <img
                                                  src={SurveyAnswersDeleteIcon}
                                                  alt="SurveyAnswersDeleteIcon"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    <div
                                      className="surveyAddBtnContainer"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems:'center'
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="add-survey-btn"
                                        onClick={() =>
                                          pushSubComponent(
                                            index,
                                            item.SubDisplayName || []
                                          )
                                        }
                                      >
                                        +
                                      </button>
                                      <span>Add sub-component</span>
                                    </div>
                                  </TableCell>
                                </TableCell>

                                <div
                                  style={{
                                    height: "63px",
                                    background: "var(--primary-hover)",
                                    textAlign: "center",
                                    border: "none!",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="remove-btn"
                                    onClick={() =>
                                      DisplayName.length > 1
                                        ? remove(index)
                                        : dispatch(
                                            showToast({
                                              text: "Atleast One Item is Required ",
                                              severity: "error",
                                            })
                                          )
                                    }
                                  >
                                    -
                                  </button>
                                  <button
                                    type="button"
                                    className="add-btn"
                                    onClick={() =>
                                      push({
                                        ComponentName: "",
                                        LimitAmount: "",
                                      })
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </>
                      );
                    }}
                  </FieldArray>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
};

export { CreateTaxStructureComp };
