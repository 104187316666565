import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { FormCustomFormik } from "../../../../../../../Components/FormComponent/FormCustomFormik";
import CreateSurveyComp from "./CreateSurveyComp";
import SelectCategory from "./SelectCategory";
import {
  getRequest,
  postRequestForm,
} from "../../../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../../../Features";
import dateFormat from "dateformat";

const EditSurvey = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const [surveyDetailsData, setSurveyDetailsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    SurveyTitle: "",
    Description: "",
    Upload: null,
    StartOn: "",
    EndOn: "",
    SurveyQuestion:[
      {
        QuestionText: "",
        Answers: [
          { AnswerId: 1, AnswerText: "" },
          {
            AnswerId: 2,
            AnswerText: "",
          },
          {
            AnswerId: 3,
            AnswerText: "",
          },
          {
            AnswerId: 4,
            AnswerText: "",
          },
        ],
        AllowMultipleAnswer: false,
      },
    ],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSurveyDetails = async () => {
    setLoading(true);
    try {
      const result = await getRequest("Survey/Details/" + id);
      setSurveyDetailsData(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const convertEndDate = (d) => {
    const endOnString = surveyDetailsData?.survey?.endOn;
    const endDate = endOnString ? new Date(endOnString) : null;

    if (endDate instanceof Date && !isNaN(endDate)) {
      // Date is valid, proceed with formatting
      const formattedEndDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      );

      return formattedEndDate;
    } else {
    }
  };
  const convertStartDate = (d) => {
    const endOnString = surveyDetailsData?.survey?.startOn;
    const endDate = endOnString ? new Date(endOnString) : null;

    if (endDate instanceof Date && !isNaN(endDate)) {
      // Date is valid, proceed with formatting
      const formattedEndDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      );

      return formattedEndDate;
    } else {
    }
  };

  const getItems = (surveyDetailsDataArg) => {
    let returnArray = [];
    surveyDetailsDataArg?.survey?.surveyQuestions?.map((question) =>
      returnArray.push({
        QuestionText: question?.questionText? question?.questionText:"",
        Answers: question?.surveyQuestionAnswers.map((answer, ind) => ({
          AnswerId: ind,
          AnswerText: answer.answerText ? answer.answerText : "",
        })),
        AllowMultipleAnswer: question.allowMultipleAnswer? question.allowMultipleAnswer : null,
      })
    );
    return returnArray;
  };
  useEffect(() => {
    setInitialValues({
      SurveyTitle: surveyDetailsData?.survey?.surveyTitle ? surveyDetailsData?.survey?.surveyTitle :"",
      Description: surveyDetailsData?.survey?.description ? surveyDetailsData?.survey?.description:"",
      Upload: surveyDetailsData?.survey?.attachment ? surveyDetailsData?.survey?.attachment :null,
      StartOn:surveyDetailsData?.survey?.startOn ? convertStartDate(surveyDetailsData?.survey?.startOn) : null,
      EndOn: surveyDetailsData?.survey?.endOn? convertEndDate(surveyDetailsData?.survey?.endOn):null,
      SurveyQuestion: getItems(surveyDetailsData),
    })

  }, [surveyDetailsData])
  
  

  const editSurveyPost = async (values) => {
    setLoading(true);
    let result = null;
    try {
      if (typeof values?.Upload === "object") {
        result = await postRequestForm("Survey/Edit", {
          id: id,
          ...values,
          Category: surveyDetailsData?.survey?.category,
          CategoryType: surveyDetailsData?.survey?.categoryType,
          SubCategoryType: surveyDetailsData?.survey?.subCategoryType ?? null,
        });
      } else {
        result = await postRequestForm("Survey/Edit", {
          id: id,
          SurveyTitle: values?.SurveyTitle,
          Description: values?.Description,
          SurveyQuestion: values?.SurveyQuestion,
          EndOn: dateFormat(values?.EndOn, "yyyy-mm-dd "),
          StartOn: dateFormat(values?.StartOn, "yyyy-mm-dd"),
          attachment: surveyDetailsData?.survey?.attachment,
          Category: surveyDetailsData?.survey?.category,
          CategoryType: surveyDetailsData?.survey?.categoryType,
          SubCategoryType: surveyDetailsData?.survey?.subCategoryType ?? null,
        });
      }
      navigate(-1);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Survey`;
    getSurveyDetails();
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const validationSchema = Yup.object({
    SurveyTitle: Yup.string().required("SurveyTitle Is required"),
    StartOn: Yup.string().required("StartOn Is required"),
    EndOn: Yup.string().required("EndOn Is required"),
    Description: Yup.string().required("Description Is required"),
  });

  const onSubmit = (values) => {
    editSurveyPost(values);
  };

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      styles={{ minWidth: "80%" }}
      loading={loading}
      FormHeading={state?.type === "survey" ? "Edit Survey" : "Edit Poll"}
      ButtonLabel={state?.type === "survey" ? "Edit Survey" : "Edit Poll"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialValues}
      returnPage={returnPage}
      propsDiv={
        <CreateSurveyComp
          setInitialValues={setInitialValues}
          formValues={formValues}
          loading={loading}
          type={state?.type}
        />
      }
    />
  );
};

export default EditSurvey;
