import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { FormCustomFormik } from "../../../../../../../Components/FormComponent/FormCustomFormik";
import CreateSurveyComp from "./CreateSurveyComp";
import SelectCategory from "./SelectCategory";

const CreateSurvey = ({ setReRefreshProp }) => {
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [daata, setData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `PeopleSol - Create Survey`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const togglePopup = (formData) => {
    setData(formData);
    setIsOpen((prev) => !prev);
  };
  const getItems = () => {
    let returnArray = [];
    returnArray = [
      {
        QuestionText: "",
        Answers: [
          { AnswerId: 1, AnswerText: "" },
          {
            AnswerId: 2,
            AnswerText: "",
          },
          {
            AnswerId: 3,
            AnswerText: "",
          },
          {
            AnswerId: 4,
            AnswerText: "",
          },
        ],
        AllowMultipleAnswer: false,
      },
    ];

    return returnArray;
  };
  const [initialValues, setInitialValues] = useState({
    SurveyTitle: "",
    Description: "",
    Upload: null,
    StartOn: "",
    EndOn: "",
    SurveyQuestion: getItems(),
  });
  const validationSchema = Yup.object({
    SurveyTitle: Yup.string().required("SurveyTitle Is required"),
    StartOn: Yup.string().required("StartOn Is required"),
    EndOn: Yup.string().required("EndOn Is required"),
    Description: Yup.string()
      .max(499, "Description cannot be more than 500 characters")
      .required("Description Is required"),
    SurveyQuestion: Yup.array().of(
      Yup.object().shape({
        QuestionText: Yup.string().required("QuestionText is required"),
        Answers: Yup.array().of(
          Yup.object().shape({
            AnswerText: Yup.string().required("AnswerText is required"),
          })
        ),
      })
    ),
  });

  const onSubmit = (values) => {
    togglePopup(values);
  };

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <>
      <FormCustomFormik
        styles={{ minWidth: "80%" }}
        chooseFormValues={chooseFormValues}
        className={"surveyCreateFormik"}
        loading={loading}
        FormHeading={state?.type === "survey" ? "Create Survey" : "Create Poll"}
        ButtonLabel={state?.type === "survey" ? "Create Survey" : "Create Poll"}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialvalue={initialValues}
        returnPage={returnPage}
        propsDiv={
          <CreateSurveyComp
            loading={loading}
            setInitialValues={setInitialValues}
            formValues={formValues}
            type={state?.type}
          />
        }
      />
      {isOpen && (
        <SelectCategory
          daata={daata}
          setIsOpen={setIsOpen}
          type={state?.type}
          setReRefreshProp={setReRefreshProp}
        />
      )}
    </>
  );
};

export default CreateSurvey;
