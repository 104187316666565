import React from 'react'
import community from "../../../../Assets/community.png";
export const Community = ({setCommunity}) => {
  return (
    <div className='blockListsContainer' style={{position:'relative'}}>
    <div className='blockListsWrapper' style={{height:"100%"}}>
  
        <div className="chatListContainer" style={{marginTop:'24px',alignItems:'center'}}>
        <img src={community} alt='community' width={223} height={157}/>
        <div className='createComm'>Create a new community</div>
        <div className='createCommbody'>Bring together a neighborhood, school or more.
Create topic-based groups for members, and
easily send them admin announcements.</div>
        </div>
        <div className="createFootCust" style={{justifyContent:'center',background:'none',borderTop:'1px solid var(--primary-hover)',position:'absolute'}}>
            <button className="button primaryButton" type="submit" style={{justifyContent:'center'}}>
             <span style={{marginLeft:'12px'}}> Get started</span> <span> <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.22852 5.625C0.883338 5.625 0.603516 5.34518 0.603516 5C0.603516 4.65482 0.883338 4.375 1.22852 4.375V5.625ZM18.7432 4.55806C18.9873 4.80214 18.9873 5.19786 18.7432 5.44194L14.7657 9.41942C14.5216 9.6635 14.1259 9.6635 13.8818 9.41942C13.6377 9.17534 13.6377 8.77961 13.8818 8.53553L17.4173 5L13.8818 1.46447C13.6377 1.22039 13.6377 0.82466 13.8818 0.580583C14.1259 0.336505 14.5216 0.336505 14.7657 0.580583L18.7432 4.55806ZM1.22852 4.375L18.3012 4.375V5.625L1.22852 5.625V4.375Z" fill="white"/>
</svg>
</span>
            </button>
          </div>
    </div>
</div>
  )
}
