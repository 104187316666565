import React from "react";
import { useNavigate } from "react-router-dom";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import {
  getContentLength,
  getFormattedCount,
  getUserInitials,
} from "../../../../../Utilities/socialAllFunctions";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import dateFormat from "dateformat";
import SocialPostViewsEyeIcon from "../../../../../Assets/SocialPostViewsEyeIcon.svg";
import SocialPostViewsEyeColorIcon from "../../../../../Assets/SocialPostViewsEyeColorIcon.svg";
import { IconButton } from "@mui/material";
import PostActivityContainer from "./PostActivityContainer";
import ContentSocialDisplay from "./ContentSocialDisplay";
import { useState } from "react";

function CommunitySharedEmployeePost({
  d,
  setViewsListActive,
  setViewsPostId,
  LoggedInUser,
  setEditDeleteSocialPostId,
  setSharePostData,
  setIsSocialPostDeletePopupActive,
  setEditDeleteSocialPostData,
  setIsSharePosEditActive,
  setIsMyPosEditActive,
}) {
  const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();
  const [viewsHovered, setIsViewsHovered] = useState(false);
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  return (
    <>
      <div className="sharedAndPostTypeContainer">
        <div
          className="postSharedContainer"
          style={{ boxShadow: "none", padding: "0px 0px" }}
        >
          {d?.postType === "team" ? (
            <div
              className="leftPostHeaderContainer"
              onClick={() => {
                handleUserProfileClick(d?.sharedBy);
              }}
            >
              <img
                src={SocialPostTeamIcon}
                alt=""
                style={{
                  height: "50px",
                  width: "50px",
                  // marginRight: "18px",
                }}
              />
              <div
                onClick={() => {
                  handleUserProfileClick(d?.sharedEmployee?.id);
                }}
                className={`${
                  d?.sharedEmployee?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePicShareCommunity  "
                }`}
              >
                {!d?.sharedEmployee?.image ? (
                  getUserInitials(
                    getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className={`socialProfilePic ${
                      d?.postType !== "organisation"
                        ? "prTeamCommunityShared"
                        : ""
                    }`}
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      d?.sharedEmployee?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">Team</label>
                <label className="dateAndViewsContainer">
                  Post from{" "}
                  <span className="nameofposter">
                    {getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)}{" "}
                  </span>
                  <span className="postHrTime">
                    {" "}
                    • {dateFormat(d?.lastUpdatedOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                  <article
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewsListActive(true);
                      setViewsPostId(d?.id);
                    }}
                    onMouseOver={() => setIsViewsHovered(d?.id)}
                    onMouseLeave={() => setIsViewsHovered(null)}
                  >
                    <img
                      src={
                        viewsHovered === d?.id
                          ? SocialPostViewsEyeColorIcon
                          : SocialPostViewsEyeIcon
                      }
                      alt=""
                      className="socialViewsImgContainer"
                    />
                    <span
                      className={
                        viewsHovered === d?.id
                          ? "postColorViews"
                          : `socialViewsSpanContainer`
                      }
                    >
                      {d?.views < 10
                        ? "0" + d?.views
                        : getFormattedCount(d?.views)}{" "}
                      Views
                    </span>
                  </article>
                </label>
              </div>
            </div>
          ) : (
            <div
              className="leftPostHeaderContainer"
              onClick={() => {
                handleUserProfileClick(d?.sharedBy);
              }}
            >
              <div
                onClick={() => {
                  handleUserProfileClick(d?.sharedEmployee?.id);
                }}
                className={`${
                  d?.sharedEmployee?.image
                    ? "socialProfilePicContainer"
                    : "socialCustomProfilePic"
                }`}
              >
                {!d?.sharedEmployee?.image ? (
                  getUserInitials(
                    getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)
                  )
                ) : (
                  <img
                    className="socialProfilePic"
                    src={
                      process.env.REACT_APP_.BASE_URL +
                      profileImages +
                      d?.sharedEmployee?.image
                    }
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = ProfileAvatar;
                    }}
                  />
                )}
              </div>

              <div className="postMainTextContainer">
                <label className="postMainName">
                  {getDisplayName(d?.sharedEmployee?.nameWithPersonNumber)}
                </label>
                <label>{d?.roleName}</label>
                <label className="dateAndViewsContainer">
                  {d?.sharedEmployee?.locationName}{" "}
                  <span className="postHrTime">
                    {" "}
                    • {dateFormat(d?.lastUpdatedOn, "hh:MM TT | dd mmm yyyy")}
                  </span>
                  <article
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewsListActive(true);
                      setViewsPostId(d?.id);
                    }}
                    onMouseOver={() => setIsViewsHovered(d?.id)}
                    onMouseLeave={() => setIsViewsHovered(null)}
                  >
                    <img
                      src={
                        viewsHovered === d?.id
                          ? SocialPostViewsEyeColorIcon
                          : SocialPostViewsEyeIcon
                      }
                      alt=""
                      className="socialViewsImgContainer"
                    />
                    <span
                      className={
                        viewsHovered === d?.id
                          ? "postColorViews"
                          : `socialViewsSpanContainer`
                      }
                    >
                      {d?.views < 10
                        ? "0" + d?.views
                        : getFormattedCount(d?.views)}{" "}
                      Views
                    </span>
                  </article>
                </label>
              </div>
            </div>
          )}

          {d?.sharedEmployee?.nameWithPersonNumber ===
            LoggedInUser.nameWithPersonNumber && (
            <div>
              <IconButton>
                <PostActivityContainer
                  onClick={() => {
                    setEditDeleteSocialPostId(d?.id);
                    setSharePostData(d);
                  }}
                  setIsSocialPostDeletePopupActive={
                    setIsSocialPostDeletePopupActive
                  }
                  setIsSharePosEditActive={setIsSharePosEditActive}
                  setIsMyPosEditActive={setIsMyPosEditActive}
                  isMyPosEditActive={false}
                  isSharePosEditActive={true}
                  setEditDeleteSocialPostId={setEditDeleteSocialPostId}
                  editDeleteSocialPostId={d?.id}
                  editDeleteSocialPostData={d}
                  setEditDeleteSocialPostData={setEditDeleteSocialPostData}
                />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      {d?.sharedContent !== null && (
        <div className="postBodyTextContainer">
          {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent ? (
            <ContentSocialDisplay content={getContentLength(d?.content)} />
          ) : (
            <ContentSocialDisplay content={d?.content?.trim()} />
          )}
          {d?.content?.split(/\r\n|\r|\n/).length > 4 && !showContent && (
            <span
              onClick={() => {
                setShowContent(true);
              }}
            >
              ...View More
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default CommunitySharedEmployeePost;
