/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateHolidaysNew } from "./CreateHolidaysNew";
import dateFormat from "dateformat";

const CreateHolidays = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();

  const [formValues, setFormValues] = useState(null);
  const [location, setLocation] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Holiday/Details/${data}`);
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Holidays `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequestForm("Holiday/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm("Holiday/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getInitalAnswerValues = () => {
    let returnArray = [];
    data
      ? detailsform?._holiday?.items?.map((item) => {
          return returnArray.push({
            Title: item.title,
            Upload: `Holidays/${item?.attachment}`,
            Date: new Date(item.date),
            Type: item.type,
          });
        })
      : (returnArray = [{ Title: "", Upload: "", Date: "", Type: "" }]);

    return returnArray;
  };

  const initialValue = {
    Name: data ? detailsform?._holiday?.name : "",
    Locations: data
      ? detailsform?.locations?.map((item) => {
          return item.locationId;
        })
      : [],
    Items: getInitalAnswerValues(),
  };

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const validationSchema = Yup.object({
    Name: Yup.string()
      .min(2)
      .max(49, " Name must be less than 50 characters")
      .required("Please Enter Name "),
    // Locations: Yup.array()
    //   .min(1, `Please Enter Location `)
    //   .required(" required"),
    Items: Yup.array().of(
      Yup.object().shape({
        // Title: Yup.string()
        //   .min(2)
        //   .max(49, "Title must be less than 50 characters")
        //   .required("Please Enter Name"),
        Date: Yup.string().required("Please Enter Date"),
        Type: Yup.string().required("Please Enter Type "),
        Upload: Yup.mixed()
          .nullable()
          .notRequired()
          .test(
            "Fichier taille",
            "File Size should be less than 1 mb",
            (value) => {
              // //console.log("val", value);
              return (
                !value ||
                (value && value.size <= 2048 * 2048) ||
                typeof value !== "object"
              );
            }
          )
          .test(
            "format",
            "File Format is Incorrect",
            (value) =>
              !value ||
              (value && SUPPORTED_FORMATS.includes(value.type)) ||
              typeof value !== "object"
          ),
      })
    ),
  });
  const onSubmit = (values) => {
    if (loading) return;
    const formData = new FormData();
    formData.append("id", data ? data : 0);
    formData.append("Name", values?.Name ? values?.Name : null);
    // formData.append("IsSelected", true);
    // formData.append(`Locations[1].IsSelected`, true);
    {
      data
        ? values?.Locations?.length === 0
          ? location?.map((item, index) => {
              return formData.append(
                `Locations[${index}].locationId`,
                item?.value
              );
            })
          : values?.Locations?.map((item, index) => {
              return formData.append(`Locations[${index}].locationId`, item);
            })
        : values?.Locations?.length === 0
        ? location?.map((item, index) => {
            return formData.append(
              `Locations[${index}].locationId`,
              item?.value
            );
          })
        : values?.Locations?.map((item, index) => {
            return formData.append(`Locations[${index}].locationId`, item);
          });
    }
    {
      data
        ? values?.Items?.map((item, index) => {
            return formData.append(`Items[${index}].Title`, item?.Title);
          })
        : values?.Items?.map((item, index) => {
            return formData.append(`Items[${index}].Title`, item?.Title);
          });
    }
    {
      data
        ? values?.Items?.map((item, index) => {
            return formData.append(
              `Items[${index}].Date`,
              dateFormat(item?.Date, "yyyy-mm-dd")
            );
          })
        : values?.Items?.map((item, index) => {
            return formData.append(
              `Items[${index}].Date`,
              dateFormat(item?.Date, "yyyy-mm-dd")
            );
          });
    }
    {
      data
        ? values?.Items?.map((item, index) => {
            return formData.append(`Items[${index}].Type`, item?.Type);
          })
        : values?.Items?.map((item, index) => {
            return formData.append(`Items[${index}].Type`, item?.Type);
          });
    }
    {
      data &&
        detailsform?._holiday?.items?.map((item, index) => {
          return formData.append(`Items[${index}].Id`, item?.id);
        });
    }

    {
      data
        ? values?.Items?.map((item, index) => {
            if (typeof item?.Upload === "object")
              return formData.append(`Items[${index}].Upload`, item?.Upload);
            return null;
          })
        : values?.Items?.map((item, index) => {
            if (typeof item?.Upload === "object")
              return formData.append(`Items[${index}].Upload`, item?.Upload);
            return null;
          });
    }

    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      loading={loading}
      styles={{ maxWidth: "85%", width: "fit-content" }}
      FormHeading={data ? "Edit Holidays" : "Create Holidays"}
      ButtonLabel={data ? "Update Holidays" : "Create Holidays"}
      onSubmit={onSubmit}
      chooseFormValues={chooseFormValues}
      enableReinitialize
      validationSchema={validationSchema}
      initialvalue={initialValue}
      returnPage={returnPage}
      propsDiv={
        <CreateHolidaysNew
          formValues={formValues}
          data={detailsform}
          location={location}
          setLocation={setLocation}
        />
      }
    />
  );
};

export { CreateHolidays };
