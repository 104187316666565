/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import { format } from "date-fns";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { Loader } from "../../../../Components/FormComponent/Loader";
import UploadImageDetail from "../../../../Components/UploadDetail/UploadImageDetail";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Verify } from "./Verify";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../Features/badgeUtils";
import ManualEntry from "../ManualEntry";
const ReimbursmentDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [selListId, setSelListId] = useState(false);

  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  useEffect(() => {
    document.title = `PeopleSol - Reimbursement Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [selId, setSelId] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, color, id, listId) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setSelListId(listId);

    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [resultData, setResultData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `Expenses/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data);
      getTravelDetails(result?.data?.data?.travalRequestId);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionList.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.summary?.actionList[0]?.id,
              approve,
              result.data?.summary?.actionList[0]?.color
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionList[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getTravelDetails = async (id) => {
    try {
      const result = await getRequest(
        `TravelHospitalityRequests/Details/${id}`
      );

      setResultData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const clickHandlerFunction = (id, type, color) => {
    const listId = getIdFromDynamicUrl(currentUrl);
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Ready for Release" &&
      type !== "Export" &&
      type !== "Confirm"
    ) {
      togglePopup(type, color, id, listId);
    } else {
      PostApRejHol(type, id, "Remark", listId);
    }
  };
  const PostApRejHol = async (type, id, Remark, listId, manualentry) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "Export"
      ) {
        await getRequest(`Expenses/${type}/${id}`);
      } else if (type === "Ready for Release") {
        await getRequest(`Expenses/HRApprove/${id}`);
      } else if (type === "Approve By Audit Team") {
        await getRequest(`Expenses/ApproveAudit?Remarks=${Remark}&ID=${id}`);
      } else if (type === "Reject By Audit Team") {
        await getRequest(`Expenses/RejectAudit?Remarks=${Remark}&ID=${id}`);
      } else if (type === "Over Budget") {
        await getRequest(
          `Expenses/overbudget?ID=${listId}&remarks=${Remark}&actionID=${id}`
        );
      } else if (type === "Reject") {
        await getRequest(`Expenses/Reject/${id}/${Remark}`);
      } else if (type === "Manual Entry") {
        await getRequest(
          `Expenses/manualentrybyrm?ID=${listId}&remarks=${Remark}&manualentry=${manualentry}&actionID=${id}`
        );
      } else if (type === "Manual Entry By HOD") {
        await getRequest(
          `Expenses/Manualentrybyhod?ID=${listId}&remarks=${Remark}&manualentry=${manualentry}&actionID=${id}`
        );
      } else if (type === "Resubmit Receipt") {
        await getRequest(`Expenses/SubmitReciept?ID=${id}&Remarks=${Remark}`);
      } else if (type === "Reject By Finance Team") {
        await getRequest(`Expenses/RejectFinance?Remarks=${Remark}&ID=${id}`);
      } else if (type === "Confirm") {
        await postRequest(`Expenses/Confirm?Id=${id}`);
      } else if (type === "Send for Correction") {
        await getRequest(`Expenses/SentForCorrection/${id}/${Remark}`);
      } else {
        await getRequest(`Expenses/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  const settledAmount =
    data?.data?.advanceRequest?.advanceSalary !== undefined
      ? data?.data?.expenseDetails?.reduce(
          (sum, card) => sum + card.approvedAmount,
          0
        ) - data?.data?.advanceRequest?.advanceSalary
      : data?.data?.expenseDetails?.reduce(
          (sum, card) => sum + card.approvedAmount,
          0
        );
  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Reimbursement Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          <>
            {tabIndex === 0 &&
              (loading ? (
                <div className="noDataFound">
                  <Loader />
                </div>
              ) : (
                <div
                  className="detailsEReq"
                  style={{ flexDirection: "column" }}
                >
                  <div className="headingForm"> Request Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Reference Number"}
                      Details={data?.data?.referenceNo}
                    />
                    <Details
                      detailTitle={"Status"}
                      Details={
                        <div
                          style={{
                            backgroundColor: getStatusColor(
                              data?.data?.status
                            )?.split(",")?.[0],
                            color: getStatusColor(data?.data?.status)?.split(
                              ","
                            )?.[1],
                            cursor: "default",
                            padding: "6px",
                            borderRadius: "6px",
                          }}
                        >
                          {data?.summary?.statusLabel}
                        </div>
                      }
                    />
                    <Details
                      detailTitle={"Travel Advance Amount Taken"}
                      Details={
                        data?.data?.advanceRequest?.advanceSalary != null
                          ? data?.data?.advanceRequest?.advanceSalary.toFixed(2)
                          : "-"
                      }
                    />
                    <Details
                      detailTitle={"Claimed Amount"}
                      Details={data?.data?.expenseDetails
                        ?.reduce((sum, card) => sum + card.amount, 0)
                        .toFixed(2)}
                    />
                    {(data?.data?.status === "Ready for Release" ||
                      data?.data?.status === "Released") && (
                      <>
                        <Details
                          detailTitle={"Approved Claimed Amount"}
                          Details={data?.data?.expenseDetails
                            ?.reduce(
                              (sum, card) => sum + card.approvedAmount,
                              0
                            )
                            .toFixed(2)}
                        />
                        <Details
                          detailTitle={"Final Amount To Be Received"}
                          Details={settledAmount.toFixed(2)}
                        />
                      </>
                    )}
                    <Details
                      detailTitle={"Reimbursment Submission Date"}
                      Details={
                        data?.data?.submissionDate
                          ? format(
                              new Date(data?.data?.submissionDate),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : "-"
                      }
                    />
                    <Details
                      detailTitle={"Last Updated On"}
                      Details={
                        data?.data?.lastUpdatedOn
                          ? format(
                              new Date(data?.data?.lastUpdatedOn),
                              "dd/MM/yyyy hh:mm a"
                            )
                          : "-"
                      }
                    />
                    <Details
                      detailTitle={"Travel Request ID"}
                      Details={
                        data?.data?.travalRequestId
                          ? data?.data?.travalRequestId
                          : "-"
                      }
                    />
                  </div>
                  <div className="headingForm"> Requester's Details</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Requester Name"}
                      Details={`${data?.data?.employee?.name} ${data?.data?.employee?.lastName}`}
                    />
                    <Details
                      detailTitle={"Requester's Manager"}
                      Details={data?.data?.employee?.rO_Name}
                    />
                    <Details
                      detailTitle={"Requester Designation"}
                      Details={data?.data?.employee?.designation}
                    />
                    <Details
                      detailTitle={"Requester Department"}
                      Details={data?.data?.employee?.department}
                    />
                    <Details
                      detailTitle={"Requester Email"}
                      Details={data?.data?.employee?.email}
                    />
                    <Details
                      detailTitle={"Contact Number"}
                      Details={data?.data?.employee?.personalPhoneNumber}
                    />
                    <Details
                      detailTitle={"Location"}
                      Details={data?.data?.employee?.locationName}
                    />
                  </div>

                  <div className="headingForm" style={{ width: "100%" }}>
                    Travel Journey Details
                  </div>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#EFF9FA",
                      borderRadius: "12px",
                      padding: "0px 0px 10px 0px",
                    }}
                  >
                    <div className="detailInner">
                      {resultData?.travellingdetails?.map((person, index) => (
                        <div style={{ width: "100%" }}>
                          {/* <div
                                        // className="headingForm"
                                        style={{
                                          width: "100%",
                                          fontFamily: "Poppins",
                                          fontWeight: "500",
                                          fontSize: "15px",
                                          padding: "4px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "#1296b0",
                                          height: "24px",
                                          margin: "10px 0px 9px 0px",
                                        }}
                                      >
                                        Journey Details
                                      </div> */}
                          <div
                            className="headingForm"
                            style={{ width: "100%", borderBottom: "none" }}
                          >
                            Travel Journey #{index + 1}
                          </div>
                          <div
                            style={{
                              // borderBottom: "1px solid #E6E6E6",
                              margin: "10px 0px",
                            }}
                            className="detailInner"
                          >
                            <Details
                              detailTitle={"Travelling Date"}
                              Details={person?.fromDate}
                            />
                            <Details
                              detailTitle={"Form Location"}
                              Details={person?.pickupLocation}
                            />
                            <Details
                              detailTitle={"Booking Note"}
                              Details={person?.bookingnote}
                            />
                            <Details
                              detailTitle={"To Location"}
                              Details={person?.dropLocation}
                            />
                          </div>
                          <div style={{ padding: "0px 12px" }}>
                            {data?.data?.expenseDetails
                              ?.filter(
                                (itz) => itz?.travellingdetailsid === person?.id
                              )
                              ?.map((item, index) => {
                                return (
                                  <>
                                    <div>
                                      <div
                                        style={{
                                          borderBottom:
                                            "1px solid rgb(230, 230, 230)",
                                          padding: "10px 0px",
                                          width: "100%",
                                        }}
                                      >
                                        <label
                                          className="repeatedHeading"
                                          style={{
                                            fontSize: "16px",
                                          }}
                                        >
                                          {index + 1}. Expense Type :
                                        </label>
                                        <label
                                          className="repeatedHeading"
                                          style={{
                                            color: "black",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {item?.expenseTypes}
                                        </label>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        // borderBottom: "1px solid #E6E6E6",
                                        margin: "10px 0px",
                                      }}
                                      className="detailInner"
                                    >
                                      {(item?.expenseTypes === "Taxi" ||
                                        item?.expenseTypes ===
                                          "Self Vehicle") && (
                                        <div className="detailInner">
                                          <Details
                                            detailTitle={"Date"}
                                            Details={format(
                                              new Date(item?.date),
                                              "dd/MM/yyyy"
                                            )}
                                          />
                                          <Details
                                            detailTitle={"From Location"}
                                            Details={item?.fromlocation}
                                          />
                                          <Details
                                            detailTitle={"To Location"}
                                            Details={item?.tolocation}
                                          />
                                          <Details
                                            detailTitle={"Category"}
                                            Details={
                                              item?.travellingCategoriesDetails
                                                ?.category
                                            }
                                          />
                                          <Details
                                            detailTitle={
                                              item?.expenseTypes ===
                                              "Self Vehicle"
                                                ? "Vehicle Type"
                                                : "Type"
                                            }
                                            Details={
                                              item?.travellingCategoriesDetails
                                                ?.type
                                            }
                                          />
                                          <Details
                                            detailTitle={"Total KM"}
                                            Details={item?.totalkm}
                                          />
                                          <Details
                                            detailTitle={"Cost per KM"}
                                            Details={item?.cost}
                                          />
                                          {/* <Details
                                            detailTitle={"Claim Amount"}
                                            Details={item?.Amount}
                                          /> */}
                                          <Details
                                            detailTitle={"Is Receipt Available"}
                                            Details={item?.isReceiptAvailable}
                                          />
                                          <Details
                                            detailTitle={"IsReceiptAvailable"}
                                            Details={item?.isReceiptAvailable}
                                          />
                                          {item?.isReceiptAvailable ===
                                            "Yes" && (
                                            <Details
                                              detailTitle={"Receipt"}
                                              Details={item?.attachment}
                                            />
                                          )}
                                          <Details
                                            detailTitle={"Reason"}
                                            Details={item?.justification}
                                          />
                                          <Details
                                            detailTitle={"Remarks"}
                                            Details={item?.remarks}
                                          />
                                        </div>
                                      )}
                                      {(item?.expenseTypes === "Food" ||
                                        item?.expenseTypes === "Gift" ||
                                        item?.expenseTypes === "Gadget") && (
                                        <div className="detailInner">
                                          <Details
                                            detailTitle={"Date"}
                                            Details={format(
                                              new Date(item?.date),
                                              "dd/MM/yyyy"
                                            )}
                                          />

                                          <Details
                                            detailTitle={"Category"}
                                            Details={
                                              item?.travellingCategoriesDetails
                                                ?.category
                                            }
                                          />

                                          {item?.expenseTypes === "Food" && (
                                            <Details
                                              detailTitle={"Total Days"}
                                              Details={item?.totaldays}
                                            />
                                          )}
                                          {item?.expenseTypes !== "Food" && (
                                            <Details
                                              detailTitle={"Total Items"}
                                              Details={item?.totalitems}
                                            />
                                          )}
                                          <Details
                                            detailTitle={"Cost per day"}
                                            Details={item?.cost}
                                          />
                                          {/* <Details
                                            detailTitle={"Claim Amount"}
                                            Details={item?.Amount}
                                          /> */}
                                          <Details
                                            detailTitle={"Is Receipt Available"}
                                            Details={item?.isReceiptAvailable}
                                          />
                                          <Details
                                            detailTitle={"IsReceiptAvailable"}
                                            Details={item?.isReceiptAvailable}
                                          />
                                          {item?.isReceiptAvailable ===
                                            "Yes" && (
                                            <Details
                                              detailTitle={"Receipt"}
                                              Details={item?.attachment}
                                            />
                                          )}
                                          <Details
                                            detailTitle={"Reason"}
                                            Details={item?.justification}
                                          />
                                          <Details
                                            detailTitle={"Remarks"}
                                            Details={item?.remarks}
                                          />
                                        </div>
                                      )}
                                      {(item?.expenseTypes === "Metro" ||
                                        item?.expenseTypes === "Local Train" ||
                                        item?.expenseTypes === "Local Bus") && (
                                        <div className="detailInner">
                                          <Details
                                            detailTitle={"Date"}
                                            Details={format(
                                              new Date(item?.date),
                                              "dd/MM/yyyy"
                                            )}
                                          />
                                          <Details
                                            detailTitle={"Form Location"}
                                            Details={item?.fromlocation}
                                          />
                                          <Details
                                            detailTitle={"To Location"}
                                            Details={item?.tolocation}
                                          />
                                          <Details
                                            detailTitle={"Category"}
                                            Details={
                                              item?.travellingCategoriesDetails
                                                ?.category
                                            }
                                          />
                                          <Details
                                            detailTitle={"Total Tickets"}
                                            Details={item?.totaltickets}
                                          />
                                          <Details
                                            detailTitle={"Cost per ticket"}
                                            Details={item?.cost}
                                          />
                                          {/* <Details
                                            detailTitle={"Claim Amount"}
                                            Details={item?.Amount}
                                          /> */}
                                          <Details
                                            detailTitle={"Is Receipt Available"}
                                            Details={item?.isReceiptAvailable}
                                          />
                                          <Details
                                            detailTitle={"IsReceiptAvailable"}
                                            Details={item?.isReceiptAvailable}
                                          />
                                          {item?.isReceiptAvailable ===
                                            "Yes" && (
                                            <Details
                                              detailTitle={"Receipt"}
                                              Details={item?.attachment}
                                            />
                                          )}
                                          <Details
                                            detailTitle={"Reason"}
                                            Details={item?.justification}
                                          />
                                          <Details
                                            detailTitle={"Remarks"}
                                            Details={item?.remarks}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {resultData?._request?.isLinkedTravelHotel && (
                    <>
                      <div className="headingForm" style={{ width: "100%" }}>
                        Hotel Booking Details{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#EFF9FA",
                          borderRadius: "12px",
                          padding: "0px 0px 10px 0px",
                        }}
                      >
                        <div>
                          {resultData?.hoteldetail?.map((person, index) => (
                            <div>
                              {/* <div
                                            // className="headingForm"
                                            style={{
                                              width: "100%",
                                              fontFamily: "Poppins",
                                              fontWeight: "500",
                                              fontSize: "15px",
                                              padding: "4px",
                                              display: "flex",
                                              alignItems: "center",
                                              color: "#1296b0",
                                              height: "24px",
                                              margin: "10px 0px 9px 0px",
                                            }}
                                          >
                                            Hotel Booking Details
                                          </div> */}
                              <div
                                className="headingForm"
                                style={{
                                  width: "100%",
                                  borderBottom: "none",
                                }}
                              >
                                Hotel Booked #{index + 1}
                              </div>
                              <div
                                style={{
                                  // borderBottom: "1px solid #E6E6E6",
                                  margin: "10px 0px",
                                }}
                                className="detailInner"
                              >
                                {/* <Details
                                  detailTitle={"Hotel Category "}
                                  Details={resultData?.category_name_hotel}
                                /> */}
                                <Details
                                  detailTitle={"Check In Time"}
                                  Details={
                                    person?.checkInTime
                                      ? format(
                                          new Date(person?.checkInTime),
                                          "dd/MM/yyyy hh:mm a"
                                        )
                                      : "-"
                                  }
                                />
                                <Details
                                  detailTitle={"Location"}
                                  Details={person?.location}
                                />
                                <Details
                                  detailTitle={"Check Out Time"}
                                  Details={
                                    person?.checkOutTime
                                      ? format(
                                          new Date(person?.checkOutTime),
                                          "dd/MM/yyyy hh:mm a"
                                        )
                                      : "-"
                                  }
                                />
                                <Details
                                  detailTitle={"Preferred Hotel"}
                                  Details={person?.preferHotel}
                                />
                              </div>
                              <div style={{ padding: "0px 12px" }}>
                                {data?.data?.expenseDetails
                                  ?.filter(
                                    (itz) =>
                                      itz?.travellingdetailsid === person?.id
                                  )
                                  ?.map((item, index) => {
                                    return (
                                      <>
                                        <div>
                                          <div
                                            style={{
                                              borderBottom:
                                                "1px solid rgb(230, 230, 230)",
                                              padding: "10px 0px",
                                              width: "100%",
                                            }}
                                          >
                                            <label
                                              className="repeatedHeading"
                                              style={{ fontSize: "16px" }}
                                            >
                                              {index + 1}. Expense Type :
                                            </label>
                                            <label
                                              className="repeatedHeading"
                                              style={{
                                                color: "black",
                                                fontSize: "16px",
                                              }}
                                            >
                                              {item?.expenseTypes}
                                            </label>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            // borderBottom: "1px solid #E6E6E6",
                                            margin: "10px 0px",
                                          }}
                                          className="detailInner"
                                        >
                                          {(item?.expenseTypes === "Taxi" ||
                                            item?.expenseTypes ===
                                              "Self Vehicle") && (
                                            <div className="detailInner">
                                              <Details
                                                detailTitle={"Date"}
                                                Details={format(
                                                  new Date(item?.date),
                                                  "dd/MM/yyyy"
                                                )}
                                              />
                                              <Details
                                                detailTitle={"Form Location"}
                                                Details={item?.fromlocation}
                                              />
                                              <Details
                                                detailTitle={"To Location"}
                                                Details={item?.tolocation}
                                              />
                                              <Details
                                                detailTitle={"Category"}
                                                Details={
                                                  item
                                                    ?.travellingCategoriesDetails
                                                    ?.category
                                                }
                                              />
                                              <Details
                                                detailTitle={
                                                  item?.expenseTypes ===
                                                  "Self Vehicle"
                                                    ? "Vehicle Type"
                                                    : "Type"
                                                }
                                                Details={
                                                  item
                                                    ?.travellingCategoriesDetails
                                                    ?.type
                                                }
                                              />
                                              <Details
                                                detailTitle={"Total KM"}
                                                Details={item?.totalkm}
                                              />
                                              <Details
                                                detailTitle={"Cost per KM"}
                                                Details={item?.cost}
                                              />
                                              {/* <Details
                                                detailTitle={"Claim Amount"}
                                                Details={item?.Amount}
                                              /> */}
                                              <Details
                                                detailTitle={
                                                  "Is Receipt Available"
                                                }
                                                Details={
                                                  item?.isReceiptAvailable
                                                }
                                              />
                                              <Details
                                                detailTitle={
                                                  "IsReceiptAvailable"
                                                }
                                                Details={
                                                  item?.isReceiptAvailable
                                                }
                                              />
                                              {item?.isReceiptAvailable ===
                                                "Yes" && (
                                                <Details
                                                  detailTitle={"Receipt"}
                                                  Details={item?.attachment}
                                                />
                                              )}
                                              <Details
                                                detailTitle={"Reason"}
                                                Details={item?.justification}
                                              />
                                              <Details
                                                detailTitle={"Remarks"}
                                                Details={item?.remarks}
                                              />
                                            </div>
                                          )}
                                          {(item?.expenseTypes === "Food" ||
                                            item?.expenseTypes === "Gift" ||
                                            item?.expenseTypes ===
                                              "Gadget") && (
                                            <div className="detailInner">
                                              <Details
                                                detailTitle={"Date"}
                                                Details={format(
                                                  new Date(item?.date),
                                                  "dd/MM/yyyy"
                                                )}
                                              />

                                              <Details
                                                detailTitle={"Category"}
                                                Details={
                                                  item
                                                    ?.travellingCategoriesDetails
                                                    ?.category
                                                }
                                              />

                                              {item?.expenseTypes ===
                                                "Food" && (
                                                <Details
                                                  detailTitle={"Total Days"}
                                                  Details={item?.totaldays}
                                                />
                                              )}
                                              {item?.expenseTypes !==
                                                "Food" && (
                                                <Details
                                                  detailTitle={"Total Items"}
                                                  Details={item?.totalitems}
                                                />
                                              )}
                                              <Details
                                                detailTitle={"Cost per day"}
                                                Details={item?.cost}
                                              />
                                              {/* <Details
                                                detailTitle={"Claim Amount"}
                                                Details={item?.Amount}
                                              /> */}
                                              <Details
                                                detailTitle={
                                                  "Is Receipt Available"
                                                }
                                                Details={
                                                  item?.isReceiptAvailable
                                                }
                                              />
                                              <Details
                                                detailTitle={
                                                  "IsReceiptAvailable"
                                                }
                                                Details={
                                                  item?.isReceiptAvailable
                                                }
                                              />
                                              {item?.isReceiptAvailable ===
                                                "Yes" && (
                                                <Details
                                                  detailTitle={"Receipt"}
                                                  Details={item?.attachment}
                                                />
                                              )}
                                              <Details
                                                detailTitle={"Reason"}
                                                Details={item?.justification}
                                              />
                                              <Details
                                                detailTitle={"Remarks"}
                                                Details={item?.remarks}
                                              />
                                            </div>
                                          )}
                                          {(item?.expenseTypes === "Metro" ||
                                            item?.expenseTypes ===
                                              "Local Train" ||
                                            item?.expenseTypes ===
                                              "Local Bus") && (
                                            <div className="detailInner">
                                              <Details
                                                detailTitle={"Date"}
                                                Details={format(
                                                  new Date(item?.date),
                                                  "dd/MM/yyyy"
                                                )}
                                              />
                                              <Details
                                                detailTitle={"Form Location"}
                                                Details={item?.fromlocation}
                                              />
                                              <Details
                                                detailTitle={"To Location"}
                                                Details={item?.tolocation}
                                              />
                                              <Details
                                                detailTitle={"Category"}
                                                Details={
                                                  item
                                                    ?.travellingCategoriesDetails
                                                    ?.category
                                                }
                                              />
                                              <Details
                                                detailTitle={"Total Tickets"}
                                                Details={item?.totaltickets}
                                              />
                                              <Details
                                                detailTitle={"Cost per ticket"}
                                                Details={item?.cost}
                                              />
                                              {/* <Details
                                                detailTitle={"Claim Amount"}
                                                Details={item?.Amount}
                                              /> */}
                                              <Details
                                                detailTitle={
                                                  "Is Receipt Available"
                                                }
                                                Details={
                                                  item?.isReceiptAvailable
                                                }
                                              />
                                              <Details
                                                detailTitle={
                                                  "IsReceiptAvailable"
                                                }
                                                Details={
                                                  item?.isReceiptAvailable
                                                }
                                              />
                                              {item?.isReceiptAvailable ===
                                                "Yes" && (
                                                <Details
                                                  detailTitle={"Receipt"}
                                                  Details={item?.attachment}
                                                />
                                              )}
                                              <Details
                                                detailTitle={"Reason"}
                                                Details={item?.justification}
                                              />
                                              <Details
                                                detailTitle={"Remarks"}
                                                Details={item?.remarks}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="headingForm"> Claimed Details</div>
                  <div className="detailInner">
                    <table
                      className="tabless tabless-bordered"
                      style={{ width: "100%" }}
                    >
                      <thead
                        style={{ backgroundColor: "var(--primary-hover)" }}
                      >
                        <tr>
                          <th width="3%">TotalAmount</th>
                          <th width="3%">Paid By Company</th>
                          <th width="3%">Advance Amount (Rs)</th>
                          <th width="3%">Claim Amount (Rs)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            {Number(resultData?.total_final_budget) +
                              Number(data?.data?.totalclaimed)}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {Number(resultData?.total_final_budget)}
                          </td>
                          <td style={{ textAlign: "center" }}>-</td>
                          <td style={{ textAlign: "center" }}>
                            {data?.data?.totalclaimed}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="headingForm"> Reporting Manager's Review</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Updated Claim Amount"}
                      Details={data?.data?.amountrm}
                    />
                    <Details
                      detailTitle={"Remarks"}
                      Details={data?.data?.remarksrm}
                    />
                  </div>
                  <div className="headingForm"> Functional HOD's Review</div>
                  <div className="detailInner">
                    <Details
                      detailTitle={"Updated Claim Amount"}
                      Details={data?.data?.amounthod}
                    />
                    <Details
                      detailTitle={"Remarks"}
                      Details={data?.data?.remarkshod}
                    />
                  </div>

                  {data?.summary?.actionList?.length > 0 ? (
                    <div
                      className="createFootDetails"
                      style={{ background: "white !important" }}
                    >
                      {data?.summary?.actionList.map((item) => (
                        <div>
                          <div
                            className="button secondaryButton"
                            onClick={() =>
                              clickHandlerFunction(
                                item?.id,
                                item?.type,
                                item?.color
                              )
                            }
                            style={{
                              background:
                                item?.type === "Export"
                                  ? "var(--primary)"
                                  : item?.color,
                              color: "white",
                            }}
                          >
                            {item?.type}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {isOpen && (
                    <Popup
                      popupHeading={typeAction}
                      content={
                        <>
                          {typeAction === "Verify" ? (
                            <div></div>
                          ) : typeAction === "Manual Entry" ||
                            typeAction === "Manual Entry By HOD" ? (
                            <ManualEntry
                              data={PostApRejHol}
                              type={typeAction}
                              listid={selListId}
                              id={selId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          ) : typeAction === "Resubmit Receipt" ? (
                            <ManualEntry
                              data={PostApRejHol}
                              type={typeAction}
                              listid={selListId}
                              id={selId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          ) : typeAction === "Update Reciept" ? (
                            <ManualEntry
                              data={PostApRejHol}
                              type={typeAction}
                              listid={selListId}
                              id={selId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          ) : (
                            <Remark
                              mod={"reimbursementOverBudget"}
                              data={PostApRejHol}
                              type={typeAction}
                              id={selId}
                              listid={selListId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          )}
                        </>
                      }
                      handleClose={togglePopup}
                    />
                  )}
                </div>
              ))}
            {tabIndex === 1 && (
              <>
                <div className="tabularMasterDetailsPageTabBodyContainer">
                  <div style={{ display: "flex", width: "100%" }}>
                    <TimeLineData
                      id={getIdFromDynamicUrl(currentUrl)}
                      url={"Expenses"}
                      requestNumber={data?.data?.referenceNo}
                      module={"Expense Request"}
                    />
                  </div>
                </div>
              </>
            )}

            {tabIndex === 2 && (
              <>
                <div
                  className="tabularMasterDetailsPageTabBodyContainer"
                  style={{ flexGrow: "initial" }}
                >
                  <Notifications
                    id={getIdFromDynamicUrl(currentUrl)}
                    module={"Expense Request"}
                    //module={data?.summary?.module}
                  />
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export { ReimbursmentDetails };
