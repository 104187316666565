import React, { useCallback, useState } from "react";
import "./Connect.css";
import "./Greetings.css";
import BirthdayPostTypeTag from "../../../../../Assets/SocialBirthdayTag.svg";
import SocialPostBirthdayIcon from "../../../../../Assets/SocialPostBirthdayIcon.svg";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useSelector } from "react-redux";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { Route, Routes, useNavigate } from "react-router-dom";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import dateFormat from "dateformat";
import GreetingsPostDetails from "./GreetingsPostDetails";
import { setBirthdayData } from "../../../../../Features/SocialSlice";
import { getContentLength } from "../../../../../Utilities/socialAllFunctions";
import LikeDislike from "./LikeDislike";
import SocialPostDetails from "./SocialPostDetails";
import ContentSocialDisplay from "./ContentSocialDisplay";

import SocialPostNotFoundIcon from "../../../../../Assets/SocialPostNotFoundIcon.svg";
import GreetingsLikeAndDislikeComponent from "./GreetingsLikeAndDislikeComponent";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
const Birthday = () => {
  const [dataFound, setDataFound] = useState(true);
  const [loading, setLoading] = useState(false);
  const { birthdayData } = useSelector((state) => state.social);
  const [showContent, setShowContent] = useState(false);
  const [showFullAttachmentActive, setShowFullAttachmentActive] =
    useState(false);

  const [commentedPost, setCommentedPost] = useState([]);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(false);
  const [dislikeId, setDislikeId] = useState(false);
  const dispatch = useDispatch();
  const [postDataCount, setPostDataCount] = useState(-1);
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });
  const scrollEventHandler = useCallback(() => {
    let innerHeight = window.innerHeight;
    let scrollTop = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollTop;
    let scrollHeight = document.querySelector(
      ".homeEngageMainCenterContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && birthdayData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 2);
  }, [loading, birthdayData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".homeEngageMainCenterContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 500);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 2)) &&
      !loading &&
      (postDataCount === -1 || birthdayData?.length < postDataCount)
    ) {
      getData();
    } else if (offset > totaloffset + 2) {
      setOffset(totaloffset + 2);
    }
  }, [offset, totaloffset]);

  useEffect(() => {
    setOffset(0);
  }, []);

 

  const getData = async () => {
    setLoading(true);
    try {
      const result = await postRequest(`SocialPost/GreetingPostList`, {
        createdOn: null,
        type: "greeting",
        SubType: "birthday",
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength:
            postDataCount === -1 || offset + 2 <= postDataCount
              ? 2
              : postDataCount - offset,
          iDisplayStart: offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      if (!result?.data?.isSuccess || result?.data?.data?.length <= 0) {
        setDataFound(false);
        dispatch(setBirthdayData([]));
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          listData.push(item?.item);
        });
        setPostDataCount(result?.data?.summary?.allCount);
        setTotalOffset(offset);
        dispatch(setBirthdayData([...birthdayData, ...listData]));
        setCurrentIndex((currentIndex) => currentIndex + 2);
        setPaginationData({
          ...paginationData,
          total: result?.data?.summary.allCount,
          per_page: paginationData.per_page,
        });
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };


  const navigate = useNavigate();
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };
  useEffect(() => {
    return () => dispatch(setBirthdayData([]));
  }, []);
  return (
    <>
      <div className="homeEngageMainCenterContainer">
        <div className="homeEngagePostContainer">
          {birthdayData.map((d, i) => {
            return (
              <div className="socialAllPostContainer" key={"social-item-" + i}>
                <div className="engagePostContentHolder">
                  <div>
                    <div className="greetingTagAndHeaderContainer">
                      <div>
                        <div className="leftPostHeaderContainer">
                          <div className="greetingsPostMainTextContainer">
                            <label className="postMainName">
                              {d?.companyName}
                            </label>
                            <label>{d?.roleName}</label>
                            <label>
                              {d?.locationName}{" "}
                              <span className="postHrTime">
                                {" "}
                                •{" "}
                                {dateFormat(
                                  d?.createdOn,
                                  "hh:MM TT | dd mmm yyyy"
                                )}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="birthdayTypeContainer">
                        <span>Birthday</span>
                        <article></article>
                        <img src={BirthdayPostTypeTag} alt="" />
                      </div>
                    </div>
                    <div className="greetingBodyContainer">
                      <div className="postBodyTextContainer">
                        <p
                          className="greetingsNamedSpan"
                          onClick={() => {
                            handleUserProfileClick(d?.mentionEmployee?.id);
                          }}
                        >
                          Happy Birthday{" "}
                          {getDisplayName(
                            d?.mentionEmployee?.nameWithPersonNumber
                          )}
                        </p>
                        {d?.content?.split(/\r\n|\r|\n/).length > 4 &&
                        !showContent ? (
                          <ContentSocialDisplay
                            content={getContentLength(d?.content)}
                          />
                        ) : (
                          <ContentSocialDisplay content={d?.content?.trim()} />
                        )}
                        {d?.content?.split(/\r\n|\r|\n/).length > 4 &&
                          !showContent && (
                            <span
                              onClick={() => {
                                setShowContent(true);
                              }}
                            >
                              ...View More
                            </span>
                          )}
                      </div>
                      <div
                        className={"greetingsImgContainer"}
                        onClick={() => {
                          setCommentedPost(d);
                          setShowFullAttachmentActive(true);
                        }}
                      >
                        <div className="greetingsImgWrapper">
                          <img
                            src={SocialPostBirthdayIcon}
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                          />
                          <div className="greetingsBirthdayContent">
                            <div>
                              <img
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.mentionEmployee?.image
                                }
                                alt=""
                              />
                            </div>
                            <h4>
                              {getDisplayName(
                                d?.mentionEmployee?.nameWithPersonNumber
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <GreetingsLikeAndDislikeComponent
                    d={d}
                    setLikeId={setLikeId}
                    setTotalLikesWatching={setTotalLikesWatching}
                    setDislikeId={setDislikeId}
                    setTotalDisLikesWatching={setTotalDisLikesWatching}
                    setCommentedPost={setCommentedPost}
                    postData={birthdayData}
                    setPostData={setBirthdayData}
                    getData={getData}
                    setShowFullAttachmentActive={setShowFullAttachmentActive}
                  />
                </div>
              </div>
            );
          })}
          {!dataFound && birthdayData?.length <= 0 && (
            <div className="noPostfoundContainer">
              <div className="noPostfoundWrapper">
                <img src={SocialPostNotFoundIcon} alt="" />
                <h5>No Post Found</h5>
                <p>No one has posted something in social</p>
              </div>
            </div>
          )}
        </div>
        {loading && <Loader />}
      </div>

      {showFullAttachmentActive && (
        <GreetingsPostDetails
          fullAtachmentData={"birthday"}
          setShowFullAttachmentActive={setShowFullAttachmentActive}
          commentedPost={commentedPost}
          setCommentedPost={setCommentedPost}
          getData={getData}
          postData={birthdayData}
          setPostData={setBirthdayData}
        />
      )}

      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
      <Routes>
        <Route path="details/:postid*" element={<SocialPostDetails />} />
      </Routes>
    </>
  );
};
export { Birthday };
