import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { Details } from "./Details";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Timeline } from "./TimeLine";
import Nodata from "../../../../Assets/Nodata.svg";
import { Notifications } from "./Notifications";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { getBadgeCount } from "../../../../Features/badgeUtils";
import { useSelector } from "react-redux";
const DetailsPage = () => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [selId, setSelId] = useState(false);
  const closeDetails = () => {
    returnPage();
  };

  const { state } = useLocation();
  const returnUrl = state?.returnUrl;
  const returnPage = () => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      //let url = currentUrl.substring(0, currentUrl.lastIndexOf("/"));
      //navigate(url.substring(0, url.lastIndexOf("/")));
      navigate(-1);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setSelId(id);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);
  // console.log(" value2", value2, approve);
  // console.log(" LoggedInUser", LoggedInUser);

  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `ManpowerIndents/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data);
      if (approve) {
        if (
          LoggedInUser.id === value2 &&
          result.data?.data?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.data?.actionlist[0]?.color,
              result.data?.data?.actionlist[0]?.id
            );
          } else
            PostApRejHol(
              approve,
              result.data?.data?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "approve" ||
        type === "Cancel" ||
        type === "Release"
      ) {
        await postRequest(`ManpowerIndents/${type}?Id=${id}`);
      } else if (type === "Mark as Closed") {
        await postRequest(`ManpowerIndents/Close?Id=${id}&Remarks=${Remark}`);
      } else {
        await postRequest(`ManpowerIndents/${type}?Id=${id}&Remarks=${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoggedInUser.id]);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Requisition Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Details
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Timeline
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Notifications
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {data && (
              <>
                {tabIndex === 0 && (
                  <>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {data?.data != null ? (
                          <div className="reqD">
                            <div className="headingForm">Position Details</div>
                            <div className="detailInner">
                              <Details
                                detailTitle={"Request Number"}
                                Details={
                                  data?.data?.manpowerIndent?.requestNumber
                                }
                              />
                              <Details
                                detailTitle={"Requisition Status"}
                                Details={
                                  <div
                                    style={{
                                      backgroundColor: getStatusColor(
                                        data?.data?.status
                                      )?.split(",")?.[0],
                                      color: getStatusColor(
                                        data?.data?.status
                                      )?.split(",")?.[1],
                                      cursor: "default",
                                      padding: "6px",
                                      borderRadius: "6px",
                                    }}
                                  >
                                    {data?.data?.status}
                                  </div>
                                }
                              />
                              <Details
                                detailTitle={"Position Role"}
                                Details={
                                  data?.data?.manpowerIndent
                                    ?.vacanctDesigination
                                }
                              />
                              <Details
                                detailTitle={"Position Level"}
                                Details={data?.data?.managementLevels}
                              />
                              <Details
                                detailTitle={"Position Location"}
                                Details={
                                  data?.data?.manpowerIndent?.vacanctLocation
                                }
                              />
                              <Details
                                detailTitle={"Position Department"}
                                Details={data?.data?.reportingManagerDepartment}
                              />
                              <Details
                                detailTitle={"Will Report to"}
                                Details={data?.data?.reportingManagerName}
                              />
                              <Details
                                detailTitle={"Expected Date of Joining"}
                                Details={
                                  data?.data?.manpowerIndent?.expectedDoj
                                    ? format(
                                        new Date(
                                          data?.data?.manpowerIndent?.expectedDoj
                                        ),
                                        "dd/MM/yyyy"
                                      )
                                    : null
                                }
                              />
                              <Details
                                detailTitle={"Proposed Annual CTC"}
                                Details={
                                  data?.data?.manpowerIndent?.proposedCtc
                                    ? `${data?.data?.manpowerIndent?.proposedCtc} Lakhs`
                                    : null
                                }
                              />
                              <Details
                                detailTitle={"Number of Open Positions"}
                                Details={
                                  data?.data?.manpowerIndent?.numberOfPositions
                                }
                              />
                              <Details
                                detailTitle={"Minimun Work Experience"}
                                Details={
                                  data?.data?.manpowerIndent?.releventExpirience
                                }
                              />

                              <Details
                                detailTitle={"Minimun Education Level"}
                                Details={
                                  data?.data?.manpowerIndent?.educationLevel
                                }
                              />
                              <Details
                                detailTitle={
                                  "Required Education Specialization"
                                }
                                Details={
                                  data?.data?.manpowerIndent?.specialization
                                }
                              />
                              <Details
                                detailTitle={"Required Functional Skills"}
                                Details={
                                  data?.data?.manpowerIndent?.skilsRequired
                                }
                              />
                              <Details
                                detailTitle={"Required Technical Skills"}
                                Details={
                                  data?.data?.manpowerIndent?.technicalEducation
                                }
                              />
                              <Details
                                detailTitle={"Preferred Industry"}
                                Details={
                                  data?.data?.manpowerIndent?.prefferdIndustry
                                }
                              />
                            </div>
                            <div className="headingForm">Job Description</div>
                            <div className="detailInner">
                              <Details
                                detailTitle={"Job Description"}
                                Details={
                                  data?.data?.manpowerIndent?.jobDescription
                                }
                              />
                              <Details
                                detailTitle={"Upload Job Description"}
                                Details={
                                  <a
                                    href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ManpowerIndents/${data?.data?.manpowerIndent?.uploads}`}
                                    target="blank"
                                  >
                                    {data?.data?.manpowerIndent?.uploads}
                                  </a>
                                }
                              />
                            </div>

                            <div className="headingForm">
                              Reason for Vacancy
                            </div>
                            <div className="detailInner">
                              <Details
                                detailTitle={"Reason for Vacancy"}
                                Details={
                                  data?.data?.manpowerIndent?.reasonForVacancy
                                }
                              />
                            </div>

                            {data?.data?.manpowerIndent?.resignatingEmployee ? (
                              <>
                                <div className="headingForm">
                                  Resignation/Termination Employee Details
                                </div>

                                <div className="detailInner">
                                  {data?.data?.manpowerIndent?.resignatingEmployee?.map(
                                    (item) => (
                                      <>
                                        <Details
                                          detailTitle={"Name"}
                                          Details={item.name}
                                        />
                                        <Details
                                          detailTitle={"Email ID"}
                                          Details={item.email}
                                        />
                                      </>
                                    )
                                  )}
                                </div>
                              </>
                            ) : null}

                            <div className="headingForm">
                              Internal Reference & Assignment
                            </div>
                            <div className="detailInner">
                              <Details
                                detailTitle={"Internal Reference Acceptable"}
                                Details={
                                  data?.data?.manpowerIndent
                                    ?.intenalArrangementNeeded
                                }
                              />
                              <Details
                                detailTitle={"Assesment Required"}
                                Details={
                                  data?.data?.manpowerIndent
                                    ?.isAssessmentRequired
                                }
                              />
                            </div>
                            {data?.data?.manpowerIndent?.kpiMpiTemplate
                              ?.length > 0 && (
                              <>
                                <div className="headingForm">Position KPI</div>

                                <table
                                  className="tabless tabless-bordered"
                                  style={{
                                    margin: "0px 18px 0 18px",
                                    overflow: "auto",
                                  }}
                                >
                                  <thead
                                    style={{
                                      backgroundColor: "var(--primary-hover)",
                                    }}
                                  >
                                    <tr>
                                      <td>S.no</td>
                                      <td>Objective</td>
                                      <td>KPI Name</td>
                                      <td>Start Date</td>
                                      <td>Expected Date of Achievement</td>
                                      <td>KPI Threshold</td>
                                      <td>Weightage Percentage</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data?.data?.manpowerIndent?.kpiMpiTemplate?.map(
                                      (item, index) => {
                                        return (
                                          <tr className="trStyle">
                                            <td>{index + 1}</td>
                                            <td>{item?.objective}</td>
                                            <td>{item?.kpiname}</td>
                                            <td>
                                              {item?.startDate
                                                ? format(
                                                    new Date(item?.startDate),
                                                    "dd/MM/yyyy"
                                                  )
                                                : null}
                                            </td>
                                            <td>
                                              {item?.expectedDateofAchievement
                                                ? format(
                                                    new Date(
                                                      item?.expectedDateofAchievement
                                                    ),
                                                    "dd/MM/yyyy"
                                                  )
                                                : null}
                                            </td>
                                            <td>{item?.threshold}</td>
                                            <td>{item?.weightage}</td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </>
                            )}

                            <div className="headingForm">Facilities</div>
                            <div className="detailInner">
                              {data?.data?.manpowerIndent?.manpowerIndentFacilities?.map(
                                (item) => {
                                  return (
                                    <Details
                                      detailTitle={item.facilityName}
                                      Details={item.value}
                                    />
                                  );
                                }
                              )}
                            </div>
                            {data?.data?.manpowerIndent?.joiningBuddy?.length >
                            0 ? (
                              <>
                                <div className="headingForm">Joining Buddy</div>
                                <div className="detailInner">
                                  {data?.data?.manpowerIndent?.joiningBuddy?.map(
                                    (item) => (
                                      <>
                                        <Details
                                          detailTitle={"Name"}
                                          Details={item.name}
                                        />
                                        <Details
                                          detailTitle={"Email"}
                                          Details={item.email}
                                        />
                                        <Details
                                          detailTitle={"Department"}
                                          Details={item.department}
                                        />
                                        <Details
                                          detailTitle={"Designation"}
                                          Details={item.designation}
                                        />
                                      </>
                                    )
                                  )}
                                </div>
                              </>
                            ) : null}
                            <div className="headingForm"> Indenter Details</div>
                            <div className="detailInner">
                              {data?.data?.manpowerIndent?.indenter.map(
                                (item) => (
                                  <>
                                    <Details
                                      detailTitle={"Name"}
                                      Details={item.name}
                                    />
                                    <Details
                                      detailTitle={"Email ID"}
                                      Details={item.email}
                                    />
                                    <Details
                                      detailTitle={"Department"}
                                      Details={item.department}
                                    />
                                    <Details
                                      detailTitle={"Designation"}
                                      Details={item.designation}
                                    />
                                  </>
                                )
                              )}
                            </div>
                            {data?.data?.actionlist.length > 0 ? (
                              <div
                                className="createFootDetails"
                                style={{ background: "white !important" }}
                              >
                                {data?.data?.actionlist.map((item) => (
                                  <div>
                                    <div
                                      className="button secondaryButton"
                                      onClick={() => {
                                        item.type !== "Approve" &&
                                        item.type !== "Release" &&
                                        item.type !== "Cancel"
                                          ? togglePopup(
                                              item?.type,
                                              item?.color,
                                              item?.id
                                            )
                                          : PostApRejHol(item?.type, item?.id);
                                      }}
                                      style={{
                                        background: item?.color,
                                        color: "white",
                                      }}
                                    >
                                      {item?.type}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                            {isOpen && (
                              <Popup
                                popupHeading={typeAction}
                                content={
                                  <>
                                    <Remark
                                      data={PostApRejHol}
                                      type={typeAction}
                                      id={selId}
                                      color={colorAction}
                                      handleClose={togglePopup}
                                    />
                                  </>
                                }
                                handleClose={togglePopup}
                              />
                            )}
                          </div>
                        ) : (
                          <div className="noDatastyle">
                            <img
                              src={Nodata}
                              alt="No data"
                              style={{ height: "150px", width: "250px" }}
                            />
                            <h4>{"No Data Found"}</h4>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <Timeline
                          id={getIdFromDynamicUrl(currentUrl)}
                          module={data?.data?.module}
                          requestNumber={
                            data?.data?.manpowerIndent?.requestNumber
                          }
                        />
                        {/* <Comments id={getIdFromDynamicUrl(currentUrl)} module={data?.data?.module} requestNumber={
                        data?.data?.manpowerIndent?.requestNumber
                      } /> */}
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={getIdFromDynamicUrl(currentUrl)}
                        //module={data?.data?.module}
                        module={"Manpower Indent Request"}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsPage };
