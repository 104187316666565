import React, { useState } from "react";

function VideoThumbnailGenerator({ fileVideo }) {
  const [thumbnail, setThumbnail] = useState(null);

  const generateThumbnail = (file) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    video.src = url;
    video.controls = true;
    video.addEventListener("loadeddata", () => {
      video.currentTime = 5;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      const imageDataURL = canvas.toDataURL("image/png");
      setThumbnail(imageDataURL);
      //   URL.revokeObjectURL(url);
    });
    video.load();
  };

  const file = fileVideo;
  if (file && file.type.startsWith("video/")) {
    generateThumbnail(file);
  }
  console.log("object thumbnail", thumbnail);
  return (
    <div>{thumbnail && <img src={thumbnail} alt="Video Thumbnail" />}</div>
  );
}

export default VideoThumbnailGenerator;
