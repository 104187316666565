import React, { useState } from "react";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import starWhite from "../../../Assets/starWhite.svg";
import starYellow from "../../../Assets/starYellow.svg";
import doubleTickGreen from "../../../Assets/doubleTickGreen.svg";
import DropdownArrowChatMesssageWhite from "../../../Assets/DropdownArrowChatMesssageWhite.svg";
import playButtonSvg from "../../../Assets/playButtonSvg.svg";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import { scrollFunc } from "../ChatInterface";

const PhotosAndVideos = ({
  message,
  messageClass,
  setPopupOpen,
  popupOpen,
  setReplyData,
  setForwardData,
  toggleBookMark,
  deleteMessage,
  LoggedInUser,
  messageType,
  isBundled = true,
  ViewFullscreen,
}) => {
  return (
    <>
      {message?.replyingToMessageId ? (
        <div
          style={
            messageClass === "sent"
              ? {
                  backgroundColor: "var(--primary)",
                  borderRadius: "6px 0px 6px 6px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }
              : {
                  backgroundColor: "var(--primary)",
                  borderRadius: "0px 6px 6px 6px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }
          }
        >
          <div
            className="message-content "
            style={
              messageClass === "sent"
                ? {
                    display: "flex",
                    backgroundColor: "#0E788D",
                    borderRadius: "6px 0px 6px 6px",
                    position: "relative",
                    maxWidth: "100%",
                    marginBottom: "5px",
                  }
                : {
                    display: "flex",
                    borderRadius: "0px 6px 6px 6px",
                    position: "relative",
                    maxWidth: "100%",
                    marginBottom: "5px",
                  }
            }
          >
            <div
              style={{
                width: "98%",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              {isBundled && (
                <p
                  id={`messageContent${message.id}`}
                  style={
                    message?.replyingToMessage?.content?.split(",")?.length > 2
                      ? {
                          overflowWrap: "break-word",
                          width: "312px",
                          height: "229px",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                          margin: "0px  -13px 4px -2px",
                        }
                      : {
                          overflowWrap: "break-word",
                          width: "312px",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-start",
                          margin: "0px  -13px 4px -2px",
                        }
                  }
                >
                  {message?.replyingToMessage?.content?.split(",")?.length > 2
                    ? message?.replyingToMessage?.content
                        ?.split(",")
                        ?.slice(0, 4)
                        ?.map((item, index) => {
                          if (
                            item?.includes("mp4") ||
                            item?.includes("webm") ||
                            item?.includes("ogv")
                          ) {
                            return (
                              <>
                                <div
                                  className="mediaCoverChatBundled cursor"
                                  onClick={(e) => {
                                    scrollFunc(message?.replyingToMessage?.id);
                                    e.stopPropagation();
                                  }}
                                >
                                  <span
                                    style={{
                                      position: "relative",
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <video
                                      style={{
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                        objectFit: "contain",
                                        borderRadius: "6px",
                                      }}
                                      src={item}
                                    ></video>
                                    <img
                                      style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                      src={playButtonSvg}
                                      alt=""
                                    />
                                  </span>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div
                                  className="mediaCoverChatBundled cursor"
                                  onClick={(e) => {
                                    scrollFunc(message?.replyingToMessage?.id);
                                    e.stopPropagation();
                                  }}
                                  // onClick={() => {
                                  //   ViewFullscreen(
                                  //     message?.replyingToMessage?.content?.split(
                                  //       ","
                                  //     ),
                                  //     message?.replyingToMessage.caption,
                                  //     index
                                  //   );
                                  // }}
                                >
                                  <img
                                    style={{
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                      objectFit: "contain",
                                      backgroundColor: "white",
                                      borderRadius: "6px",
                                    }}
                                    src={item}
                                    alt="Message"
                                  />
                                </div>
                              </>
                            );
                          }
                        })
                    : message?.replyingToMessage?.content
                        ?.split(",")
                        ?.slice(0, 4)
                        ?.map((item, index) => {
                          if (
                            item?.includes("mp4") ||
                            item?.includes("webm") ||
                            item?.includes("ogv")
                          ) {
                            return (
                              <>
                                <div
                                  className="mediaCoverChat cursor"
                                  onClick={(e) => {
                                    scrollFunc(message?.replyingToMessage?.id);
                                    e.stopPropagation();
                                  }}
                                  // onClick={() => {
                                  //   ViewFullscreen(
                                  //     message?.replyingToMessage?.content?.split(
                                  //       ","
                                  //     ),
                                  //     message?.replyingToMessage.caption,
                                  //     index
                                  //   );
                                  // }}
                                >
                                  <span
                                    style={{
                                      position: "relative",
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    <video
                                      style={{
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                        objectFit: "contain",
                                        borderRadius: "6px",
                                      }}
                                      src={item}
                                    ></video>
                                    <img
                                      style={{
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)",
                                      }}
                                      src={playButtonSvg}
                                      alt=""
                                    />
                                  </span>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div
                                  className="mediaCoverChat cursor"
                                  onClick={(e) => {
                                    scrollFunc(message?.replyingToMessage?.id);
                                    e.stopPropagation();
                                  }}
                                  // onClick={() => {
                                  //   ViewFullscreen(
                                  //     message?.replyingToMessage?.content?.split(
                                  //       ","
                                  //     ),
                                  //     message?.replyingToMessage?.caption,
                                  //     index
                                  //   );
                                  // }}
                                >
                                  <img
                                    style={{
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                      objectFit: "contain",
                                      backgroundColor: "white",
                                      borderRadius: "6px",
                                    }}
                                    src={item}
                                    alt="Message"
                                  />
                                </div>
                              </>
                            );
                          }
                        })}
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    maxWidth: "254px",
                    overflowWrap: "break-word",
                    marginLeft: "4px",
                  }}
                >
                  {message?.replyingToMessage?.caption}
                </div>
              </div>
            </div>
            <div
              onClick={(e) => {
                setPopupOpen((prev) => {
                  if (prev.isActive) {
                    if (prev.Id === (message.Id ?? message.id)) {
                      return { isActive: false, Id: 0 };
                    } else {
                      return {
                        isActive: true,
                        Id: message.Id ?? message.id,
                      };
                    }
                  } else {
                    return {
                      isActive: true,
                      Id: message.Id ?? message.id,
                    };
                  }
                });
                e.stopPropagation();
              }}
              style={{ width: "15px", height: "10px", cursor: "pointer" }}
            >
              <img
                className="DropdownArrowChatDesign"
                style={
                  popupOpen.isActive &&
                  (message.Id ?? message.id) === popupOpen.Id
                    ? { display: "block" }
                    : {}
                }
                src={
                  messageClass === "sent"
                    ? DropdownArrowChatMesssageWhite
                    : DropdownArrowChatMesssage
                }
                alt=""
              />
            </div>
            <OutsideClickHandler
              key={message.id ?? message.Id}
              id={message.id ?? message.Id}
              onOutsideClick={(id) =>
                setPopupOpen((prev) => {
                  if (prev.isActive) {
                    if (prev.Id === id) {
                      return { isActive: false, Id: 0 };
                    } else {
                      return prev;
                    }
                  } else {
                    return prev;
                  }
                })
              }
            >
              <div
                className="chatActionsPopup"
                style={
                  popupOpen.isActive &&
                  (message.Id ?? message.id) === popupOpen.Id
                    ? {
                        display: "block",
                        position: "absolute",
                        zIndex: "6",
                        right: "-50px",
                        top: "26px",
                        color: "black",
                      }
                    : { display: "none" }
                }
              >
                <div
                  style={{ borderRadius: "6px 6px 0px 0px" }}
                  onClick={(e) => {
                    setReplyData({
                      replyId: popupOpen?.Id,
                      replyContent: message.content,
                      data: message,
                    });
                    setPopupOpen({ isActive: false, Id: 0 });
                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                    e.stopPropagation();
                  }}
                >
                  Reply
                </div>
                <div>Info</div>
                <div
                  onClick={(e) => {
                    setForwardData({
                      isActive: true,
                      msgId: [message.id],
                      userId: [],
                      conversationId: [message?.conversationId],
                    });
                    setPopupOpen({ isActive: false, Id: 0 });
                    e.stopPropagation();
                  }}
                >
                  Forward Message
                </div>
                <div
                  onClick={() => {
                    toggleBookMark();
                    setPopupOpen({ isActive: false, Id: 0 });
                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                  }}
                >
                  Star
                </div>
                <div
                  style={{ borderRadius: "0px 0px 6px 6px" }}
                  onClick={() => {
                    deleteMessage(
                      message.conversationId,
                      LoggedInUser?.personNumber,
                      [message.id]
                    );
                    setPopupOpen({ isActive: false, Id: 0 });

                    setForwardData({
                      isActive: false,
                      msgId: [],
                      userId: [],
                      conversationId: [],
                    });
                  }}
                >
                  Delete Message
                </div>
                {/* {messageClass === "sent" ? ( 
      

        
      ) : (
        <>
          <div
            style={{ borderRadius: "6px 6px 0px 0px" }}
            onClick={() => {
              setReplyData({
                replyId: popupOpen?.Id,
                replyContent: message.content,
                data: message,
              });
              setPopupOpen({ isActive: false, Id: 0 });
            }}
          >
            Reply
          </div>
          <div>Forward</div>
          <div>Star</div>
          <div style={{ borderRadius: "0px 0px 6px 6px" }}>
            Delete
          </div>
        </>
      )}
       */}
              </div>
            </OutsideClickHandler>
            {message?.content?.split(",")?.length - 4 > 0 && (
              <div
                className={
                  messageClass === "sent"
                    ? "mediaCoverChatBundledAbsolute"
                    : "mediaCoverChatBundledAbsoluteRecieve"
                }
              >
                <div
                  className={
                    messageClass === "sent"
                      ? "mediaCoverChatBundledAbsoluteTop"
                      : "mediaCoverChatBundledAbsoluteTopRecieve"
                  }
                ></div>
                <div
                  style={
                    messageClass === "sent"
                      ? { backgroundColor: "#1296b0", color: "white" }
                      : { backgroundColor: "white", color: "#1296b0" }
                  }
                  className={
                    messageClass === "sent"
                      ? "mediaCoverChatBundledAbsoluteCenter"
                      : "mediaCoverChatBundledAbsoluteCenterRecieve"
                  }
                >
                  +{" "}
                  {message?.replyingToMessage?.content?.split(",")?.length - 4}
                </div>
                <div
                  className={
                    messageClass === "sent"
                      ? "mediaCoverChatBundledAbsoluteBottom"
                      : "mediaCoverChatBundledAbsoluteBottomRecieve"
                  }
                ></div>
              </div>
            )}
          </div>
          <div
            style={
              message?.content?.length > 65
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                  }
            }
          >
            <div
              style={
                message?.content?.length > 65
                  ? {
                      maxWidth: "427px",
                      width: "100%",
                      color: "white",
                      overflowWrap: "break-word",
                    }
                  : {
                      maxWidth: "427px",
                      width: "calc(100% - 50px)",
                      color: "white",
                    }
              }
            >
              {message?.content}
            </div>

            <div
              style={
                message?.content?.length > 65
                  ? {
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }
                  : {
                      display: "flex",
                      justifyContent: "flex-end",
                    }
              }
            >
              <div
                style={{
                  display: "flex",
                  textAlign: "end",
                  width: "50px",
                  alignItems: "flex-end",
                  justifyContent: "flex-end", // backgroundColor: "blueviolet",
                }}
              >
                {message?.hasBookmarked === true && (
                  <img
                    src={messageClass === "sent" ? starWhite : starYellow}
                    style={{ margin: "0px 5px 0px 5px" }}
                    alt="bookmark"
                  />
                )}
                <img src={doubleTickGreen} alt="read" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="message-content "
          style={
            messageClass === "sent"
              ? {
                  display: "flex",
                  borderRadius: "6px 0px 6px 6px",
                  position: "relative",
                }
              : {
                  display: "flex",
                  borderRadius: "0px 6px 6px 6px",
                  position: "relative",
                }
          }
        >
          <div
            style={{
              width: "98%",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            {!isBundled && (
              <p
                id={`messageContent${message.id}`}
                style={{
                  overflowWrap: "break-word",
                  width: "312px",
                  // height: "229px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  margin: "0px  -13px 4px -2px",
                }}
              >
                {message.content?.split(",")?.map((item) => {
                  if (
                    item?.includes("mp4") ||
                    item?.includes("webm") ||
                    item?.includes("ogv")
                  ) {
                    return (
                      <>
                        <div className="mediaCoverChat">
                          <span
                            style={{
                              position: "relative",
                              maxHeight: "100%",
                              maxWidth: "100%",
                            }}
                          >
                            <video
                              style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "contain",
                              }}
                              src={item}
                            ></video>
                            <img
                              style={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                              }}
                              src={playButtonSvg}
                              alt=""
                            />
                          </span>
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div className="mediaCoverChat">
                          <img
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              objectFit: "contain",
                              backgroundColor: "white",
                            }}
                            src={item}
                            alt="Message"
                          />
                        </div>
                      </>
                    );
                  }
                })}
              </p>
            )}
            {isBundled && (
              <p
                id={`messageContent${message.id}`}
                style={
                  message.content?.split(",")?.length > 2
                    ? {
                        overflowWrap: "break-word",
                        width: "312px",
                        height: "229px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        margin: "0px  -13px 4px -2px",
                      }
                    : {
                        overflowWrap: "break-word",
                        width: "312px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        margin: "0px  -13px 4px -2px",
                      }
                }
              >
                {message.content?.split(",")?.length > 2
                  ? message.content
                      ?.split(",")
                      ?.slice(0, 4)
                      ?.map((item, index) => {
                        if (
                          item?.includes("mp4") ||
                          item?.includes("webm") ||
                          item?.includes("ogv")
                        ) {
                          return (
                            <>
                              <div
                                className="mediaCoverChatBundled cursor"
                                onClick={() => {
                                  ViewFullscreen(
                                    message.content?.split(","),
                                    message.caption,
                                    index
                                  );
                                }}
                              >
                                <span
                                  style={{
                                    position: "relative",
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <video
                                    style={{
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                      objectFit: "contain",
                                      borderRadius: "6px",
                                    }}
                                    src={item}
                                  ></video>
                                  <img
                                    style={{
                                      position: "absolute",
                                      left: "50%",
                                      top: "50%",
                                      transform: "translate(-50%, -50%)",
                                    }}
                                    src={playButtonSvg}
                                    alt=""
                                  />
                                </span>
                              </div>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <div
                                className="mediaCoverChatBundled cursor"
                                onClick={() => {
                                  ViewFullscreen(
                                    message.content?.split(","),
                                    message.caption,
                                    index
                                  );
                                }}
                              >
                                <img
                                  style={{
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                    backgroundColor: "white",
                                    borderRadius: "6px",
                                  }}
                                  src={item}
                                  alt="Message"
                                />
                              </div>
                            </>
                          );
                        }
                      })
                  : message.content
                      ?.split(",")
                      ?.slice(0, 4)
                      ?.map((item, index) => {
                        if (
                          item?.includes("mp4") ||
                          item?.includes("webm") ||
                          item?.includes("ogv")
                        ) {
                          return (
                            <>
                              <div
                                className="mediaCoverChat cursor"
                                onClick={() => {
                                  ViewFullscreen(
                                    message.content?.split(","),
                                    message.caption,
                                    index
                                  );
                                }}
                              >
                                <span
                                  style={{
                                    position: "relative",
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                  }}
                                >
                                  <video
                                    style={{
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                      objectFit: "contain",
                                      borderRadius: "6px",
                                    }}
                                    src={item}
                                  ></video>
                                  <img
                                    style={{
                                      position: "absolute",
                                      left: "50%",
                                      top: "50%",
                                      transform: "translate(-50%, -50%)",
                                    }}
                                    src={playButtonSvg}
                                    alt=""
                                  />
                                </span>
                              </div>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <div
                                className="mediaCoverChat cursor"
                                onClick={() => {
                                  ViewFullscreen(
                                    message.content?.split(","),
                                    message.caption,
                                    index
                                  );
                                }}
                              >
                                <img
                                  style={{
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    objectFit: "contain",
                                    backgroundColor: "white",
                                    borderRadius: "6px",
                                  }}
                                  src={item}
                                  alt="Message"
                                />
                              </div>
                            </>
                          );
                        }
                      })}
              </p>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  maxWidth: "254px",
                  overflowWrap: "break-word",
                  marginLeft: "4px",
                }}
              >
                {message.caption}
              </div>
              <div
                style={{
                  display: "flex",
                  textAlign: "end",
                  width: "40px",
                  height: "20px",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                {message?.hasBookmarked === true && (
                  <img
                    src={messageClass === "sent" ? starWhite : starYellow}
                    style={{ margin: "0px 5px 0px 5px" }}
                    alt="bookmark"
                  />
                )}
                <img src={doubleTickGreen} alt="read" />
              </div>
            </div>
          </div>
          <div
            onClick={(e) => {
              setPopupOpen((prev) => {
                if (prev.isActive) {
                  if (prev.Id === (message.Id ?? message.id)) {
                    return { isActive: false, Id: 0 };
                  } else {
                    return {
                      isActive: true,
                      Id: message.Id ?? message.id,
                    };
                  }
                } else {
                  return {
                    isActive: true,
                    Id: message.Id ?? message.id,
                  };
                }
              });
              e.stopPropagation();
            }}
            style={{ width: "15px", height: "10px", cursor: "pointer" }}
          >
            <img
              className="DropdownArrowChatDesign"
              style={
                popupOpen.isActive &&
                (message.Id ?? message.id) === popupOpen.Id
                  ? { display: "block" }
                  : {}
              }
              src={
                messageClass === "sent"
                  ? DropdownArrowChatMesssageWhite
                  : DropdownArrowChatMesssage
              }
              alt=""
            />
          </div>
          <OutsideClickHandler
            key={message.id ?? message.Id}
            id={message.id ?? message.Id}
            onOutsideClick={(id) =>
              setPopupOpen((prev) => {
                if (prev.isActive) {
                  if (prev.Id === id) {
                    return { isActive: false, Id: 0 };
                  } else {
                    return prev;
                  }
                } else {
                  return prev;
                }
              })
            }
          >
            <div
              className="chatActionsPopup"
              style={
                popupOpen.isActive &&
                (message.Id ?? message.id) === popupOpen.Id
                  ? {
                      display: "block",
                      position: "absolute",
                      zIndex: "6",
                      right: "-50px",
                      top: "26px",
                      color: "black",
                    }
                  : { display: "none" }
              }
            >
              <div
                style={{ borderRadius: "6px 6px 0px 0px" }}
                onClick={(e) => {
                  setReplyData({
                    replyId: popupOpen?.Id,
                    replyContent: message.content,
                    data: message,
                  });
                  setPopupOpen({ isActive: false, Id: 0 });
                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                  e.stopPropagation();
                }}
              >
                Reply
              </div>
              <div>Info</div>
              <div
                onClick={(e) => {
                  setForwardData({
                    isActive: true,
                    msgId: [message.id],
                    userId: [],
                    conversationId: [message?.conversationId],
                  });
                  setPopupOpen({ isActive: false, Id: 0 });
                  e.stopPropagation();
                }}
              >
                Forward Message
              </div>
              <div
                onClick={() => {
                  toggleBookMark();
                  setPopupOpen({ isActive: false, Id: 0 });
                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                }}
              >
                Star
              </div>
              <div
                style={{ borderRadius: "0px 0px 6px 6px" }}
                onClick={() => {
                  deleteMessage(
                    message.conversationId,
                    LoggedInUser?.personNumber,
                    [message.id]
                  );
                  setPopupOpen({ isActive: false, Id: 0 });

                  setForwardData({
                    isActive: false,
                    msgId: [],
                    userId: [],
                    conversationId: [],
                  });
                }}
              >
                Delete Message
              </div>
              {/* {messageClass === "sent" ? ( 
        

          
        ) : (
          <>
            <div
              style={{ borderRadius: "6px 6px 0px 0px" }}
              onClick={() => {
                setReplyData({
                  replyId: popupOpen?.Id,
                  replyContent: message.content,
                  data: message,
                });
                setPopupOpen({ isActive: false, Id: 0 });
              }}
            >
              Reply
            </div>
            <div>Forward</div>
            <div>Star</div>
            <div style={{ borderRadius: "0px 0px 6px 6px" }}>
              Delete
            </div>
          </>
        )}
         */}
            </div>
          </OutsideClickHandler>
          {message?.content?.split(",")?.length - 4 > 0 && (
            <div
              className={
                messageClass === "sent"
                  ? "mediaCoverChatBundledAbsolute"
                  : "mediaCoverChatBundledAbsoluteRecieve"
              }
            >
              <div
                className={
                  messageClass === "sent"
                    ? "mediaCoverChatBundledAbsoluteTop"
                    : "mediaCoverChatBundledAbsoluteTopRecieve"
                }
              ></div>
              <div
                style={
                  messageClass === "sent"
                    ? { backgroundColor: "#1296b0", color: "white" }
                    : { backgroundColor: "white", color: "#1296b0" }
                }
                className={
                  messageClass === "sent"
                    ? "mediaCoverChatBundledAbsoluteCenter"
                    : "mediaCoverChatBundledAbsoluteCenterRecieve"
                }
              >
                + {message.content?.split(",")?.length - 4}
              </div>
              <div
                className={
                  messageClass === "sent"
                    ? "mediaCoverChatBundledAbsoluteBottom"
                    : "mediaCoverChatBundledAbsoluteBottomRecieve"
                }
              ></div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PhotosAndVideos;
