import React, { useState } from 'react';

function TabbedInterface({setActiveTab,activeTab}) {
   

    const handleClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="tab-container">
            <button
                className={`tab-button ${activeTab === 'chat' ? 'active' : ''}`}
                onClick={() => handleClick('chat')}
            >
                Chat
            </button>
            <button
                className={`tab-button ${activeTab === 'updates' ? 'active' : ''}`}
                onClick={() => handleClick('updates')}
            >
                Updates
            </button>
            <button
                className={`tab-button ${activeTab === 'calls' ? 'active' : ''}`}
                onClick={() => handleClick('calls')}
            >
                Calls
            </button>

        </div>
    );
}

export default TabbedInterface;
