import React from "react";
import SurvayMainDisplayList from "./SurvayMainDisplayList";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSurveyData } from "../../../../../../../Features/SocialSlice";

function PollPage({ reRefresh, setReRefreshProp }) {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(setSurveyData([]));
  }, []);
  return (
    <div className="surveyPageContainer">
      <div className="surveyPageWrapper">
        <SurvayMainDisplayList type="poll" reRefresh={reRefresh} setReRefreshProp={setReRefreshProp} />
      </div>
    </div>
  );
}

export default PollPage;
