import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";

function DeductionCompany() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deduction, setDeduction] = useState([]);
  // const keys = Object.keys(deduction);
  // const values = keys.map(key => deduction[key]);

  const getDeduction = async () => {
    try {
      const result = await getRequest(
        "PayrollDashboard/CompanyDashboard/Deduction/CList"
      );
      setDeduction(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getDeduction();
  }, []);

  return (
    <div>
      <div
        style={{
          width: "calc(100% - 24px)",
          boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
          borderRadius: "6px",
          margin: "12px",
        }}
      >
 <div
            style={{
              width: "100%",
              height: "fit-content",
              boxShadow: "0px 1.89445px 10px rgba(0, 0, 0, 0.04)",
              borderRadius: "6px",
              marginTop: "12px",
            }}
          >
            <div className="headingContainer">
              <h5>Deduction Summary {2024}</h5>
            </div>
            <div
              style={{
                padding: "12px",
                display: "flex",
                gap: "12px",
                overflow: "auto",
                height: "calc(100% - 50px)",
              }}
            >
              <div className="payrollSummaryContainer tdsSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>TDS</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction?.tds}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer esicSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>ESIC</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction.esic}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer insuranceSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>PT</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction.pt}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer epfSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>EPF</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction?.epf}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer gratuitySummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>
                    Gratuity
                  </p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction?.gratuity}
                  </h5>
                </div>
              </div>
              <div className="payrollSummaryContainer loanSummary">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.91876 9.86592C9.27136 9.86592 10.3679 8.80058 10.3679 7.48643C10.3679 6.17227 9.27136 5.10693 7.91876 5.10693C6.56616 5.10693 5.46967 6.17227 5.46967 7.48643C5.46967 8.80058 6.56616 9.86592 7.91876 9.86592Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.9187 1.14111C6.1866 1.14111 4.52543 1.80964 3.30065 2.99961C2.07586 4.18959 1.38779 5.80354 1.38779 7.48643C1.38779 8.98709 1.71597 9.96903 2.61233 11.0557L7.9187 17.0044L13.2251 11.0557C14.1214 9.96903 14.4496 8.98709 14.4496 7.48643C14.4496 5.80354 13.7615 4.18959 12.5368 2.99961C11.312 1.80964 9.65081 1.14111 7.9187 1.14111V1.14111Z"
                    stroke="#8A88ED"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  <p style={{ fontWeight: "500", lineheight: "14px" }}>Loan</p>
                  <h5 style={{ color: "var(--primary-text)" }}>
                    {deduction?.loan ?? "-"}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        <div
          className="table-container"
          style={{
            width: "100%",
            padding: "12px",
            display: "flex",
            overflow: "auto",
            gap: "12px",
          }}
        >
          <table className="custom-table">
            <thead className="table-row">
              <tr>
                <th>Month</th>
                <th>Total Deduction</th>
                <th>ESIC</th>
                <th>TDS</th>
                <th>Gratuity</th>
                <th>LWF</th>
                <th>EPF</th>
                <th>Loan</th>
                <th>PT</th>
              </tr>
            </thead>
            <tbody>
              {deduction?.deductions?.map((row) => {
                console.log("row", row);
                return (
                  <tr key={row.id} className="table-row">
                    <td>{row.monthPayout ?? "-"}</td>
                    <td>{row.totalDeduction ?? "-"}</td>
                    <td>{row.esi ?? "-"}</td>
                    <td>{row.tds ?? "-"}</td>
                    <td>{row.gratuity ?? "-"}</td>
                    <td>{row.lwf ?? "-"}</td>
                    <td>{row.epf ?? "-"}</td>
                    <td>{row.loan ?? "-"}</td>
                    <td>{row.pt ?? "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DeductionCompany;
