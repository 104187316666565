import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CreateClaimTypeComp } from "../FormComp/CreateClaimTypeComp";

const CreateClaimType = (props) => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState({});
  const [firstTimeRender, setFirstTimeRender] = useState(false);
  const data = state?.dataEdit?.id;

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `PeopleSol - Create Expense Type`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = React.useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [categoryGlobalArray, setCategoryGlobalArray] = useState([]);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("ExpenseTypes/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getDetails = async () => {
    try {
      const result = await getRequest(`ExpenseTypes/Details/${data}`);
      setDetail(result.data.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);

  const Edit = async (values) => {
    try {
      const result = await postRequest("ExpenseTypes/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const handleModeChange = async (index, value) => {
    try {
      const result = await getRequest(
        `TravelAdvanceEligibilities/Getcategoryinconfig?mode=${value}`
      );
      const categories = result.data?.results?.map((item) => ({
        label: item.text,
        value: item.id,
      }));
      setCategoryArray((prevArray) => {
        const updatedArray = [...prevArray];
        updatedArray[index] = categories;
        return updatedArray;
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occurred in fetching categories",
          severity: "error",
        })
      );
    }
  };
  const handleModeChangeEdit = async (index, value) => {
    try {
      const result = await getRequest(
        `TravelAdvanceEligibilities/Getcategoryinconfig?mode=${value}`
      );
      const categories = result.data?.results?.map((item) => ({
        label: item.text,
        value: item.id,
      }));

      setCategoryArray((prevArray) => {
        const updatedArray = [...prevArray];
        updatedArray[index] = categories;
        return updatedArray;
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occurred in fetching categories",
          severity: "error",
        })
      );
    }
  };

  const getInitalTravel = () => {
    let returnArray = [];
    if (data) {
      detail?._expenseTypes?.expensetypesmodes?.map((item, index) => {
        !firstTimeRender && handleModeChangeEdit(index, item?.mode);
        return returnArray.push({
          mode: item?.mode,
          category:
            typeof item?.categories === "string"
              ? item?.categories.split(",").map((item) => item)
              : item?.categories,
        });
      });
      if (
        !firstTimeRender &&
        detail?._expenseTypes?.expensetypesmodes?.length > 0
      ) {
        setFirstTimeRender(true);
      }
    } else
      returnArray = [
        {
          mode: "",
          category: "",
        },
      ];

    return returnArray;
  };

  const initialvalue = {
    perdayEligibilty: data ? detail?._expenseTypes?.title : "",
    traveladvanceeligibilitiesdetails: getInitalTravel(),
  };
  const validationSchema = Yup.object({
    perdayEligibilty: Yup.string().required("Please Enter Title "),
    traveladvanceeligibilitiesdetails: Yup.array().of(
      Yup.object().shape({
        category: Yup.array()
          .min(1, `Please Select Atleast One Category `)
          .required("Please Select Category"),
        mode: Yup.string().required("Please Select Mode"),
      })
    ),
  });

  const onSubmit = (values) => {
    const newStructure = values?.traveladvanceeligibilitiesdetails?.map(
      (item, index) => ({
        categories:
          typeof item?.category === "string"
            ? item?.category
            : item?.category.join(","),
        mode: item?.mode,
      })
    );
    const edit = {
      Title: values?.perdayEligibilty?.toString(),
      Expensetypesmodes: newStructure,

      Id: data,
    };

    const create = {
      Title: values?.perdayEligibilty?.toString(),
      Expensetypesmodes: newStructure,
      id: 0,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      styles={{ width: "80%", maxWidth: "80%" }}
      chooseFormValues={chooseFormValues}
      FormHeading={
        data ? "Edit Expense Type Request" : "Create Expense Type Request"
      }
      ButtonLabel={
        data ? "Edit Expense Type Request" : "Create Expense Type Request"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateClaimTypeComp
          handleModeChange={handleModeChange}
          formValues={formValues}
          data={data ? true : false}
          categoryArray={categoryArray}
          setCategoryArray={setCategoryArray}
        />
      }
    />
  );
};

export { CreateClaimType };
