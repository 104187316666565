import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateTravelEligibilityComp } from "../FormComp/CreateTravelEligibilityComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CreateTravelCategoryComp } from "../FormComp/CreateTravelCategoryComp";

const CreateTravelCategory = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const [detail, setDetail] = React.useState([]);
  const [formValues, setFormValues] = useState({});
  const getInitalTravel = () => {
    let returnArray = [];
    data
      ? detail?.categoriesdetails?.map((item, index) => {
          return returnArray.push({
            Id: item?.id,
            category: item?.category,
            budget: item?.budget,
            type: item?.type,
          });
        })
      : (returnArray = [
          {
            category: "",
            budget: "",
            type: "",
          },
        ]);

    return returnArray;
  };
  const [initialValue, setInitialValue] = useState({
    mode: data ? detail?.travelCategories?.mode : "",
    TravellingCategoriesDetails: getInitalTravel(),
  });
  useEffect(() => {
    setInitialValue({
      mode: data ? detail?.travelCategories?.mode : "",
      TravellingCategoriesDetails: getInitalTravel(),
    });
  }, [detail]);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = `PeopleSol - Create Travel Eligibility`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const [loading, setLoading] = useState(true);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("TravelCategories/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getDetails = async () => {
    try {
      const result = await getRequest(`TravelCategories/Details?ID=${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);

  const Edit = async (values) => {
    try {
      const result = await postRequest("TravelCategories/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const validationSchema = Yup.object({
    mode: Yup.string().required("Please Select Mode"),
    TravellingCategoriesDetails: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required("Please Enter Category"),
        budget: Yup.string().required("Please Enter Budget"),
        type: Yup.string().required("Please Enter Type"),
      })
    ),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
      id: 0,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      styles={{ width: "70%", maxWidth: "70%" }}
      chooseFormValues={chooseFormValues}
      FormHeading={
        data
          ? "Edit Travel Category Eligibility"
          : "Create Travel Category Eligibility"
      }
      ButtonLabel={
        data
          ? "Edit Travel Category Eligibility"
          : "Create Travel Category Eligibility"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialValue}
      returnPage={returnPage}
      propsDiv={
        <CreateTravelCategoryComp
          initialValue={initialValue}
          setInitialValue={setInitialValue}
          formValues={formValues}
          setFormValues={setFormValues}
          data={data}
        />
      }
    />
  );
};

export { CreateTravelCategory };
