import React, { useState } from "react";
import RadioButtonComponent from "./RadioButtonComponent";
import PollRadioButtonComponent from "./PollRadioButtonComponent";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSurveyData } from "../../../../../../../Features/SocialSlice";

function PollQuestionAnswerContainer({
  questionData,
  setSelectedData,
  selectedData,
}) {
  const { surveyData } = useSelector((state) => state.social);
  const [selected, setSelected] = useState([]);
  const [selectedQuestionsId, setSelectedQuestionsId] = useState([]);
  const [previousAnsId, setPreviousAnsId] = useState([]);
  const dispatch = useDispatch();
  const calculatePercentage = (id, ansData, queData) => {
    if (!previousAnsId.includes(ansData?.id)) {
      setPreviousAnsId([...previousAnsId, ansData?.id]);

      let count = 0;
      let x = queData?.answerOption?.map((a, i) => {
        if (ansData?.id === a?.id) {
          count = count + a?.respondCount + 1;
          return { ...a, respondCount: a?.respondCount + 1 };
        }
        count = count + a?.respondCount;
        return a;
      });

      let newData = surveyData.map((d) => {
        if (d?.id === id) {
          let newQue = d?.questions?.map((q) => {
            if (q?.id === queData?.id) {
              let newAns = q?.answerOption?.map((a) => {
                if (a?.id === ansData?.id) {
                  return {
                    ...a,
                    respondCount: a?.respondCount + 1,
                    respondPercentage: ((a.respondCount + 1) / count) * 100,
                  };
                } else {
                  return {
                    ...a,
                    respondPercentage: (a.respondCount / count) * 100,
                  };
                }
              });
              if (q.allowMultipleAnswer) {
                return { ...q, answerOption: newAns };
              } else {
                return {
                  ...q,
                  answerOption: newAns,
                  respondAnswer: [
                    ...q.respondAnswer,
                    { id: 0, answerId: ansData?.id },
                  ],
                };
              }
            } else {
              return q;
            }
          });
          return { ...d, questions: newQue };
        } else {
          return d;
        }
      });

      dispatch(setSurveyData(newData));
    }
  };
  const selectOptions = (ansData, queData) => {
    if (selectedQuestionsId?.includes(queData?.id)) {
      if (!queData?.allowMultipleAnswer) {
        if (selected.includes(ansData?.id)) {
          let x = selected?.filter((dt) => dt !== ansData?.id);
          setSelected(x);
          let y = selectedData?.filter((dtt) => dtt?.AnswerId !== ansData?.id);
          setSelectedData(y);
          let z = selectedQuestionsId?.filter((dttt) => dttt !== queData?.id);
          setSelectedQuestionsId(z);
        } else {
          let x = selected?.filter((dt) => {
            return !queData?.answerOption?.some((ansd) => ansd?.id === dt);
          });
          setSelected(x);
          let y = selectedData?.filter(
            (dtt) => dtt?.QuestionId !== queData?.id
          );
          setSelectedData(y);
          setSelected([...x, ansData?.id]);
          setSelectedData([
            ...y,
            {
              Id: 0,
              SurveyId: questionData?.id,
              QuestionId: queData?.id,
              AnswerId: ansData?.id,
            },
          ]);
        }
      } else {
        setSelected((prev) => [...prev, ansData?.id]);
        setSelectedData((prev) => [
          ...prev,
          {
            Id: 0,
            SurveyId: questionData?.id,
            QuestionId: queData?.id,
            AnswerId: ansData?.id,
          },
        ]);
      }
    } else {
      setSelected((prev) => [...prev, ansData?.id]);
      setSelectedQuestionsId((prev) => [...prev, queData?.id]);
      setSelectedData((prev) => [
        ...prev,
        {
          Id: 0,
          SurveyId: questionData?.id,
          QuestionId: queData?.id,
          AnswerId: ansData?.id,
        },
      ]);
    }
  };

  const findSelectedOptions = (id, respondAnswer) => {
    return (
      respondAnswer?.some((element) => {
        return element?.answerId === id;
      }) || false
    );
  };

  return (
    <div className="questionAnswerContainer">
      <div className="questionAnswerWrapper">
        {questionData?.questions?.map((queData, queDataIndex) => {
          return (
            <div className="questionAnswerList" key={queDataIndex}>
              {queData?.allowMultipleAnswer && (
                <div className="surveyNote">
                  <h5>Note</h5>
                  <p>Multiple Selection Allowed.</p>
                </div>
              )}
              <label>{queData?.questionText}</label>
              {queData?.allowMultipleAnswer ? (
                <PollRadioButtonComponent
                  calculatePercentage={calculatePercentage}
                  findSelectedOptions={findSelectedOptions}
                  queData={queData}
                  selectOptions={selectOptions}
                  selected={selected}
                  questionData={questionData}
                />
              ) : (
                <PollRadioButtonComponent
                  calculatePercentage={calculatePercentage}
                  findSelectedOptions={findSelectedOptions}
                  queData={queData}
                  selectOptions={selectOptions}
                  selected={selected}
                  id={"xcvbh"}
                  questionData={questionData}
                />
              )}
              {questionData?.questions?.length - 1 > queDataIndex && (
                <div className="surevyQuestionSeperator"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PollQuestionAnswerContainer;
