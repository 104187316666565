import * as Yup from "yup";

export const serviceTypeSchemaIt = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Name"),
  categoryId: Yup.string().required("Please Select Service Category"),
  requestCategoryId: Yup.string().required("Please Select Request Category"),
  assetRequestType: Yup.string().when("requestCategoryId", {
    is: (val) => val == 3,
    then: () =>
      Yup.string().required("Please Select Asset Request Type").nullable(),
  }),
  teamId: Yup.string().required("Please Select the Team"),
  tatHigh: Yup.string().required("Please Enter Tat High"),
  tatMedium: Yup.string().required("Please Enter Tat Medium"),
  tatLow: Yup.string().required("Please Enter Tat Low"),
});
export const reqCategorySchemaIt = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Name"),
});
export const teammemberSchemaIT = Yup.object({
  ticketTypeId: Yup.string().required("Please Select Service Type"),
  teamId: Yup.string().required("Please Select Helpdesk Team"),
});
export const workflowSchemaIT = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Name"),
  ticketTypeId: Yup.string().required("Please Select Service Type"),
  requestCategoryId: Yup.string().required("Please Select Request Category"),
  items: Yup.array().of(
    Yup.object().shape({
      Name: Yup.string()
        .max(149, " Name must be less than 150 characters")
        .required("Workflow required"),

      actionType: Yup.string().required("Action Type Required"),

      emailViewers: Yup.string().when("actionType", {
        is: (val) => val === "Send an Email",
        then: () => Yup.string().required("Email required"),
      }),
      approver: Yup.string().when("actionType", {
        is: (val) => val === "Initiate an Approval",
        then: () => Yup.string().required("required").nullable(),
      }),
      ifApproved: Yup.string().when("actionType", {
        is: (val) => val === "Initiate an Approval",
        then: () => Yup.string().required("required").nullable(),
      }),
      ifRejected: Yup.string().when("actionType", {
        is: (val) => val === "Initiate an Approval",
        then: () => Yup.string().required("required").nullable(),
      }),
      tat: Yup.string()
        .nullable()
        .when("actionType", {
          is: (val) => val === "Initiate an Approval",
          then: () => Yup.string().required("required").nullable(),
        }),
    })
  ),
});
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const servicevendors = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Name"),
  emailAddresses: Yup.string()
    .max(319, "Email must be less than 320 characters")
    .required("Please Enter Email"),
  phone: Yup.string()
    .min(5)
    .max(15, "Phone Number must be less than 10 characters")
    .matches(phoneRegExp, "Phone number is not valid"),
  tatHigh: Yup.string().required("Please Enter TAT High"),
  tatMedium: Yup.string().required("Please Enter TAT Medium"),
  tatLow: Yup.string().required("Please Enter TAT Low"),
  emailCc: Yup.string()
    .max(319, "Email must be less than 320 characters")
    .nullable(),
});
export const teamsSchemaIT = Yup.object({
  name: Yup.string()
    .max(49, " Name must be less than 50 characters")
    .required(" Please Enter Name"),
  itsmHelpdeskTeammembers: Yup.array().of(
    Yup.object().shape({
      employeeId: Yup.string().required("Please Select Name"),
      level: Yup.number()
        .min(1)
        .max(999999, "Level Must be less than 7 digits")
        .required("Please Enter Level"),
      locationIds: Yup.array()
        .min(1, `Please Select Location `)
        .required(" required"),
    })
  ),
});
