import React, { useEffect, useState } from "react";
import DropdownArrowChatMesssage from "../../../Assets/DropdownArrowChatMesssage.svg";
import pdfDocIcon from "../../../Assets/pdfDocIcon.svg";
import pdfDocIconRecieve from "../../../Assets/pdfDocIconRecieve.svg";
import starWhite from "../../../Assets/starWhite.svg";
import starYellow from "../../../Assets/starYellow.svg";
import doubleTickGreen from "../../../Assets/doubleTickGreen.svg";
import DropdownArrowChatMesssageWhite from "../../../Assets/DropdownArrowChatMesssageWhite.svg";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import PDFComponent from "./PDFComponent";
import { downloadBlobChat } from "./chatServices";
import { scrollFunc } from "../ChatInterface";

const DocumentsChat = ({
  message,
  messageClass,
  setPopupOpen,
  popupOpen,
  setReplyData,
  setForwardData,
  toggleBookMark,
  deleteMessage,
  LoggedInUser,
  messageType,
}) => {
  const [numPages, setNumPages] = useState();
  const [base64BLobImg, setBase64BLobImg] = useState("");
  return (
    <>
      {message?.replyingToMessageId ? (
        <div
          style={
            messageClass === "sent"
              ? {
                  backgroundColor: "var(--primary)",
                  borderRadius: "6px 0px 6px 6px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }
              : {
                  backgroundColor: "var(--primary)",
                  borderRadius: "0px 6px 6px 6px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }
          }
        >
          <div
            className="cursor"
            onClick={(e) => {
              scrollFunc(message?.replyingToMessage?.id);
              e.stopPropagation();
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              width: "100%",
            }}
          >
            {message?.replyingToMessage?.content
              ?.split(",")
              ?.map((item, index) => {
                return (
                  <div
                    className="message-content cursor unselectable"
                    style={
                      index === 0
                        ? messageClass === "sent"
                          ? {
                              display: "flex",
                              padding: "8px",
                              borderRadius: "6px 0px 6px 6px",
                              position: "relative",
                              maxWidth: "100%",

                              margin: "0px 0px 7px 0px",
                              backgroundColor: "#0E788D",
                            }
                          : {
                              display: "flex",
                              padding: "8px",

                              borderRadius: "0px 6px 6px 6px",
                              position: "relative",
                              maxWidth: "100%",
                              margin: "0px 0px 7px 0px",
                            }
                        : messageClass === "sent"
                        ? {
                            display: "flex",
                            padding: "8px",
                            borderRadius: "6px 6px 6px 6px",
                            position: "relative",
                            maxWidth: "100%",

                            margin: "0px 0px 7px 0px",
                            backgroundColor: "#0E788D",
                          }
                        : {
                            display: "flex",
                            padding: "8px",

                            borderRadius: "6px 6px 6px 6px",
                            position: "relative",
                            maxWidth: "100%",
                            margin: "0px 0px 7px 0px",
                          }
                    }
                  >
                    {" "}
                    <div
                      style={
                        index === 0
                          ? messageClass === "sent"
                            ? {
                                backgroundColor: "#6ABCCC",
                                height: "6px",
                                position: "absolute",
                                width: "100%",
                                left: "0px",
                                top: "0px",
                                borderRadius: "6px 0px 0px 0px",
                              }
                            : {
                                backgroundColor: "#6ABCCC",
                                height: "6px",
                                position: "absolute",
                                width: "100%",
                                left: "0px",
                                top: "0px",
                                borderRadius: "0px 6px 0px 0px",
                              }
                          : messageClass === "sent"
                          ? {
                              backgroundColor: "#6ABCCC",
                              height: "6px",
                              position: "absolute",
                              width: "100%",
                              left: "0px",
                              top: "0px",
                              borderRadius: "6px 6px 0px 0px",
                            }
                          : {
                              backgroundColor: "#6ABCCC",
                              height: "6px",
                              position: "absolute",
                              width: "100%",
                              left: "0px",
                              top: "0px",
                              borderRadius: "6px 6px 0px 0px",
                            }
                      }
                    ></div>
                    <div
                      style={{
                        width: "98%",
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "-14px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "7px",
                          margin: "6px 0px 0px 0px",
                          justifyContent: "space-between",
                          width: "286px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              style={{ height: "30px" }}
                              src={
                                messageClass === "sent"
                                  ? pdfDocIcon
                                  : pdfDocIconRecieve
                              }
                              alt=""
                            />
                          </div>
                          <div>
                            <div>{item.split("/").pop()}</div>
                            {item?.slice(-3) === "pdf" ? (
                              <div>
                                {numPages} Page .{" "}
                                {item?.slice(-3)?.toUpperCase()}
                              </div>
                            ) : item?.slice(-3) === "jpg" ||
                              item?.slice(-3) === "png" ||
                              item?.slice(-3) === "svg" ? (
                              <div>{item?.slice(-3)}</div>
                            ) : item?.slice(-4) === "jpeg" ||
                              item?.slice(-4) === "xlsx" ||
                              item?.slice(-4) === "docx" ? (
                              <div>{item?.slice(-4)?.toUpperCase()}</div>
                            ) : (
                              <div> File</div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <p
              id={`messageContent${message.id}`}
              style={{ width: "100%", overflowWrap: "break-word" }}
            >
              {item?.slice(-3) === "pdf" && (
                <>
                  <div className="mediaCoverChat">
                    <span
                      style={{
                        position: "relative",
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    >
                      <img src="" alt="" />
                    </span>
                  </div>
                </>
              )}
            </p> */}
                      {item?.slice(-3) === "pdf" && (
                        <>
                          <div
                            className="mediaCoverChat"
                            style={{
                              justifyContent: "start",
                              alignItems: "start",
                              width: " 286px",
                              height: "152px",
                              margin: "10px 0px 0 0",
                            }}
                          >
                            <div>
                              <PDFComponent
                                imageUrlProp={item}
                                setNumPages={setNumPages}
                                setBase64BLobImg={setBase64BLobImg}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {index === 0 && (
                      <>
                        <div
                          onClick={(e) => {
                            setPopupOpen((prev) => {
                              if (prev.isActive) {
                                if (prev.Id === (message.Id ?? message.id)) {
                                  return { isActive: false, Id: 0 };
                                } else {
                                  return {
                                    isActive: true,
                                    Id: message.Id ?? message.id,
                                  };
                                }
                              } else {
                                return {
                                  isActive: true,
                                  Id: message.Id ?? message.id,
                                };
                              }
                            });
                            e.stopPropagation();
                          }}
                          style={{
                            width: "15px",
                            height: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            className="DropdownArrowChatDesign"
                            style={
                              popupOpen.isActive &&
                              (message.Id ?? message.id) === popupOpen.Id
                                ? { display: "block", margin: "7px 0 0 0" }
                                : { margin: "7px 0 0 0" }
                            }
                            src={
                              messageClass === "sent"
                                ? DropdownArrowChatMesssageWhite
                                : DropdownArrowChatMesssage
                            }
                            alt=""
                          />
                        </div>
                        <OutsideClickHandler
                          key={message.id ?? message.Id}
                          id={message.id ?? message.Id}
                          onOutsideClick={(id) =>
                            setPopupOpen((prev) => {
                              if (prev.isActive) {
                                if (prev.Id === id) {
                                  return { isActive: false, Id: 0 };
                                } else {
                                  return prev;
                                }
                              } else {
                                return prev;
                              }
                            })
                          }
                        >
                          <div
                            className="chatActionsPopup"
                            style={
                              popupOpen.isActive &&
                              (message.Id ?? message.id) === popupOpen.Id
                                ? {
                                    display: "block",
                                    position: "absolute",
                                    zIndex: "6",
                                    right: "-50px",
                                    top: "26px",
                                    color: "black",
                                  }
                                : { display: "none" }
                            }
                          >
                            <div
                              style={{ borderRadius: "6px 6px 0px 0px" }}
                              onClick={(e) => {
                                setReplyData({
                                  replyId: popupOpen?.Id,
                                  replyContent: message.content,
                                  data: message,
                                });
                                setPopupOpen({ isActive: false, Id: 0 });
                                setForwardData({
                                  isActive: false,
                                  msgId: [],
                                  userId: [],
                                  conversationId: [],
                                });
                                e.stopPropagation();
                              }}
                            >
                              Reply
                            </div>
                            <div>Info</div>
                            <div
                              onClick={(e) => {
                                setForwardData({
                                  isActive: true,
                                  msgId: [message.id],
                                  userId: [],
                                  conversationId: [message?.conversationId],
                                });
                                setPopupOpen({ isActive: false, Id: 0 });
                                e.stopPropagation();
                              }}
                            >
                              Forward Message
                            </div>
                            <div
                              onClick={() => {
                                toggleBookMark();
                                setPopupOpen({ isActive: false, Id: 0 });
                                setForwardData({
                                  isActive: false,
                                  msgId: [],
                                  userId: [],
                                  conversationId: [],
                                });
                              }}
                            >
                              Star
                            </div>
                            <div
                              style={{ borderRadius: "0px 0px 6px 6px" }}
                              onClick={() => {
                                deleteMessage(
                                  message.conversationId,
                                  LoggedInUser?.personNumber,
                                  [message.id]
                                );
                                setPopupOpen({ isActive: false, Id: 0 });

                                setForwardData({
                                  isActive: false,
                                  msgId: [],
                                  userId: [],
                                  conversationId: [],
                                });
                              }}
                            >
                              Delete Message
                            </div>
                            {/* {messageClass === "sent" ? ( 
        

          
        ) : (
          <>
            <div
              style={{ borderRadius: "6px 6px 0px 0px" }}
              onClick={() => {
                setReplyData({
                  replyId: popupOpen?.Id,
                  replyContent: message.content,
                  data: message,
                });
                setPopupOpen({ isActive: false, Id: 0 });
              }}
            >
              Reply
            </div>
            <div>Forward</div>
            <div>Star</div>
            <div style={{ borderRadius: "0px 0px 6px 6px" }}>
              Delete
            </div>
          </>
        )}
         */}
                          </div>
                        </OutsideClickHandler>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
          <div
            style={
              message?.content?.length > 65
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%",
                  }
            }
          >
            <div
              style={
                message?.content?.length > 65
                  ? {
                      maxWidth: "427px",
                      width: "100%",
                      color: "white",
                      overflowWrap: "break-word",
                    }
                  : {
                      maxWidth: "427px",
                      width: "calc(100% - 50px)",
                      color: "white",
                    }
              }
            >
              {message?.content}
            </div>

            <div
              style={
                message?.content?.length > 65
                  ? {
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }
                  : {
                      display: "flex",
                      justifyContent: "flex-end",
                    }
              }
            >
              <div
                style={{
                  display: "flex",
                  textAlign: "end",
                  width: "50px",
                  alignItems: "flex-end",
                  justifyContent: "flex-end", // backgroundColor: "blueviolet",
                }}
              >
                {message?.hasBookmarked === true && (
                  <img
                    src={messageClass === "sent" ? starWhite : starYellow}
                    style={{ margin: "0px 5px 0px 5px" }}
                    alt="bookmark"
                  />
                )}
                <img src={doubleTickGreen} alt="read" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {message?.content?.split(",")?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  if (item?.slice(-3) === "pdf") {
                    downloadBlobChat(
                      base64BLobImg,
                      "pdf",
                      item,
                      item.split("/").pop()
                    );
                  } else if (item?.slice(-4) === "docx") {
                    downloadBlobChat(
                      base64BLobImg,
                      "docx",
                      item,
                      item.split("/").pop()
                    );
                  } else if (item?.slice(-4) === "xlsx") {
                    downloadBlobChat(
                      base64BLobImg,
                      "xlsx",
                      item,
                      item.split("/").pop()
                    );
                  } else if (item?.slice(-3) === "png") {
                    downloadBlobChat(
                      base64BLobImg,
                      "png",
                      item,
                      item.split("/").pop()
                    );
                  } else if (item?.slice(-3) === "jpg") {
                    downloadBlobChat(
                      base64BLobImg,
                      "jpg",
                      item,
                      item.split("/").pop()
                    );
                  } else if (item?.slice(-4) === "jpeg") {
                    downloadBlobChat(
                      base64BLobImg,
                      "jpeg",
                      item,
                      item.split("/").pop()
                    );
                  }
                }}
                className="message-content cursor unselectable"
                style={
                  index === 0
                    ? messageClass === "sent"
                      ? {
                          display: "flex",
                          padding: "8px",
                          borderRadius: "6px 0px 6px 6px",
                          position: "relative",
                          maxWidth: "100%",
                          margin: "0px 0px 7px 0px",
                        }
                      : {
                          display: "flex",
                          padding: "8px",
                          borderRadius: "0px 6px 6px 6px",
                          position: "relative",
                          maxWidth: "100%",
                          margin: "0px 0px 7px 0px",
                        }
                    : messageClass === "sent"
                    ? {
                        display: "flex",
                        padding: "8px",
                        borderRadius: "6px 6px 6px 6px",
                        position: "relative",
                        maxWidth: "100%",
                        margin: "0px 0px 7px 0px",
                      }
                    : {
                        display: "flex",
                        padding: "8px",
                        borderRadius: "6px 6px 6px 6px",
                        position: "relative",
                        maxWidth: "100%",
                        margin: "0px 0px 7px 0px",
                      }
                }
              >
                {" "}
                <div
                  style={
                    index === 0
                      ? messageClass === "sent"
                        ? {
                            backgroundColor: "#6ABCCC",
                            height: "6px",
                            position: "absolute",
                            width: "100%",
                            left: "0px",
                            top: "0px",
                            borderRadius: "6px 0px 0px 0px",
                          }
                        : {
                            backgroundColor: "#6ABCCC",
                            height: "6px",
                            position: "absolute",
                            width: "100%",
                            left: "0px",
                            top: "0px",
                            borderRadius: "0px 6px 0px 0px",
                          }
                      : messageClass === "sent"
                      ? {
                          backgroundColor: "#6ABCCC",
                          height: "6px",
                          position: "absolute",
                          width: "100%",
                          left: "0px",
                          top: "0px",
                          borderRadius: "6px 6px 0px 0px",
                        }
                      : {
                          backgroundColor: "#6ABCCC",
                          height: "6px",
                          position: "absolute",
                          width: "100%",
                          left: "0px",
                          top: "0px",
                          borderRadius: "6px 6px 0px 0px",
                        }
                  }
                ></div>
                <div
                  style={{
                    width: "98%",
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "-14px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      paddingLeft: "7px",
                      margin: "6px 0px 0px 0px",
                      justifyContent: "space-between",
                      width: "286px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          style={{ height: "30px" }}
                          src={
                            messageClass === "sent"
                              ? pdfDocIcon
                              : pdfDocIconRecieve
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <div>{item.split("/").pop()}</div>
                        {item?.slice(-3) === "pdf" ? (
                          <div>
                            {numPages} Page . {item?.slice(-3)?.toUpperCase()}
                          </div>
                        ) : item?.slice(-3) === "jpg" ||
                          item?.slice(-3) === "png" ||
                          item?.slice(-3) === "svg" ? (
                          <div>{item?.slice(-3)}</div>
                        ) : item?.slice(-4) === "jpeg" ||
                          item?.slice(-4) === "xlsx" ||
                          item?.slice(-4) === "docx" ? (
                          <div>{item?.slice(-4)?.toUpperCase()}</div>
                        ) : (
                          <div> File</div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          textAlign: "end",
                          width: "50px",
                          alignItems: "flex-end",
                          justifyContent: "flex-end", // backgroundColor: "blueviolet",
                        }}
                      >
                        {message?.hasBookmarked === true && (
                          <img
                            src={
                              messageClass === "sent" ? starWhite : starYellow
                            }
                            style={{ margin: "0px 5px 0px 5px" }}
                            alt="bookmark"
                          />
                        )}
                        <img src={doubleTickGreen} alt="read" />
                      </div>
                    </div>
                  </div>
                  {/* <p
                id={`messageContent${message.id}`}
                style={{ width: "100%", overflowWrap: "break-word" }}
              >
                {item?.slice(-3) === "pdf" && (
                  <>
                    <div className="mediaCoverChat">
                      <span
                        style={{
                          position: "relative",
                          maxHeight: "100%",
                          maxWidth: "100%",
                        }}
                      >
                        <img src="" alt="" />
                      </span>
                    </div>
                  </>
                )}
              </p> */}
                  {item?.slice(-3) === "pdf" && (
                    <>
                      <div
                        className="mediaCoverChat"
                        style={{
                          justifyContent: "start",
                          alignItems: "start",
                          width: " 286px",
                          height: "152px",
                          margin: "10px 0px 0 0",
                        }}
                      >
                        <div>
                          <PDFComponent
                            imageUrlProp={item}
                            setNumPages={setNumPages}
                            setBase64BLobImg={setBase64BLobImg}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {index === 0 && (
                  <>
                    <div
                      onClick={(e) => {
                        setPopupOpen((prev) => {
                          if (prev.isActive) {
                            if (prev.Id === (message.Id ?? message.id)) {
                              return { isActive: false, Id: 0 };
                            } else {
                              return {
                                isActive: true,
                                Id: message.Id ?? message.id,
                              };
                            }
                          } else {
                            return {
                              isActive: true,
                              Id: message.Id ?? message.id,
                            };
                          }
                        });
                        e.stopPropagation();
                      }}
                      style={{
                        width: "15px",
                        height: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        className="DropdownArrowChatDesign"
                        style={
                          popupOpen.isActive &&
                          (message.Id ?? message.id) === popupOpen.Id
                            ? { display: "block", margin: "7px 0 0 0" }
                            : { margin: "7px 0 0 0" }
                        }
                        src={
                          messageClass === "sent"
                            ? DropdownArrowChatMesssageWhite
                            : DropdownArrowChatMesssage
                        }
                        alt=""
                      />
                    </div>
                    <OutsideClickHandler
                      key={message.id ?? message.Id}
                      id={message.id ?? message.Id}
                      onOutsideClick={(id) =>
                        setPopupOpen((prev) => {
                          if (prev.isActive) {
                            if (prev.Id === id) {
                              return { isActive: false, Id: 0 };
                            } else {
                              return prev;
                            }
                          } else {
                            return prev;
                          }
                        })
                      }
                    >
                      <div
                        className="chatActionsPopup"
                        style={
                          popupOpen.isActive &&
                          (message.Id ?? message.id) === popupOpen.Id
                            ? {
                                display: "block",
                                position: "absolute",
                                zIndex: "6",
                                right: "-50px",
                                top: "26px",
                                color: "black",
                              }
                            : { display: "none" }
                        }
                      >
                        <div
                          style={{ borderRadius: "6px 6px 0px 0px" }}
                          onClick={(e) => {
                            setReplyData({
                              replyId: popupOpen?.Id,
                              replyContent: message.content,
                              data: message,
                            });
                            setPopupOpen({ isActive: false, Id: 0 });
                            setForwardData({
                              isActive: false,
                              msgId: [],
                              userId: [],
                              conversationId: [],
                            });
                            e.stopPropagation();
                          }}
                        >
                          Reply
                        </div>
                        <div>Info</div>
                        <div
                          onClick={(e) => {
                            setForwardData({
                              isActive: true,
                              msgId: [message.id],
                              userId: [],
                              conversationId: [message?.conversationId],
                            });
                            setPopupOpen({ isActive: false, Id: 0 });
                            e.stopPropagation();
                          }}
                        >
                          Forward Message
                        </div>
                        <div
                          onClick={() => {
                            toggleBookMark();
                            setPopupOpen({ isActive: false, Id: 0 });
                            setForwardData({
                              isActive: false,
                              msgId: [],
                              userId: [],
                              conversationId: [],
                            });
                          }}
                        >
                          Star
                        </div>
                        <div
                          style={{ borderRadius: "0px 0px 6px 6px" }}
                          onClick={() => {
                            deleteMessage(
                              message.conversationId,
                              LoggedInUser?.personNumber,
                              [message.id]
                            );
                            setPopupOpen({ isActive: false, Id: 0 });

                            setForwardData({
                              isActive: false,
                              msgId: [],
                              userId: [],
                              conversationId: [],
                            });
                          }}
                        >
                          Delete Message
                        </div>
                        {/* {messageClass === "sent" ? ( 
          
  
            
          ) : (
            <>
              <div
                style={{ borderRadius: "6px 6px 0px 0px" }}
                onClick={() => {
                  setReplyData({
                    replyId: popupOpen?.Id,
                    replyContent: message.content,
                    data: message,
                  });
                  setPopupOpen({ isActive: false, Id: 0 });
                }}
              >
                Reply
              </div>
              <div>Forward</div>
              <div>Star</div>
              <div style={{ borderRadius: "0px 0px 6px 6px" }}>
                Delete
              </div>
            </>
          )}
           */}
                      </div>
                    </OutsideClickHandler>
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DocumentsChat;
