import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import EditPencilIcon from "../../../Assets/EditPencilIcon.svg";
import EPFCover from "../../../Assets/EPFCover.png";
import { IconButton } from "@mui/material";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { Form, Formik } from "formik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { CheckboxFormiks } from "../../../Components/FormComponent/CheckboxFormiks";
import { Loader } from "../../../Components/FormComponent/Loader";
const ESICTab = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - ESIC `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [ePFData, setEPFData] = useState({});
  const getEmployeeData = async () => {
    try {
      setLoading(true);
      dispatch(isLoading(true));
      const result = await getRequest("StatutoryCompliance/DetailsESI");
      if (result.data) {
        if (result.data.isEnable) setEnabled(true);
        setEPFData(result.data);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const disableEPF = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `StatutoryCompliance/DisableESI/${ePFData.id}`
      );
      if (result.data) {
        setEnabled(false);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("StatutoryCompliance/EditeESI", values);
      getEmployeeData();
      setEditActive(false);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);
  const submitEditCompany = (values) => {
    const editData = {
      id: values.id,
      esiNumber: values.esiNumber,
      deductionCycle: values.deductionCycle,
      employeesContributionPercentage: values.employeesContributionPercentage,
      employerContributionPercentage: values.employerContributionPercentage,
      employerContributionInCTC: values.employerContributionInCTC,
    };
    createPageData(editData);
  };
  return (
    <div className="fullTableContainer centerContent" style={{height:'calc(100% - 105px)',width:'100%',}}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {editActive ? (
            <div
              className={`companyCard ${editActive && "editActiveBackground"}`}
            >
              <div className="companyCardContainer" style={{height:'100%',}}>
                <div className="headingContainer">
                  <h3>Employees' State Insurance</h3>
                  <IconButton
                    onClick={() => setEditActive((prev) => !prev)}
                    className={`${editActive && "pencilBackground"}`}
                  >
                    <img
                      src={EditPencilIcon}
                      alt="Edit Pencil"
                      className="editPencilIcon"
                    />
                  </IconButton>
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={ePFData}
                  onSubmit={submitEditCompany}
                >
                  <Form style={{    height: 'calc(100% - 120px)'}}>
                    <div style={{ display: "flex", height: '100%',overflow:'auto' }} >
                      <div style={{ width: "60%" }}>
                        <div className="formcustom">
                          <div>
                            <LabelCustom labelName={"ESI Number"} />
                            <InputCustomFormik
                              name="esiNumber"
                              placeholder="AA/AAA/0000000/000"
                            />
                          </div>
                          <div>
                            <LabelCustom labelName={"Deduction Cycle"} />
                            <InputCustomFormik
                              name="deductionCycle"
                              disabled={true}
                            />
                          </div>
                        </div>
                        <div>
                          <LabelCustom
                            labelName={
                              "Employees' Contribution (% of Gross Pay)"
                            }
                          />
                          <InputCustomFormik
                            name="employeesContributionPercentage"
                            disabled={true}
                          />
                        </div>
                        <div>
                          <LabelCustom
                            labelName={
                              "Employer's Contribution (% of Gross Pay)"
                            }
                          />
                          <InputCustomFormik
                            name="employerContributionPercentage"
                            disabled={true}
                          />
                        </div>
                        <div>
                          <LabelCustom labelName={"CTC Inclusions"} />
                          <div>
                            <CheckboxFormiks
                              name="employerContributionInCTC"
                              labelName="Include employer's contribution in the CTC"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.02)",
                          backgroundColor: "white",
                          borderRadius: "4px",
                          width: "40%",
                          padding: "12px",
                          margin: "24px 12px 12px 12px",
                          fontSize: "12px",
                          lineHeight: "22px",
                          height: "fit-content",
                        }}
                      >
                        Note: ESI deductions will be made only if the employee’s
                        monthly salary is less than or equal to ₹21,000. If the
                        employee gets a salary revision which increases their
                        monthly salary above ₹21,000, they would have to
                        continue making ESI contributions till the end of the
                        contribution period in which the salary was revised
                        (April-September or October-March).
                      </div>
                      {editActive && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            position: "absolute",
                            width: "calc(100% - 24px)",
                            bottom: "12px",
                            borderTop: "1px solid var(--secondary-text)",
                            margin: "0 12px",
                          }}
                        >
                          <button
                            className="button secondaryButton"
                            type="button"
                            onClick={() => setEditActive((prev) => !prev)}
                          >
                            Cancel
                          </button>
                          <button
                            className="button primaryButton"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          ) : enabled ? (
            <div className="companyCard">
              <div className="companyCardContainer">
                <div className="headingContainer">
                  <h3>Employees' State Insurance</h3>
                  <div style={{ display: "flex" ,alignItems:'center'}}>
                    <button
                      className="disableContainer button"
                      onClick={disableEPF}
                    >
                      <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.500244"
                          y="1.12598"
                          width="18"
                          height="18"
                          rx="2"
                          stroke="#1296B0"
                        />
                        <circle
                          cx="9.50021"
                          cy="10.1259"
                          r="5.65573"
                          fill="#F71D1D"
                        />
                        <line
                          x1="12.4458"
                          y1="9.9978"
                          x2="6.55432"
                          y2="9.9978"
                          stroke="white"
                          stroke-linecap="round"
                        />
                      </svg>
                      <label>Disable ESI</label>
                    </button>
                    <IconButton
                      onClick={() => setEditActive(true)}
                      className={`${editActive && ""}`}
                    >
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.15" d="M0.128906 4.6377C0.128906 2.42856 1.91977 0.637695 4.12891 0.637695H16.1289C18.338 0.637695 20.1289 2.42856 20.1289 4.6377V16.6377C20.1289 18.8468 18.338 20.6377 16.1289 20.6377H4.12891C1.91977 20.6377 0.128906 18.8468 0.128906 16.6377V4.6377Z" fill="#FB7158"/>
<line x1="10.5057" y1="15.2377" x2="14.57" y2="15.2377" stroke="#FB7158" stroke-width="0.8" stroke-linecap="round"/>
<path d="M10.443 7.95224L5.28809 13.0727V15.1174H7.36522L12.4619 9.98814M10.443 7.95224L11.7637 6.64034C12.3217 6.08605 13.223 6.08756 13.7791 6.64372V6.64372C14.3353 7.19994 14.3368 8.10131 13.7823 8.6593L12.4619 9.98814M10.443 7.95224L12.4619 9.98814" stroke="#FB7158" stroke-width="0.8"/>
</svg>

                    </IconButton>
                  </div>
                </div>
                <div className="dataTableRowContainer">
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">ESI Number</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData.esiNumber}
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">Deduction Cycle</label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">
                      {ePFData.deductionCycle}
                    </label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">
                      Employees' Contribution Rate
                    </label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">{`${ePFData.employeesContributionPercentage}% of Gross Pay`}</label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">
                      Employer's Contribution Rate
                    </label>
                    <label className="dataTableRowCell">:</label>
                    <label className="dataTableRowCell">{`${ePFData.employerContributionPercentage}% of Gross Pay`}</label>
                  </div>
                  <div className="dataTableRow">
                    <label className="dataTableRowCell">CTC Inclusions</label>
                    <label className="dataTableRowCell">:</label>
                    <div className="dataTableRowCell">
                      <div className="dataTableRow">
                        <label
                          style={
                            ePFData.employerContributionInCTC
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {ePFData.employerContributionInCTC ? "✔" : "✖"}
                        </label>
                        <label>
                          {ePFData.employerContributionInCTC
                            ? "Employer's contribution is included in the CTC."
                            : "Employer's contribution is not included in the CTC."}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                maxWidth: "50%",
                gap: "12px",
              }}
            >
              <img src={EPFCover} alt="EPF Cover" />
              <h5>Are you registered for ESI?</h5>
              <p>
                Organizations having 10 or more employees must register for
                Employee State Insurance (ESI). This scheme provides cash
                allowances and medical benefits for employees whose monthly
                salary is less than ₹21,000.
              </p>
              <button
                className="primaryButton button"
                onClick={() => setEditActive(true)}
              >
                Enable ESI
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export { ESICTab };
