import React, { useEffect, useRef, useState } from "react";
import { ChatMessage } from "./Components/ChatMessage";
import { useSelector } from "react-redux";

export const scrollFunc = (replyId) => {
  const element = document.getElementById("content" + replyId);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
    const targetDiv = element.querySelector(".message-content");
    if (targetDiv) {
      targetDiv.style.position = "relative";
      targetDiv.classList.add("translucent-overlay");
      setTimeout(() => {
        targetDiv.classList.remove("translucent-overlay");
      }, 1100);
    }
  }
};
const ChatInterface = ({
  deleteMessage,
  setReplyData,
  LoggedInUser,
  setForwardData,
  forwardData,
  replyData,
  togglePopup,
  handleContactClick,
  ViewFullscreen,
  activeChatId,
  scrollToBottom,
}) => {
  const sortedMessageList = useSelector(
    (state) => state.chat.activeMessageList
  );

  // useEffect(() => {
  //   // setSortedMessageList(...sortedMessageList, ...newMessages);
  //   if (messageResponse?.conversationId) {
  //     console.log("object messageResponse", {
  //       messages: [{ ...messageResponse }],
  //     });
  //     setSortedMessageList((prev) => [
  //       ...prev,
  //       { messages: [{ ...messageResponse }] },
  //     ]);
  //   }
  //   if (newMessages?.conversationId) {
  //     console.log("object newMessages", {
  //       messages: [{ ...newMessages }],
  //     });
  //     setSortedMessageList((prev) => [
  //       ...prev,
  //       { messages: [{ ...newMessages }] },
  //     ]);
  //   }
  // }, [newMessages, messageResponse]);

  //   const scrollFuunc = () => {
  //     const element = document.getElementById("content" + replyData?.replyId);
  //     element.scrollIntoView();
  //   };
  const prevActiveChatId = useRef(null);

  useEffect(() => {
    if (prevActiveChatId.current === null && sortedMessageList.length > 0) {
      scrollToBottom();
      prevActiveChatId.current = activeChatId;
    } else if (
      prevActiveChatId.current !== activeChatId &&
      sortedMessageList.length > 0
    ) {
      scrollToBottom();
      prevActiveChatId.current = activeChatId;
    }
  }, [sortedMessageList, activeChatId]);

  return (
    <>
      {sortedMessageList?.map((item, index) => {
        let header = null;

        return (
          <div key={item?.messages[0]?.id}>
            {header && (
              <div className="message-date-header">
                <div>{header}</div>
              </div>
            )}
            <div
              id={`content${item?.messages[0]?.id}`}
              style={
                forwardData?.isActive &&
                forwardData?.msgId?.includes(item?.messages[0].id)
                  ? {
                      padding: "0px 12px",
                      backgroundColor: "rgb(0 0 0 / 5%)",
                    }
                  : {
                      padding: "0px 12px",
                    }
              }
            >
              <ChatMessage
                ViewFullscreen={ViewFullscreen}
                deleteMessage={deleteMessage}
                handleContactClick={handleContactClick}
                togglePopup={togglePopup}
                replyData={replyData}
                forwardData={forwardData}
                setForwardData={setForwardData}
                LoggedInUser={LoggedInUser}
                setReplyData={setReplyData}
                key={item?.messages[0]?.id}
                message={item?.messages[0]}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ChatInterface;
